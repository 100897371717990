import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../api.service';
import {Output, EventEmitter, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {Router, ParamMap} from '@angular/router';

@Component({
    selector: 'app-maschere',
    templateUrl: './maschere.component.html',
    styleUrls: ['./maschere.component.css']
})
export class MaschereComponent implements OnInit {
    @Output() searchModelChange: EventEmitter<any> = new EventEmitter();
    searchModelMaschere: string;
    id = this.activatedRoute.snapshot.paramMap.get('id');
    displayedColumns = ['nome', 'descrizione'];
    schema: [];
    Maschere: [];
    showSystemMasks = false;
    dataSource: [];
    dashColor: string;
    backColor: string;
    squareColor: string;
    cardColor: string;

    // tslint:disable-next-line:max-line-length variable-name
    constructor(private route: ActivatedRoute, private router: Router, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, public apiService: ApiService, private activatedRoute: ActivatedRoute) {
        iconRegistry.addSvgIcon(
            'CondividiIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/share-24px.svg'));
        iconRegistry.addSvgIcon(
            'ModificaIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/create-24px.svg'));
        iconRegistry.addSvgIcon(
            'ClonaIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/content_copy-24px.svg'));
        iconRegistry.addSvgIcon(
            'PermessiiIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/lock-24px.svg'));
        iconRegistry.addSvgIcon(
            'EliminaIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/delete-24px.svg'));


    }

    ngOnInit(): void {
        this.apiService.namePath = this.apiService.namePath.split('-')[0];
        this.dashColor = this.apiService.dashColor;
        this.backColor = this.apiService.backColor;
        this.squareColor = this.apiService.squareColor;
        this.cardColor = this.apiService.cardColor;

        // tslint:disable-next-line:triple-equals
        this.apiService.getAllMasks().subscribe(data => {
            this.Maschere = data;

        });
    }

    updateSearchModelMaschere(value): void {
        this.searchModelMaschere = value;
        this.searchModelChange.emit(this.searchModelMaschere);
    }

    clickMaschera(idMask: string): void {
        this.router.navigate(['../maskDetails', {id: idMask, idTask: 0, path: 'maschere'}], {relativeTo: this.route});
    }

    goBack(): void {
        this.router.navigate(['../maschere'], {relativeTo: this.route});
    }
}
