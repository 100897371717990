<div style="display: flex" class="bigbox">
  <div style="width: 100%" class="tree">

    <dx-tree-view *ngIf="this.openId"
                  #treeview
                  style="text-align: left !important;"
                  [rootValue]="this.apiService.parentFolderId"
                  [searchEnabled]="true"
                  dataStructure="plain"
                  searchMode="contains"
                  displayExpr="fullPath"
                  [createChildren]="createChildren"
                  hasItemsExpr="hasChilds"
                  [searchEditorOptions]="{placeholder: this.translate.instant('menu.search') + '...'}"
                  (onItemClick)="folderClick($event)"
                  itemTemplate="itemTemplate"
                  (onInitialized)="saveViewInstance($event)"

    >
      <div *dxTemplate="let itemObj of 'itemTemplate'">
        {{itemObj.name}}
        <button style="float: right; margin-top: -10px" mat-icon-button
                [matMenuTriggerFor]="afterMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #afterMenu="matMenu" xPosition="after">
          <div style="text-align: center;"><h3 style="margin-bottom: 0px;">
            <b>{{itemObj.name}}</b></h3></div>
          <hr>
          <button mat-menu-item (click)="this.openAddFolderDialog(itemObj.id)">
            <mat-icon>add</mat-icon>
            <span>{{'labels.newDossier' | translate}}</span></button>

          <button mat-menu-item (click)="openInfoDialog(itemObj.id, itemObj.authorCompleteName, itemObj.name)">
            <mat-icon>info</mat-icon>
            <span>{{'labels.info' | translate}}</span></button>
        </mat-menu>
      </div>
    </dx-tree-view>
    <dx-tree-view *ngIf="!this.openId"
                  #treeview
                  style="text-align: left !important;"
                  [rootValue]="0"
                  [searchEnabled]="true"
                  dataStructure="plain"
                  searchMode="contains"
                  displayExpr="fullPath"
                  [createChildren]="createChildren"
                  hasItemsExpr="hasChilds"
                  [searchEditorOptions]="{placeholder: this.translate.instant('menu.search') + '...'}"

                  itemTemplate="itemTemplate"

    >
      <div *dxTemplate="let itemObj of 'itemTemplate'" style = "display: flex">
        <div (click)="folderClick(itemObj)" style = "width: 85%">{{itemObj.name}}</div>
        <div style = "width: 15%">
          <button title="{{itemObj.name}}" style="float: right; margin-top: -10px" mat-icon-button
                  [matMenuTriggerFor]="afterMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #afterMenu="matMenu" xPosition="after">
            <div style="text-align: center;"><h3 style="margin-bottom: 0px;">
              <b>{{itemObj.name}}</b></h3></div>
            <hr>
            <button mat-menu-item (click)="this.openAddFolderDialog(itemObj.id)">
              <mat-icon>add</mat-icon>
              <span>{{'labels.newDossier' | translate}}</span></button>
            <!--<button mat-menu-item (click) = "this.openEditFolderDialog(itemObj.id, itemObj.name)">
                <mat-icon>create</mat-icon>
                <span>Rinomina</span></button>-->

            <button mat-menu-item (click)="openInfoDialog(itemObj.id, itemObj.authorCompleteName, itemObj.name)">
              <mat-icon>info</mat-icon>
              <span>{{'labels.info' | translate}}</span></button>
            <!--<button mat-menu-item>
                <mat-icon>lock</mat-icon>
                <span>Permessi</span></button>-->
            <!-- <button mat-menu-item>
                 <mat-icon>share</mat-icon>
                 <span>Condividi</span></button>-->
            <!--<button mat-menu-item>
                <mat-icon>integration_instructions</mat-icon>
                <span>Modalità di profilazione</span></button>-->
            <!--<button mat-menu-item>
                <mat-icon>backup</mat-icon>
                <span>Sincronizzazione con ARXdrive</span></button>-->
            <!--<button mat-menu-item>
                <mat-icon>update</mat-icon>
                <span>Aggiorna elenco</span></button>-->

          </mat-menu>
        </div>
      </div>
    </dx-tree-view>
    ​<!--(onItemClick)="onItemClick($event)"-->
  </div>


</div>
