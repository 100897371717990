<mat-toolbar  style="background: {{this.apiService.dashColor}}; color:#fff;">
  <mat-form-field style="padding: 10px;width: 23%; text-align: left; margin-right: 3%">
    <mat-label>{{'labels.filter' | translate}}</mat-label>
    <input matInput [(ngModel)]="this.filter" (ngModelChange)="this.filterChange()">
    <button mat-button matSuffix mat-icon-button title="{{'labels.filter' | translate}}"
            style="color: {{this.apiService.dashColor}}"
            (click)="openFilterDialog()">
      <mat-icon>filter_list</mat-icon>
    </button>
    <button mat-button *ngIf="this.filter" style="color: {{this.apiService.dashColor}}" matSuffix mat-icon-button
            title="{{'labels.cancel' | translate}}" (click)="this.filter=''; this.filterChange()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field appearance="standard" style="padding: 10px;width: 23%; text-align: left">
    <mat-label>{{'labels.order' | translate}}</mat-label>
    <mat-select [(ngModel)]="this.orderBy" name="{{'labels.order' | translate}}"
                (selectionChange)="this.sortTask(this.orderBy, true, this.orderDirection)">
      <mat-option [value]="this.translate.instant('labels.startDate')">
        {{'labels.startDate' | translate}}
      </mat-option>
    </mat-select>

  </mat-form-field>
  <button mat-button matSuffix mat-icon-button title="{{'labels.descending' | translate}}"
          style="color: {{this.apiService.dashColor}}"
          *ngIf="this.orderDirection === 'desc'" (click)="this.sortTask(this.orderBy, true, 'asc')">
    <mat-icon>arrow_downward</mat-icon>
  </button>
  <button mat-button matSuffix mat-icon-button title="{{'labels.ascending' | translate}}"
          style="color: {{this.apiService.dashColor}}"
          (click)="this.sortTask(this.orderBy, true, 'desc')"
          *ngIf="this.orderDirection === 'asc'">
    <mat-icon>arrow_upward</mat-icon>
  </button>
  <mat-form-field appearance="standard" style="padding: 10px;width: 23%; text-align: left">
    <mat-label>{{'labels.changeGrouping' | translate}}</mat-label>
    <mat-select [(value)]="this.groupBy" (selectionChange)="this.refreshTasks()">
      <mat-option value="DM_WORKFLOW_NOME">{{'labels.workFlowName' | translate}}</mat-option>
      <mat-option value="DM_PROFILE_STATO">{{'labels.state' | translate}}</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-icon-button title="{{'labels.update' | translate}}" (click)="this.refreshTasks()"
          style="float: right; color: #fff; width: 5%;">
    <mat-icon>autorenew</mat-icon>
  </button>
  <button mat-flat-button style="color:{{this.apiService.dashColor}}; margin:1%; overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;" class="align-left-buttons"
          title="{{'buttons.newDoc' | translate}}"
          (click)="maschereClick()">
    <mat-icon svgIcon="MaschereIcon" style="margin-right: 8px">tab</mat-icon>
    <span class="spanlabel" style="margin: auto !important;">{{'buttons.newDoc' | translate}}</span>
  </button>
  <button mat-flat-button style="color:{{this.apiService.dashColor}}; margin:1%; overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;"
          class="align-left-buttons-child" title="{{'labels.search' | translate}}"
          (click)="ricercaClick(this.translate.instant('labels.search'))">
    <mat-icon svgIcon="RicercaIcon" style="margin-right: 8px">search</mat-icon>
    <span class="spanlabel" style="margin: auto !important;">{{'buttons.searchDocuments' | translate}}</span>
  </button>
</mat-toolbar>

<div class="center" style = "height: 82%;">
  <mat-card class="cardcomponentMasks" id="style-4"
            style="overflow: hidden">
    <div>

      <mat-card-title style="color:{{this.apiService.dashColor}};float:left; text-transform:uppercase; font-size:16px;width: 10%;">
        <p style="float: left; margin-left: 10%;">Task
          # {{this.nTask}} </p>
      </mat-card-title>


      <hr class="riga" style="color:{{this.apiService.dashColor}}; width: 90%; float: right;">
      <br>
    </div>
    <cdk-virtual-scroll-viewport *ngIf="nTask == 0" style="margin-left: 3%; margin-right: 3%; padding-bottom: 70%;"
                                 id="style-4"
                                 itemSize="6"
                                 class="cdk">
      <h1 style="text-align: center; color: {{this.apiService.dashColor}}">{{'labels.noTaskFound' | translate}}</h1>
    </cdk-virtual-scroll-viewport>

    <div style="width: 100%; overflow: auto; height: 100%" *ngIf="true">
      <ng-container class="cdkcontains">
        <ng-container *ngFor="let group of this.groups">

          <mat-expansion-panel style="margin:0.3%;box-shadow:1px 1px {{this.apiService.dashColor}}" [expanded] = "true">
            <mat-expansion-panel-header>
              <mat-panel-title style="color: {{this.apiService.dashColor}};">
                <b>{{group}} ({{this.countGroupItems(group)}})</b>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngFor="let task of this.taskList">
              <ng-container
                *ngIf="task.defaultFields.get(this.groupBy) == group || (task.defaultFields.get(this.groupBy) == null && group === this.translate.instant('labels.noDocument'))">
                <mat-card #tasks [multiple]="false" *ngIf="task.disabledForFilter == false && task.disabledForPriority == false &&
                                                task.disabledForExpired == false && task.disabledForRead == false" style="text-align: left; margin: 1%;" >
                  <ng-container>
                    <mat-icon mat-list-icon *ngIf="task.defaultFields.get('LETTO') == 'S'"
                              style="color: {{this.getColor(task.defaultFields.get('DM_PROCESSI_COLORE'))}};  margin-right:2%;">
                      drafts
                    </mat-icon>
                    <mat-icon *ngIf="task.defaultFields.get('LETTO') == 'N'"
                              style="color: {{this.getColor(task.defaultFields.get('DM_PROCESSI_COLORE'))}};  margin-right:2%;" >
                      markunread
                    </mat-icon>
                  </ng-container>
                  <mat-card-title><b>{{task.defaultFields.get('NOMETASK')}}</b></mat-card-title>
                  <mat-card-subtitle>{{'labels.author' | translate}}
                    : {{task.defaultFields.get('DM_PROFILE_AUTOREDESCRIPTION')}}</mat-card-subtitle>
                  <mat-card-subtitle style="color: #BC3F39;" *ngIf="this.convertStringToDate(task.defaultFields.get('DATA_SCADENZA')) < this.todayDate">{{'labels.expired' | translate}}
                  </mat-card-subtitle>
                  <mat-card-actions>
                    <button mat-raised-button style="background: {{this.apiService.dashColor}}; color:#fff;" (click)="clickTask(task.defaultFields.get('ID'))">Vai al task</button>
                  </mat-card-actions>
                </mat-card>


              </ng-container>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </ng-container>
    </div>
  </mat-card>
</div>
