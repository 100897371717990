import {Component, Inject, OnInit, TemplateRef, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import {DatePipe, DOCUMENT} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ApiService} from '../../api.service';
import * as fileSaver from 'file-saver';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {EditFromFileComponent, EditRevisionComponent} from '../../VociMenu/dettaglitask/dettaglitask.component';
import {ViewBodyToSave} from '../../viewBodyToSave';
import {BodyFieldToSave} from '../../bodyFieldToSave';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatSort, MatSortable} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {MatMenuTrigger} from '@angular/material/menu';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {fromEvent, Subscription} from 'rxjs';
import {TemplatePortal} from '@angular/cdk/portal';
import {filter, take} from 'rxjs/operators';
import {FilterDialogComponent} from '../../VociMenu/default-home/default-home.component';
import {ResizeEvent} from 'angular-resizable-element';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {DialogDocumentalClassComponent} from '../../additionalDialog/dialogDocumentalClass';
import {DialogSearchDocComponent} from '../../additionalDialog/dialogSearchDocComponents';
import {DialogMultiDTOComponent} from '../../additionalDialog/dialogMultiDTOComponent';
import {DialogTableDTOComponent} from '../../additionalDialog/dialogTableDTOComponent';
import {DialogAddressBookV2Component} from '../../additionalDialog/dialog-address-book-v2/dialog-address-book-v2.component';
import {DialogComboDTOComponent} from '../../additionalDialog/dialogComboDTOComponents';
import {DialogMaskComponent} from '../../additionalDialog/dialogMaskComponents';
import {DialogClassViewDTOComponentDTOComponent} from '../../additionalDialog/dialogClassViewDTOComponent';
import {FormBuilder, UntypedFormGroup} from '@angular/forms';
import {DashboardComponent} from '../../dashboard/dashboard.component';
import {DxTreeViewModule, DxSelectBoxModule, DxDataGridComponent, DxTreeViewComponent} from 'devextreme-angular';
import TreeView from 'devextreme/ui/tree_view';
import {Product, Service} from '../../app.service';
import {AddFolderDialogComponent, InfoDialogComponent} from '../../VociMenu/fascicoli/fascicoli.component';
import {TranslateService} from '@ngx-translate/core';

export interface Item {
  id: number;
  name: string;
}


@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [Service]
})


export class ViewComponent implements OnInit, AfterViewInit {
  id = this.activatedRoute.snapshot.paramMap.get('id');
  firstnodepath = this.activatedRoute.snapshot.paramMap.get('firstnodepath');
  idTask = this.activatedRoute.snapshot.paramMap.get('idTask');
  operationId = this.activatedRoute.snapshot.paramMap.get('operationId');
  displayedColumns: Array<string> = new Array<string>();
  row: any = {};
  v: any = {};
  buildResult: Array<string> = new Array<string>();
  Result: Array<any> = new Array<any>();
  noItems = true;
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;
  rowsNum: string;
  private dataSource: MatTableDataSource<any>;
  selection = new SelectionModel(true, []);
  selectedDocN: string;
  selectedDocNArray: Array<any> = new Array<any>();
  isSelectedDocArrayToUse = true;
  filter: string;
  filterTags: Array<any> = new Array<any>();
  commands: any;
  folders: Array<any> = new Array<any>();
  numSelected = 0;
  lastSelectedIndex: number;

  // EditProfile section
  docId: string;
  idDoc: string;
  profile: Array<any> = new Array<any>();
  breakpoint: number;
  edit = false;
  maskName: string;
  splitMaskName: string;
  splitMaskOrder: string;
  splitMaskCat: string;
  classProfileType: string;
  classProfileName: string;
  classProfileValue: string;
  fields: Array<any> = new Array<any>();
  nomiAoo: Array<any> = new Array<any>();
  originsFields: Array<any> = new Array<any>();
  documenTypes: Array<any> = new Array<any>();
  stateFields: Array<any> = new Array<any>();
  toFields: Array<any> = new Array<any>();
  FromFields: Array<any> = new Array<any>();
  Row: [];
  fieldDataSource: Array<any> = [];
  multiValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  tableValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  comboValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  addressBookSelected: Map<string, string[]> = new Map<string, string[]>();
  addressBookSelectedToShow: any;
  fieldName = '';
  keyField = '';
  fieldsNoAdditional: Array<any> = new Array<any>();
  additionalFields: Array<any> = new Array<any>();
  mapMultiValueToSave: Map<string, object[]> = new Map<string, object[]>();
  mapTableValueToSave: Map<string, object[]> = new Map<string, object[]>();
  tableAssociationField: object = {};
  comboAssociationField: any = {};
  mapTableValueToSee: Map<string, string> = new Map<string, string>();
  mapAddressBookToSee: Map<string, any> = new Map<string, any>();
  mapClasseBookToSave: Map<string, Array<string>> = new Map<string, Array<string>>();
  // CLASSE BOX
  ELEMENT_DATA_CLASS: Array<object> = new Array<object>();
  arrayToObjectClass: { [index: string]: { content: string } } = {};
  dataSourceClass: MatTableDataSource<object>;
  displayedColumnsClass: Array<string> = new Array<string>();
  docNumberClasseboxTemp: Array<string> = new Array<string>();
  optionsMask: any = {};
  dataMaskToSave: any = {};
  dataValueAssociations: any = {};
  validationCheck: boolean;
  jsonToSendArxivar: any = {};
  fileListMain: FileList;
  fileMain: File;
  formDataMain: FormData;
  bufferIdInfo: Array<any> = new Array<any>();
  fileInfo: any = {name: '', buffer: ''};
  badgeBufferMain: number;
  arrayToObject: { [index: string]: { content: string } } = {};
  canUnlock = true;
  idContact: string;
  checked: boolean;
  mapAddressBookSave: Array<any> = new Array<any>();
  comboValues: any = {};
  tempCombo: Array<any> = new Array<any>();
  selectToAdd: string;
  keyToAdd: string;
  whatToShow = 'default';
  notes: Array<any> = new Array<any>();

  // note section
  notesList: any;
  showAddNote = false;
  newNote: string;
  addLabel = this.translate.instant('labels.add');

  // attachments section
  attachments = [];

// chip list
  mapMultiValueToSee: Map<string, any> = new Map<string, any>();
  multiValuteDTOSelectedToShow: Array<any> = new Array<any>();
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  progressSpinnerValue = 0;
  ccFieldData: any = new Array<any>();


  constructor(public translate: TranslateService, private viewBody: ViewBodyToSave, public dialog: MatDialog, private snackBar: MatSnackBar,
              private datePipe: DatePipe, private route: ActivatedRoute,
              private router: Router, iconRegistry: MatIconRegistry,
              // tslint:disable-next-line:max-line-length
              sanitizer: DomSanitizer, public apiService: ApiService, private activatedRoute: ActivatedRoute, public overlay: Overlay,
              public viewContainerRef: ViewContainerRef, private dashMethods: DashboardComponent) {
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('userMenu') userMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  sub: Subscription;


  open({x, y}: MouseEvent, user, docN): void {
    this.selectedDocN = docN;
    this.close();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({x, y})
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        }
      ]);
    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.userMenu, this.viewContainerRef, {
      $implicit: user
    }));

    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close());
  }

  delete(user): void {
    // delete user
    this.close();
  }

  close(): void {
    // tslint:disable-next-line:no-unused-expression
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.sort.sort(({id: 'DOCNUMBER,System ID', start: 'asc'}) as MatSortable);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  openFilterDialog(): void {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '40%',
      data: {tags: this.filterTags}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.resultFilter) {
        this.filterTags = result.resultFilter;
        this.filterChange();
      }
    });
  }

  dontSelectWhenClicked(docN: string): void {
    this.dataSource.filteredData.forEach((data) => {
      if (data['DOCNUMBER,System ID'] === docN) {
        if (data.selected) {
          data.selected = false;
          this.selectedDocNArray.splice(this.selectedDocNArray.indexOf(docN), 1);
          this.numSelected--;
        } else {
          data.selected = true;
          this.selectedDocNArray.push(data['DOCNUMBER,System ID']);
          this.numSelected++;
        }
      }
    });
  }

  clickedRow(event: any, row: any, docN: string): void {
    if (docN !== this.docId) {
      if (this.selectedDocNArray.length > 0 && event.shiftKey) {
        let currentIndex: number;
        console.log(this.dataSource);
        this.dataSource.filteredData.forEach((data, index) => {
          if (data['DOCNUMBER,System ID'] === docN) {
            currentIndex = index;
          }
        });
        if (currentIndex > this.lastSelectedIndex) {
          this.dataSource.filteredData.forEach((data, index) => {
            if ((index > this.lastSelectedIndex) && (index <= currentIndex)) {
              if (data.selected === false) {
                data.selected = true;
                this.selectedDocNArray.push(data['DOCNUMBER,System ID']);
                this.numSelected++;
                if (index === this.dataSource.filteredData.length) {
                  this.lastSelectedIndex = index;
                }
              }
            }
          });
        } else if (currentIndex === this.lastSelectedIndex) {
          this.selectedDocNArray.splice(this.selectedDocNArray.indexOf(docN), 1);
          row.selected = false;
          this.numSelected--;
        } else {
          this.dataSource.filteredData.forEach((data, index) => {
            if ((index >= currentIndex) && (index < this.lastSelectedIndex)) {
              if (data.selected === false) {
                data.selected = true;
                this.selectedDocNArray.push(data['DOCNUMBER,System ID']);
                this.numSelected++;
                if (index === this.dataSource.filteredData.length) {
                  this.lastSelectedIndex = index;
                }
              }
            }
          });
        }
      } else {
        if (row.selected) {
          this.selectedDocNArray.splice(this.selectedDocNArray.indexOf(docN), 1);
          row.selected = false;
          this.numSelected--;
        } else {
          this.selectedDocNArray.push(docN);
          row.selected = true;
          this.numSelected++;
          this.dataSource.filteredData.forEach((data, index) => {
            if (data['DOCNUMBER,System ID'] === docN) {
              this.lastSelectedIndex = index;
            }
          });
        }
      }
    }
  }

  DBclickedRow(event: any, row: any, docN: string): void {
    if (this.numSelected === 0) {
      this.goToProfile(docN);
    } else {
      this.cancelSelection();
    }
  }

  onShift(): void {

  }

  cancelSelection(): void {
    this.dataSource.filteredData.forEach(data => {
      if (data.selected) {
        data.selected = false;
      }
    });
    this.numSelected = 0;
    this.selectedDocNArray = new Array<any>();
  }

  filterChange(): void {
    if (this.filter !== null && this.filter !== undefined) {
      let found;
      const tempRes: Array<any> = new Array<any>();
      let tempColumns: Array<any> = new Array<any>();
      tempColumns = this.filterTags;
      this.displayedColumns.forEach(d => {
        tempColumns.forEach(t => {
          if (d.includes(t.tag)) {
            t.tag = d;
          }
        });
      });
      /*console.log(tempColumns);
      console.log(this.Result);*/
      this.Result.forEach(r => {
        found = false;
        tempColumns.forEach(dc => {
          if (dc.active) {
            if (r[dc.tag]) {
              if (r[dc.tag].toString().toLocaleLowerCase().includes(this.filter.toLocaleLowerCase())) {
                found = true;
              }
            }
          }

        });
        if (found) {
          tempRes.push(r);
        }
      });
      this.dataSource = new MatTableDataSource(tempRes);
      this.ngAfterViewInit();
    }
  }

  enableFolder(id): void {
    this.folders.forEach(fol => {
      if (fol.id === id) {
        if (fol.enabled) {
          fol.enabled = false;
        } else {
          fol.enabled = true;
        }
      }
    });
  }

  startEvent(eventId: string, docN: number): void {
    if (this.selectedDocNArray.length > 0 && this.isSelectedDocArrayToUse) {
      this.isSelectedDocArrayToUse = false;
      this.selectedDocNArray.forEach(d => {
        this.startEvent(eventId, d);
      });
      this.isSelectedDocArrayToUse = true;
    } else {
      this.apiService.getWorkflowEvents([docN]).subscribe(res => {
        res.forEach(v => {
          if (v.eventId.toString() === eventId) {
            this.apiService.canStartEventByDocnumber(docN.toString()).subscribe(result => {
              if (result) {
                this.apiService.startWorkflow(docN.toString(), eventId).subscribe(r => {

                  this.snackBar.open(this.translate.instant('advices.workflowStarted'), null, {
                    duration: 4000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    panelClass: ['mat-toolbar', 'custom-success-bg']
                  });
                });
              }
            });
          }
        });
      });
    }
    this.close();
  }

  ngOnInit(): void {
    this.selectedDocNArray = new Array<any>();
    this.numSelected = this.selectedDocNArray.length;
    this.folders = this.apiService.commandFolders;
    if (!this.idTask) {
      this.commands = this.apiService.profileCommands;
    } else {
      this.commands = {
        id: -1,
        type: 2,
        name: 'Comandi SECAM Task',
        author: 2,
        authorCompleteName: null,
        creationDate: '2021-10-20T10:51:30.103+02:00',
        priority: 1,
        details: [
          {
            id: -1,
            label: 'Aggiungi al task',
            elementType: -1,
            elementId: '',
            elementAction: 0,
            orderIndex: 0,
            parentId: 0,
            layoutId: 40,
            operation: 0,
            favourite: true,
            translations: null,
            childs: null
          }
        ],
        usingType: 14,
        users: [
          {
            layoutId: 40,
            userId: 124,
            completeName: 'SCM\\EVRSECAM',
            category: 4,
            isUserDisabled: false
          }
        ],
        isSystem: false
      };
    }

    console.log(this.commands);
    if (this.apiService.namePath.includes(this.translate.instant('labels.search') + ' - ')) {
      this.apiService.namePath = this.apiService.namePath.split('-')[0];
    }
    this.apiService.namePath += ' - ' + this.translate.instant('labels.search');
    this.apiService.addPath(this.translate.instant('labels.search'), 'dashboard/dashnav/view', {
      id: this.id,
      firstnodepath: this.firstnodepath
    });
    this.Result = new Array<any>();
    this.displayedColumns = new Array<string>();
    this.buildResult = new Array<string>();
    this.dashColor = this.apiService.dashColor;
    this.backColor = this.apiService.backColor;
    this.squareColor = this.apiService.squareColor;
    this.cardColor = this.apiService.cardColor;
    this.rowsNum = this.apiService.viewDataToShow.data.length;
    // this.displayedColumns.push('select');     SELEZIONE MULTIPLA
    // this.displayedColumns.push('selected');
    this.apiService.viewDataToShow.columns.forEach(val => {
      if (val.visible || val.id === 'DOCNUMBER') {

        this.displayedColumns.push(val.id + ',' + val.label);
      }
    });
    if (this.filterTags.length === 0) {
      this.displayedColumns.forEach(v => {
        this.filterTags.push({tag: v.substr(v.indexOf(','), v.length), active: true});
      });
    }

    this.apiService.viewDataToShow.data.forEach(res => {
      this.buildResult = new Array<string>();
      this.noItems = false;
      this.apiService.viewDataToShow.columns.forEach((item, index) => {
        if (item.columnType === 'DateTime') {
          if (res[index]) {
            res[index] = new Date(res[index]);
          } else {
            res[index] = '';
          }
        }
        this.buildResult[this.apiService.viewDataToShow.columns[index].id + ',' +
        this.apiService.viewDataToShow.columns[index].label] = res[index];
      });
      this.buildResult['selected'] = false;

      this.Result.push(this.buildResult);
      // // // console.log(this.buildResult);

    });
    /*this.Result.forEach(resRow => {
         if (resRow['ALLEGATI,Allegati'] === '1') {
             this.apiService.docAttachments(resRow['DOCNUMBER,System ID']).subscribe(val => {
                 resRow['ALLEGATI,Allegati'] = val.length;
             });
         }
         if (resRow['NOTE,Note'] === 'S') {
             this.apiService.getNotes(resRow['DOCNUMBER,System ID']).subscribe(val => {
                 resRow['NOTE,Note'] = val.length;
             });
         }
     });*/


    this.displayedColumns.push(this.translate.instant('labels.edit'));
    this.displayedColumns.push(this.translate.instant('labels.preview'));
    const demoRes: Array<any> = new Array<any>();
    this.Result.forEach((v, index) => {
      if (index < 25) {
        demoRes.push(v);
      }
    });
    this.dataSource = new MatTableDataSource(demoRes);

    this.filterChange();
    this.loadRows();
    console.log(this.displayedColumns);

  }

  addToTask(docN: string): void {
    console.log(this.apiService.namePathV2);
    this.apiService.insertDocumentInTaskByView(this.idTask, this.operationId, [docN]).subscribe(res => {
      this.apiService.namePathV2.forEach(v => {
        if (v.params.id === this.idTask) {
          this.dashMethods.pathClick(v.route, v.params);
        }
      });
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });


  }


  openPreview(docNumber: string, revision: string): void {
    this.dontSelectWhenClicked(docNumber);
    const dialogRef = this.dialog.open(PreviewComponent, {
      width: 'fit-content',
      panelClass: 'custom-dialog-container',
      data: {docN: docNumber, rev: revision}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  openRevisions(docNumber: string): void {
    this.dontSelectWhenClicked(docNumber);
    const dialogRef = this.dialog.open(RevisionsComponent, {
      width: '60%',
      panelClass: 'custom-dialog-container',
      data: {docN: docNumber}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  addToFolder(docNum: number): void {
    const dialogRef = this.dialog.open(AddProfileToFolderComponent, {
      width: '30%',
      height: '75%',
      data: {docN: docNum}
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  containFolder(docNum: number): void {
    const dialogRef = this.dialog.open(ContainFolderComponent, {
      width: '30%',
      height: '75%',
      data: {docN: docNum}
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  editProfile(docN: string): void {
    this.docId = docN;
    this.dontSelectWhenClicked(docN);
    this.edit = true;
    this.apiService.getProfileSchemaSwitched(docN).subscribe(data => {
      this.id = data.maskId;
      this.fields = data.fields;
      // console.log(data);
      this.apiService.getDocTypeMode('2').subscribe(datas => {
        // console.log(datas);
        datas.forEach(v => {
          if (v.isLeaf) {
            this.documenTypes.push(v);
          }
        });

      });
      this.optionsMask = data.options;
      // // console.log(data);
      if (data.editOptions.canUnlockProfile === false) {
        this.canUnlock = false;
      }


      data.fields.forEach(findClass => {
        if (true) {  // check if visible
          this.dataMaskToSave[findClass.name] = findClass.value;
          this.dataValueAssociations[findClass.name] = findClass.associations;
        }
        if (findClass.className === 'AdditionalFieldClasseDTO') {
          this.mapClasseBookToSave.set(findClass.name, findClass.value);
          findClass.columns.forEach(column => {
            this.displayedColumnsClass.push(column.label);
          });
          this.displayedColumnsClass.push('Azioni');
          this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
        }
        if (findClass.className === 'ToFieldDTO') {
          const selected: Array<string> = new Array<string>();
          // // console.log(findClass);
          this.addressBookSelectedToShow = [];
          if (findClass.value) {
            findClass.value.forEach(item => {
              // // console.log(item);
              this.addressBookSelectedToShow.push({name: item.society, id: item.contactId});
              selected.push(item.contactId);

            });

            this.addressBookSelected.set(findClass.name, selected);
            // // console.log(this.addressBookSelected);
            this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
            // // console.log(this.mapAddressBookToSee);
          } else {
            this.mapAddressBookToSee.set(findClass.name, []);
          }

        }
        if (findClass.className === 'FromFieldDTO') {
          const selected: Array<string> = new Array<string>('');
          this.addressBookSelectedToShow = [];
          if (findClass.value) {
            this.addressBookSelectedToShow.push({name: findClass.value.society, id: findClass.value.contactId});
            selected.push(findClass.value.contactId);
            // // console.log(this.addressBookSelectedToShow);
            this.addressBookSelected.set(findClass.name, selected);
            this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
            // // console.log(this.mapAddressBookToSee);
          } else {
            this.mapAddressBookToSee.set(findClass.name, []);
          }


        }
        if (findClass.className === 'CcFieldDTO') {
          const selected: Array<string> = new Array<string>();
          console.log(findClass);
          this.addressBookSelectedToShow = [];
          if (findClass.value) {
            findClass.value.forEach(item => {
              // // console.log(item);
              this.addressBookSelectedToShow.push({name: item.society, id: item.contactId});
              selected.push(item.contactId);

            });

            this.addressBookSelected.set(findClass.name, selected);
            // // console.log(this.addressBookSelected);
            this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
            // // console.log(this.mapAddressBookToSee);
          } else {
            this.mapAddressBookToSee.set(findClass.name, []);
          }

        }
        if (findClass.className === 'AdditionalFieldMultivalueDTO') {
          this.dataMaskToSave[findClass.name] = findClass.value;
          this.multiValuteDTOSelectedToShow = new Array<any>();
          this.dataMaskToSave[findClass.name].forEach(v => {
            this.multiValuteDTOSelectedToShow.push(v);
          });

          this.multiValuteDTOSelected.set(this.fieldName, this.dataMaskToSave[findClass.name]);
          this.mapMultiValueToSee.set(findClass.name, this.multiValuteDTOSelectedToShow);
        }

        if (findClass.className === 'DocumentTypeFieldDTO') {
          this.classProfileType = findClass.className;
          this.classProfileName = findClass.name;
          this.classProfileValue = findClass.value;
        }
      });
      this.fields.forEach(v => {
        if (v.className === 'AdditionalFieldComboDTO') {
          this.apiService.getProfileValuesWithFilter(v.name, this.getJsonFields().fields).subscribe(results => {
            // console.log(results);
            this.tempCombo = new Array<any>();
            results.dataSource.forEach(res => {
              this.selectToAdd = '';
              this.keyToAdd = '';
              res.columns.forEach(r => {
                if (results.selectField.includes(r.id)) {
                  this.selectToAdd += r.value + ' - ';
                }
                if (results.keyField.includes(r.id)) {
                  this.keyToAdd += r.value + ' - ';
                }
              });
              this.tempCombo.push({
                selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3)
              });
              this.comboValues[v.name] = this.tempCombo;
            });
          });
        }
      });
      this.checkFormula();
      this.apiService.getAoo().subscribe(data1 => {
        this.nomiAoo = data1;
      });
      this.apiService.getOrigins().subscribe(data2 => {
        this.originsFields = data2;
      });

      this.apiService.getStates().subscribe(data3 => {
        this.stateFields = data3;
      });
    });

  }

  getClass(selected: boolean): string {
    if (!selected) {
      return 'tableRow';
    } else {
      return 'tableRowSelected';
    }
  }

  loadRows(): void {
    setTimeout(() => {
      this.filter = '';
      this.filterChange();
    }, 1);
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  refreshView(): void {
    if (!this.idTask) {
      if (this.firstnodepath === 'ricerca') {
        this.apiService.doSearch(this.apiService.viewBody).subscribe(results => {
          this.apiService.viewDataToShow = results;
          this.ngOnInit();
          this.ngAfterViewInit();
        });
      } else {
        this.apiService.getViewFilter(this.apiService.viewBody).subscribe(data => {
          this.apiService.viewDataToShow = data;
          this.ngOnInit();
          this.ngAfterViewInit();
        });
      }
    }
  }

  goToProfile(docNum: string): void {
    this.router.navigate(['../profile', {
      idDocument: docNum,
      taskId: '0',
      idv: this.id,
      firstnodepath: this.firstnodepath
    }], {relativeTo: this.route});
  }

  goBack(): void {
    if (this.firstnodepath === 'ricerca') {
      this.router.navigate(['../ricerca'], {relativeTo: this.route});
    } else if (this.firstnodepath === 'checkup') {
      this.router.navigate(['../checkup'], {relativeTo: this.route});

    } else {
      this.router.navigate(['../viewDetails', {id: this.id, firstnodepath: this.firstnodepath}], {relativeTo: this.route});

    }
  }

  openProfileNotesDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(ProfileNotesComponent, {
      width: '50%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshView();
    });
  }

  openEditFileDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(EditFromFileProfileComponent, {
      width: '20%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshView();
    });
  }

  openEditRevisionDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(EditRevisionProfileComponent, {
      width: '20%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshView();
    });
  }

  openShowAttachmentsDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(ShowAttachmentsDialogComponent, {
      width: '60%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshView();
    });
  }

  openRelationsDialog(docNumber: string): void {
    this.router.navigate(['../relations', {relDocN: docNumber}], {relativeTo: this.route});
  }

  exportMultipleToCSV(): void {
    let header;
    const data: Array<any> = new Array<any>();
    this.Result.forEach(res => {
      header = Object.keys(res);
      const tempArray: Array<any> = new Array<any>();
      console.log(res.selected);
      if (res.selected === true) {
        header.forEach((v, index) => {
          let value: any;
          value = res[v];
          // console.log(value);
          if (res[v] instanceof Date) {
            value = this.TransformDataFormat(res[v]);
          }
          if (value) {
            value = value.toString().replace(',', '');
          }
          tempArray[v.substr(v.indexOf(',') + 1, v.length)] = value;
        });

        data.push(tempArray);
      }
      header.forEach((v, index) => {
        header[index] = v.substr(v.indexOf(',') + 1, v.length);
      });
    });
    this.exportAll(data, header);
  }

  exportAll(data: any, header): void {
    this.apiService.exportCSV(data, 'profiles', header);
  }

  exportToCSV(id: string): void {
    if (this.selectedDocNArray.length > 0) {
      this.exportMultipleToCSV();
    } else {
      this.Result.forEach(res => {
        if (res['DOCNUMBER,System ID'] === id) {
          this.apiService.getUserSettings().subscribe(response => {
            if (response.profile) {
              const header = Object.keys(res);
              const tempArray: Array<any> = new Array<any>();
              header.forEach((v, index) => {
                header[index] = v.substr(v.indexOf(',') + 1, v.length);
                let value: string;
                // // console.log(res);
                // // console.log(header);
                if (res[v] !== null) {
                  value = res[v];
                  if (res[v] instanceof Date) {
                    value = this.TransformDataFormat(res[v]);
                  }
                } else {
                  value = '';
                }

                value = value.toString().replace(',', '');
                tempArray[v.substr(v.indexOf(',') + 1, v.length)] = value;
              });
              this.apiService.exportCSV([tempArray], 'profile', header);
            } else {
              const header = Object.keys(res);
              const tempArray: Array<any> = new Array<any>();
              header.forEach((v, index) => {
                header[index] = v.substr(v.indexOf(',') + 1, v.length);
                let value: string;
                value = res[v];
                if (value) {
                  value = value.toString().replace(',', '');
                }
                tempArray[v.substr(v.indexOf(',') + 1, v.length)] = value;
              });
              this.apiService.exportCSV([tempArray], 'profile', header);
            }
          });
        }
      });
    }

  }

  endEditProfile(): void {
    this.docId = '';
  }

  signDoc(Id: string): void {
    let name = '';
    this.apiService.getProfileDetailsClass(Id).subscribe(result => {
      name = result.profileInfo.fileName;
      // // console.log(result);
      this.apiService.downloadDocument(Id).subscribe(response => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        const self = this;
        // tslint:disable-next-line:only-arrow-functions
        reader.onloadend = function(): void {
          let base64data = reader.result.toString();
          base64data = base64data.substr(base64data.indexOf(',') + 1);
          const dialogRef = self.dialog.open(SignerDetailsComponent, {
            width: '20%',
            data: {}
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res.signerEmail) {
              self.apiService.docusignSendEnvelope(base64data, name,
                name.substr(name.lastIndexOf('.') + 1), res.signerEmail, res.signerName).subscribe(resu => {
                // // console.log(resu);
                self.snackBar.open('Operazione effettuata con successo!', null, {
                  duration: 4000,
                  horizontalPosition: 'end',
                  verticalPosition: 'bottom',
                  panelClass: ['mat-toolbar', 'custom-success-bg']
                });
              });
            } else {
              self.snackBar.open('Operazione annullata', null, {
                duration: 4000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom',
                panelClass: ['mat-toolbar', 'custom-danger-bg']
              });
            }
          });
        };
        // tslint:disable-next-line:no-unused-expression
      }, errorLog => {
        this.snackBar.open('Nessun documento associato', null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    }, errorLog => {
      this.snackBar.open('Nessun documento associato', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  downloadDoc(Id: string): void {
    if (this.selectedDocNArray.length > 0 && this.isSelectedDocArrayToUse) {
      this.isSelectedDocArrayToUse = false;
      this.selectedDocNArray.forEach(d => {
        this.downloadDoc(d);
      });
      this.isSelectedDocArrayToUse = true;
    } else {
      let name = '';
      this.apiService.getProfileDetailsClass(Id).subscribe(result => {
        name = result.profileInfo.fileName;
        // // // console.log(name);
        this.apiService.downloadDocument(Id).subscribe(response => {
          const blob = new Blob([response], {type: 'application/octet-stream'});
          fileSaver.saveAs(blob, name);
          // tslint:disable-next-line:no-unused-expression
        }, errorLog => {
          this.snackBar.open(this.translate.instant('advices.noDocumentAssociated'), null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      }, errorLog => {
        this.snackBar.open(this.translate.instant('advices.noDocumentAssociated'), null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    }

  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  checkIfDate(field: any): boolean {
    return field instanceof Date;
  }

  TransformDataFormat(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');
  }

  /*TransformDataFormatToSee(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');
  }*/
  getFormat(doc: string): any {
    let format = '';
    this.apiService.getProfileDetailsClass(doc).subscribe(result => {
      format = result.profileInfo.fileName;
      return format;
    });
  }

  goToProcess(docId: string): void {
    let id = '';
    this.apiService.getWorkflowId(docId).subscribe(result => {
      result.forEach(val => {
        id = val.id;
        this.router.navigate(['../process', {
          processId: id,
          path: 'view',
          idv: this.id,
          firstnodepath: this.firstnodepath
        }], {relativeTo: this.route});
      });
    });
  }

  // edit profile section -------------------------------------------
  checkComboAssociations(field: any): void {
    let comboResult: any = {};
    let selectedElement;
    this.fieldName = field.name;
    this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
      comboResult = results;
      this.comboAssociationField = {};
      this.comboAssociationField = Object.keys(results.associations);
      this.fieldDataSource = new Array<any>();
      this.keyField = results.keyField;
      if (results.dataSource != null) {

        results.dataSource.forEach(item => {
          // console.log(item);
          item.columns.forEach(v => {
            if (v.id.toUpperCase() === this.keyField.toUpperCase() && v.value === field.value) {
              selectedElement = item;
            }
          });

        });
      } else {
        // EMPTY COMBO
      }
      this.comboAssociationField.forEach(res => {
        selectedElement.columns.forEach(v2 => {
          if (v2.id === comboResult.associations[res]) {
            this.dataMaskToSave[res.toUpperCase()] = v2.value;
          }
        });
      });
      this.getAllProfileValue();
    });
  }

  checkFormula(): void {
    let pastFormulas: Array<any> = new Array<any>();
    let found = false;
    pastFormulas = new Array<any>();
    this.fields.forEach(f => {
      if (f.formula) {
        found = false;
        pastFormulas.push({name: f.name, formula: f.formula});
        pastFormulas.forEach(pf => {
          if (pf.formula.toString().includes(f.name.toString())) {
            this.apiService.getProfileFormula(this.getUpdatedFields(f.name, f.formula)).subscribe(res => {
              this.dataMaskToSave[f.name] = res;
              this.apiService.getProfileFormula(this.getUpdatedFields(pf.name, pf.formula)).subscribe(res2 => {
                this.dataMaskToSave[pf.name] = res2;
              });
            });
            found = true;
          }
        });
        if (!found) {
          this.apiService.getProfileFormula(this.getUpdatedFields(f.name, f.formula)).subscribe(res => {
            this.dataMaskToSave[f.name] = res;
            this.fields.forEach(field => {
              if (field.formula) {
                if (field.formula.toString().includes(f.name)) {
                  this.apiService.getProfileFormula(this.getUpdatedFields(field.name, field.formula)).subscribe(res2 => {
                    this.dataMaskToSave[field.name] = res2;
                  });
                }
              }
            });
          });
        }
      }
    });
  }

  getUpdatedFields(name: string, form: string): any {
    this.jsonToSendArxivar =
      {
        fieldName: name,
        formula: form,
        searchFilterDto: {
          fields: []
        }
      };
    this.fields.forEach((field, index) => {
      if (this.dataMaskToSave[field.name]) {
        if (field.className === 'AdditionalFieldDateTimeDTO') {
          this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
        } else {
          this.fields[index].value = this.dataMaskToSave[field.name];
        }
      }
      this.jsonToSendArxivar.searchFilterDto.fields.push(this.fields[index]);
    });
    return this.jsonToSendArxivar;
  }

  deleteDocument(docN: string): void {
    this.apiService.deleteProfile(docN).subscribe(res => {
      this.close();
      this.refreshView();
      this.snackBar.open(this.translate.instant('advices.profileDeleted'), null, {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-warning-bg']
      });
    }, errorLog => {
      this.close();
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  deleteAttach(id: string): void {
    this.apiService.deleteAttachment(id).subscribe(res => {
      this.snackBar.open('Allegato eliminato con successo', null, {
        duration: 7000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-warning-bg']
      });
      this.refresh();
    });
  }

  refresh(): void {
    this.apiService.docAttachments(this.docId).subscribe(val => {
      this.attachments = val;
      // // console.log(this.attachments);
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 7000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  openAddExternalAttachmentDialog(): void {
    const dialogRef = this.dialog.open(AddExternalAttachmentComponent, {
      width: '20%',
      height: '45%',
      data: {docN: this.docId}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refresh();
    });
  }

  downloadDocForAttachment(Id: string, name: string): void {
    // // // console.log(name);
    this.apiService.downloadAttachment(Id, true).subscribe(response => {
      const blob = new Blob([response], {type: 'application/octet-stream'});
      fileSaver.saveAs(blob, name);
      // tslint:disable-next-line:no-unused-expression
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });

  }

  TransformDataFormatToShow(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy h:mm:ss', '', 'en-GB');
  }

  opendocumentalClassDialog(): void {
    this.apiService.classDocMode = '2';
    const dialogRef = this.dialog.open(DialogDocumentalClassComponent, {
      width: '40%',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result) {
        this.fields.forEach(v => {
          if (v.name === 'DocumentType') {
            this.dataMaskToSave[v.name] = Number(result.docClassId);
            v.value = Number(result.docClassId);
            this.documentChanged({isUserInput: true}, result.docType, result.type1, result.type2);
          }
        });
      }
    });
  }

  deleteNote(id: string): void {
    this.apiService.deleteNoteProfile(id).subscribe(res => {
      this.apiService.getNotes(this.docId).subscribe(v => {
        this.notesList = v;
      });
      this.snackBar.open(this.translate.instant('advices.noteDeletedCorrectly'), null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-warning-bg']
      });
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  saveNewNote(): void {
    if (this.newNote !== undefined && this.newNote !== '') {
      this.apiService.addNote({
        docnumber: this.docId,
        comment: this.newNote,
        isEdit: false,
        aosflag: false,
        isPaNote: false
      }).subscribe(res => {
        this.newNote = '';
        this.apiService.getNotes(this.docId).subscribe(v => {
          this.notesList = v;
        });
        this.snackBar.open(this.translate.instant('advices.noteDeletedCorrectly'), null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });
      }, errorLog => {
        this.snackBar.open(errorLog.error.userMessage, null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    } else {
      this.snackBar.open('No valid text', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    }

  }

  addNoteTrue(): void {
    if (this.showAddNote) {
      this.showAddNote = false;
      this.addLabel = 'Aggiungi';
    } else {
      this.showAddNote = true;
      this.addLabel = 'Nascondi';
    }
  }

  searchMaskFromClassDTO(field: any): void {
    if (this.edit) {
      if (!field.locked) {
        const dialogRef = this.dialog.open(DialogSearchDocComponent, {
          width: '80%',
          height: 'fit-content',
          data: {
            classField: field
          }
        });
        dialogRef.afterClosed().subscribe(res => {
          // // console.log(res);
          res.dataResult.forEach(result => {
            this.docNumberClasseboxTemp = new Array<string>();
            // // console.log(this.mapClasseBookToSave);
            this.docNumberClasseboxTemp = this.mapClasseBookToSave.get(result.fieldName);
            // // console.log(this.docNumberClasseboxTemp);

            this.docNumberClasseboxTemp.push(result.docNumber);
            this.mapClasseBookToSave.set(result.fieldName, this.docNumberClasseboxTemp);
            this.arrayToObject = {};
            this.apiService.getProfileDetailsClass(result.docNumber).subscribe(callResults => {
              callResults.fields.forEach(fieldClass => {
                this.displayedColumnsClass.forEach(colum => {
                  if (colum === fieldClass.description) {
                    this.arrayToObject[colum] = fieldClass.value;
                  }
                });
              });
              this.ELEMENT_DATA_CLASS.push(this.arrayToObject);
              this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
              // // console.log(this.ELEMENT_DATA_CLASS);
            });
          });
        });
      }
    }
  }

  documentChanged(event: any, tipo1: string, tipo2: string, tipo3: string): void {
    if (event.isUserInput) {
      this.apiService.additionalProfiles(tipo1, tipo2, tipo3, this.fields[0].value).subscribe(data => {
        this.fieldsNoAdditional = new Array<any>();
        this.fields.forEach(v => {
          if (v.isAdditional === false) {
            this.fieldsNoAdditional.push(v);
          }
        });
        this.additionalFields = data;
        this.additionalFields.forEach(v => {
          this.fieldsNoAdditional.push(v);
        });
        this.fields = this.fieldsNoAdditional;
        this.fields.forEach(v => {
          if (v.className === 'AdditionalFieldComboDTO') {
            this.apiService.getProfileValuesWithFilter(v.name, this.getJsonFields().fields).subscribe(results => {
              // console.log(results);
              this.tempCombo = new Array<any>();
              results.dataSource.forEach(res => {
                this.selectToAdd = '';
                this.keyToAdd = '';
                res.columns.forEach(r => {
                  if (results.selectField.includes(r.id)) {
                    this.selectToAdd += r.value + ' - ';
                  }
                  if (results.keyField.includes(r.id)) {
                    this.keyToAdd += r.value + ' - ';
                  }
                });
                this.tempCombo.push({
                  selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                  keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3)
                });
                this.comboValues[v.name] = this.tempCombo;
              });
            });
          }
        });
        // console.log(this.fields);
      });
    }
  }

  openMultiValueDTO(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
        // console.log(results);
        this.multiValuteDTOSelected.set(this.fieldName, this.dataMaskToSave[field.name]);
        this.fieldDataSource = new Array<object>();
        if (results.dataSource != null) {
          results.dataSource.forEach(item => {
            this.fieldDataSource.push(item.columns);
          });
        } else {
          // EMPTY MULTI VALUE
        }
        const dialogRef = this.dialog.open(DialogMultiDTOComponent, {
          id: 'style-4',
          width: '40%',
          height: '60%',
          data: {
            additionalFieldMultiDTO: this.fieldDataSource,
            fieldName: field.name,
            multiValueToSelected: this.multiValuteDTOSelected,
            selectField: results.selectField
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result.dataResult === 'delete') {
            this.multiValuteDTOSelectedToShow = new Array<any>();
            this.dataMaskToSave[this.fieldName] = '';
            this.mapMultiValueToSee.set(this.fieldName, '');
            this.multiValuteDTOSelected.set(this.fieldName, []);
          }
          this.multiValuteDTOSelectedToShow = new Array<any>();
          if (result.dataResult !== false) {
            result.dataResult.forEach(item => {
              this.multiValuteDTOSelectedToShow.push(item);
            });
            this.dataMaskToSave[this.fieldName] = result.dataResult;
            this.mapMultiValueToSave.set(this.fieldName, result.dataToSave); // non serve probabilmente
            this.multiValuteDTOSelected.set(this.fieldName, result.dataResult); // non serve probabilmente
            this.mapMultiValueToSee.set(this.fieldName, this.multiValuteDTOSelectedToShow);
            console.log(this.mapMultiValueToSee);
          }
          this.getAllProfileValue();
          this.checkFormula();
        });
      });
    }
  }

  openTableDTO(field: any): void {
    if (this.edit) {
      if (!field.locked) {
        this.fieldName = field.name;
        this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
          this.tableAssociationField = {};
          this.tableAssociationField = Object.keys(results.associations)
            .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
          this.fieldDataSource = new Array<object>();
          if (results.dataSource != null) {
            this.keyField = results.keyField;
            results.dataSource.forEach(item => {
              this.fieldDataSource.push(item.columns);
            });
          } else {
            // EMPTY MULTI VALUE
          }
          const dialogRef = this.dialog.open(DialogTableDTOComponent, {
            id: 'style-4',
            width: '80%',
            height: '95%',
            data: {
              additionalFieldTableDTO: this.fieldDataSource,
              fieldName: field.name,
              tableValueToSelected: this.tableValuteDTOSelected,
              selectField: results.selectField
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result.dataResult === 'delete') {
              this.fields.forEach((item, index) => {
                if (this.comboAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                  this.fields[index].value = '';
                  this.dataMaskToSave[this.fields[index].name] = '';
                }
                if (item.name === this.fieldName) {
                  this.fields[index].value = '';
                  this.dataMaskToSave[this.fields[index].name] = '';
                }
              });
            }
            if (result.dataResult !== false) {
              this.mapTableValueToSave = result.dataResult;
              this.fields.forEach((item, index) => {
                if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                  this.fields[index].value = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
                  this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
                }
                if (item.name === this.fieldName) {
                  this.fields[index].value = result.dataResult[this.keyField];
                  this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.keyField];
                }
              });
            }
            this.getAllProfileValue();
          });
        });
      }
    }
  }

  removeAddressBook(v: any, fieldName: string): void {
    this.addressBookSelectedToShow = [];
    const tempSelected = [];
    console.log(this.dataMaskToSave);
    console.log(fieldName);
    if (fieldName !== 'From') {
      this.dataMaskToSave[fieldName].forEach((value, index) => {
        if (value.contactId === v.id) {
          this.dataMaskToSave[fieldName].splice(index, 1);
        }
      });
      this.dataMaskToSave[fieldName].forEach((value) => {
        this.addressBookSelectedToShow.push({name: value.society, id: value.contactId});
        tempSelected.push(value.contactId);
      });
    } else {
      this.dataMaskToSave[fieldName] = {};
    }

    this.mapAddressBookToSee.set(fieldName, this.addressBookSelectedToShow);
    this.addressBookSelected.set(this.fieldName, tempSelected);
  }

  openAddressBook(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.SearchAllAddressBook().subscribe(results => {
        console.log(this.addressBookSelected);
        this.apiService.viewDataToShow = results;
        let name = field.name;
        if (field.description === 'Anagrafica cliente') {
          // name = 'From';
        } else {

        }
        const dialogRef = this.dialog.open(DialogAddressBookV2Component, {
          id: 'style-4',
          width: '80%',
          height: '95%',
          data: {
            fieldName: name,
            addressBookSelected: this.addressBookSelected,
            selectField: 'DM_RUBRICA_RAGIONE_SOCIALE',

          }
        });


        dialogRef.afterClosed().subscribe(result => {
          this.mapAddressBookSave = new Array<any>();
          let fromFieldValue = new Object();
          this.addressBookSelectedToShow = [];
          // this.addressBookSelected = new Map<string, string[]>();
          let type: string;
          switch (this.fieldName) {
            case 'To':
              type = '0';
              break;
            case 'From':
              type = '1';
              break;
            case 'Cc':
              type = '2';
              break;
            case 'Senders':
              type = '3';
              break;
          }
          result.dataResult.forEach((val, index) => {

            this.idContact = val;
            this.apiService.getContactAddressBook(this.idContact, type).subscribe(res => {
              // // console.log(res);
              if (type === '0' || type === '2') {
                this.mapAddressBookSave.push(res);
              } else {
                fromFieldValue = res;
                this.dataMaskToSave[this.fieldName] = fromFieldValue;
              }
              if (index === result.dataResult.length - 1) {
                if (result.dataResult !== false) {
                  result.contactsNames.forEach(item => {
                    this.addressBookSelectedToShow.push({name: item, id: val});
                  });
                  if (type === '0' || type === '2') {
                    this.dataMaskToSave[this.fieldName] = this.mapAddressBookSave;
                  } else {
                    this.dataMaskToSave[this.fieldName] = fromFieldValue;
                  }
                  this.addressBookSelected.set(this.fieldName, result.dataResult);
                  this.mapAddressBookToSee.set(this.fieldName, this.addressBookSelectedToShow);
                }
                this.checkFormula();
                this.getAllProfileValue();
              }
            });
          });
        });
      });
    }
  }


  openComboValueDTO(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
        this.comboAssociationField = {};
        this.comboAssociationField = Object.keys(results.associations)
          .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
        this.fieldDataSource = new Array<object>();
        if (results.dataSource != null) {
          this.keyField = results.keyField;
          results.dataSource.forEach(item => {
            this.fieldDataSource.push(item.columns[0]);
          });
        } else {
          // EMPTY MULTI VALUE
        }
        const dialogRef = this.dialog.open(DialogComboDTOComponent, {
          id: 'style-4',
          width: '80%',
          height: '95%',
          data: {
            additionalFieldComboDTO: this.fieldDataSource,
            fieldName: field.name,
            comboValueToSelected: this.comboValuteDTOSelected,
            selectField: results.selectField
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result.dataResult === 'delete') {
            this.fields.forEach((item, index) => {
              if (this.comboAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                this.fields[index].value = '';
                this.dataMaskToSave[this.fields[index].name] = '';
              }
              if (item.name === this.fieldName) {
                this.fields[index].value = '';
                this.dataMaskToSave[this.fields[index].name] = '';
              }
            });
          }
          if (result.dataResult !== false) {
            this.fields.forEach((item, index) => {
              if (this.comboAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                this.fields[index].value = result.dataResult[this.comboAssociationField[item.name.toLowerCase()]];
                this.dataMaskToSave[this.fields[index].name] =
                  result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
              }
              if (item.name === this.fieldName) {
                this.fields[index].value = result.dataResult[this.keyField];
                this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.keyField];
              }
            });
          }
          this.getAllProfileValue();
        });
      });
    }
  }

  insertMaskFromClassDTO(field: any): void {
    if (this.edit) {
      if (!field.locked) {
        if (this.bufferIdInfo.length > 0) {
          this.jsonToSendArxivar = {
            document: {
              fileNames: [],
              bufferIds: []
            },
            fields: [],
            notes: this.notes
          };
          this.bufferIdInfo.forEach(file => {
            this.jsonToSendArxivar.document.fileNames.push(file.name);
            this.jsonToSendArxivar.document.bufferIds.push(file.buffer);
          });
        } else {
          this.jsonToSendArxivar = {
            fields: [],
            notes: this.notes
          };
        }
        this.fields.forEach((field1, index) => {
          if (field1.visible) {
            // // // console.log(this.dataMaskToSave);
            if (field1.className === 'AdditionalFieldDateTimeDTO') {
              this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field1.name]);
            } else {
              this.fields[index].value = this.dataMaskToSave[field1.name];
            }
          }
          this.jsonToSendArxivar.fields.push(this.fields[index]);
        });
        this.fieldName = field.name;
        const dialogRef = this.dialog.open(DialogMaskComponent, {
          width: '80%',
          data: {
            json: this.jsonToSendArxivar,
            id: field.insertMaskId,
            fieldName: field.name,
            dataMask: this.dataMaskToSave,
            dataAssociations: this.dataValueAssociations
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          // // // console.log(result);
          if (result.hasOwnProperty('docNumber')) {
            this.docNumberClasseboxTemp = new Array<string>();
            this.docNumberClasseboxTemp = this.mapClasseBookToSave.get(result.fieldClassName);
            this.docNumberClasseboxTemp.push(result.docNumber);
            this.mapClasseBookToSave.set(result.fieldClassName, this.docNumberClasseboxTemp);
            this.arrayToObject = {};
            this.apiService.getProfileDetailsClass(result.docNumber).subscribe(callResults => {
              callResults.fields.forEach(fieldClass => {
                this.displayedColumnsClass.forEach(colum => {
                  if (colum === fieldClass.description) {
                    this.arrayToObject[colum] = fieldClass.value;
                  }
                });
              });
              this.ELEMENT_DATA_CLASS.push(this.arrayToObject);
              this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
              // // // console.log(this.ELEMENT_DATA_CLASS);
            });
          }
        });
      }
    }
  }

  openClassMasks(field: any): void {

    if (!field.locked) {
      this.fieldName = field.name;
      const dialogRef = this.dialog.open(DialogClassViewDTOComponentDTOComponent, {
        width: '90%',
        height: 'fit-content',
        data: {
          classField: field,
          mapClassValue: this.mapClasseBookToSave.get(field.name)
        }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }

  }

  onResize(event): void {
    this.breakpoint = (event.target.innerWidth <= 600) ? 4 : 6;
  }


  showFileSelection(event): void {
    this.fileInfo = {name: '', buffer: ''};
    this.fileListMain = event.target.files;
    if (this.fileListMain.length > 0) {
      this.fileMain = this.fileListMain[0];
      this.formDataMain = new FormData();
      this.formDataMain.append('file', this.fileMain, this.fileMain.name);
      this.apiService.openBuffer(this.formDataMain).subscribe(result => {
        this.fileInfo.name = this.fileMain.name;
        this.fileInfo.buffer = result[0];
        this.bufferIdInfo.push(this.fileInfo);
        this.badgeBufferMain = this.bufferIdInfo.length;
      });
    }
  }

  showAttachments(): void {
    this.whatToShow = 'attachments';
  }

  showNotes(): void {
    this.whatToShow = 'note';
  }

  showDesk(): void {
    this.whatToShow = 'default';
  }

  removeFile(file: any): void {
    this.apiService.deleteBuffer(file).subscribe(result => {
      if (result) {
        this.snackBar.open('File ' + file.name + ' ' + this.translate.instant('labels.deleted') + '!', null, {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });
        this.bufferIdInfo.forEach((item, index) => {
          if (item.buffer === file.buffer) {
            this.bufferIdInfo.splice(index, 1);
          }
        });
        this.badgeBufferMain = this.bufferIdInfo.length;
      }
    });
  }

  saveMask(): void {
    this.dontSelectWhenClicked(this.docId);
    this.whatToShow = 'default';
    this.validationCheck = true;
    this.fields.forEach(field => {

      if (field.required) {
        // // console.log(this.dataMaskToSave);
        if (this.dataMaskToSave[field.name] === '' || this.dataMaskToSave[field.name] == null) {
          this.snackBar.open(this.translate.instant('labels.theField') + ((field.description === 'Aoo') ? this.translate.instant('labels.company') : field.description) +
            this.translate.instant('labels.isMandatory'), null, {
            duration: 5000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
          this.validationCheck = false;
        }
      }

    });
    if (this.validationCheck) {
      this.buildDataMask();
    }
  }

  getJsonFields(): any {
    if (this.bufferIdInfo.length > 0) {
      this.jsonToSendArxivar = {
        document: {
          fileNames: [],
          bufferIds: []
        },
        fields: []
      };
      this.bufferIdInfo.forEach(file => {
        this.jsonToSendArxivar.document.fileNames.push(file.name);
        this.jsonToSendArxivar.document.bufferIds.push(file.buffer);
      });
    } else {
      this.jsonToSendArxivar = {
        fields: []
      };
    }
    // // // console.log(this.dataMaskToSave);
    this.fields.forEach((field, index) => {

      // // console.log(this.dataMaskToSave);
      if (field.className === 'AdditionalFieldDateTimeDTO') {
        this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
      } else {
        this.fields[index].value = this.dataMaskToSave[field.name];
      }


      // // // console.log(this.dataMaskToSave[field.name]);

      // // // console.log(field);

      this.jsonToSendArxivar.fields.push(this.fields[index]);
    });
    return this.jsonToSendArxivar;
  }

  buildDataMask(): void {
    if (this.bufferIdInfo.length > 0) {
      this.jsonToSendArxivar = {
        document: {
          fileNames: [],
          bufferIds: []
        },
        fields: []
      };
      this.bufferIdInfo.forEach(file => {
        this.jsonToSendArxivar.document.fileNames.push(file.name);
        this.jsonToSendArxivar.document.bufferIds.push(file.buffer);
      });
    } else {
      this.jsonToSendArxivar = {
        fields: []
      };
    }
    // // // console.log(this.dataMaskToSave);
    this.fields.forEach((field, index) => {

      // // console.log(this.dataMaskToSave);
      if (field.className === 'AdditionalFieldDateTimeDTO') {
        this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
      } else {
        this.fields[index].value = this.dataMaskToSave[field.name];
      }


      // // // console.log(this.dataMaskToSave[field.name]);

      // // // console.log(field);

      this.jsonToSendArxivar.fields.push(this.fields[index]);
    });
    // console.log(this.fields);
    // // console.log(this.dataMaskToSave);
    this.apiService.updateProfile(this.jsonToSendArxivar, this.docId).subscribe(results => {
      // // console.log(results);
      this.snackBar.open(this.translate.instant('advices.profileUpdated'), null, {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-success-bg']
      });
      this.refreshView();
      this.endEditProfile();

    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 7000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });

    // this.goBack();
    this.edit = false;
  }

  TransformDataFormatToSave(Data: string): string {

    if (Data === '') {
      return '0001-01-01T00:00:00';
    }
    return this.datePipe.transform(Data, 'yyyy-MM-dd', '', 'en-GB');
  }

  lock(): void {
    this.whatToShow = 'default';
    this.edit = false;

  }

  unLock(): void {
    if (this.canUnlock) {
      this.edit = true;
    } else {
      // // console.log('non sbloccabile');
    }
  }

  remove(v: string, fieldName: string): void {
    this.multiValuteDTOSelectedToShow = new Array<any>();
    this.dataMaskToSave[fieldName].forEach((value, index) => {
      if (value === v) {
        this.dataMaskToSave[fieldName].splice(index, 1);
      }
    });
    this.dataMaskToSave[fieldName].forEach((value) => {
      this.multiValuteDTOSelectedToShow.push(value);
    });
    this.mapMultiValueToSee.set(fieldName, this.multiValuteDTOSelectedToShow);
  }


  getAllProfileValue(): void {

    this.fields.forEach((val, index) => {
      if (val.className === 'AdditionalFieldComboDTO') {
        this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
          // console.log(results);
          this.tempCombo = new Array<any>();
          results.dataSource.forEach(res => {
            this.selectToAdd = '';
            this.keyToAdd = '';
            res.columns.forEach(r => {
              if (results.selectField.toLowerCase().includes(r.id.toLowerCase())) {
                this.selectToAdd += r.value + ' - ';
              }
              if (results.keyField.toLowerCase().includes(r.id.toLowerCase())) {
                this.keyToAdd += r.value + ' - ';
              }
            });
            this.tempCombo.push({
              selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
              keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3)
            });
            this.comboValues[val.name] = this.tempCombo;
          });
        });
      } else {
        if (val.className === 'AdditionalFieldTableDTO' || val.className === 'AdditionalFieldMultivalueDTO') {
          this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
            this.tableAssociationField = {};
            this.tableAssociationField = Object.keys(results.associations)
              .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
            console.log(this.tableAssociationField);
            if (results.dataSource.length === 0) {
              this.fields.forEach((item, subindex) => {
                if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                  this.fields[subindex].value = '';
                  this.dataMaskToSave[this.fields[subindex].name] = '';
                }
                if (item.name === val.name) {
                  this.fields[subindex].value = '';
                  this.dataMaskToSave[this.fields[subindex].name] = '';
                }
              });
            }
          });
        }
      }

    });

    this.checkFormula();


  }
}

@Component({
  selector: 'app-editfileprofiledialog',
  templateUrl: 'editFromFileProfileDialog.html',
  styleUrls: ['./view.component.css']
})
export class EditFromFileProfileComponent {
  fileListMain: FileList;
  fileMain: File;
  fileinserito = '';
  fileToBase: File; // file
  selectedOption: string;
  formDataMain: FormData;
  cacheCode: string;
  taskId: string;
  bufferIdInfo: Array<any> = new Array<any>();
  badgeBufferMain = 0;
  docId: string;
  NomeFile: string;

  constructor(public translate: TranslateService, private snackBar: MatSnackBar, @Inject(DOCUMENT) private document: Document,
              public dialogRef: MatDialogRef<EditFromFileProfileComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService) {
    this.docId = data.docN;
  }


  onClick(): void {

  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  removeFile(): void {
    this.badgeBufferMain = 0;
  }

  // tslint:disable-next-line:typedef
  fileChangeEventOld(event) {
    this.fileListMain = event.target.files;
    this.formDataMain = new FormData();
    this.formDataMain.append('file', this.fileMain, this.fileMain.name);
    this.apiService.insertFileInCache(this.formDataMain).subscribe(data => {
      this.cacheCode = data;
    });
    this.fileinserito = this.translate.instant('advices.fileAddedCorrectly');
  }

  fileChangeEvent(event): void {
    this.fileListMain = event.target.files;
    if (this.fileListMain.length > 0) {
      this.fileMain = this.fileListMain[0];
      this.formDataMain = new FormData();
      this.formDataMain.append('file', this.fileMain, this.fileMain.name);
      this.NomeFile = this.fileMain.name;
      this.apiService.insertFileInCache(this.formDataMain).subscribe(data => {
        this.cacheCode = data;
        this.fileinserito = this.translate.instant('advices.fileAddedCorrectly');
        this.badgeBufferMain = 1;

      });
    }

  }

  sendFile(): void {
    this.apiService.checkInOutProfile(this.docId, this.cacheCode, 0, false).subscribe(data => {
      this.snackBar.open(this.translate.instant('advices.fileAddedCorrectly'), null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-success-bg']
      });
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 7000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
    this.closeDialog();
  }


  // tslint:disable-next-line:typedef
  handleFile(event) {
    const binaryString = event.target.result;
    this.fileToBase = binaryString;
    // // console.log(this.fileToBase);
  }

}

@Component({
  selector: 'app-signerdetails',
  templateUrl: 'signerDetailsDialog.html',
  styleUrls: ['./view.component.css']
})
export class SignerDetailsComponent {
  email = '';
  name = '';
  error: string;

  constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<SignerDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService) {

  }


  checkSigner(): void {
    if (this.email !== '' && this.name !== '') {
      this.dialogRef.close({signerEmail: this.email, signerName: this.name});
    } else {
      this.error = 'Compilare tutti i campi!';
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-revisionsdialog',
  templateUrl: 'revisionsDialog.html',
  styleUrls: ['./view.component.css']
})
export class RevisionsComponent {
  docNumber: string;
  selectedDoc: string;
  revisions: Array<any> = new Array<any>();
  selectedRevision: string;
  @ViewChild('userMenu') userMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  sub: Subscription;

  constructor(public translate: TranslateService, private snackBar: MatSnackBar, public dialogRef: MatDialogRef<PreviewComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService, private sanitizer: DomSanitizer, public overlay: Overlay,
              public viewContainerRef: ViewContainerRef) {
    this.docNumber = data.docN;
    this.apiService.getProfileRevisions(this.docNumber).subscribe(res => {
      this.revisions = res;
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
      this.closeDialog();
    });
  }

  refresh(): void {
    this.apiService.getProfileRevisions(this.docNumber).subscribe(res => {
      this.revisions = res;
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
      this.closeDialog();
    });
  }

  deleteRevision(revId: number): void {
    this.apiService.deleteRevision(revId).subscribe(res => {
      this.snackBar.open(this.translate.instant('advices.reviewDeleted'), null, {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-warning-bg']
      });
      this.refresh();
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  setRevision(revN: string): void {
    this.apiService.revisionProfile(this.docNumber, revN, '1').subscribe(res => {
      this.snackBar.open(this.translate.instant('labels.revision') + ' ' + revN + ' ' + this.translate.instant('advices.set'), null, {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-success-bg']
      });
      this.refresh();
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  downloadDocFromRevision(Id: string, name: string, forView: boolean): void {
    // // // console.log(name);
    this.apiService.downloadDocRevision(Id, forView).subscribe(response => {
      const blob = new Blob([response], {type: 'application/octet-stream'});
      fileSaver.saveAs(blob, name);
      // tslint:disable-next-line:no-unused-expression
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });

  }

  open({x, y}: MouseEvent, user, docN): void {
    this.selectedDoc = docN;
    console.log(docN);
    this.close();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({x, y})
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        }
      ]);
    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.userMenu, this.viewContainerRef, {
      $implicit: user
    }));

    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close());
  }

  delete(user): void {
    // delete user
    this.close();
  }

  close(): void {
    // tslint:disable-next-line:no-unused-expression
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-previewdialog',
  templateUrl: 'previewDialog.html',
  styleUrls: ['./view.component.css']
})
export class PreviewComponent {
  docNumber: string;
  revision: string;
  imageURL: string;
  uploadForm: UntypedFormGroup;
  image: any;
  index = 0;
  maxPages: number;

  constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<PreviewComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService, private sanitizer: DomSanitizer) {
    this.docNumber = data.docN;
    this.revision = data.rev;
    this.apiService.getFileTotalPagesPreview(data.docN, data.rev).subscribe(info => {
      console.log(info);
      this.maxPages = info.countPages;
      this.apiService.getFilePreview(data.docN, data.rev, 0).subscribe(res => {
        const objectURL = URL.createObjectURL(res);
        this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);

      }, errorLog => {
        this.snackBar.open(errorLog.error.userMessage, null, {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    }, errorLog => {
      this.closeDialog();
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  nextPage(): void {
    this.apiService.getFilePreview(this.docNumber, this.revision, ++this.index).subscribe(res => {
      const objectURL = URL.createObjectURL(res);
      this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);

    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  prevPage(): void {
    this.apiService.getFilePreview(this.docNumber, this.revision, --this.index).subscribe(res => {
      const objectURL = URL.createObjectURL(res);
      this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);

    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}


@Component({
  selector: 'app-editrevisionprofiledialog',
  templateUrl: 'editRevisionProfileDialog.html',
  styleUrls: ['./view.component.css']
})
export class EditRevisionProfileComponent {
  docNumber: string;
  revisionNumber: string;
  ELEMENT_DATA: object[] = new Array();
  dataSource: MatTableDataSource<object>;
  displayedColumns: string[] = ['name', 'value'];
  squareColor: string;
  panelOpenState = false;

  constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<EditRevisionProfileComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService) {
    this.docNumber = data.docN;
  }

  sendRevision(): void {
    this.apiService.revisionProfile(this.docNumber, this.revisionNumber, '0').subscribe(data => {
      // // console.log(data);
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 7000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-showattachmentsdialog',
  templateUrl: 'showAttachmentsDialog.html',
  styleUrls: ['./view.component.css']
})
export class ShowAttachmentsDialogComponent {
  docId: string;
  attachments = [];
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;
  isTask = false;
  procId = '';
  internalAttach: Array<any> = new Array<any>();
  externalAttach: Array<any> = new Array<any>();
  columns: Array<any> = new Array<any>();

  constructor(public translate: TranslateService, public dialog: MatDialog, private snackBar: MatSnackBar, public dialogRef: MatDialogRef<ShowAttachmentsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService, private datePipe: DatePipe) {
    this.dashColor = this.apiService.dashColor;
    this.backColor = this.apiService.backColor;
    this.squareColor = this.apiService.squareColor;
    this.cardColor = this.apiService.cardColor;
    if (data.isTask) {
      this.isTask = data.isTask;
      this.procId = data.procId;
    }
    this.docId = data.docN;

    if (!this.isTask) {
      this.apiService.docAttachments(this.docId).subscribe(val => {
        this.attachments = val;
        // // console.log(this.attachments);
      }, errorLog => {
        this.snackBar.open(errorLog.error.userMessage, null, {
          duration: 7000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    } else {
      this.apiService.getTaskAttachments(this.procId).subscribe(val => {
        this.columns = val.columns;
        console.log(val);
        val.data.forEach(v => {
          if (v[val.columns.findIndex(x => x.id === 'TIPOALLEGATO')] === 1) {
            this.externalAttach.push(v);
          } else {
            this.internalAttach.push(v);
          }
        });
        console.log(this.externalAttach);
        console.log(this.internalAttach);
      }, errorLog => {
        this.snackBar.open(errorLog.error.userMessage, null, {
          duration: 7000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    }
  }

  getIndex(keyId: string): number {
    return this.columns.findIndex(x => x.id === keyId);
  }

  addTaskAttachment(typeAtt: string): void {
    this.dialogRef.close({type: typeAtt});
  }

  deleteAttach(id: string): void {
    if (!this.isTask) {
      this.apiService.deleteAttachment(id).subscribe(res => {
        this.snackBar.open(this.translate.instant('advices.attachmentDeleted'), null, {
          duration: 3000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-warning-bg']
        });
        this.refresh();
      });
    } else {
      this.apiService.deleteTaskAttachment(id).subscribe(res => {
        this.snackBar.open(this.translate.instant('advices.attachmentDeleted'), null, {
          duration: 3000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-warning-bg']
        });
        this.refresh();
      });
    }
  }

  refresh(): void {
    if (!this.isTask) {
      this.apiService.docAttachments(this.docId).subscribe(val => {
        this.attachments = val;
        // // console.log(this.attachments);
      }, errorLog => {
        this.snackBar.open(errorLog.error.userMessage, null, {
          duration: 7000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    } else {
      this.apiService.getTaskAttachments(this.procId).subscribe(val => {
        this.columns = val.columns;
        this.externalAttach = new Array<any>();
        this.internalAttach = new Array<any>();

        val.data.forEach(v => {
          if (v[val.columns.findIndex(x => x.id === 'TIPOALLEGATO')] === 1) {
            this.externalAttach.push(v);
          } else {
            this.internalAttach.push(v);
          }
        });

      }, errorLog => {
        this.snackBar.open(errorLog.error.userMessage, null, {
          duration: 7000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    }
  }

  openAddExternalAttachmentDialog(): void {
    const dialogRef = this.dialog.open(AddExternalAttachmentComponent, {
      width: '20%',
      height: '45%',
      data: {docN: this.docId}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refresh();
    });
  }

  profileClick(idDoc: string): void {
    this.dialogRef.close({type: 'goToProfile', docId: idDoc});
  }

  downloadTaskDoc(Id: any, name: string): void {
    // // // console.log(name);
    this.apiService.downloadTaskAttachment(Id).subscribe(response => {

      const blob = new Blob([response], {type: 'application/octet-stream'});
      fileSaver.saveAs(blob, name);
      // tslint:disable-next-line:no-unused-expression
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });

  }

  downloadDoc(Id: any, name: string): void {
    // // // console.log(name);
    this.apiService.downloadAttachment(Id, true).subscribe(response => {
      const blob = new Blob([response], {type: 'application/octet-stream'});
      fileSaver.saveAs(blob, name);
      // tslint:disable-next-line:no-unused-expression
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });

  }

  TransformDataFormatToShow(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy h:mm:ss', '', 'en-GB');
  }

  closeDialog(): void {
    this.dialogRef.close({type: false});
  }
}

@Component({
  selector: 'app-addexternalattachmentdialog',
  templateUrl: 'addExternalAttachmentDialog.html',
  styleUrls: ['./view.component.css']
})
export class AddExternalAttachmentComponent {
  bufferIdInfo: Array<any> = new Array<any>();
  fileInfo: any = {name: '', buffer: ''};
  badgeBufferMain: number;
  fileListMain: FileList;
  fileMain: File;
  formDataMain: FormData;
  commento = '';
  docNumber: string;
  isTask = false;
  taskId = '';

  constructor(public translate: TranslateService, private snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<ShowAttachmentsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService) {
    this.docNumber = data.docN;
    if (data.isTask) {
      this.isTask = true;
      this.taskId = data.taskId;
    }
  }

  showFileSelection(event): void {
    console.log(event);
    this.fileInfo = {name: '', buffer: ''};
    this.fileListMain = event.target.files;
    if (this.fileListMain.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      this.addToBuffer(0);
    }
    console.log(this.bufferIdInfo);
  }

  addToBuffer(index: number): void {
    this.formDataMain = new FormData();
    this.formDataMain.append('file', this.fileListMain[index], this.fileListMain[index].name);
    this.apiService.openBuffer(this.formDataMain).subscribe(result => {
      this.bufferIdInfo.push({name: this.fileListMain[index].name, buffer: result[0]});
      this.badgeBufferMain = this.bufferIdInfo.length;
      if (this.fileListMain[index + 1]) {
        this.addToBuffer(index + 1);
      }
    });
  }

  removeFile(file: any): void {
    this.apiService.deleteBuffer(file).subscribe(result => {
      if (result) {
        this.snackBar.open('File ' + file.name +  this.translate.instant('labels.deleted') + '!', null, {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-warning-bg']
        });
        this.bufferIdInfo.forEach((item, index) => {
          if (item.buffer === file.buffer) {
            this.bufferIdInfo.splice(index, 1);
          }
        });
        this.badgeBufferMain = this.bufferIdInfo.length;
      }
    });
  }

  send(): void {
    this.bufferIdInfo.forEach(v => {
      if (!this.isTask) {
        this.apiService.insertExternal(this.docNumber, v.buffer, this.commento).subscribe(result => {
          this.snackBar.open(this.translate.instant('advices.attachmentAdded'), null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-success-bg']
          });
        }, errorLog => {
          this.snackBar.open(errorLog.error.userMessage, null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      } else {
        this.apiService.insertTaskExternal(this.taskId, v.buffer).subscribe(result => {
          this.snackBar.open(this.translate.instant('advices.attachmentAdded'), null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-success-bg']
          });
        }, errorLog => {
          this.snackBar.open(errorLog.error.userMessage, null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      }

    });
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}


@Component({
  selector: 'app-profilenotesdialog',
  templateUrl: 'profileNotesDialog.html',
  styleUrls: ['./view.component.css']
})
export class ProfileNotesComponent {
  docN: string;
  notesList: any;
  dashColor: string;
  squareColor: string;
  showAddNote = false;
  newNote: string;
  addLabel = this.translate.instant('labels.add');

  constructor(public translate: TranslateService, private snackBar: MatSnackBar, public dialogRef: MatDialogRef<ProfileNotesComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService) {
    this.docN = data.docN;
    this.apiService.getNotes(this.docN).subscribe(v => {
      this.notesList = v;
    });
    this.dashColor = this.apiService.dashColor;
    this.squareColor = this.apiService.squareColor;
  }

  deleteNote(id: string): void {
    this.apiService.deleteNoteProfile(id).subscribe(res => {
      this.apiService.getNotes(this.docN).subscribe(v => {
        this.notesList = v;
      });
      this.snackBar.open(this.translate.instant('advices.noteDeletedCorrectly'), null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-warning-bg']
      });
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  saveNewNote(): void {
    if (this.newNote !== undefined && this.newNote !== '') {
      this.apiService.addNote({
        docnumber: this.docN,
        comment: this.newNote,
        isEdit: false,
        aosflag: false,
        isPaNote: false
      }).subscribe(res => {
        this.newNote = '';
        this.apiService.getNotes(this.docN).subscribe(v => {
          this.notesList = v;
        });
        this.snackBar.open(this.translate.instant('advices.noteAddedCorrectly'), null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });
      }, errorLog => {
        this.snackBar.open(errorLog.error.userMessage, null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    } else {
      this.snackBar.open('Invalid text', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    }

  }

  addNoteTrue(): void {
    if (this.showAddNote) {
      this.showAddNote = false;
      this.addLabel = this.translate.instant('labels.add');
    } else {
      this.showAddNote = true;
      this.addLabel = this.translate.instant('labels.hide');;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }


}

@Component({
  selector: 'app-addtofolder',
  templateUrl: 'addToFolder.html',
  styleUrls: ['./view.component.css']
})
export class AddProfileToFolderComponent {
  error: string;
// tree
  @ViewChild(DxTreeViewComponent, {static: true}) treeView: DxTreeViewComponent;
  clickedFolderName = '';
  docResult = [];
  sysId: Array<string> = new Array<string>();
  aoo: Array<string> = new Array<string>();
  showTable = false;
  isLoading = false;

  datas: Array<any> = new Array<any>();
  nodesPath: Array<any> = new Array<any>();
  index: number;
  dataMap: Map<string, string[]> = new Map<string, string[]>();
  dataToInsert: Array<any> = new Array<any>();
  products: Product[];
  currentItem: Product;
  treeData: Array<any> = new Array<any>();

  tempData: Array<object> = new Array<object>();
  viewInstance: TreeView;
  openId = '';
  currentTable: any;
  docId: string;

  saveViewInstance(e): void {
    // console.log(e);
    this.viewInstance = e.component;
    // console.log(this.viewInstance);
  }


  constructor(public translate: TranslateService, private snackBar: MatSnackBar, public dialogRef: MatDialogRef<AddProfileToFolderComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService, public dialog: MatDialog) {
    this.docId = data.docN;
  }

  selectItem(e): void {
    this.currentItem = e.itemData;
  }


  createChildren = (parentNode) => {
    if (parentNode) {
      return this.apiService.getChilds(parentNode.itemData.id).toPromise();
    } else {
      if (this.openId) {
        return this.apiService.getChilds(this.openId).toPromise();
      } else {
        return this.apiService.getChilds('0').toPromise();
      }
    }
  };

  fillChilds(id: string): any {
    // console.log('blabal');
    const dataToInsertChild = new Array<any>();
    this.apiService.getChilds(id).subscribe(dynamicData => {
      this.treeData.push(dynamicData);
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  folderClick(e): void {
    this.apiService.getContainFolder(this.docId).subscribe(result => {
      console.log(result);
      if (result.length === 0) {
        const folderId = e.id;
        this.apiService.addProfileToFolder(folderId, [this.docId]).subscribe(res => {
          this.snackBar.open(this.translate.instant('labels.profileWithSystemId') + this.docId + this.translate.instant('labels.addedSuccessfullyToFolder') + e.name, null, {
            duration: 3000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-success-bg']
          });
          this.closeDialog();
        }, errorLog => {
          this.snackBar.open(errorLog.error.userMessage, null, {
            duration: 3000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      } else {
        result.forEach(folder => {
          this.apiService.removeDocsFromFolder(folder.id, [this.docId]).subscribe(remove => {
          }, errorLog => {
            this.snackBar.open(errorLog.error.userMessage, null, {
              duration: 3000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
          });
        });
        const folderId = e.id;
        this.apiService.addProfileToFolder(folderId, [this.docId]).subscribe(res => {
          this.snackBar.open(this.translate.instant('labels.profileWithSystemId') + this.docId + this.translate.instant('labels.addedSuccessfullyToFolder') + e.name, null, {
            duration: 3000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-success-bg']
          });
          this.closeDialog();
        }, errorLog => {
          this.snackBar.open(errorLog.error.userMessage, null, {
            duration: 3000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      }

    });

  }

  openAddFolderDialog(id: string): void {
    const dialogRef = this.dialog.open(AddFolderDialogComponent, {
      width: '20%',
      data: {folderId: id}
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openInfoDialog(id: string, autore: string, nome: string): void {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: '20%',
      data: {folderId: id, author: autore, name: nome}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}

@Component({
  selector: 'app-containfolder',
  templateUrl: 'containFolder.html',
  styleUrls: ['./view.component.css']
})
export class ContainFolderComponent {
  error: string;
// tree
  @ViewChild(DxTreeViewComponent, {static: true}) treeView: DxTreeViewComponent;
  clickedFolderName = '';
  docResult = [];
  sysId: Array<string> = new Array<string>();
  aoo: Array<string> = new Array<string>();
  showTable = false;
  isLoading = false;

  datas: Array<any> = new Array<any>();
  nodesPath: Array<any> = new Array<any>();
  index: number;
  dataMap: Map<string, string[]> = new Map<string, string[]>();
  dataToInsert: Array<any> = new Array<any>();
  products: Product[];
  currentItem: Product;
  treeData: Array<any> = new Array<any>();

  tempData: Array<object> = new Array<object>();
  viewInstance: TreeView;
  openId: string;
  currentTable: any;
  docId: string;
  containedFoldersIds: Array<string> = new Array<string>();


  saveViewInstance(e): void {
    // console.log(e);
    this.viewInstance = e.component;
    // console.log(this.viewInstance);
  }


  constructor(public translate: TranslateService, private snackBar: MatSnackBar, public dialogRef: MatDialogRef<ContainFolderComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService, public dialog: MatDialog) {
    this.docId = data.docN;
    this.apiService.getContainFolder(data.docN).subscribe(res => {
      if (res[0]) {
        this.containedFoldersIds.push(res[0].id);
        this.openId = res[0].parentId;
      } else {
        this.openId = 'NO ELEMENT';
      }

    });
  }

  selectItem(e): void {
    this.currentItem = e.itemData;
  }

  isContained(id: string): boolean {
    let found = false;
    this.containedFoldersIds.forEach(i => {
      if (i === id) {
        found = true;
      }
    });
    return found;
  }

  createChildren = (parentNode) => {
    if (parentNode) {
      return this.apiService.getChilds(parentNode.itemData.id).toPromise();
    } else {
      if (this.openId) {
        return this.apiService.getContainFolder(this.docId).toPromise();
      } else {
        return this.apiService.getChilds('0').toPromise();
      }
    }
  };

  fillChilds(id: string): any {
    // console.log('blabal');
    const dataToInsertChild = new Array<any>();
    this.apiService.getChilds(id).subscribe(dynamicData => {
      this.treeData.push(dynamicData);
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  folderClick(e): void {


  }

  openAddFolderDialog(id: string): void {
    const dialogRef = this.dialog.open(AddFolderDialogComponent, {
      width: '20%',
      data: {folderId: id}
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openInfoDialog(id: string, autore: string, nome: string): void {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: '20%',
      data: {folderId: id, author: autore, name: nome}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
