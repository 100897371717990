<div class="center">


  <mat-card id="style-4" class="cardcomponentMasks">
    <div style="display: flex;">
      <button style="float: left;color:{{this.dashColor}};" title="{{'labels.find' | translate}}" (click)="saveMask()"
              mat-icon-button
              aria-label="icon-button send">
        <mat-icon>search</mat-icon>
      </button>
      <button style="float: left;color:{{this.dashColor}};" title="{{'labels.cancelFields' | translate}}"
              (click)="cleanFields()"
              mat-icon-button
              aria-label="icon-button send">
        <mat-icon>format_paint</mat-icon>
      </button>
    </div>
    <hr class="riga" style="border-top: 2px solid {{this.dashColor}};">
    <br>
    <mat-card-content>


      <br>
      <form class="flexform">
        <!--mat-grid-list [cols]="breakpoint" cols="4" rowHeight="100px" (window:resize)="onResize($event)"-->
        <ng-container *ngFor="let field of fields">
          <!--mat-grid-tile [colspan]="4"
                         [rowspan]="1" class="gridtile" [ngSwitch]="field.className"
                         *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"-->
          <ng-container *ngIf="field.isAdditional == true">

            <ng-container [ngSwitch]="field.className"
                          *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"
                          style="height:fit-content; width: 100%;">
              <!-- NumberFieldDTO -->

              <mat-form-field *ngSwitchCase="'FieldBaseForSearchAooDto'" class="form-field"
                              appearance="outline">
                <mat-label>Azienda</mat-label>
                <!--<button mat-icon-button matPrefix
                        style="margin-right: 5px; border-right: 0.5px solid {{this.apiService.squareColor}}">
                    <mat-icon style="color: {{this.apiService.squareColor}}">filter_alt</mat-icon>
                </button>-->
                <mat-select [id]="field.name" [value]="field.value || ''"
                            [required]="field.required"
                            [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                            [disabled]="field.locked">
                  <mat-option *ngFor="let aoo of nomiAoo" [value]="aoo.code">
                    {{aoo.code}} - {{aoo.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <h3 *ngSwitchCase="'FieldBaseForSearchDTO'" style="width: 95%">
                <!--Campo aggiuntivo "Generale"-->
                <br>{{field.description}}<br>
                <hr>
              </h3>

              <mat-form-field *ngSwitchCase="'FieldBaseForSearchStringDto'" class="form-field"
                              appearance="outline">
                <mat-label>{{field.description}}</mat-label>
                <!--<button mat-icon-button matPrefix
                        style="margin-right: 5px; border-right: 0.5px solid {{this.apiService.squareColor}}">
                    <mat-icon style="color: {{this.apiService.squareColor}}">filter_alt</mat-icon>
                </button>-->
                <ng-container *ngIf="field.additionalFieldType == 0">
                  <input ngModel [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </ng-container>
                <ng-container *ngIf="field.additionalFieldType == 3">
                  <mat-select [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                              [id]="field.name">
                    <mat-option>--Cancella--</mat-option>
                    <mat-option *ngFor="let val of this.comboValues[field.name]" [value]="val">
                      {{val}}
                    </mat-option>
                  </mat-select>
                </ng-container>
                <ng-container *ngIf="field.additionalFieldType == 4">
                  <!--TableDTO-->
                  <input (click)="openTableDTO(field)" ngModel [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                  <button mat-button matSuffix mat-icon-button (click)="openTableDTO(field)"
                          style="color: {{this.apiService.dashColor}}; float: right;">
                    <mat-icon>table_chart</mat-icon>
                  </button>
                </ng-container>
              </mat-form-field>
              <div *ngSwitchCase="'FieldBaseForSearchIntDto'" class="form-field"
                   appearance="outline">
                <!--Tipo documento-->
                <mat-form-field class="form-field-docType" style="width: 86%"
                                appearance="outline">
                  <mat-label>{{field.description}}</mat-label>
                  <!-- <button mat-icon-button matPrefix
                           style="margin-right: 5px; border-right: 0.5px solid {{this.apiService.squareColor}}">
                       <mat-icon style="color: {{this.apiService.squareColor}}">filter_alt</mat-icon>
                   </button>-->
                  <ng-container *ngIf="field.name == 'Origine'">

                    <mat-select [id]="field.name" [id]="field.name"
                                [value]="field.value || ''"
                                [required]="field.required"
                                [(ngModel)]="dataMaskToSave[field.name]"
                                name="{{field.name}}"
                                [disabled]="field.locked">
                      <mat-option
                        *ngFor="let originField of originsFields"
                        [value]="originField.value">
                        {{originField.description}}
                      </mat-option>
                    </mat-select>
                  </ng-container>
                  <ng-container *ngIf="field.name == 'Autore'">

                    <mat-select [id]="field.name" [id]="field.name"
                                [value]="field.value || ''"
                                [required]="field.required"
                                [(ngModel)]="dataMaskToSave[field.name]"
                                name="{{field.name}}"
                                [disabled]="field.locked">
                      <mat-option *ngFor="let author of this.users"
                                  [value]="author.user">
                        {{author.completeName}}
                      </mat-option>
                    </mat-select>
                  </ng-container>
                  <ng-container
                    *ngIf="field.name != 'Origine' && field.name != 'Autore'">
                    <input ngModel [value]="field.value || ''" type="number"
                           [(ngModel)]="dataMaskToSave[field.name]"
                           name="{{field.name}}"
                           [id]="field.name" [required]="field.required"
                           [disabled]="field.locked"
                           maxlength="{{field.numMaxChar || 100}}" matInput
                           placeholder="{{field.description}}">
                  </ng-container>
                </mat-form-field>
                <span>
                                    <button
                                      style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                      (click)="cleanFieldContact(field.name)"
                                      mat-icon-button
                                      aria-label="icon-button send">
                                        <mat-icon>cleaning_services</mat-icon>
                                    </button>
                                </span>
              </div>
              <mat-form-field *ngSwitchCase="'FieldBaseForSearchContactDto'" class="form-field"
                              appearance="outline">
                <!--DA-->
                <mat-label>{{field.description}}</mat-label>
                <mat-chip-list #chipList aria-label="MultiValue">
                  <div style="width: 100%; display: flex;">
                    <div style="width: 80%">
                      <mat-chip *ngFor="let element of this.mapAddressBookToSee.get(field.name)" [selectable]="false"
                                [disabled]="field.locked"
                                [removable]="removable" (removed)="removeAddressBook(element, field.name)"
                                [value]="element.id">
                        {{element.name}}
                        <mat-icon matChipRemove *ngIf="removable && !(field.locked)">cancel</mat-icon>
                      </mat-chip>
                    </div>
                    <div style="width: 20%; text-align: right">
                      <button matSuffix mat-icon-button (click)="openAddressBook(field)"
                              style="color: {{this.apiService.dashColor}}" [disabled]="field.locked">
                        <mat-icon>notes</mat-icon>
                      </button>
                    </div>
                  </div>
                </mat-chip-list>
              </mat-form-field>
              <ng-container *ngIf="this.fieldsOptions[field.name] != 'compreso'">
                <mat-form-field *ngSwitchCase="'FieldBaseForSearchDateTimeDto'" class="form-field"
                                appearance="outline">
                  <mat-label>{{field.description}}</mat-label>
                  <button [matMenuTriggerFor]="menu" mat-icon-button matPrefix
                          style="margin-right: 5px; border-right: 0.5px solid {{this.apiService.squareColor}}">
                    <mat-icon style="color: {{this.apiService.squareColor}}">filter_alt</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="this.setFieldOptions(field.name, 'non compreso')">
                      = Uguale
                    </button>
                    <button mat-menu-item (click)="this.setFieldOptions(field.name, 'compreso')">
                      [] Compreso
                    </button>
                  </mat-menu>
                  <input style="color:#29648A;" [value]="field.value || ''" matInput
                         [matDatepicker]="picker" onkeydown="return false;"
                         [(ngModel)]="dataMaskToSave[field.name + 'valore1']" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         (click)="picker.open()">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker disabled="false" #picker color="primary"></mat-datepicker>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="this.fieldsOptions[field.name] == 'compreso'">
                <mat-form-field *ngSwitchCase="'FieldBaseForSearchDateTimeDto'" class="form-field"
                                style="width: 20.75%;"
                                appearance="outline">
                  <mat-label>Inizio: {{field.description}}</mat-label>
                  <button [matMenuTriggerFor]="menu" mat-icon-button matPrefix
                          style="margin-right: 5px; border-right: 0.5px solid {{this.apiService.squareColor}}">
                    <mat-icon style="color: {{this.apiService.squareColor}}">filter_alt</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="this.setFieldOptions(field.name, 'non compreso')">
                      = Uguale
                    </button>
                    <button mat-menu-item (click)="this.setFieldOptions(field.name, 'compreso')">
                      [] Compreso
                    </button>
                  </mat-menu>
                  <input style="color:#29648A;" [value]="field.value || ''" matInput
                         [matDatepicker]="picker" onkeydown="return false;"
                         [(ngModel)]="dataMaskToSave[field.name + 'valore1']" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         (click)="picker.open()">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker disabled="false" #picker color="primary"></mat-datepicker>
                </mat-form-field>
                <mat-form-field *ngSwitchCase="'FieldBaseForSearchDateTimeDto'" class="form-field"
                                style="width: 20.75%; margin-left: 1%"
                                appearance="outline">
                  <mat-label>Fine: {{field.description}}</mat-label>
                  <input style="color:#29648A;" [value]="field.value || ''" matInput
                         [matDatepicker]="picker" onkeydown="return false;"
                         [(ngModel)]="dataMaskToSave[field.name + 'valore2']" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         (click)="picker.open()">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker disabled="false" #picker color="primary"></mat-datepicker>
                </mat-form-field>
                <!--<mat-form-field *ngSwitchDefault class="form-field" appearance="outline">
                    <mat-label>{{field.description}}</mat-label>
                    <input matInput placeholder="{{field.description}}">
                </mat-form-field>--> <!--nome timbro, sostitutiva, protocollo pa-->
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="field.isAdditional == false">
            <ng-container *ngFor="let voce of this.menuNameFields">
              <ng-container *ngIf="voce.name == field.name && voce.group == 0">
                <ng-container [ngSwitch]="field.className"
                              *ngIf="field.visible" style="height:fit-content; ">

                  <div *ngSwitchCase="'FieldBaseForSearchDocumentTypeDto'" class="form-field" style=" display: flex;">


                    <mat-form-field style="display: block; width:  90%;"
                                    appearance="outline">
                      <mat-label>{{field.description}}</mat-label>
                      <mat-select [value]="field.value || ''" [id]="field.name"
                                  [required]="field.required"
                                  [(ngModel)]="dataMaskToSave[field.name]"
                                  name="{{field.name}}"
                                  [disabled]="field.locked"
                      >
                        <mat-option
                          (onSelectionChange)="documentChanged($event, documenType.documentType,documenType.type2,documenType.type3)"
                          *ngFor="let documenType of documenTypes"
                          [value]="documenType.id">
                          {{documenType.description}} - {{documenType.key}}
                        </mat-option>
                      </mat-select>

                    </mat-form-field>
                    <span>
                                    <button style="width: 3%;"
                                            style="color: {{this.dashColor}};float: right;"
                                            title="{{'labels.cancelFields' | translate}}" *ngIf="true"
                                            (click)="cleanField(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                    <button [disabled]="field.locked" matSuffix mat-icon-button *ngIf="true"
                                            style="width: 3%;"
                                            style="color: {{this.dashColor}};"
                                            (click)="opendocumentalClassDialog()">
                                        <mat-icon style="vertical-align: bottom;">more_vert</mat-icon>
                                    </button>
                                </span>
                  </div>
                  <mat-form-field *ngSwitchCase="'FieldBaseForSearchAooDto'" class="form-field"
                                  appearance="outline">
                    <mat-label>{{'labels.company' | translate}}</mat-label>
                    <!--<button mat-icon-button matPrefix
                            style="margin-right: 5px; border-right: 0.5px solid {{this.apiService.squareColor}}">
                        <mat-icon style="color: {{this.apiService.squareColor}}">filter_alt</mat-icon>
                    </button>-->
                    <mat-select [id]="field.name" [value]="field.value || ''"
                                [required]="field.required"
                                [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                [disabled]="field.locked">
                      <mat-option *ngFor="let aoo of nomiAoo" [value]="aoo.code">
                        {{aoo.code}} - {{aoo.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <h3 *ngSwitchCase="'FieldBaseForSearchDTO'" style="width: 95%">
                    <!--Campo aggiuntivo "Generale"-->
                    <br>{{field.description}}<br>
                    <hr>
                  </h3>

                  <mat-form-field *ngSwitchCase="'FieldBaseForSearchStringDto'" class="form-field"
                                  appearance="outline">
                    <mat-label>{{field.description}}</mat-label>
                    <!--<button mat-icon-button matPrefix
                            style="margin-right: 5px; border-right: 0.5px solid {{this.apiService.squareColor}}">
                        <mat-icon style="color: {{this.apiService.squareColor}}">filter_alt</mat-icon>
                    </button>-->

                    <ng-container *ngIf="field.name == 'WorkFlow'">
                      <mat-select [id]="field.name" [value]="field.value || ''"
                                  [required]="field.required"
                                  [(ngModel)]="dataMaskToSave[field.name]"
                                  name="{{field.name}}"
                                  [disabled]="field.locked">
                        <mat-option *ngFor="let wf of this.workFlow"
                                    [value]="wf.id">
                          {{wf.name}}
                        </mat-option>
                      </mat-select>
                    </ng-container>
                    <ng-container *ngIf="field.name == 'Stato'">

                      <mat-select [id]="field.name" [value]="field.value || ''"
                                  [required]="field.required"
                                  [(ngModel)]="dataMaskToSave[field.name]"
                                  name="{{field.name}}"
                                  [disabled]="field.locked">
                        <mat-option *ngFor="let stateField of stateFields"
                                    [value]="stateField.id">
                          {{stateField.description}}
                        </mat-option>
                      </mat-select>
                    </ng-container>
                    <ng-container
                      *ngIf="field.name != 'Stato' && field.name != 'WorkFlow'">
                      <ng-container *ngIf="field.additionalFieldType == 0">
                        <input ngModel [value]="field.value || ''"
                               [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                               [id]="field.name" [required]="field.required" [disabled]="field.locked"
                               maxlength="{{field.numMaxChar || 100}}" matInput
                               placeholder="{{field.description}}">
                      </ng-container>
                      <ng-container *ngIf="field.additionalFieldType == 3">
                        <mat-select [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                    [id]="field.name">
                          <mat-option>--{{'labels.cancel' | translate}}--</mat-option>
                          <mat-option *ngFor="let val of this.comboValues[field.name]" [value]="val">
                            {{val}}
                          </mat-option>
                        </mat-select>
                      </ng-container>
                      <ng-container *ngIf="field.additionalFieldType == 4">
                        <!--TableDTO-->
                        <input (click)="openTableDTO(field)" ngModel [value]="field.value || ''"
                               [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                               [id]="field.name" [required]="field.required" [disabled]="field.locked"
                               maxlength="{{field.numMaxChar || 100}}" matInput
                               placeholder="{{field.description}}">
                        <button mat-button matSuffix mat-icon-button (click)="openTableDTO(field)"
                                style="color: {{this.apiService.dashColor}}; float: right;">
                          <mat-icon>table_chart</mat-icon>
                        </button>
                      </ng-container>

                    </ng-container>


                  </mat-form-field>
                  <div *ngSwitchCase="'FieldBaseForSearchIntDto'"
                       appearance="outline" class="form-field">
                    <!--Tipo documento-->
                    <mat-form-field style="display: block"
                                    appearance="outline">
                      <mat-label>{{field.description}}</mat-label>
                      <!-- <button mat-icon-button matPrefix
                               style="margin-right: 5px; border-right: 0.5px solid {{this.apiService.squareColor}}">
                           <mat-icon style="color: {{this.apiService.squareColor}}">filter_alt</mat-icon>
                       </button>-->
                      <ng-container *ngIf="field.name == 'Origine'">

                        <mat-select [id]="field.name" [id]="field.name"
                                    [value]="field.value || ''"
                                    [required]="field.required"
                                    [(ngModel)]="dataMaskToSave[field.name]"
                                    name="{{field.name}}"
                                    [disabled]="field.locked">
                          <mat-option
                            *ngFor="let originField of originsFields"
                            [value]="originField.value">
                            {{originField.description}}
                          </mat-option>
                        </mat-select>
                      </ng-container>
                      <ng-container *ngIf="field.name == 'Autore'">

                        <mat-select [id]="field.name" [id]="field.name"
                                    [value]="field.value || ''"
                                    [required]="field.required"
                                    [(ngModel)]="dataMaskToSave[field.name]"
                                    name="{{field.name}}"
                                    [disabled]="field.locked">
                          <mat-option *ngFor="let author of this.users"
                                      [value]="author.user">
                            {{author.completeName}}
                          </mat-option>
                        </mat-select>
                      </ng-container>
                      <ng-container
                        *ngIf="field.name != 'Origine' && field.name != 'Autore'">
                        <input ngModel [value]="field.value || ''" type="number"
                               [(ngModel)]="dataMaskToSave[field.name]"
                               name="{{field.name}}"
                               [id]="field.name" [required]="field.required"
                               [disabled]="field.locked"
                               maxlength="{{field.numMaxChar || 100}}" matInput
                               placeholder="{{field.description}}">
                      </ng-container>
                    </mat-form-field>
                    <span>
                                    <button
                                      style="color: {{this.dashColor}};float: right;"
                                      title="{{'labels.cancelFields' | translate}}" *ngIf="false"
                                      (click)="cleanFieldContact(field.name)"
                                      mat-icon-button
                                      aria-label="icon-button send">
                                        <mat-icon>cleaning_services</mat-icon>
                                    </button>
                                </span>
                  </div>
                  <mat-form-field *ngSwitchCase="'FieldBaseForSearchContactDto'" class="form-field"
                                  appearance="outline">
                    <!--DA-->
                    <mat-label>{{field.description}}</mat-label>
                    <mat-chip-list #chipList aria-label="MultiValue">
                      <div style="width: 100%; display: flex;">
                        <div style="width: 80%">
                          <mat-chip *ngFor="let element of this.mapAddressBookToSee.get(field.name)"
                                    [selectable]="false"
                                    [disabled]="field.locked"
                                    [removable]="removable" (removed)="removeAddressBook(element, field.name)"
                                    [value]="element.id">
                            {{element.name}}
                            <mat-icon matChipRemove *ngIf="removable && !(field.locked)">cancel</mat-icon>
                          </mat-chip>
                        </div>
                        <div style="width: 20%; text-align: right">
                          <button matSuffix mat-icon-button (click)="openAddressBook(field)"
                                  style="color: {{this.apiService.dashColor}}" [disabled]="field.locked">
                            <mat-icon>notes</mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-chip-list>
                  </mat-form-field>
                  <ng-container *ngIf="this.fieldsOptions[field.name] != 'compreso'">
                    <mat-form-field *ngSwitchCase="'FieldBaseForSearchDateTimeDto'" class="form-field"
                                    appearance="outline">
                      <mat-label>{{field.description}}</mat-label>
                      <button [matMenuTriggerFor]="menu" mat-icon-button matPrefix
                              style="margin-right: 5px; border-right: 0.5px solid {{this.apiService.squareColor}}">
                        <mat-icon style="color: {{this.apiService.squareColor}}">filter_alt</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="this.setFieldOptions(field.name, 'non compreso')">
                          = {{'labels.same' | translate }}
                        </button>
                        <button mat-menu-item (click)="this.setFieldOptions(field.name, 'compreso')">
                          [] {{'labels.included' | translate }}
                        </button>
                      </mat-menu>
                      <input style="color:#29648A;" [value]="field.value || ''" matInput
                             [matDatepicker]="picker" onkeydown="return false;"
                             [(ngModel)]="dataMaskToSave[field.name + 'valore1']" name="{{field.name}}"
                             [id]="field.name" [required]="field.required" [disabled]="field.locked"
                             (click)="picker.open()">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker disabled="false" #picker color="primary"></mat-datepicker>
                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngIf="this.fieldsOptions[field.name] == 'compreso'">
                    <mat-form-field *ngSwitchCase="'FieldBaseForSearchDateTimeDto'" class="form-field"
                                    style="width: 20.75%;"
                                    appearance="outline">
                      <mat-label>{{'labels.start' | translate}}: {{field.description}}</mat-label>
                      <button [matMenuTriggerFor]="menu" mat-icon-button matPrefix
                              style="margin-right: 5px; border-right: 0.5px solid {{this.apiService.squareColor}}">
                        <mat-icon style="color: {{this.apiService.squareColor}}">filter_alt</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="this.setFieldOptions(field.name, 'non compreso')">
                          = {{'labels.same' | translate}}
                        </button>
                        <button mat-menu-item (click)="this.setFieldOptions(field.name, 'compreso')">
                          <> {{'labels.included' | translate}}
                        </button>
                      </mat-menu>
                      <input style="color:#29648A;" [value]="field.value || ''" matInput
                             [matDatepicker]="picker" onkeydown="return false;"
                             [(ngModel)]="dataMaskToSave[field.name + 'valore1']" name="{{field.name}}"
                             [id]="field.name" [required]="field.required" [disabled]="field.locked"
                             (click)="picker.open()">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker disabled="false" #picker color="primary"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field *ngSwitchCase="'FieldBaseForSearchDateTimeDto'" class="form-field"
                                    style="width: 20.75%; margin-left: 1%"
                                    appearance="outline">
                      <mat-label>{{'labels.end' | translate}}: {{field.description}}</mat-label>
                      <input style="color:#29648A;" [value]="field.value || ''" matInput
                             [matDatepicker]="picker" onkeydown="return false;"
                             [(ngModel)]="dataMaskToSave[field.name + 'valore2']" name="{{field.name}}"
                             [id]="field.name" [required]="field.required" [disabled]="field.locked"
                             (click)="picker.open()">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker disabled="false" #picker color="primary"></mat-datepicker>
                    </mat-form-field>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>


        <!--/mat-grid-list-->
        <mat-card-actions>
        </mat-card-actions>


      </form>


    </mat-card-content>

  </mat-card>
</div>
