<!-- Resources -->
<div class="center" style="height: 100%; margin: auto; opacity: 100%" role="main">
  <div class="formo">
    <mat-card class="card">
      <img class="pointer" style="vertical-align: middle; margin-bottom: 5%;     padding: 25px;
    max-width: 100%;"
           alt="Logo" [src]="img"/>
      <mat-card-content *ngIf="this.changePasswordVar == false">
        <form *ngIf = "this.forgotPassword == false">
          <mat-form-field class="full-width-input" appearance="outline">
            <mat-label>Username</mat-label>
            <input matInput [(ngModel)]="username" [formControl]="usernameFormControl" [errorStateMatcher]="matcher"
                   placeholder="Username">
            <mat-error *ngIf="usernameFormControl.hasError('required')">
              {{'advices.usernameRequired' | translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input" appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput [(ngModel)]="password" [formControl]="passwordFormControl" [errorStateMatcher]="matcher"
                   [type]="hide ? 'password' : 'text'" placeholder="Password">
            <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="passwordFormControl.hasError('required')">
              {{'passwordRequired' | translate}}
            </mat-error>
          </mat-form-field>
          <p *ngIf="this.expired == true" style="color: red">{{'labels.sessionExpired' | translate}}</p>
          <mat-card-actions>
            <button type="submit" class="login-button" (click)="login()" mat-stroked-button *ngIf="forgotPassword == false">{{'buttons.login' | translate}}</button>
            <button type="submit" class="login-button" (click)="retrievePasswordClick()" mat-stroked-button *ngIf="forgotPassword == true">{{'buttons.send' | translate}}</button>
          </mat-card-actions>
        </form>
        <form *ngIf = "this.forgotPassword == true">
          <h2>{{'labels.enterUsernameForPasswordRecovery' | translate}}</h2>
          <mat-form-field class="full-width-input" appearance="outline">
            <mat-label>Username</mat-label>
            <input matInput [(ngModel)]="username" [formControl]="usernameFormControl" [errorStateMatcher]="matcher"
                   placeholder="Username">
            <mat-error *ngIf="usernameFormControl.hasError('required')">
              {{'advices.usernameRequired' | translate}}
            </mat-error>
          </mat-form-field>
          <p *ngIf="this.expired == true" style="color: red">{{'labels.sessionExpired' | translate}}</p>
          <mat-card-actions>
            <button type="submit" class="login-button" (click)="login()" mat-stroked-button *ngIf="forgotPassword == false">{{'buttons.login' | translate}}</button>
            <button type="submit" class="login-button" (click)="retrievePasswordClick()" mat-stroked-button *ngIf="forgotPassword == true">{{'buttons.send' | translate}}</button>
          </mat-card-actions>
        </form>

        <div style = "margin-top: 2%">
          <button class="link" (click) = "forgotClicked()" *ngIf="forgotPassword == false">{{'labels.forgotPassword' | translate}}
          </button>
          <button mat-stroked-button (click) = "forgotClicked()" *ngIf="forgotPassword == true">{{'buttons.goBack' | translate}}</button>
        </div>
        <div style = "margin-top: 2%" *ngIf = "true">
          <button class="link" (click) = "useLanguage('it')" *ngIf = "true">IT</button>
          <span *ngIf = "true">/</span>
          <button class="link" (click) = "useLanguage('en')" *ngIf = "true">EN</button>
        </div>
      </mat-card-content>
      <mat-card-content *ngIf="this.changePasswordVar == true && this.forgotPassword == false">
        <div style="text-align: center">
          <h2>{{'labels.changePassword' | translate}}</h2>
          <hr>
          <br>
          <mat-form-field class="full-width-input" appearance="outline">
            <mat-label>{{'labels.oldPassword' | translate}}</mat-label>
            <input matInput required (change)="checkErrors()" [(ngModel)]="this.oldPassword" type="password">
          </mat-form-field>
          <mat-form-field class="full-width-input" appearance="outline">
            <mat-label>{{'labels.newPassword' | translate}}</mat-label>
            <input matInput required (change)="checkErrors()" [(ngModel)]="this.newPassword" type="password">
          </mat-form-field>
          <mat-form-field class="full-width-input" appearance="outline">
            <mat-label>{{'labels.confirmPassword' | translate}}</mat-label>
            <input matInput required (change)="checkErrors()" [(ngModel)]="this.confirmPassword" type="password">
          </mat-form-field>
          <button mat-stroked-button (click)="changePassword()">{{'labels.changePassword' | translate}}</button>
          <br>
          <p style="color: red;">{{this.error}}</p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<footer style="text-align: center">
  <p>
    <span style="color: white">Created by </span>
    <a target="_blank" href="https://www.betsoft-srl.it/" style="color: #3c97bf;">Betsoft S.r.l</a>
  </p>
</footer>

<div class="bg"></div>
<div class="bg bg2"></div>
<div class="bg bg3"></div>






