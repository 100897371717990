<div style = "display: block">
  <div style = "height: 10%; width: 100%; display: flex">
    <h3 style="color: {{this.apiService.dashColor}}; width: 60%">Elenco prenotazioni
      giorno {{this.TransformDataFormatToShow(this.eventDay)}}:</h3>
    <div style = " width: 40%; text-align: right">
      <button title="Salva"  (click)="closeDialog()" mat-icon-button color="warn"
              aria-label="icon-button save">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <hr class="riga" style="color:{{this.apiService.dashColor}};">

  <div>
    <mat-list role="list">
      <ng-container *ngFor = "let event of this.events">
        <mat-list-item role="listitem" style = "border-bottom: 1px solid {{event['color']}}">{{event['dipendente']}} ({{event['tipologia']}}, {{event['inizio']}} - {{event['fine']}})</mat-list-item>
      </ng-container>

    </mat-list>
  </div>

</div>

