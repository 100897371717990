import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {ApiService} from '../api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {Observable} from 'rxjs';
import {error} from 'selenium-webdriver';
import {ToolbarComponent} from '../layout/toolbar/toolbar.component';
import {BodyFieldToSave} from '../bodyFieldToSave';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  oldPassword = '';
  newPassword = '';
  confirmPassword = '';
  constrains: any;
  error: string;
  expired = this.activatedRoute.snapshot.paramMap.get('expired');
  username: string;
  password: string;
  isSettingVisible = false;
  URL = this.apiService.BASE_URL;
  ID = this.apiService.CLIENT_ID;
  SECRET = this.apiService.CLIENT_SECRET;
  changePasswordVar = false;
  img: string;
  usernameFormControl = new UntypedFormControl('', [
    Validators.required,
  ]);
  passwordFormControl = new UntypedFormControl('', [
    Validators.required,
  ]);
  hide = true;
  forgotPassword = false;
  matcher = new MyErrorStateMatcher();

  constructor(private http: HttpClient, private translate: TranslateService, public apiService: ApiService, private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      let code = params['code'];

      // console.log(code);// OUTPUT 1534
    });
    this.apiService.getLogo().subscribe(data => {
        this.img = 'data:image/png;base64,' + data[0].logo;
        /*this.http.get('assets/Assets.json').subscribe(logo => {
          let jsonData: any;
          jsonData = logo;
          this.img = jsonData.LOGO;
        });*/
      }
    );
  }

  useLanguage(language: string): void {
    this.translate.use(language);
    localStorage.setItem('lang', language);
  }

  retrievePasswordClick(): void {
    this.apiService.retrievePassword(this.username).subscribe(res => {
        this.translate.get('advices.passwordRestoredSuccessfully').subscribe(result => {
          this.snackBar.open(result, null, {
            duration: 2000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: ['mat-toolbar', 'custom-success-bg']
          });
        });
        this.forgotPassword = false;
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
        if (error.error.userMessage) {
          this.snackBar.open(error.error.userMessage, null, {
            duration: 2000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        } else {
          this.translate.get('advices.errorWithArxivarConnection').subscribe(result => {
            this.snackBar.open(result, null, {
              duration: 2000,
              horizontalPosition: 'end',
              verticalPosition: 'top',
              panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
          });
        }
      });
  }

  checkErrors(): void {

    if (this.newPassword === this.oldPassword) {
      this.translate.get('advices.newPasswordSameAsOld').subscribe(res => {
        this.error = res;
      });
    } else if (this.newPassword !== this.confirmPassword) {
      this.translate.get('advices.passwordMustMatch').subscribe(res => {
        this.error = res;
      });
    } else {
      this.error = '';
    }

  }

  forgotClicked(): void {
    this.forgotPassword = !this.forgotPassword;
  }

  changePassword(): void {
    if (this.oldPassword !== '' && this.newPassword !== '' && this.confirmPassword !== '') {
      if (this.newPassword === this.oldPassword) {
        this.translate.get('advices.newPasswordSameAsOld').subscribe(res => {
          this.error = res;
        });
      } else if (this.newPassword !== this.confirmPassword) {
        this.translate.get('advices.passwordMustMatch').subscribe(res => {
          this.error = res;
        });
      } else {
        this.apiService.changePassword(this.oldPassword, this.newPassword).subscribe(res => {
          this.router.navigate(['dashboard']);
          this.translate.get('advices.passwordChangedSuccessfully').subscribe(result => {
            this.snackBar.open(result, null, {
              duration: 3000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-success-bg']
            });
          });
        }, errorLog => {
          this.snackBar.open(errorLog.error.userMessage, null, {
            duration: 5000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      }
    } else {
      this.translate.get('advices.allFieldsMustBeCompiled').subscribe(res => {
        this.error = res;
      });
    }
  }

  openAvDemo(): void {
    window.open('http://av-demo.frontendly.it/login');
  }

  openAcDemo(): void {
    window.open('http://ac-demo.frontendly.it/login');
  }

  openGeDemo(): void {
    window.open('http://ge-demo.frontendly.it/login');
  }

  openLaDemo(): void {
    window.open('http://la-demo.frontendly.it/login');
  }

  setSettings(): void {
    if (this.isSettingVisible) {
      this.isSettingVisible = false;
    } else {
      this.isSettingVisible = true;
    }
  }

  login(): void {

    if (!this.passwordFormControl.hasError('required') && !this.usernameFormControl.hasError('required')) {
      const credentialUser: JSON = {
        languageCultureName: localStorage.getItem('lang'),
        username: this.username,
        password: this.password,
        clientId: this.apiService.CLIENT_ID,
        clientSecret: this.apiService.CLIENT_SECRET
      } as unknown as JSON;
      this.apiService.login(credentialUser).subscribe(data => {
          this.changePasswordVar = false;
          this.apiService.setLoggedInInformation(data);
          localStorage.setItem('username', this.username);
          localStorage.setItem('expires', data.expires);
          if (data.arxuserMustChangePassword) {
            this.changePasswordVar = true;
          } else {
            this.router.navigate(['dashboard']);
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => {
          this.snackBar.open(error.error.userMessage, null, {
            duration: 2000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
    }
  }

  saveConnection(): void {
    localStorage.setItem('BASE_URL', this.URL);
    localStorage.setItem('CLIENT_ID', this.ID);
    localStorage.setItem('CLIENT_SECRET', this.SECRET);
    window.location.reload();
  }

}
