<div style="width: 100%">
    <button style="float: right;" title="{{'labels.close' | translate}}" (click)="closeDialog()" mat-icon-button color="warn"
            aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div style="width: 100%; display: flex">
    <h2 style="text-align: center; width: 100%;">{{'labels.folder' | translate}}: {{this.name}}</h2>
</div>
<div style="text-align: center">
    <p>
        <b>ID: </b>{{this.id}}
    </p>
    <p>
        <b>{{'labels.author' | translate}}: </b>{{this.author}}
    </p>
    <p>
        <b>{{'labels.documentsContained' | translate}}</b>{{this.nDocs}}
    </p>
    <p>
        <b>{{'labels.foldersContained' | translate}}</b>{{this.nChilds}}
    </p>
</div>

