import {AfterViewInit, Component, Inject, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../api.service';
import {Output, EventEmitter, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {Router, ParamMap} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CommentoDialog, CommentoDialogComponent} from '../dettaglitask/dettaglitask.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {TemplatePortal} from '@angular/cdk/portal';
import {fromEvent, Subscription} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-rubrica',
  templateUrl: './rubrica.component.html',
  styleUrls: ['./rubrica.component.css']
})

export class RubricaComponent implements OnInit, AfterViewInit {
  MatRubPubblica: [][];
  utentiInterni: Array<any> = new Array<any>();
  utentiPubblici: Array<any> = new Array<any>();
  utentiPubbliciTable: Array<any> = new Array<any>();
  utentiPrivati: Array<any> = new Array<any>();
  utentiPrivatiTable: Array<any> = new Array<any>();
  panelOpenState = false;
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;
  underCatPub: Array<any> = new Array<any>();
  underCatPriv: Array<any> = new Array<any>();
  publicUsers: any;
  displayedColumns: string[] = ['Ragione_sociale', 'Codice', 'id'];
  clickedRagSoc: string;
  clickedType: string;
  publicPaginators: Array<any> = new Array<any>();
  clickedId: string;
  private dataSourcePU: Array<MatTableDataSource<any>> = new Array<MatTableDataSource<any>>();
  private dataSourcePR: Array<MatTableDataSource<any>> = new Array<MatTableDataSource<any>>();
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild('userMenu') userMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  sub: Subscription;

  // tslint:disable-next-line:variable-name max-line-length
  constructor(public translate: TranslateService, public dialog: MatDialog, private snackBar: MatSnackBar, private route: ActivatedRoute,
              private router: Router, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
              public apiService: ApiService, private activatedRoute: ActivatedRoute,
              public overlay: Overlay, public viewContainerRef: ViewContainerRef) {
    iconRegistry.addSvgIcon(
      'CartellaIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/folder-24px.svg'));

  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      let ind = 0;
      this.underCatPub.forEach((u, index) => {
        this.dataSourcePU[u.name].paginator = this.paginator.toArray()[index];
        ind = index;
      });
      this.underCatPriv.forEach((u) => {
        this.dataSourcePR[u.name].paginator = this.paginator.toArray()[++ind];
      });
    }, 1000);

  }

  open({x, y}: MouseEvent, user, ragSoc, id, type): void {
    this.clickedRagSoc = ragSoc;
    this.clickedId = id;
    this.clickedType = type;
    this.close();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({x, y})
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.userMenu, this.viewContainerRef, {
      $implicit: user
    }));

    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close());
  }


  close(): void {
    // tslint:disable-next-line:no-unused-expression
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  ngOnInit(): void {
    this.apiService.namePath = this.apiService.namePath.split('-')[0];
    this.utentiInterni = new Array<any>();
    this.utentiPubblici = new Array<any>();
    this.utentiPrivati = new Array<any>();
    this.underCatPub = new Array<any>();
    this.underCatPriv = new Array<any>();
    this.dashColor = this.apiService.dashColor;
    this.backColor = this.apiService.backColor;
    this.squareColor = this.apiService.squareColor;
    this.cardColor = this.apiService.cardColor;





    /*this.apiService.getAddressBook(addressbookpubbliche).subscribe(data => {
        console.log('pubbliche');
        console.log(data);
        this.utentiPubblici = this.apiService.mappingObject(
            this.apiService.entriesObject(
                this.apiService.entriesObject(data)
            )
        );
        console.log(this.utentiPubbliciTable);
        this.underCatPub.forEach(c => {
            this.dataSourcePU[c.name] = new MatTableDataSource(this.utentiPubbliciTable[c.name]);
        });
        this.ngAfterViewInit();
    });*/

    this.apiService.getAddressBookCategry().subscribe(data => {
      data.forEach(val => {
        if (val.id > 0 && val.type === 0) {
          this.underCatPub.push({name: val.addressBook, id: val.id});
        } else if (val.id > 0 && val.type === 1) {
          this.underCatPriv.push({name: val.addressBook, id: val.id});
        }
      });
      // console.log(this.underCatPub);
      // console.log(this.underCatPriv);
      this.apiService.SearchAllAddressBook().subscribe(results => {

        this.underCatPub.forEach(c => {
          this.utentiPubbliciTable[c.name] = [];
        });

        results.data.forEach(d => {
          this.underCatPub.forEach(c => {
            if (d[8] === c.name) {
              this.utentiPubbliciTable[c.name].push({Ragione_sociale: d[4], Codice: d[2], id: d[3]});
            }
          });
        });
        this.underCatPriv.forEach(c => {
          this.utentiPrivatiTable[c.name] = [];
        });

        results.data.forEach(d => {
          this.underCatPriv.forEach(c => {
            if (d[8] === c.name) {
              this.utentiPrivatiTable[c.name].push({Ragione_sociale: d[4], Codice: d[2], id: d[3]});
            }
          });
        });
        this.underCatPub.forEach(c => {
          this.dataSourcePU[c.name] = new MatTableDataSource(this.utentiPubbliciTable[c.name]);
        });
        this.underCatPriv.forEach(c => {
          this.dataSourcePR[c.name] = new MatTableDataSource(this.utentiPrivatiTable[c.name]);
        });
        this.ngAfterViewInit();
      });
    });

  }

  applyFilter(event: Event, catName): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourcePU[catName].filter = filterValue.trim().toLowerCase();

    if (this.dataSourcePU[catName].paginator) {
      this.dataSourcePU[catName].paginator.firstPage();
    }
  }

  applyFilterPR(event: Event, catName): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourcePR[catName].filter = filterValue.trim().toLowerCase();

    if (this.dataSourcePR[catName].paginator) {
      this.dataSourcePR[catName].paginator.firstPage();
    }
  }

  delete(idC: string): void {
    const idArray: Array<string> = new Array<string>();
    idArray.push(idC);
    this.apiService.deleteAddressBook(idArray).subscribe(data => {
      this.snackBar.open(this.translate.instant('advices.contactDeleted'), null, {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-warning-bg']
      });
      this.ngOnInit();
    });
  }

  addContact(): void {
    this.router.navigate(['../dettaglirubrica', {new: 'new', cat: 'new'}], {relativeTo: this.route});
  }

  addCategory(category: string,): void {
    const body: JSON = {
      addressBook: 'prova 2',
      default: false,
      type: 'Public'
    } as unknown as JSON;
  }

  openRenameCategoryDialog(idC: string, nameC: string, category: string): void {
    const dialogRef = this.dialog.open(RenameCategoryDialogComponent, {
      width: '20%',
      data: {id: idC, name: nameC, cat: category}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.res === true) {
        this.snackBar.open(this.translate.instant('advices.categoryRenamed'), null, {
          duration: 3000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });
        this.ngOnInit();
      }
    });
  }

  deleteCat(id: string): void {
    this.apiService.deleteCategory(id).subscribe(data => {
      this.snackBar.open(this.translate.instant('advices.categoryDeleted'), null, {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-warning-bg']
      });
      this.ngOnInit();
    });
  }

  clickRubrica(idr: string, catR: string): void {
    this.router.navigate(['../dettaglirubrica', {id: idr, cat: catR}], {relativeTo: this.route});
  }

  openAddCategoryDialog(category: string): void {
    const dialogRef = this.dialog.open(AddCategoryDialogComponent, {
      width: '20%',
      data: {cat: category}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.res === true) {
        this.ngOnInit();
      }
    });
  }
}


@Component({
  selector: 'app-addcatdialog',
  templateUrl: 'addCategoryDialog.html',
})
export class AddCategoryDialogComponent {
  newCat = '';
  name: string;
  cat: string;

  constructor(public apiService: ApiService,
              public dialogRef: MatDialogRef<AddCategoryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.cat = data.cat;
    if (data.cat === 'Public') {
      this.name = 'Rubriche pubbliche';
    }
    if (data.cat === 'Private') {
      this.name = 'Rubriche private';
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  create(): void {
    const body: JSON = {
      addressBook: this.newCat,
      default: false,
      type: this.cat
    } as unknown as JSON;
    this.apiService.addCategory(body).subscribe(data => {
      this.dialogRef.close({res: true});
    });
  }

  closeDialog(): void {
    this.dialogRef.close({res: false});
  }
}

@Component({
  selector: 'app-renamecatdialog',
  templateUrl: 'renameCategoryDialog.html',
})
export class RenameCategoryDialogComponent {
  newCat = '';
  name: string;
  cat: number;
  id: string;

  constructor(public apiService: ApiService,
              public dialogRef: MatDialogRef<RenameCategoryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.newCat = data.name;
    this.id = data.id;
    if (data.cat === 'Public') {
      this.cat = 0;
    }
    if (data.cat === 'Private') {
      this.cat = 1;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  rename(): void {
    const body: JSON = {
      id: this.id,
      addressBook: this.newCat,
      default: false,
      type: this.cat
    } as unknown as JSON;
    this.apiService.renameCategory(body).subscribe(data => {
      this.dialogRef.close({res: true});
    });
  }

  closeDialog(): void {
    this.dialogRef.close({res: false});
  }
}
