<ng-container *ngIf="!this.isTask">
  <div style="width: 100%">
    <button style="float: right;" title="{{'labels.save' | translate}}" (click)="closeDialog()" mat-icon-button color="warn"
            aria-label="icon-button save">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div style="width: 100%; height: 50px; display: flex;">
    <h2 style="text-align: center; width: 100%;">{{'labels.attachmentsList' | translate}}: ({{this.attachments.length}})</h2>
  </div>

  <div style="text-align: center">
    <cdk-virtual-scroll-viewport itemSize="50" class="cdk" id="style-4">
      <ng-container *ngIf="this.attachments.length == 0">
        {{'labels.noAttachmentsFound' | translate}}
      </ng-container>
      <div style="text-align: left;">
        <mat-selection-list #viste [multiple]="false">
          <ng-container *ngIf="this.attachments.length != 0">
            <div *ngFor="let attack of this.attachments" style="display: flex">

              <mat-list-item (click)=downloadDoc(attack.id,attack.originalname) style="width: 80%;">
                <div style="width: 100%">
                  <ng-container
                    *ngIf="(attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))=='pdf'">
                    <mat-icon>picture_as_pdf</mat-icon>
                  </ng-container>
                  <ng-container
                    *ngIf="(attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))=='docx'">
                    <mat-icon>description</mat-icon>
                  </ng-container>
                  <ng-container
                    *ngIf="(attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))=='doc'">
                    <mat-icon>article</mat-icon>
                  </ng-container>
                  <ng-container
                    *ngIf="(attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))=='xlsx'">
                    <mat-icon>ballot</mat-icon>
                  </ng-container>
                  <ng-container
                    *ngIf="(attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))=='csv'">
                    <mat-icon>dashboard</mat-icon>
                  </ng-container>
                  <ng-container
                    *ngIf="(attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))!='docx' && (attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))!='pdf' && (attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))!='doc' && (attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))!='xlsx' && (attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))!='csv'">
                    {{(attack.originalname.substr(attack.originalname.indexOf('.') + 1, attack.originalname.length))}}
                  </ng-container>
                  <span style="margin-left: 10px;">{{attack.originalname}}</span>
                </div>
                <div style="width: 100%">
              <span *ngIf="attack.comment != ''"
                    style="margin-left: 10px; color: {{this.dashColor}};">{{attack.comment}}</span>
                  <span
                    style="margin-left: 10px; color: darkgrey;">{{this.TransformDataFormatToShow(attack.importdate)}}</span>
                </div>
              </mat-list-item>
              <div style="width: 15%">
                <button mat-icon-button style="color: red; float: right" (click)="this.deleteAttach(attack.id)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>

          </ng-container>
        </mat-selection-list>
      </div>
    </cdk-virtual-scroll-viewport>
    <ng-container *ngIf="!this.isTask"><br>
      <hr>
      <br>

      <button mat-stroked-button style="margin-right: 20px" (click)="openAddExternalAttachmentDialog()">{{'labels.addAttachmentExt' | translate}}
      </button>
      <!-- TODO ALLEGATO INTERNO  -->
      <button mat-stroked-button [disabled]="true">{{'labels.addAttachmentInt' | translate}}</button>
    </ng-container>
    <ng-container *ngIf="this.isTask"><br>
      <hr>
      <br>

      <button mat-stroked-button style="margin-right: 20px" (click)="this.addTaskAttachment('addExternal')">{{'labels.addAttachmentExt' | translate}}
      </button>
      <!-- TODO ALLEGATO INTERNO  -->
      <button mat-stroked-button (click)="this.addTaskAttachment('addInternal')">{{'labels.addAttachmentInt' | translate}}</button>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="this.isTask">
  <div style="width: 100%">
    <button style="float: right;" title="{{'labels.save' | translate}}" (click)="closeDialog()" mat-icon-button color="warn"
            aria-label="icon-button save">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div style="width: 100%; height: fit-content; display: flex;">
    <div style="text-align: center; width: 50%;">
      <h2>{{'labels.attachmentListExt' | translate}}({{this.externalAttach.length}})</h2>
      <cdk-virtual-scroll-viewport itemSize="50" class="cdk" id="style-4">
        <ng-container *ngIf="this.externalAttach.length == 0">
          {{'labels.noAttachmentsFound' | translate}}
        </ng-container>
        <div style="text-align: left;">
          <mat-selection-list #viste [multiple]="false">
            <ng-container *ngIf="this.externalAttach.length != 0">
              <div *ngFor="let attack of this.externalAttach" style="display: flex">

                <mat-list-item (click)="downloadTaskDoc(attack[this.getIndex('ID')],attack[this.getIndex('NOMEORIGINALE')])"
                               style="width: 80%;">
                  <div style="width: 100%">
                    <ng-container
                      *ngIf="(attack[this.getIndex('NOMEORIGINALE')].substr(attack[this.getIndex('NOMEORIGINALE')].indexOf('.')+1, attack[this.getIndex('NOMEORIGINALE')].length))=='pdf'">
                      <mat-icon>picture_as_pdf</mat-icon>
                    </ng-container>
                    <ng-container
                      *ngIf="(attack[this.getIndex('NOMEORIGINALE')].substr(attack[this.getIndex('NOMEORIGINALE')].indexOf('.')+1, attack[this.getIndex('NOMEORIGINALE')].length))=='docx'">
                      <mat-icon>description</mat-icon>
                    </ng-container>
                    <ng-container
                      *ngIf="(attack[this.getIndex('NOMEORIGINALE')].substr(attack[this.getIndex('NOMEORIGINALE')].indexOf('.')+1, attack[this.getIndex('NOMEORIGINALE')].length))=='xls'">
                      <mat-icon style="color: darkgreen;">
                        ballot
                      </mat-icon>
                    </ng-container>
                    <ng-container
                      *ngIf="(attack[this.getIndex('NOMEORIGINALE')].substr(attack[this.getIndex('NOMEORIGINALE')].indexOf('.')+1, attack[this.getIndex('NOMEORIGINALE')].length))=='doc'">
                      <mat-icon>article</mat-icon>
                    </ng-container>
                    <ng-container
                      *ngIf="(attack[this.getIndex('NOMEORIGINALE')].substr(attack[this.getIndex('NOMEORIGINALE')].indexOf('.')+1, attack[this.getIndex('NOMEORIGINALE')].length))=='xlsx'">
                      <mat-icon>ballot</mat-icon>
                    </ng-container>
                    <ng-container
                      *ngIf="(attack[this.getIndex('NOMEORIGINALE')].substr(attack[this.getIndex('NOMEORIGINALE')].indexOf('.')+1, attack[this.getIndex('NOMEORIGINALE')].length))=='csv'">
                      <mat-icon>dashboard</mat-icon>
                    </ng-container>
                    <ng-container
                      *ngIf="(attack[this.getIndex('NOMEORIGINALE')].substr(attack[this.getIndex('NOMEORIGINALE')].indexOf('.')+1, attack[this.getIndex('NOMEORIGINALE')].length))!='docx' && (attack[this.getIndex('NOMEORIGINALE')].substr(attack[this.getIndex('NOMEORIGINALE')].indexOf('.')+1, attack[this.getIndex('NOMEORIGINALE')].length))!='pdf' && (attack[this.getIndex('NOMEORIGINALE')].substr(attack[this.getIndex('NOMEORIGINALE')].indexOf('.')+1, attack[this.getIndex('NOMEORIGINALE')].length))!='doc' && (attack[this.getIndex('NOMEORIGINALE')].substr(attack[this.getIndex('NOMEORIGINALE')].indexOf('.')+1, attack[this.getIndex('NOMEORIGINALE')].length))!='xlsx' && (attack[this.getIndex('NOMEORIGINALE')].substr(attack[this.getIndex('NOMEORIGINALE')].indexOf('.')+1, attack[this.getIndex('NOMEORIGINALE')].length))!='csv' && (attack[this.getIndex('NOMEORIGINALE')].substr(attack[this.getIndex('NOMEORIGINALE')].indexOf('.')+1, attack[this.getIndex('NOMEORIGINALE')].length))!='xls'">

                    </ng-container>
                    <span style="margin-left: 10px;">{{attack[this.getIndex('NOMEORIGINALE')]}}</span>
                  </div>
                  <div style="width: 100%">

                    <span
                      style="margin-left: 10px; color: darkgrey;">{{this.TransformDataFormatToShow(attack[this.getIndex('DATA')])}}</span>
                  </div>
                </mat-list-item>
                <div style="width: 15%">
                  <button mat-icon-button style="color: red; float: right"
                          (click)="this.deleteAttach(attack[this.getIndex('ID')])">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>

            </ng-container>
          </mat-selection-list>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
    <div style="text-align: center; width: 50%;">
      <h2>{{'labels.attachmentListInt' | translate}}({{this.internalAttach.length}})</h2>
      <cdk-virtual-scroll-viewport itemSize="50" class="cdk" id="style-4">
        <ng-container *ngIf="this.internalAttach.length == 0">
          {{'labels.noAttachmentsFound' | translate}}
        </ng-container>
        <div style="text-align: left;">
          <mat-selection-list #viste [multiple]="false">
            <ng-container *ngIf="this.internalAttach.length != 0">
              <div *ngFor="let attack of this.internalAttach" style="display: flex">

                <mat-list-item (click)="downloadTaskDoc(attack[this.getIndex('ID')],'DOC.' + attack[this.getIndex('FORMATO')])"
                               style="width: 80%;">

                  <div style="width: 100%">
                   {{attack[this.getIndex('DM_PROFILE_DOCNAME')]}} - {{attack[this.getIndex('ID')]}}
                    <span
                      style="margin-left: 10px; color: darkgrey;">{{this.TransformDataFormatToShow(attack[this.getIndex('DATA')])}}</span>
                  </div>
                </mat-list-item>
                <div style="width: 15%">
                  <button mat-icon-button style="color: {{this.apiService.dashColor}}; float: right"
                          (click)="this.profileClick(attack[this.getIndex('DOCNUMBER')])">
                    <mat-icon>arrow_forward</mat-icon>
                  </button>
                  <button mat-icon-button style="color: red; float: right"
                          (click)="this.deleteAttach(attack[this.getIndex('ID')])">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>

            </ng-container>
          </mat-selection-list>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>

  <div style="text-align: center">

    <ng-container><br>
      <hr>
      <br>

      <button mat-stroked-button style="margin-right: 20px" (click)="this.addTaskAttachment('addExternal')">{{'labels.addExternalAttachment' | translate}}
      </button>
      <!-- TODO ALLEGATO INTERNO  -->
      <button mat-stroked-button (click)="this.addTaskAttachment('addInternal')">{{'labels.addInternalAttachment' | translate}}</button>
    </ng-container>
  </div>

</ng-container>
