import {ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {parseISO, startOfDay} from 'date-fns';
import {CalendarEvent, CalendarView, DAYS_OF_WEEK} from 'angular-calendar';
import {Subject} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EditRevisionProfileComponent} from '../view/view.component';
import {DatePipe} from '@angular/common';
import {ContextMenuComponent} from 'ngx-contextmenu';
import moment from 'moment';

@Component({
  selector: 'app-tabella-presenze',
  templateUrl: './gestione-prenotazioni.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./gestione-prenotazioni.component.css']
})

export class GestionePrenotazioniAutoComponent implements OnInit {
  @ViewChild(ContextMenuComponent) public basicMenu: ContextMenuComponent;
  dataRifIndex: number;
  tipologiaIndex: number;
  dipendenteIndex: number;
  docnumberIndex: number;
  oraInizioIndex: number;
  oraFineIndex: number;
  refresh: Subject<any> = new Subject();
  eventType = '';
  startHour: any;
  endHour: any;
  view: CalendarView = CalendarView.Month;
  bodyGetPrenotazioni = {
    id: 'f7c9bf5adfd6401fb32d0c684529e885',
    searchFilterDto: {
      description: null,
      daAAndOr: 0,
      fields: []
    },
    selectFilterDto: {
      fields:
        [
          {
            fieldType: 2,
            selected: true,
            name: 'DATA690_193',
            index: 2,
            orderBy: {
              direction: 0,
              index: 0
            }
          },
          {
            fieldType: 2,
            selected: true,
            name: 'COMBO691_193',
            index: 0,
            orderBy: {
              direction: 0,
              index: 0
            }
          },
          {
            fieldType: 2,
            selected: true,
            name: 'COMBO737_204',
            index: 1,
            orderBy: {
              direction: 0,
              index: 0
            }
          },
          {
            fieldType: 2,
            selected: true,
            name: 'NUMERIC738_204',
            index: 3,
            orderBy: {
              direction: 0,
              index: 0
            }
          },
          {
            fieldType: 2,
            selected: true,
            name: 'NUMERIC739_204',
            index: 4,
            orderBy: {
              direction: 0,
              index: 0
            }
          },
          {
            fieldType: 0,
            selected: true,
            name: 'DOCNUMBER',
            index: 0,
            orderBy: {
              direction: 0,
              index: 0
            }
          },
          {
            fieldType: 0,
            selected: true,
            name: 'REVISIONE',
            index: 0,
            orderBy: {
              direction: 0,
              index: 0
            }
          },
          {
            fieldType: 0,
            selected: true,
            name: 'AOO',
            index: 0,
            orderBy: {
              direction: 0,
              index: 0
            }
          },
          {
            fieldType: 0,
            selected: true,
            name: 'ORIGINALE',
            index: 0,
            orderBy: {
              direction: 0,
              index: 0
            }
          },
          {
            fieldType: 0,
            selected: true,
            name: 'CLASSEDOC',
            index: 0,
            orderBy: {
              direction: 0,
              index: 0
            }
          },
          {
            fieldType: 0,
            selected: true,
            name: 'WFVERSIONSTATE',
            index: 0,
            orderBy: {
              direction: 0,
              index: 0
            }
          }
        ],
      maxItems: 0
    },
    maxItems: 0
  };

  viewDate: Date = new Date();


  locale = 'it';
  // @ts-ignore
  events: CalendarEvent[] = [];

  /*{
        start: startOfDay(new Date()),
        title: 'presenzaFull',
        cssClass: 'presenzaFull',
      },
      {
        start: startOfDay(new Date()),
        title: 'presenzaMattino',
        cssClass: 'presenzaMattino',
      },
      {
        start: startOfDay(new Date()),
        title: 'presenzaPomeriggio',
        cssClass: 'presenzaPomeriggio',
      },
      {
        start: startOfDay(new Date()),
        title: 'smartWorking',
        cssClass: 'smartWorking',
      },
      {
        start: startOfDay(new Date()),
        title: 'offWorking',
        cssClass: 'offWorking',
      }*/

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  CalendarView = CalendarView;

  dataSource: any;

  constructor(public apiService: ApiService, private datePipe: DatePipe, public dialog: MatDialog, private route: ActivatedRoute,
              private router: Router, private snackBar: MatSnackBar) {

    this.apiService.getViewFilter(this.bodyGetPrenotazioni).subscribe(result => {
      this.dataSource = result;
      result.columns.forEach((v, index) => {
        if (v.id === 'DATA690_193') {
          this.dataRifIndex = index;
        } else if (v.id === 'COMBO691_193') {
          this.dipendenteIndex = index;
        } else if (v.id === 'COMBO737_204') {
          this.tipologiaIndex = index;
        } else if (v.id === 'DOCNUMBER') {
          this.docnumberIndex = index;
        } else if (v.id === 'TESTO740_204') {
          this.oraInizioIndex = index;
        } else if (v.id === 'TESTO741_204') {
          this.oraFineIndex = index;
        }
      });
      result.data.forEach(v => {
        switch (v[this.tipologiaIndex]) {
          case 'Auto fiat 500 5 posti':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'presenzaFull',
              }
            );
            break;
          case 'Furgone fiat doblo 2 posti':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'presenzaMattino',
              }
            );
            break;
          case 'Pulmino fiat Daily 9 posti':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'offWorking',
              }
            );
            break;
          case 'Auto Ford Focus 5 posti':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'presenzaPomeriggio',
              }
            );
            break;
          case 'Camioncino Iveco Daily 3 posti':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'smartWorking',
              }
            );
            break;
        }
      });
      this.refresh.next();
    });
  }

  cellClicked(event: any): void {

    const tempEventsToPass = [];
    this.dataSource.data.forEach(v => {
      const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
      if (date1.getTime() === event.day.date.getTime()) {
        tempEventsToPass.push({
          data: event.day.date,
          dipendente: v[this.dipendenteIndex],
          tipologia: v[this.tipologiaIndex],
          inizio: v[this.oraInizioIndex],
          fine: v[this.oraFineIndex]
        });
      }
    });
    const dialogRef = this.dialog.open(ShowPrenotazioniAutoEventComponent, {
      width: '20%',
      data: {events: tempEventsToPass, day: event.day.date}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.reloadCalendar();
    });
  }

  reloadCalendar(): void {
    this.apiService.getViewFilter(this.bodyGetPrenotazioni).subscribe(result => {
      this.dataSource = result;
      this.events = new Array<any>();
      result.columns.forEach((v, index) => {
        if (v.id === 'DATA690_193') {
          this.dataRifIndex = index;
        } else if (v.id === 'COMBO691_193') {
          this.dipendenteIndex = index;
        } else if (v.id === 'COMBO737_204') {
          this.tipologiaIndex = index;
        } else if (v.id === 'DOCNUMBER') {
          this.docnumberIndex = index;
        } else if (v.id === 'TESTO740_204') {
          this.oraInizioIndex = index;
        } else if (v.id === 'TESTO741_204') {
          this.oraFineIndex = index;
        }
      });
      result.data.forEach(v => {
        switch (v[this.tipologiaIndex]) {
          case 'Auto fiat 500 5 posti':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'presenzaFull',
              }
            );
            break;
          case 'Furgone fiat doblo 2 posti':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'presenzaMattino',
              }
            );
            break;
          case 'Pulmino fiat Daily 9 posti':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'offWorking',
              }
            );
            break;
          case 'Auto Ford Focus 5 posti':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'presenzaPomeriggio',
              }
            );
            break;
          case 'Camioncino Iveco Daily 3 posti':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'smartWorking',
              }
            );
            break;
        }
      });
      this.refresh.next();
    });
  }

  startHourChanged(event: any): void {
    this.startHour = event;
  }

  endHourChanged(event: any): void {
    this.endHour = event;
  }

  setEventType(event: string): void {

    this.eventType = event;
  }

  resetVariables(): void {
    console.log(this.startHour);
    console.log(this.endHour);

    this.eventType = '';
    this.startHour = '';
    this.endHour = '';
  }

  isCompreso(startHourToCheck: string, endHourToCheck: string, startHour: string, endHour: string): void {

  }

  addEvent(event: any): void {
    console.log(event);
    const tempEvents = [];
    this.dataSource.data.forEach(v => {
      const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
      if (date1.getTime() === event.item.getTime()) {
        tempEvents.push({
          data: event.item,
          dipendente: v[this.dipendenteIndex],
          tipologia: v[this.tipologiaIndex],
          inizio: v[this.oraInizioIndex],
          fine: v[this.oraFineIndex]
        });
      }
    });
    console.log(tempEvents);
    tempEvents.forEach(te => {
      let isOk = true;
      if (te.tipologia === this.eventType) {
        if (this.startHour >= te.inizio && this.startHour <= te.fine) {
          if (this.startHour === te.fine) {

          } else {
            isOk = false;
          }
        } else {

        }
        if (this.startHour <= te.inizio && this.endHour > te.inizio) {
          isOk = false;
        } else {

        }


        if (!isOk) {
          this.snackBar.open('Il veicolo selezionato: ' + this.eventType + ' non è disponibile nel periodo di tempo scelto', null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        }else{
          if (this.eventType !== '') {
            const json = {
              fields: [
                {
                  value: this.TransformDataFormatToSave(event.item),
                  additionalFieldType: 1,
                  groupId: 0,
                  binderFieldId: 0,
                  taskWorkVariableId: 0,
                  validationType: 0,
                  validationString: '',
                  name: 'DATA690_193',
                  externalId: '',
                  description: 'Data di riferimento',
                  order: 0,
                  dataSource: null,
                  required: false,
                  formula: '',
                  className: 'AdditionalFieldDateTimeDTO',
                  locked: false,
                  comboGruppiId: null,
                  dependencyFields: [],
                  associations: [],
                  isAdditional: true,
                  visible: true,
                  predefinedProfileFormula: null,
                  visibilityCondition: '',
                  mandatoryCondition: '',
                  addressBookDefaultFilter: null,
                  enabledAddressBook: [],
                  columns: null,
                  focus: true
                },
                {
                  limitToList: false,
                  displayValue: null,
                  value: this.eventType,
                  numMaxChar: 500,
                  numRows: 0,
                  additionalFieldType: 3,
                  groupId: 0,
                  binderFieldId: 0,
                  taskWorkVariableId: 0,
                  validationType: 0,
                  validationString: '',
                  name: 'COMBO737_204',
                  externalId: '',
                  description: 'Tipologia',
                  order: 1,
                  dataSource: null,
                  required: false,
                  formula: '',
                  className: 'AdditionalFieldComboDTO',
                  locked: false,
                  comboGruppiId: null,
                  dependencyFields: [],
                  associations: [],
                  isAdditional: true,
                  visible: true,
                  predefinedProfileFormula: null,
                  visibilityCondition: '',
                  mandatoryCondition: '',
                  addressBookDefaultFilter: null,
                  enabledAddressBook: [],
                  columns: null
                },
                {
                  limitToList: true,
                  displayValue: null,
                  value: localStorage.getItem('username'),
                  numMaxChar: 100,
                  numRows: 0,
                  additionalFieldType: 3,
                  groupId: 0,
                  binderFieldId: 0,
                  taskWorkVariableId: 0,
                  validationType: 0,
                  validationString: '',
                  name: 'COMBO691_193',
                  externalId: '',
                  description: 'Dipendente',
                  order: 1,
                  dataSource: null,
                  required: false,
                  formula: '',
                  className: 'AdditionalFieldComboDTO',
                  locked: false,
                  comboGruppiId: null,
                  dependencyFields: [],
                  associations: [],
                  isAdditional: true,
                  visible: true,
                  predefinedProfileFormula: null,
                  visibilityCondition: '',
                  mandatoryCondition: '',
                  addressBookDefaultFilter: null,
                  enabledAddressBook: [],
                  columns: null
                },
                {
                  displayValue: null,
                  value: this.startHour,
                  numMaxChar: 100,
                  numRows: 1,
                  additionalFieldType: 0,
                  groupId: 0,
                  binderFieldId: 0,
                  taskWorkVariableId: 0,
                  validationType: 0,
                  validationString: '',
                  name: 'TESTO740_204',
                  externalId: '',
                  description: 'Ora inizio',
                  order: 3,
                  dataSource: null,
                  required: false,
                  formula: '',
                  className: 'AdditionalFieldStringDTO',
                  locked: false,
                  comboGruppiId: null,
                  dependencyFields: [],
                  associations: [],
                  isAdditional: true,
                  visible: true,
                  predefinedProfileFormula: null,
                  visibilityCondition: '',
                  mandatoryCondition: '',
                  addressBookDefaultFilter: null,
                  enabledAddressBook: [],
                  columns: null
                },
                {
                  displayValue: null,
                  value: this.endHour,
                  numMaxChar: 100,
                  numRows: 1,
                  additionalFieldType: 0,
                  groupId: 0,
                  binderFieldId: 0,
                  taskWorkVariableId: 0,
                  validationType: 0,
                  validationString: '',
                  name: 'TESTO741_204',
                  externalId: '',
                  description: 'Ora fine',
                  order: 4,
                  dataSource: null,
                  required: false,
                  formula: '',
                  className: 'AdditionalFieldStringDTO',
                  locked: false,
                  comboGruppiId: null,
                  dependencyFields: [],
                  associations: [],
                  isAdditional: true,
                  visible: true,
                  predefinedProfileFormula: null,
                  visibilityCondition: '',
                  mandatoryCondition: '',
                  addressBookDefaultFilter: null,
                  enabledAddressBook: [],
                  columns: null
                }
              ]
            };
            let found = false;
            if (this.dataSource) {
              if (true) {


                this.dataSource.data.forEach(v => {
                  const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
                  if (date1.getTime() === event.item.getTime() && localStorage.getItem('username') === v[this.dipendenteIndex]) {
                    found = true;
                    this.apiService.deleteProfile(v[this.docnumberIndex]).subscribe(res => {
                      this.apiService.insertNewProfile(json, 'da313e3116ea427a885add2e2e818340').subscribe(res => {
                        this.snackBar.open('Evento aggiunto correttamente', null, {
                          duration: 4000,
                          horizontalPosition: 'end',
                          verticalPosition: 'bottom',
                          panelClass: ['mat-toolbar', 'custom-success-bg']
                        });
                        this.reloadCalendar();

                      }, errorLog => {
                        this.snackBar.open(errorLog.error.userMessage, null, {
                          duration: 4000,
                          horizontalPosition: 'end',
                          verticalPosition: 'bottom',
                          panelClass: ['mat-toolbar', 'custom-danger-bg']
                        });
                      });
                    }, errorLog => {
                      this.snackBar.open(errorLog.error.userMessage, null, {
                        duration: 4000,
                        horizontalPosition: 'end',
                        verticalPosition: 'bottom',
                        panelClass: ['mat-toolbar', 'custom-danger-bg']
                      });
                    });
                  }
                });
              }
            }
            if (!found) {
              this.apiService.insertNewProfile(json, 'da313e3116ea427a885add2e2e818340').subscribe(res => {
                this.snackBar.open('Evento aggiunto correttamente', null, {
                  duration: 4000,
                  horizontalPosition: 'end',
                  verticalPosition: 'bottom',
                  panelClass: ['mat-toolbar', 'custom-success-bg']
                });
                this.reloadCalendar();

              }, errorLog => {
                this.snackBar.open(errorLog.error.userMessage, null, {
                  duration: 4000,
                  horizontalPosition: 'end',
                  verticalPosition: 'bottom',
                  panelClass: ['mat-toolbar', 'custom-danger-bg']
                });
              });

            }
          }
        }
      }
    });
    if (tempEvents.length === 0){
      if (this.eventType !== '') {
        const json = {
          fields: [
            {
              value: this.TransformDataFormatToSave(event.item),
              additionalFieldType: 1,
              groupId: 0,
              binderFieldId: 0,
              taskWorkVariableId: 0,
              validationType: 0,
              validationString: '',
              name: 'DATA690_193',
              externalId: '',
              description: 'Data di riferimento',
              order: 0,
              dataSource: null,
              required: false,
              formula: '',
              className: 'AdditionalFieldDateTimeDTO',
              locked: false,
              comboGruppiId: null,
              dependencyFields: [],
              associations: [],
              isAdditional: true,
              visible: true,
              predefinedProfileFormula: null,
              visibilityCondition: '',
              mandatoryCondition: '',
              addressBookDefaultFilter: null,
              enabledAddressBook: [],
              columns: null,
              focus: true
            },
            {
              limitToList: false,
              displayValue: null,
              value: this.eventType,
              numMaxChar: 500,
              numRows: 0,
              additionalFieldType: 3,
              groupId: 0,
              binderFieldId: 0,
              taskWorkVariableId: 0,
              validationType: 0,
              validationString: '',
              name: 'COMBO737_204',
              externalId: '',
              description: 'Tipologia',
              order: 1,
              dataSource: null,
              required: false,
              formula: '',
              className: 'AdditionalFieldComboDTO',
              locked: false,
              comboGruppiId: null,
              dependencyFields: [],
              associations: [],
              isAdditional: true,
              visible: true,
              predefinedProfileFormula: null,
              visibilityCondition: '',
              mandatoryCondition: '',
              addressBookDefaultFilter: null,
              enabledAddressBook: [],
              columns: null
            },
            {
              limitToList: true,
              displayValue: null,
              value: localStorage.getItem('username'),
              numMaxChar: 100,
              numRows: 0,
              additionalFieldType: 3,
              groupId: 0,
              binderFieldId: 0,
              taskWorkVariableId: 0,
              validationType: 0,
              validationString: '',
              name: 'COMBO691_193',
              externalId: '',
              description: 'Dipendente',
              order: 1,
              dataSource: null,
              required: false,
              formula: '',
              className: 'AdditionalFieldComboDTO',
              locked: false,
              comboGruppiId: null,
              dependencyFields: [],
              associations: [],
              isAdditional: true,
              visible: true,
              predefinedProfileFormula: null,
              visibilityCondition: '',
              mandatoryCondition: '',
              addressBookDefaultFilter: null,
              enabledAddressBook: [],
              columns: null
            },
            {
              displayValue: null,
              value: this.startHour,
              numMaxChar: 100,
              numRows: 1,
              additionalFieldType: 0,
              groupId: 0,
              binderFieldId: 0,
              taskWorkVariableId: 0,
              validationType: 0,
              validationString: '',
              name: 'TESTO740_204',
              externalId: '',
              description: 'Ora inizio',
              order: 3,
              dataSource: null,
              required: false,
              formula: '',
              className: 'AdditionalFieldStringDTO',
              locked: false,
              comboGruppiId: null,
              dependencyFields: [],
              associations: [],
              isAdditional: true,
              visible: true,
              predefinedProfileFormula: null,
              visibilityCondition: '',
              mandatoryCondition: '',
              addressBookDefaultFilter: null,
              enabledAddressBook: [],
              columns: null
            },
            {
              displayValue: null,
              value: this.endHour,
              numMaxChar: 100,
              numRows: 1,
              additionalFieldType: 0,
              groupId: 0,
              binderFieldId: 0,
              taskWorkVariableId: 0,
              validationType: 0,
              validationString: '',
              name: 'TESTO741_204',
              externalId: '',
              description: 'Ora fine',
              order: 4,
              dataSource: null,
              required: false,
              formula: '',
              className: 'AdditionalFieldStringDTO',
              locked: false,
              comboGruppiId: null,
              dependencyFields: [],
              associations: [],
              isAdditional: true,
              visible: true,
              predefinedProfileFormula: null,
              visibilityCondition: '',
              mandatoryCondition: '',
              addressBookDefaultFilter: null,
              enabledAddressBook: [],
              columns: null
            }
          ]
        };
        let found = false;
        if (this.dataSource) {
          if (true) {


            this.dataSource.data.forEach(v => {
              const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
              if (date1.getTime() === event.item.getTime() && localStorage.getItem('username') === v[this.dipendenteIndex]) {
                found = true;
                this.apiService.deleteProfile(v[this.docnumberIndex]).subscribe(res => {
                  this.apiService.insertNewProfile(json, 'da313e3116ea427a885add2e2e818340').subscribe(res => {
                    this.snackBar.open('Evento aggiunto correttamente', null, {
                      duration: 4000,
                      horizontalPosition: 'end',
                      verticalPosition: 'bottom',
                      panelClass: ['mat-toolbar', 'custom-success-bg']
                    });
                    this.reloadCalendar();

                  }, errorLog => {
                    this.snackBar.open(errorLog.error.userMessage, null, {
                      duration: 4000,
                      horizontalPosition: 'end',
                      verticalPosition: 'bottom',
                      panelClass: ['mat-toolbar', 'custom-danger-bg']
                    });
                  });
                }, errorLog => {
                  this.snackBar.open(errorLog.error.userMessage, null, {
                    duration: 4000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    panelClass: ['mat-toolbar', 'custom-danger-bg']
                  });
                });
              }
            });
          }
        }
        if (!found) {
          this.apiService.insertNewProfile(json, 'da313e3116ea427a885add2e2e818340').subscribe(res => {
            this.snackBar.open('Evento aggiunto correttamente', null, {
              duration: 4000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-success-bg']
            });
            this.reloadCalendar();

          }, errorLog => {
            this.snackBar.open(errorLog.error.userMessage, null, {
              duration: 4000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
          });

        }
      }
    }


  }

  removeEvent(event: any): void {
    console.log(this.dataSource);
    let found = false;
    this.dataSource.data.forEach(v => {
      const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
      if (date1.getTime() === event.getTime() && localStorage.getItem('username') === v[this.dipendenteIndex]) {
        found = true;
        this.apiService.deleteProfile(v[this.docnumberIndex]).subscribe(res => {
          this.snackBar.open('Evento eliminato con successo', null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-success-bg']
          });
          this.reloadCalendar();
        }, errorLog => {
          this.snackBar.open(errorLog.error.userMessage, null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      }
    });
    if (!found) {
      this.snackBar.open('Nessun evento da eliminare', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-warning-bg']
      });

    }
    this.refresh.next();
  }

  isEventSetForUser(date: Date): boolean {
    let found = false;
    if (this.dataSource) {
      this.dataSource.data.forEach(v => {
        const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
        if (date1.getTime() === date.getTime() && localStorage.getItem('username') === v[this.dipendenteIndex]) {
          found = true;
        }
      });
    }

    if (found) {
      return true;
    } else {
      return false;
    }
  }

  getEventType(date: Date): string {
    let found = false;
    let tipologia = '';
    if (this.dataSource) {
      this.dataSource.data.forEach(v => {
        const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
        if (date1.getTime() === date.getTime() && localStorage.getItem('username') === v[this.dipendenteIndex]) {
          found = true;
          tipologia = v[this.tipologiaIndex];

        }
      });
    }
    if (!found) {
      return '';
    } else {
      return tipologia;
    }

  }

  TransformDataFormatToSave(Data: string): string {

    if (Data === '' || !Data) {
      return '0001-01-01T00:00:00';
    }
    // // console.log(Data);
    const dateMomentObject = moment(Data, 'DD/MM/YYYY'); // 1st argument - string, 2nd argument - format
    const dateObject = dateMomentObject.toDate();
    // // console.log(dateMomentObject);
    if (dateMomentObject.isValid()) {
      return this.datePipe.transform(dateObject, 'yyyy-MM-dd', '', 'en-GB');
    } else {
      return this.datePipe.transform(Data, 'yyyy-MM-dd', '', 'en-GB');
    }

  }

  ngOnInit(): void {
    console.log(this.apiService.userInfo);
    // @ts-ignore
    /* const calendar = document.getElementById('calendario')
     console.log(calendar);*/
  }

  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    console.log(date);
    // let x=this.adminService.dateFormat(date)
    // this.openAppointmentList(x)
  }
}

@Component({
  selector: 'app-showprenotazionieventdialog',
  templateUrl: 'showPrenotazioniEventDialog.html',
  styleUrls: ['./gestione-prenotazioni.component.css']
})
export class ShowPrenotazioniAutoEventComponent {
  events: Array<any> = new Array<any>();
  eventDay: any;

  constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<ShowPrenotazioniAutoEventComponent>, private datePipe: DatePipe,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService) {
    this.events = data.events;
    console.log(this.events);
    this.events.forEach(v => {
      switch (v.tipologia) {
        case 'Auto fiat 500 5 posti':
          v.color = '#de7565';
          break;
        case 'Furgone fiat doblo 2 posti':
          v.color = '#f4ccac';
          break;
        case 'Pulmino fiat Daily 9 posti':
          v.color = '#6a6a6a';
          break;
        case 'Auto Ford Focus 5 posti':
          v.color = '#c2cf9d';
          break;
        case 'Camioncino Iveco Daily 3 posti':
          v.color = '#b8c1df';
          break;
      }
    });
    console.log(this.events);
    this.eventDay = data.day;
  }

  TransformDataFormatToShow(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');
  }

  sendRevision(): void {

  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

