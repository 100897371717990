import {ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {parseISO, startOfDay} from 'date-fns';
import {CalendarEvent, CalendarView, DAYS_OF_WEEK} from 'angular-calendar';
import {Subject} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EditRevisionProfileComponent} from '../view/view.component';
import {DatePipe} from '@angular/common';
import {ContextMenuComponent} from 'ngx-contextmenu';
import moment from 'moment';
import { HttpParameterCodec } from '@angular/common/http';
@Component({
  selector: 'app-tabella-presenze',
  templateUrl: './gestione-prenotazioni.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./gestione-prenotazioni.component.css']
})

export class GestionePrenotazioniComponent implements OnInit {
  @ViewChild(ContextMenuComponent) public basicMenu: ContextMenuComponent;
  dataRifIndex: number;
  dataEndIndex: number;
  tipologiaIndex: number;
  dipendenteIndex: number;
  docnumberIndex: number;
  oraInizioIndex: number;
  clienteIndex: number;
  ganttIndex: number;
  statoIndex: number;
  attivitaIndex: number;
  dataLegenda: Array<any> = new Array<any>();
  refresh: Subject<any> = new Subject();
  eventType = '';
  startHour: any;
  endHour: any;
  view: CalendarView = CalendarView.Month;
  bodyGetChecklist = {
    id: 'adc361c6dd384d2c9dee931035ff7d27',
    searchFilterDto: {
      daAAndOr: 1,
    }
  };
  bodyGetFerie = {
    id: '0f84b1f5290242c39ade2e49e0035a2a', // 0f84b1f5290242c39ade2e49e0035a2a
    searchFilterDto: {
      daAAndOr: 1,
    }
  };
  impegnoTotale = 0;
  clickedDate = '';
  viewDate: Date = new Date();


  locale = 'it';
  // @ts-ignore
  events: CalendarEvent[] = [];

  /*{
        start: startOfDay(new Date()),
        title: 'presenzaFull',
        cssClass: 'presenzaFull',
      },
      {
        start: startOfDay(new Date()),
        title: 'presenzaMattino',
        cssClass: 'presenzaMattino',
      },
      {
        start: startOfDay(new Date()),
        title: 'presenzaPomeriggio',
        cssClass: 'presenzaPomeriggio',
      },
      {
        start: startOfDay(new Date()),
        title: 'smartWorking',
        cssClass: 'smartWorking',
      },
      {
        start: startOfDay(new Date()),
        title: 'offWorking',
        cssClass: 'offWorking',
      }*/

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  CalendarView = CalendarView;

  dataSource: any;
  dataSourceFerie: any;
  ferieMittenteIndex: number;
  ferieTipologiaIndex: number;
  ferieDataInizioIndex: number;
  ferieDataFineIndex: number;
  ferieDataPermessoIndex: number;
  ferieOraInizioIndex: number;
  ferieOraFineIndex: number;
  hideFeriePermessiVar = true;
  selectedTecnico = '';


  constructor(public apiService: ApiService, private datePipe: DatePipe, public dialog: MatDialog, private route: ActivatedRoute,
              private router: Router, private snackBar: MatSnackBar) {
  }

  convertHex(hexCode, opacity = 0.1): string {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    const r = parseInt(hex.substring(0, 2), 16),
      // tslint:disable-next-line:prefer-const
      g = parseInt(hex.substring(2, 4), 16),
      // tslint:disable-next-line:prefer-const
      b = parseInt(hex.substring(4, 6), 16);

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
      opacity = opacity / 100;
    }

    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
  }

  stringToColour(text: string): string {
    let hash = 0;
    // tslint:disable-next-line:no-shadowed-variable
    for (let i = 0; i < text.length; i++) {
      // tslint:disable-next-line:no-bitwise
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      // tslint:disable-next-line:no-bitwise
      const value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }

  showTecnico(nomeTecnico: string): void {

    let loadImpegno = false;
    if (this.selectedTecnico === '') {
      this.selectedTecnico = nomeTecnico;
    } else if (this.selectedTecnico !== nomeTecnico) {
      this.selectedTecnico = nomeTecnico;
    } else {
      this.selectedTecnico = '';
      loadImpegno = true;
    }
    if (!loadImpegno){
      this.impegnoTotale = 0;
      this.events.forEach(e => {
        if (e.tecnico.toLowerCase() === nomeTecnico.toLowerCase() && e.id === 'schedulazione'){

          if (e.hourNumber) {
            this.impegnoTotale += e.hourNumber;
          }

        }
      });
    }
  }

  hideFeriePermessi(): void {
    this.hideFeriePermessiVar = !this.hideFeriePermessiVar;
  }

  cellClicked(event: any): void {

    const tempEventsToPass = [];
    this.dataSource.data.forEach(v => {
      const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
      if (date1.getTime() === event.day.date.getTime()) {
        tempEventsToPass.push({
          data: event.day.date,
          dipendente: v[this.dipendenteIndex],
          tipologia: v[this.tipologiaIndex],
          inizio: v[this.oraInizioIndex]
        });
      }
    });
    const dialogRef = this.dialog.open(ShowPrenotazioniEventComponent, {
      width: '25%',
      data: {events: tempEventsToPass, day: event.day.date, docN: event.docn}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.reloadCalendar();
    });
  }

  TransformDataFormatToShow(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');
  }

  reloadCalendarFeriePermessi(): void {
    this.apiService.getViewFilter(this.bodyGetChecklist).subscribe(result => {
      this.apiService.getViewFilter(this.bodyGetFerie).subscribe(ferie => {
        this.events = new Array<any>();
        this.dataSourceFerie = ferie;
        ferie.columns.forEach((f, index) => {
          if (f.id === 'MITTENTE') {
            this.ferieMittenteIndex = index;
          } else if (f.id === 'COMBO29_16') {
            this.ferieTipologiaIndex = index;
          } else if (f.id === 'DATA26_16') {
            this.ferieDataInizioIndex = index;
          } else if (f.id === 'DATA27_16') {
            this.ferieDataFineIndex = index;
          } else if (f.id === 'DATA31_16') {
            this.ferieDataPermessoIndex = index;
          } else if (f.id === 'NUMERIC32_16') {
            this.ferieOraInizioIndex = index;
          } else if (f.id === 'NUMERIC33_16') {
            this.ferieOraFineIndex = index;
          }
        });
        ferie.data.forEach(fd => {
          if (fd[this.ferieOraFineIndex]) {
            this.events.push({
                id: 'ferie',
                start: startOfDay(parseISO(fd[this.ferieDataPermessoIndex])),
                title: 'Permesso: ' + fd[this.ferieMittenteIndex],
                hour: fd[this.ferieOraInizioIndex] + ' - ' + fd[this.ferieOraFineIndex],
                color: {
                  primary: this.stringToColour(fd[this.ferieMittenteIndex]),
                  secondary: this.convertHex(this.stringToColour(fd[this.ferieMittenteIndex]))
                },
                extraColor: this.stringToColour(fd[this.ferieMittenteIndex]),
                cssClass: 'smartWorking',
                tecnico: fd[this.ferieMittenteIndex],
              }
            );
          } else {
            this.events.push({
                id: 'ferie',
                start: startOfDay(parseISO(fd[this.ferieDataInizioIndex])),
                end: startOfDay(parseISO(fd[this.ferieDataFineIndex])),
                title: 'Ferie: ' + fd[this.ferieMittenteIndex],
                hour: fd[this.ferieOraInizioIndex],
                color: {
                  primary: this.stringToColour(fd[this.ferieMittenteIndex]),
                  secondary: this.convertHex(this.stringToColour(fd[this.ferieMittenteIndex]))
                },
                extraColor: this.stringToColour(fd[this.ferieMittenteIndex]),
                cssClass: 'smartWorking',
                tecnico: fd[this.ferieMittenteIndex],
              }
            );
          }


        });

        this.dataSource = result;
        result.columns.forEach((v, index) => {
          if (v.id === 'DATA538_91') {
            this.dataRifIndex = index;
          } else if (v.id === 'DESTINATARIO') {
            this.clienteIndex = index;
          } else if (v.id === 'DATA539_91') {
            this.dataEndIndex = index;
          } else if (v.id === 'MITTENTE') {
            this.dipendenteIndex = index;
          } else if (v.id === 'DOCNAME') {
            this.tipologiaIndex = index;
          } else if (v.id === 'DOCNUMBER') {
            this.docnumberIndex = index;
          } else if (v.id === 'TESTO541_91') {
            this.oraInizioIndex = index;
          } else if (v.id === 'STATO') {
            this.statoIndex = index;
          } else if (v.id === 'CHECK537_91') {
            this.ganttIndex = index;
          } else if (v.id === 'TESTO369_91') {
            this.attivitaIndex = index;
          }
        });
        this.dataLegenda = new Array<any>();
        result.data.forEach(v => {
          let found = false;
          this.dataLegenda.forEach(res => {
            if (res.nome === v[this.dipendenteIndex]) {
              found = true;
            }
          });
          if (!found) {
            this.dataLegenda.push({nome: v[this.dipendenteIndex], colore: this.stringToColour(v[this.dipendenteIndex])});
          }
          switch (v[this.tipologiaIndex]) {
            case 'Auto fiat 500 5 posti':
              this.events.push({
                  start: startOfDay(parseISO(v[this.dataRifIndex])),
                  title: v[this.tipologiaIndex],
                  cssClass: 'presenzaFull',
                }
              );
              break;
            default:
              let found = false;
              this.events.forEach(e => {
                if (e.title === v[this.clienteIndex] + ': ' + v[this.attivitaIndex] && e.tecnico === v[this.dipendenteIndex]) {
                  found = true;
                  if (v[this.ganttIndex] === 1 && v[this.statoIndex] !== 'Chiuso') {
                    if (e.start > startOfDay(parseISO(v[this.dataRifIndex]))) {
                      e.start = startOfDay(parseISO(v[this.dataRifIndex]));
                    }
                    if (e.end < startOfDay(parseISO(v[this.dataEndIndex]))) {
                      e.end = startOfDay(parseISO(v[this.dataEndIndex]));
                    }
                    e.hourNumber += Number(v[this.oraInizioIndex].replace(/\D/g, ''));
                    e.checkList.push({
                      dipendente: v[this.dipendenteIndex], docN: v[this.docnumberIndex], oggetto: v[this.tipologiaIndex],
                      dataInizio: v[this.dataRifIndex], dataFine: v[this.dataEndIndex], tempo: v[this.oraInizioIndex]
                    });
                  }

                }
              });
              if (!found && v[this.ganttIndex] === 1 && v[this.statoIndex] !== 'Chiuso') {
                this.events.push({
                    id: 'schedulazione',
                    start: startOfDay(parseISO(v[this.dataRifIndex])),
                    end: startOfDay(parseISO(v[this.dataEndIndex])),
                    title: v[this.clienteIndex] + ': ' + v[this.attivitaIndex],
                    hourNumber: Number(v[this.oraInizioIndex].replace(/\D/g, '')),
                    attivita: v[this.attivitaIndex],
                    color: {
                      primary: this.stringToColour(v[this.dipendenteIndex]),
                      secondary: this.convertHex(this.stringToColour(v[this.dipendenteIndex]))
                    },
                    extraColor: this.stringToColour(v[this.dipendenteIndex]),
                    cssClass: 'smartWorking',
                    checkList: [{
                      dipendente: v[this.dipendenteIndex], docN: v[this.docnumberIndex], oggetto: v[this.tipologiaIndex],
                      dataInizio: v[this.dataRifIndex], dataFine: v[this.dataEndIndex], tempo: v[this.oraInizioIndex]
                    }],
                    tecnico: v[this.dipendenteIndex],
                  }
                );
              }

          }
        });
        this.refresh.next();
      });


    });
  }
  reloadCalendar(): void {
    this.apiService.getViewFilter(this.bodyGetChecklist).subscribe(result => {
        this.events = new Array<any>();
        this.dataSource = result;
        result.columns.forEach((v, index) => {
          if (v.id === 'DATA538_91') {
            this.dataRifIndex = index;
          } else if (v.id === 'DESTINATARIO') {
            this.clienteIndex = index;
          } else if (v.id === 'DATA539_91') {
            this.dataEndIndex = index;
          } else if (v.id === 'MITTENTE') {
            this.dipendenteIndex = index;
          } else if (v.id === 'DOCNAME') {
            this.tipologiaIndex = index;
          } else if (v.id === 'DOCNUMBER') {
            this.docnumberIndex = index;
          } else if (v.id === 'TESTO541_91') {
            this.oraInizioIndex = index;
          } else if (v.id === 'STATO') {
            this.statoIndex = index;
          } else if (v.id === 'CHECK537_91') {
            this.ganttIndex = index;
          } else if (v.id === 'TESTO369_91') {
            this.attivitaIndex = index;
          }
        });
        this.dataLegenda = new Array<any>();
        result.data.forEach(v => {
          let found = false;
          this.dataLegenda.forEach(res => {
            if (res.nome === v[this.dipendenteIndex]) {
              found = true;
            }
          });
          if (!found) {
            this.dataLegenda.push({nome: v[this.dipendenteIndex], colore: this.stringToColour(v[this.dipendenteIndex])});
          }
          switch (v[this.tipologiaIndex]) {
            case 'Auto fiat 500 5 posti':
              this.events.push({
                  start: startOfDay(parseISO(v[this.dataRifIndex])),
                  title: v[this.tipologiaIndex],
                  cssClass: 'presenzaFull',
                }
              );
              break;
            default:
              let found = false;
              this.events.forEach(e => {
                if (e.title === v[this.clienteIndex] + ': ' + v[this.attivitaIndex] && e.tecnico === v[this.dipendenteIndex]) {
                  found = true;
                  if (v[this.ganttIndex] === 1 && v[this.statoIndex] !== 'Chiuso') {
                    if (e.start > startOfDay(parseISO(v[this.dataRifIndex]))) {
                      e.start = startOfDay(parseISO(v[this.dataRifIndex]));
                    }
                    if (e.end < startOfDay(parseISO(v[this.dataEndIndex]))) {
                      e.end = startOfDay(parseISO(v[this.dataEndIndex]));
                    }
                    e.hourNumber += Number(v[this.oraInizioIndex].replace(/\D/g, ''));
                    e.checkList.push({
                      dipendente: v[this.dipendenteIndex], docN: v[this.docnumberIndex], oggetto: v[this.tipologiaIndex],
                      dataInizio: v[this.dataRifIndex], dataFine: v[this.dataEndIndex], tempo: v[this.oraInizioIndex]
                    });
                  }

                }
              });
              if (!found && v[this.ganttIndex] === 1 && v[this.statoIndex] !== 'Chiuso') {
                this.events.push({
                    id: 'schedulazione',
                    start: startOfDay(parseISO(v[this.dataRifIndex])),
                    end: startOfDay(parseISO(v[this.dataEndIndex])),
                    title: v[this.clienteIndex] + ': ' + v[this.attivitaIndex],
                    hourNumber: Number(v[this.oraInizioIndex].replace(/\D/g, '')),
                    attivita: v[this.attivitaIndex],
                    color: {
                      primary: this.stringToColour(v[this.dipendenteIndex]),
                      secondary: this.convertHex(this.stringToColour(v[this.dipendenteIndex]))
                    },
                    extraColor: this.stringToColour(v[this.dipendenteIndex]),
                    cssClass: 'smartWorking',
                    checkList: [{
                      dipendente: v[this.dipendenteIndex], docN: v[this.docnumberIndex], oggetto: v[this.tipologiaIndex],
                      dataInizio: v[this.dataRifIndex], dataFine: v[this.dataEndIndex], tempo: v[this.oraInizioIndex]
                    }],
                    tecnico: v[this.dipendenteIndex],
                  }
                );
              }

          }
        });
        this.refresh.next();
      });



  }
  eventClick(event: any): void {
    console.log(event);

    const dialogRef = this.dialog.open(ShowPrenotazioniEventComponent, {
      width: '37%',
      data: {events: event.event.checkList, attivita: event.event.title, day: event.sourceEvent, progetto: event.event.attivita, oreImpegno: event.event.hourNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.reloadCalendar();
    });
  }

  startHourChanged(event: any): void {
    this.startHour = event;
  }

  endHourChanged(event: any): void {
    this.endHour = event;
  }

  setEventType(event: string): void {

    this.eventType = event;
  }

  resetVariables(): void {
    console.log(this.startHour);
    console.log(this.endHour);

    this.eventType = '';
    this.startHour = '';
    this.endHour = '';
  }

  isCompreso(startHourToCheck: string, endHourToCheck: string, startHour: string, endHour: string): void {

  }

  getNumInterventi(events: any): number {
    let contatore = 0;
    events.forEach(e => {
      if (e.id === 'schedulazione') {
        contatore++;
      }
    });
    return contatore;
  }

  addEvent(event: any): void {
    console.log(event);
    const tempEvents = [];
    this.dataSource.data.forEach(v => {
      const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
      if (date1.getTime() === event.item.getTime()) {
        tempEvents.push({
          data: event.item,
          dipendente: v[this.dipendenteIndex],
          tipologia: v[this.tipologiaIndex],
          inizio: v[this.oraInizioIndex]
        });
      }
    });
    console.log(tempEvents);
    tempEvents.forEach(te => {
      let isOk = true;
      if (te.tipologia === this.eventType) {
        if (this.startHour >= te.inizio && this.startHour <= te.fine) {
          if (this.startHour === te.fine) {

          } else {
            isOk = false;
          }
        } else {

        }
        if (this.startHour <= te.inizio && this.endHour > te.inizio) {
          isOk = false;
        } else {

        }


        if (!isOk) {
          this.snackBar.open('Il veicolo selezionato: ' + this.eventType + ' non è disponibile nel periodo di tempo scelto', null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        } else {
          if (this.eventType !== '') {
            const json = {
              fields: [
                {
                  value: this.TransformDataFormatToSave(event.item),
                  additionalFieldType: 1,
                  groupId: 0,
                  binderFieldId: 0,
                  taskWorkVariableId: 0,
                  validationType: 0,
                  validationString: '',
                  name: 'DATA690_193',
                  externalId: '',
                  description: 'Data di riferimento',
                  order: 0,
                  dataSource: null,
                  required: false,
                  formula: '',
                  className: 'AdditionalFieldDateTimeDTO',
                  locked: false,
                  comboGruppiId: null,
                  dependencyFields: [],
                  associations: [],
                  isAdditional: true,
                  visible: true,
                  predefinedProfileFormula: null,
                  visibilityCondition: '',
                  mandatoryCondition: '',
                  addressBookDefaultFilter: null,
                  enabledAddressBook: [],
                  columns: null,
                  focus: true
                },
                {
                  limitToList: false,
                  displayValue: null,
                  value: this.eventType,
                  numMaxChar: 500,
                  numRows: 0,
                  additionalFieldType: 3,
                  groupId: 0,
                  binderFieldId: 0,
                  taskWorkVariableId: 0,
                  validationType: 0,
                  validationString: '',
                  name: 'COMBO737_204',
                  externalId: '',
                  description: 'Tipologia',
                  order: 1,
                  dataSource: null,
                  required: false,
                  formula: '',
                  className: 'AdditionalFieldComboDTO',
                  locked: false,
                  comboGruppiId: null,
                  dependencyFields: [],
                  associations: [],
                  isAdditional: true,
                  visible: true,
                  predefinedProfileFormula: null,
                  visibilityCondition: '',
                  mandatoryCondition: '',
                  addressBookDefaultFilter: null,
                  enabledAddressBook: [],
                  columns: null
                },
                {
                  limitToList: true,
                  displayValue: null,
                  value: localStorage.getItem('username'),
                  numMaxChar: 100,
                  numRows: 0,
                  additionalFieldType: 3,
                  groupId: 0,
                  binderFieldId: 0,
                  taskWorkVariableId: 0,
                  validationType: 0,
                  validationString: '',
                  name: 'COMBO691_193',
                  externalId: '',
                  description: 'Dipendente',
                  order: 1,
                  dataSource: null,
                  required: false,
                  formula: '',
                  className: 'AdditionalFieldComboDTO',
                  locked: false,
                  comboGruppiId: null,
                  dependencyFields: [],
                  associations: [],
                  isAdditional: true,
                  visible: true,
                  predefinedProfileFormula: null,
                  visibilityCondition: '',
                  mandatoryCondition: '',
                  addressBookDefaultFilter: null,
                  enabledAddressBook: [],
                  columns: null
                },
                {
                  displayValue: null,
                  value: this.startHour,
                  numMaxChar: 100,
                  numRows: 1,
                  additionalFieldType: 0,
                  groupId: 0,
                  binderFieldId: 0,
                  taskWorkVariableId: 0,
                  validationType: 0,
                  validationString: '',
                  name: 'TESTO740_204',
                  externalId: '',
                  description: 'Ora inizio',
                  order: 3,
                  dataSource: null,
                  required: false,
                  formula: '',
                  className: 'AdditionalFieldStringDTO',
                  locked: false,
                  comboGruppiId: null,
                  dependencyFields: [],
                  associations: [],
                  isAdditional: true,
                  visible: true,
                  predefinedProfileFormula: null,
                  visibilityCondition: '',
                  mandatoryCondition: '',
                  addressBookDefaultFilter: null,
                  enabledAddressBook: [],
                  columns: null
                },
                {
                  displayValue: null,
                  value: this.endHour,
                  numMaxChar: 100,
                  numRows: 1,
                  additionalFieldType: 0,
                  groupId: 0,
                  binderFieldId: 0,
                  taskWorkVariableId: 0,
                  validationType: 0,
                  validationString: '',
                  name: 'TESTO741_204',
                  externalId: '',
                  description: 'Ora fine',
                  order: 4,
                  dataSource: null,
                  required: false,
                  formula: '',
                  className: 'AdditionalFieldStringDTO',
                  locked: false,
                  comboGruppiId: null,
                  dependencyFields: [],
                  associations: [],
                  isAdditional: true,
                  visible: true,
                  predefinedProfileFormula: null,
                  visibilityCondition: '',
                  mandatoryCondition: '',
                  addressBookDefaultFilter: null,
                  enabledAddressBook: [],
                  columns: null
                }
              ]
            };
            let found = false;
            if (this.dataSource) {
              if (true) {


                this.dataSource.data.forEach(v => {
                  const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
                  if (date1.getTime() === event.item.getTime() && localStorage.getItem('username') === v[this.dipendenteIndex]) {
                    found = true;
                    this.apiService.deleteProfile(v[this.docnumberIndex]).subscribe(res => {
                      this.apiService.insertNewProfile(json, 'da313e3116ea427a885add2e2e818340').subscribe(res => {
                        this.snackBar.open('Evento aggiunto correttamente', null, {
                          duration: 4000,
                          horizontalPosition: 'end',
                          verticalPosition: 'bottom',
                          panelClass: ['mat-toolbar', 'custom-success-bg']
                        });
                        this.reloadCalendar();

                      }, errorLog => {
                        this.snackBar.open(errorLog.error.userMessage, null, {
                          duration: 4000,
                          horizontalPosition: 'end',
                          verticalPosition: 'bottom',
                          panelClass: ['mat-toolbar', 'custom-danger-bg']
                        });
                      });
                    }, errorLog => {
                      this.snackBar.open(errorLog.error.userMessage, null, {
                        duration: 4000,
                        horizontalPosition: 'end',
                        verticalPosition: 'bottom',
                        panelClass: ['mat-toolbar', 'custom-danger-bg']
                      });
                    });
                  }
                });
              }
            }
            if (!found) {
              this.apiService.insertNewProfile(json, 'da313e3116ea427a885add2e2e818340').subscribe(res => {
                this.snackBar.open('Evento aggiunto correttamente', null, {
                  duration: 4000,
                  horizontalPosition: 'end',
                  verticalPosition: 'bottom',
                  panelClass: ['mat-toolbar', 'custom-success-bg']
                });
                this.reloadCalendar();

              }, errorLog => {
                this.snackBar.open(errorLog.error.userMessage, null, {
                  duration: 4000,
                  horizontalPosition: 'end',
                  verticalPosition: 'bottom',
                  panelClass: ['mat-toolbar', 'custom-danger-bg']
                });
              });

            }
          }
        }
      }
    });
    if (tempEvents.length === 0) {
      if (this.eventType !== '') {
        const json = {
          fields: [
            {
              value: this.TransformDataFormatToSave(event.item),
              additionalFieldType: 1,
              groupId: 0,
              binderFieldId: 0,
              taskWorkVariableId: 0,
              validationType: 0,
              validationString: '',
              name: 'DATA690_193',
              externalId: '',
              description: 'Data di riferimento',
              order: 0,
              dataSource: null,
              required: false,
              formula: '',
              className: 'AdditionalFieldDateTimeDTO',
              locked: false,
              comboGruppiId: null,
              dependencyFields: [],
              associations: [],
              isAdditional: true,
              visible: true,
              predefinedProfileFormula: null,
              visibilityCondition: '',
              mandatoryCondition: '',
              addressBookDefaultFilter: null,
              enabledAddressBook: [],
              columns: null,
              focus: true
            },
            {
              limitToList: false,
              displayValue: null,
              value: this.eventType,
              numMaxChar: 500,
              numRows: 0,
              additionalFieldType: 3,
              groupId: 0,
              binderFieldId: 0,
              taskWorkVariableId: 0,
              validationType: 0,
              validationString: '',
              name: 'COMBO737_204',
              externalId: '',
              description: 'Tipologia',
              order: 1,
              dataSource: null,
              required: false,
              formula: '',
              className: 'AdditionalFieldComboDTO',
              locked: false,
              comboGruppiId: null,
              dependencyFields: [],
              associations: [],
              isAdditional: true,
              visible: true,
              predefinedProfileFormula: null,
              visibilityCondition: '',
              mandatoryCondition: '',
              addressBookDefaultFilter: null,
              enabledAddressBook: [],
              columns: null
            },
            {
              limitToList: true,
              displayValue: null,
              value: localStorage.getItem('username'),
              numMaxChar: 100,
              numRows: 0,
              additionalFieldType: 3,
              groupId: 0,
              binderFieldId: 0,
              taskWorkVariableId: 0,
              validationType: 0,
              validationString: '',
              name: 'COMBO691_193',
              externalId: '',
              description: 'Dipendente',
              order: 1,
              dataSource: null,
              required: false,
              formula: '',
              className: 'AdditionalFieldComboDTO',
              locked: false,
              comboGruppiId: null,
              dependencyFields: [],
              associations: [],
              isAdditional: true,
              visible: true,
              predefinedProfileFormula: null,
              visibilityCondition: '',
              mandatoryCondition: '',
              addressBookDefaultFilter: null,
              enabledAddressBook: [],
              columns: null
            },
            {
              displayValue: null,
              value: this.startHour,
              numMaxChar: 100,
              numRows: 1,
              additionalFieldType: 0,
              groupId: 0,
              binderFieldId: 0,
              taskWorkVariableId: 0,
              validationType: 0,
              validationString: '',
              name: 'TESTO740_204',
              externalId: '',
              description: 'Ora inizio',
              order: 3,
              dataSource: null,
              required: false,
              formula: '',
              className: 'AdditionalFieldStringDTO',
              locked: false,
              comboGruppiId: null,
              dependencyFields: [],
              associations: [],
              isAdditional: true,
              visible: true,
              predefinedProfileFormula: null,
              visibilityCondition: '',
              mandatoryCondition: '',
              addressBookDefaultFilter: null,
              enabledAddressBook: [],
              columns: null
            },
            {
              displayValue: null,
              value: this.endHour,
              numMaxChar: 100,
              numRows: 1,
              additionalFieldType: 0,
              groupId: 0,
              binderFieldId: 0,
              taskWorkVariableId: 0,
              validationType: 0,
              validationString: '',
              name: 'TESTO741_204',
              externalId: '',
              description: 'Ora fine',
              order: 4,
              dataSource: null,
              required: false,
              formula: '',
              className: 'AdditionalFieldStringDTO',
              locked: false,
              comboGruppiId: null,
              dependencyFields: [],
              associations: [],
              isAdditional: true,
              visible: true,
              predefinedProfileFormula: null,
              visibilityCondition: '',
              mandatoryCondition: '',
              addressBookDefaultFilter: null,
              enabledAddressBook: [],
              columns: null
            }
          ]
        };
        let found = false;
        if (this.dataSource) {
          if (true) {


            this.dataSource.data.forEach(v => {
              const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
              if (date1.getTime() === event.item.getTime() && localStorage.getItem('username') === v[this.dipendenteIndex]) {
                found = true;
                this.apiService.deleteProfile(v[this.docnumberIndex]).subscribe(res => {
                  this.apiService.insertNewProfile(json, 'da313e3116ea427a885add2e2e818340').subscribe(res => {
                    this.snackBar.open('Evento aggiunto correttamente', null, {
                      duration: 4000,
                      horizontalPosition: 'end',
                      verticalPosition: 'bottom',
                      panelClass: ['mat-toolbar', 'custom-success-bg']
                    });
                    this.reloadCalendar();

                  }, errorLog => {
                    this.snackBar.open(errorLog.error.userMessage, null, {
                      duration: 4000,
                      horizontalPosition: 'end',
                      verticalPosition: 'bottom',
                      panelClass: ['mat-toolbar', 'custom-danger-bg']
                    });
                  });
                }, errorLog => {
                  this.snackBar.open(errorLog.error.userMessage, null, {
                    duration: 4000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    panelClass: ['mat-toolbar', 'custom-danger-bg']
                  });
                });
              }
            });
          }
        }
        if (!found) {
          this.apiService.insertNewProfile(json, 'da313e3116ea427a885add2e2e818340').subscribe(res => {
            this.snackBar.open('Evento aggiunto correttamente', null, {
              duration: 4000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-success-bg']
            });
            this.reloadCalendar();

          }, errorLog => {
            this.snackBar.open(errorLog.error.userMessage, null, {
              duration: 4000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
          });

        }
      }
    }


  }

  addIntervent(): void {
    this.router.navigate(['../maskDetails', {
      id: '812976cf45904b6590d072288f538379', idTask: 0,
      path: 'calendario', data: this.TransformDataFormatToSave(this.clickedDate)
    }], {relativeTo: this.route});
  }

  setDayOpened(event: any): void {
    this.clickedDate = event.item;
  }

  removeEvent(event: any): void {
    console.log(this.dataSource);
    let found = false;
    this.dataSource.data.forEach(v => {
      const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
      if (date1.getTime() === event.getTime() && localStorage.getItem('username') === v[this.dipendenteIndex]) {
        found = true;
        this.apiService.deleteProfile(v[this.docnumberIndex]).subscribe(res => {
          this.snackBar.open('Evento eliminato con successo', null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-success-bg']
          });
          this.reloadCalendar();
        }, errorLog => {
          this.snackBar.open(errorLog.error.userMessage, null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      }
    });
    if (!found) {
      this.snackBar.open('Nessun evento da eliminare', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-warning-bg']
      });

    }
    this.refresh.next();
  }

  isEventSetForUser(date: Date): boolean {
    let found = false;
    if (this.dataSource) {
      this.dataSource.data.forEach(v => {
        const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
        if (date1.getTime() === date.getTime() && localStorage.getItem('username') === v[this.dipendenteIndex]) {
          found = true;
        }
      });
    }

    if (found) {
      return true;
    } else {
      return false;
    }
  }

  getEventType(date: Date): string {
    let found = false;
    let tipologia = '';
    if (this.dataSource) {
      this.dataSource.data.forEach(v => {
        const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
        if (date1.getTime() === date.getTime() && localStorage.getItem('username') === v[this.dipendenteIndex]) {
          found = true;
          tipologia = v[this.tipologiaIndex];

        }
      });
    }
    if (!found) {
      return '';
    } else {
      return tipologia;
    }

  }

  TransformDataFormatToSave(Data: string): string {

    if (Data === '' || !Data) {
      return '0001-01-01T00:00:00';
    }
    // // console.log(Data);
    const dateMomentObject = moment(Data, 'DD/MM/YYYY'); // 1st argument - string, 2nd argument - format
    const dateObject = dateMomentObject.toDate();
    // // console.log(dateMomentObject);
    if (dateMomentObject.isValid()) {
      return this.datePipe.transform(dateObject, 'yyyy-MM-dd', '', 'en-GB');
    } else {
      return this.datePipe.transform(Data, 'yyyy-MM-dd', '', 'en-GB');
    }

  }

  ngOnInit(): void {
    this.reloadCalendar();


    // @ts-ignore
    /* const calendar = document.getElementById('calendario')
     console.log(calendar);*/
  }

  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    console.log(date);
    // let x=this.adminService.dateFormat(date)
    // this.openAppointmentList(x)
  }
}

@Component({
  selector: 'app-showprenotazionieventdialog',
  templateUrl: 'showPrenotazioniEventDialog.html',
  styleUrls: ['./gestione-prenotazioni.component.css']
})
export class ShowPrenotazioniEventComponent {
  events: Array<any> = new Array<any>();
  eventDay: any;
  title: string;
  oreImpegnate: string;
  nomeProgetto: string;
  selectedDay: any;

  constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<ShowPrenotazioniEventComponent>, private datePipe: DatePipe,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService) {
    this.events = data.events;
    this.title = data.attivita;
    this.selectedDay = data.day.date;
    this.nomeProgetto = data.progetto;
    this.oreImpegnate = data.oreImpegno;
    this.events.forEach(v => {
      switch (v.tipologia) {
        case 'Auto fiat 500 5 posti':
          v.color = '#de7565';
          break;
        case 'Furgone fiat doblo 2 posti':
          v.color = '#f4ccac';
          break;
        case 'Pulmino fiat Daily 9 posti':
          v.color = '#6a6a6a';
          break;
        case 'Auto Ford Focus 5 posti':
          v.color = '#c2cf9d';
          break;
        case 'Camioncino Iveco Daily 3 posti':
          v.color = '#b8c1df';
          break;
        default:
          v.color = '#b8c1df';
      }
    });
    this.eventDay = data.day;
  }
  openProject(): void{
    window.open('https://next.betsoft-srl.it/ARXivarNextWebPortal/#!/view/9bc2a25a86034e848c72862defdb89b6?p=%5B%5B%22DocName%22%2C4%2C%22'
      + encodeURIComponent(this.nomeProgetto) + '%22%2Cnull%5D%2C%5B%22DocumentType%22%2C3%2C10%5D%2C%5B%22fromTo%22%2C1%5D%2C%5B%22maxResults%22%2C0%5D%5D', '_blank');
  }
  TransformDataFormatToShow(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');
  }

  sendRevision(): void {

  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

