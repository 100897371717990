<style>
  .material-symbols-outlined {
    font-variation-settings: 'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
  }
</style>

<ng-template
  #monthCellTemplate
  let-day="day"
  let-openDay="openDay"
  let-locale="locale"
  let-tooltipPlacement="tooltipPlacement"
  let-highlightDay="highlightDay"
  let-unhighlightDay="unhighlightDay"
  let-eventClicked="eventClicked"
>
  <div
    class="fill-height"
    [contextMenu]="basicMenu"
    [contextMenuSubject]="day.date"
  >
    <div class="cal-cell-top" style="text-align: left !important;">

      <!--<div style="width: 100%; display: flex" *ngIf="this.isEventSetForUser(day.date)">
        <mat-icon>done</mat-icon>
        <span style="margin: 2%">{{this.getEventType(day.date)}}</span>
      </div>-->
      <hr style="color: {{this.apiService.dashColor}}; margin: 0">
      <div style="width:100%; text-align: left">
        <span *ngIf="day.badgeTotal > 0" style="margin: 2%"
        >Prenotazioni: {{ day.badgeTotal }}</span
        ></div>


      <span class="cal-day-number"
      >{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span
      >

    </div>

    <div class="cal-events">
      <div
        class="cal-event"
        *ngFor="let event of day.events"

        [ngClass]="event?.cssClass"
        (mouseenter)="highlightDay.emit({event: event})"
        (mouseleave)="unhighlightDay.emit({event: event})"
        [mwlCalendarTooltip]="event.title | calendarEventTitle:'monthTooltip':event"
        [tooltipPlacement]="tooltipPlacement"
        (click)="$event.stopPropagation(); eventClicked.emit({event: event})"
      ></div>
    </div>
  </div>
</ng-template>


<context-menu #basicMenu (open)="this.resetVariables()" style="width: 100% !important;">

  <ng-template contextMenuItem passive="true">
    <ng-container *ngIf="this.eventType == ''">
      <p style="text-align: center">Aggiungi prenotazione:</p>
      <hr>
      <div style="display: flex; max-width: 500px;flex-direction: column;">


        <button mat-button (click)="this.setEventType('Auto fiat 500 5 posti')">
          Auto fiat 500 5 posti
        </button>
        <button mat-button (click)="this.setEventType('Furgone fiat doblo 2 posti')">
          Furgone fiat doblo 2 posti
        </button>
        <button mat-button (click)="this.setEventType('Pulmino fiat Daily 9 posti')">
          Pulmino fiat Daily 9 posti
        </button>
        <button mat-button (click)="this.setEventType('Auto Ford Focus 5 posti')">
          Auto Ford Focus 5 posti
        </button>
        <button mat-button (click)="this.setEventType('Camioncino Iveco Daily 3 posti')">
          Camioncino Iveco Daily 3 posti
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="this.eventType != ''">
      <p style="text-align: center">Inserimento prenotazione per {{this.eventType}}:</p>
      <hr>
      <div style="text-align: center; display: flex">
        <div style="width: 100%;">
          <span>Ora di inizio</span>
          <div style="width: 0%; text-align: left; margin: 2%; margin-bottom: 3%">
            <ngx-timepicker-field [controlOnly]="true" (timeChanged)="startHourChanged($event)" [format]="24"
                                  [defaultTime]="''"></ngx-timepicker-field>
          </div>


          <span>Ora di fine</span>
          <div style="width: 0%; text-align: left; margin: 2%">
            <ngx-timepicker-field style="width: 230px !important;" [controlOnly]="true"
                                  (timeChanged)="endHourChanged($event)" [format]="24"
                                  [defaultTime]="''"></ngx-timepicker-field>

          </div>
        </div>
        <!--<div style="width: 68%">
          <mat-form-field appearance="outline" style = "height: 100%; width: 100%">
            <mat-label>Oggetto</mat-label>
            <textarea matInput style="resize: none;"> </textarea>
          </mat-form-field>
        </div>-->
      </div>

    </ng-container>
  </ng-template>
  <ng-template contextMenuItem (execute)="this.addEvent($event)">
    <div style="text-align: center; width: 500px !important;margin: 2%">
      <button mat-stroked-button style="color: mediumseagreen; width: 90%; " *ngIf="this.eventType != ''">Conferma
      </button>

      <!-- <button mat-stroked-button style="color: red; width: 90%;">Esci</button>-->
    </div>
  </ng-template>
  <ng-template contextMenuItem (execute)="removeEvent($event.item)">
    <div style="text-align: center; width: 500px !important;margin: 2%">
      <button mat-stroked-button style="color: red; width: 90%; ">Rimuovi prenotazioni
      </button>
    </div>
  </ng-template>

</context-menu>

<div class="center">
  <mat-card class="cardcomponentMasks" id="style-4">
    <h3 class="titles" style="color:{{this.apiService.dashColor}};">
      <mat-card-title><p>Calendario prenotazioni:</p>

      </mat-card-title>
    </h3>
    <hr class="riga" style="color:{{this.apiService.dashColor}}; margin-bottom: 1% !important">

    <mat-card-content><!--hai cliccato la maschera con id: {{this.id}}-->
      <div style="heigth: 5%; width: 100%; display: flex; margin-bottom: 1%">
        <div style="display: flex; margin-right: 3%;">
          <mat-icon class="material-symbols-outlined" style="color: #de7565">
            circle
          </mat-icon>
          <span style="width: fit-content;    margin: auto;"><b>Auto fiat 500 5 posti</b></span>
        </div>
        <div style="display: flex; margin-right: 3%;">
          <mat-icon class="material-symbols-outlined" style="color: #f4ccac">
            circle
          </mat-icon>
          <span style="width: fit-content;    margin: auto;"><b>Furgone fiat doblo 2 posti</b></span>
        </div>
        <div style="display: flex; margin-right: 3%;">
          <mat-icon class="material-symbols-outlined" style="color: #6a6a6a">
            circle
          </mat-icon>

          <span style="width: fit-content;    margin: auto;"><b>Pulmino fiat Daily 9 posti</b></span>
        </div>
        <div style="display: flex; margin-right: 3%;">
          <mat-icon class="material-symbols-outlined" style="color: #c2cf9d">
            circle
          </mat-icon>

          <span style="width: fit-content;    margin: auto;"><b>Auto Ford Focus 5 posti</b></span>
        </div>
        <div style="display: flex; margin-right: 3%;">
          <mat-icon class="material-symbols-outlined" style="color: #b8c1df">
            circle
          </mat-icon>

          <span style="width: fit-content;    margin: auto; "><b>Camioncino Iveco Daily 3 posti</b></span>
        </div>

      </div>
      <hr class="riga" style="color:{{this.apiService.dashColor}};">
      <div style="display: flex">
        <div style="width: 40%; text-align: left">
          <h1>{{ viewDate | calendarDate:(view + 'ViewTitle'):'it' }}</h1>
        </div>

        <div style="width: 60%; text-align: right">
          <div class="btn btn-primary"
               style="background-color: {{this.apiService.dashColor}}; border-color: {{this.apiService.dashColor}}"
               mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate">
            Indietro
          </div>
          <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
            Oggi
          </div>
          <div class="btn btn-primary"
               style="background-color: {{this.apiService.dashColor}}; border-color: {{this.apiService.dashColor}}"
               mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">
            Avanti
          </div>
        </div>


      </div>
      <mwl-calendar-month-view
        id="calendario"
        [viewDate]="viewDate"
        (dayClicked)="cellClicked($event)"
        [events]="events"
        [refresh]="refresh"
        [locale]="locale"
        [weekStartsOn]="weekStartsOn"
        [cellTemplate]="monthCellTemplate"
        [weekendDays]="weekendDays">
      </mwl-calendar-month-view>
    </mat-card-content>
    <div style="text-align: left;">

    </div>

  </mat-card>
</div>

