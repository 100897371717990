<div style="width: 100%">
    <button style="float: right;" title="{{'labels.close' | translate}}" (click)="closeDialog()" mat-icon-button color="warn"
            aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div style="width: 100%; display: flex">
    <h2 style="text-align: center; width: 100%;">{{'labels.renameFolder' | translate}}</h2>
</div>
<div style="text-align: center">
    <mat-form-field>
        <input [(ngModel)]="this.name" type="text" required matInput name="value"
               placeholder="{{'labels.folderName' | translate}}">
    </mat-form-field>
    <br><br>
    <hr>
    <br>
    <button mat-stroked-button (click) = "this.send()">{{'buttons.send' | translate}}</button>
</div>

