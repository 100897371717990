<div>
    <button style="float: right;" title="{{'labels.save' | translate}}" (click)="closeDialog()" mat-icon-button color="warn" aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div style="text-align: center"><h2>{{'labels.renameCategory' | translate}}:<br></h2>
    <mat-form-field>
        <input [(ngModel)]="this.newCat" matInput name="Nome"
               placeholder="{{'labels.name' | translate}} {{'labels.category' | translate}}"
        >
    </mat-form-field>
    <br><br>
    <hr>
    <br>
    <button mat-stroked-button (click)="rename()">{{'labels.rename' | translate}}</button>
</div>
