<div style = "height: 100%">
  <div style="position: -webkit-sticky;
  position: sticky;
  top: 0;">
    <mat-form-field style="width: 60%">
      <mat-label>{{'labels.filter' | translate}}</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="{{'labels.filter' | translate}}..." #input>
      <button mat-button *ngIf="this.filter" matSuffix mat-icon-button
              aria-label="{{'labels.cancel' | translate}}" (click)="this.filter=''; this.filterChange()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <button title="{{'labels.save' | translate}}" (click)="closeDialog()" mat-icon-button color="warn" aria-label="icon-button save">
      <mat-icon>close</mat-icon>
    </button>
    <button style="color:{{this.squareColor}};" title="{{'labels.close' | translate}}" (click)="saveSelectedValues()" mat-icon-button
            aria-label="icon-button save">
      <mat-icon>save</mat-icon>
    </button>
    <button title="{{'labels.clearField' | translate}}" (click)="deleteField()" mat-icon-button color="warn" aria-label="icon-button save">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <mat-card-content style="height: 90%;">
    <div class="example-container mat-elevation-z8 " id="style-4">
      <table mat-table [dataSource]="dataSource">
        <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
          <ng-container *ngIf="disCol == 'select'">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container *ngIf="disCol != 'select'">
            <th mat-header-cell *matHeaderCellDef>{{disCol}}</th>
            <td mat-cell *matCellDef="let element">{{element[disCol]}}
            </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">{{'labels.noRecord' | translate}}: "{{input.value}}"</td>
        </tr>
      </table>
    </div>
  </mat-card-content>
</div>
