import {BrowserModule} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {AppRoutingModule} from './app-routing.module';
import {AngularMaterialModule} from './angular-material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {FooterComponent} from './layout/footer/footer.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ToolbarComponent} from './layout/toolbar/toolbar.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {NavBarComponent} from './layout/nav-bar/nav-bar.component';
import {DashnavComponent} from './dashnav/dashnav.component';
import {DatePipe, PathLocationStrategy, registerLocaleData} from '@angular/common';

import {HomeComponent} from './VociMenu/home/home.component';
import {RubricaComponent} from './VociMenu/rubrica/rubrica.component';
import {ArchiviazioneComponent} from './VociMenu/archiviazione/archiviazione.component';
import {RicercaComponent} from './VociMenu/ricerca/ricerca.component';
import {UltimaricercaComponent} from './VociMenu/ultimaricerca/ultimaricerca.component';
import {VisteComponent} from './VociMenu/viste/viste.component';
import {MaschereComponent} from './VociMenu/maschere/maschere.component';
import {TaskComponent} from './VociMenu/task/task.component';
import {FascicoliComponent} from './VociMenu/fascicoli/fascicoli.component';
import {StrumentiComponent} from './VociMenu/strumenti/strumenti.component';
import {GestioneComponent} from './VociMenu/gestione/gestione.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MaskFilterPipe} from './mask-filter.pipe';
import {VistaFilterPipe} from './vista-filter.pipe';
import {MaskDetailsComponent} from './VociMenu/maskDetails/maskDetails.component';
import {ViewDetailsComponent} from './VociMenu/viewDetails/viewDetails.component';
import {ModelliComponent} from './VociMenu/modelli/modelli.component';
import {ProfiliComponent} from './VociMenu/profili/profili.component';
import {
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS
} from '@angular/material/core';
import {MatMenuModule} from '@angular/material/menu';
import {DettaglirubricaComponent} from './VociMenu/dettaglirubrica/dettaglirubrica.component';
import {DettaglitaskComponent} from './VociMenu/dettaglitask/dettaglitask.component';
import {SettingsComponent} from './VociMenu/settings/settings.component';
import {HomeV2Component} from './MenuItemsV2/home-v2/home-v2.component';
import {DashboardV2Component} from './dashboard-v2/dashboard-v2.component';
import {MasktreatmentsComponent} from './MenuItemsV2/masktreatments/masktreatments.component';
import {MasksupplierComponent} from './MenuItemsV2/masksuppliers/masksuppliers.component';
import {MaskclientsComponent} from './MenuItemsV2/maskclients/maskclients.component';
import {MaskresourcesComponent} from './MenuItemsV2/maskresources/maskresources.component';
import {MaskagreementsComponent} from './MenuItemsV2/maskagreements/maskagreements.component';
import {MaskdatabreachComponent} from './MenuItemsV2/maskdatabreach/maskdatabreach.component';
import {MaskrequestsComponent} from './MenuItemsV2/maskrequests/maskrequests.component';
import {ViewRegistersComponent} from './MenuItemsV2/viewsRegisters/viewsRegisters.component';
import {ReportComponent} from './MenuItemsV2/report/report.component';
import {CommentoDialogComponent} from './VociMenu/dettaglitask/dettaglitask.component';
import {DialogTableDTOComponent} from './additionalDialog/dialogTableDTOComponent';
import {DialogMultiDTOComponent} from './additionalDialog/dialogMultiDTOComponent';
import {DialogAddressBookComponent} from './additionalDialog/dialogAddressBookComponent';
import {DialogComboDTOComponent} from './additionalDialog/dialogComboDTOComponents';
import {DialogMaskComponent} from './additionalDialog/dialogMaskComponents';
import {DialogClassViewDTOComponentDTOComponent} from './additionalDialog/dialogClassViewDTOComponent';
import {DialogProfileComponent} from './additionalDialog/dialogProfileComponents';
import {DialogSearchDocComponent} from './additionalDialog/dialogSearchDocComponents';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {PreviewDialogComponent} from './VociMenu/dettaglitask/dettaglitask.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ActionDialogComponent} from './VociMenu/dettaglitask/dettaglitask.component';
import {AddNoteDialogComponent} from './VociMenu/dettaglitask/dettaglitask.component';
import {EditFromFileComponent} from './VociMenu/dettaglitask/dettaglitask.component';
import {EditRevisionComponent} from './VociMenu/dettaglitask/dettaglitask.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTreeModule} from '@angular/material/tree';
import {ProfileComponent} from './MenuItemsV2/profile/profile.component';
import {ProcessComponent} from './MenuItemsV2/process/process.component';
import {ViewComponent} from './MenuItemsV2/view/view.component';
import {SearchandeditComponent} from './MenuItemsV2/searchandedit/searchandedit.component';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AttachmentsDialogComponent} from './VociMenu/dettaglitask/dettaglitask.component';
import {DefaultHomeComponent} from './VociMenu/default-home/default-home.component';
import {ModelDetailComponent} from './VociMenu/model-detail/model-detail.component';
import {MatTabsModule} from '@angular/material/tabs';
import {AddCategoryDialogComponent} from './VociMenu/rubrica/rubrica.component';
import {RenameCategoryDialogComponent} from './VociMenu/rubrica/rubrica.component';
import {InsertFileDialogComponent} from './VociMenu/model-detail/model-detail.component';
import {DialogDocumentalClassComponent} from './additionalDialog/dialogDocumentalClass';
import {EditFromFileProfileComponent} from './MenuItemsV2/view/view.component';
import {EditRevisionProfileComponent} from './MenuItemsV2/view/view.component';
import {ShowAttachmentsDialogComponent} from './MenuItemsV2/view/view.component';
import {AddExternalAttachmentComponent} from './MenuItemsV2/view/view.component';
import {IxfeComponent} from './ixfe/ixfe.component';
import {MatSortModule} from '@angular/material/sort';
import {RedirectViewComponent} from './MenuItemsV2/redirect-view/redirect-view.component';
import {DxSelectBoxModule, DxTreeViewModule} from 'devextreme-angular';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {RedirectFoldersComponent} from './MenuItemsV2/redirect-fodlers/redirect-folders.component';
import {AddFolderDialogComponent} from './VociMenu/fascicoli/fascicoli.component';
import {RenameFolderDialogComponent} from './VociMenu/fascicoli/fascicoli.component';
import {InfoDialogComponent} from './VociMenu/fascicoli/fascicoli.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {ButtonModule} from 'primeng/button';
import {NotesDialogComponent} from './VociMenu/dettaglitask/dettaglitask.component';
import {NotifySentDialogComponent} from './ixfe/ixfe.component';
import {NotifyRicDialogComponent} from './ixfe/ixfe.component';
import {ShowEventComponent} from './MenuItemsV2/tabella-presenze/tabella-presenze.component';
import {FilterDialogComponent} from './VociMenu/default-home/default-home.component';
import {ReportdetailsComponent} from './MenuItemsV2/reportdetails/reportdetails.component';
import {ReportDownloadDialogComponent} from './MenuItemsV2/reportdetails/reportdetails.component';
import {ReportExpandDialogComponent} from './MenuItemsV2/reportdetails/reportdetails.component';
import {ReportInfoDialogComponent} from './MenuItemsV2/reportdetails/reportdetails.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {RedirectComponentsComponent} from './redirect-components/redirect-components.component';
import {CheckUpComponent} from './AdditionalComponents/check-up/check-up.component';
import {ChangePasswordDialogComponent} from './layout/nav-bar/nav-bar.component';
import {SignerDetailsComponent} from './MenuItemsV2/view/view.component';
import {ChangeUrlDialogComponent} from './layout/nav-bar/nav-bar.component';
import {ProfileNotesComponent} from './MenuItemsV2/view/view.component';
import {RouteReuseStrategy} from '@angular/router';
import {CacheRouteReuseStrategy} from './cache-route-reuse.strategy';
import {AddColorComponent} from './VociMenu/settings/settings.component';
import {ColorSketchModule} from 'ngx-color/sketch';
import {ColorChromeModule} from 'ngx-color/chrome';
import {RedirectMaskComponent} from './MenuItemsV2/redirect-mask/redirect-mask.component';
import {PowerBiComponent} from './MenuItemsV2/power-biA/power-bi.component';
import {PowerBiCTEAMComponent} from './MenuItemsV2/power-bi-cteam/power-bi-cteam.component';
import {RelationsComponent} from './MenuItemsV2/relations/relations.component';
import {ProgressSpinnerDialogComponent} from './additionalDialog/progress-spinner-dialog/progress-spinner-dialog.component';
import {CalendarioComponent} from './MenuItemsV2/calendario/calendario.component';
import {DialogAddressBookV2Component} from './additionalDialog/dialog-address-book-v2/dialog-address-book-v2.component';
import {PowerBiTecniciComponent} from './MenuItemsV2/power-bi-tecnici/power-bi-tecnici.component';
import {RevisionsComponent} from './MenuItemsV2/view/view.component';
import {ResizableModule} from 'angular-resizable-element';
import {PreviewComponent} from './MenuItemsV2/view/view.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {ViewDetailsForTaskComponent} from './VociMenu/viewDetails/viewDetailsForTask';
import {
  MomentDateModule,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import {CalendarioTratComponent} from './MenuItemsV2/calendario-trat/calendario-trat.component';
import {TrattativeComponent} from './MenuItemsV2/trattative/trattative.component';
import {GantateamComponent} from './MenuItemsV2/gantateam/gantateam.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FullPreviewComponent} from './VociMenu/dettaglitask/dettaglitask.component';
import {CalendarioMaiaComponent} from './MenuItemsV2/calendario-maia/calendario-maia.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSliderModule} from '@angular/material/slider';
import {BottomSheetOverview} from './dashboard/dashboard.component';
import {DynamicJobComponent} from './VociMenu/dettaglitask/dettaglitask.component';
import {AddProfileToFolderComponent} from './MenuItemsV2/view/view.component';
import {ContainFolderComponent} from './MenuItemsV2/view/view.component';
import {TabellaPresenzeComponent} from './MenuItemsV2/tabella-presenze/tabella-presenze.component';
import localeIt from '@angular/common/locales/it';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {ContextMenuModule} from '@perfectmemory/ngx-contextmenu';
import {GestionePrenotazioniComponent} from './MenuItemsV2/gestione-checklist-ferie/gestione-prenotazioni.component';
import {ShowPrenotazioniEventComponent} from './MenuItemsV2/gestione-checklist-ferie/gestione-prenotazioni.component';
import {GestionePrenotazioniAutoComponent} from './MenuItemsV2/gestione-prenotazioni-auto/gestione-prenotazioni.component';
import {ShowPrenotazioniAutoEventComponent} from './MenuItemsV2/gestione-prenotazioni-auto/gestione-prenotazioni.component';

registerLocaleData(localeIt);

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    EditRevisionComponent,
    EditFromFileComponent,
    NotesDialogComponent,
    ProfileNotesComponent,
    ChangeUrlDialogComponent,
    RevisionsComponent,
    AddProfileToFolderComponent,
    SignerDetailsComponent,
    VistaFilterPipe,
    PreviewComponent,
    ViewDetailsForTaskComponent,
    ChangePasswordDialogComponent,
    ReportDownloadDialogComponent,
    ContainFolderComponent,
    ReportdetailsComponent,
    ReportExpandDialogComponent,
    ReportInfoDialogComponent,
    AddColorComponent,
    FilterDialogComponent,
    AddFolderDialogComponent,
    EditFromFileProfileComponent,
    EditRevisionProfileComponent,
    RedirectViewComponent,
    LoginComponent,
    RenameFolderDialogComponent,
    GestionePrenotazioniAutoComponent,
    ShowPrenotazioniAutoEventComponent,
    AddCategoryDialogComponent,
    FullPreviewComponent,
    BottomSheetOverview,
    ShowPrenotazioniEventComponent,
    ShowAttachmentsDialogComponent,
    InsertFileDialogComponent,
    ShowEventComponent,
    InfoDialogComponent,
    AddExternalAttachmentComponent,
    AttachmentsDialogComponent,
    GestionePrenotazioniComponent,
    MaskFilterPipe,
    DialogTableDTOComponent,
    TabellaPresenzeComponent,
    RenameCategoryDialogComponent,
    ActionDialogComponent,
    DialogDocumentalClassComponent,
    CommentoDialogComponent,
    FooterComponent,
    ToolbarComponent,
    DashboardComponent,
    NavBarComponent,
    HomeComponent,
    AddNoteDialogComponent,
    DynamicJobComponent,
    DashnavComponent,
    RubricaComponent,
    ArchiviazioneComponent,
    RicercaComponent,
    UltimaricercaComponent,
    VisteComponent,
    MaschereComponent,
    TaskComponent,
    FascicoliComponent,
    StrumentiComponent,
    GestioneComponent,
    MaskDetailsComponent,
    ViewDetailsComponent,
    ModelliComponent,
    ProfiliComponent,
    DettaglirubricaComponent,
    DettaglitaskComponent,
    SettingsComponent,
    HomeV2Component,
    DashboardV2Component,
    MasktreatmentsComponent,
    MasksupplierComponent,
    MaskclientsComponent,
    MaskresourcesComponent,
    MaskagreementsComponent,
    MaskdatabreachComponent,
    MaskrequestsComponent,
    ViewRegistersComponent,
    ReportComponent,
    DialogMultiDTOComponent,
    DialogAddressBookComponent,
    DialogComboDTOComponent,
    DialogMaskComponent,
    DialogClassViewDTOComponentDTOComponent,
    DialogProfileComponent,
    DialogSearchDocComponent,
    PreviewDialogComponent,
    ProfileComponent,
    ProcessComponent,
    ViewComponent,
    SearchandeditComponent,
    DefaultHomeComponent,
    ModelDetailComponent,
    IxfeComponent,
    RedirectFoldersComponent,
    NotifySentDialogComponent,
    NotifyRicDialogComponent,
    RedirectComponentsComponent,
    CheckUpComponent,
    RedirectMaskComponent,
    PowerBiComponent,
    PowerBiCTEAMComponent,
    RelationsComponent,
    ProgressSpinnerDialogComponent,
    CalendarioComponent,
    DialogAddressBookV2Component,
    PowerBiTecniciComponent,
    CalendarioTratComponent,
    TrattativeComponent,
    GantateamComponent,
    CalendarioMaiaComponent
  ],
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: 'it',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ColorSketchModule,
    BrowserModule,
    FormsModule,
    ResizableModule,
    ScrollingModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    AngularMaterialModule,
    FlexLayoutModule,
    MatCardModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatCheckboxModule,
    NgxMaterialTimepickerModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatTreeModule,
    MatTabsModule,
    MatSortModule,
    BrowserModule,
    DxTreeViewModule,
    DxSelectBoxModule,

    MatButtonToggleModule,

    MatAutocompleteModule,
    MatSliderModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ContextMenuModule.forRoot({
      useBootstrap4: false,
    })
  ],
  providers: [DatePipe,
    {
      provide: MAT_DATE_LOCALE, useValue: {
        provide: MAT_DATE_FORMATS,
        useValue: MY_FORMATS
      }
    },
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    {provide: RouteReuseStrategy, useClass: CacheRouteReuseStrategy}],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})


export class AppModule {
}

// tslint:disable-next-line:typedef
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

