<div class="center">
  <mat-card class="cardcomponentMasks" id="style-4" style="overflow: hidden; display: grid !important; grid-template-rows: 30px 30px auto;">
    <div style="display: flex">
      <div style="width: 50%; margin: auto">
        <ng-container *ngIf="this.edit == false">
          <button style="float:left" title="{{'labels.blocked' | translate}}" style="color: {{this.dashColor}};" (click)="unLock()"
                  mat-icon-button
                  aria-label="icon-button send">
            <mat-icon>lock</mat-icon>
          </button>
          <button style="float:left" title="{{'labels.open' | translate}}" style="color: {{this.dashColor}};"
                  (click)="downloadDoc(this.docId)"
                  mat-icon-button
                  aria-label="icon-button send">
            <mat-icon>get_app</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="this.edit == true">
          <button style="float:left; color:{{this.dashColor}};" title="{{'labels.save' | translate}}" (click)="saveMask()"
                  mat-icon-button
                  aria-label="icon-button send">
            <mat-icon>save</mat-icon>
          </button>
          <button style="float:left" title="{{'labels.unblocked' | translate}}" style="color: {{this.dashColor}};" (click)="lock()"
                  mat-icon-button
                  aria-label="icon-button send">
            <mat-icon style="color: {{this.dashColor}};">lock_open</mat-icon>
          </button>
          <button style="float:left" title="{{'labels.open' | translate}}" style="color: {{this.dashColor}};"
                  (click)="downloadDoc(this.docId)"
                  mat-icon-button
                  aria-label="icon-button send">
            <mat-icon>get_app</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="this.edit == true">

          <button *ngIf="badgeBufferMain >0" style="float:left; color:{{this.dashColor}};"
                  [matMenuTriggerFor]="menuFile"
                  aria-label="{{'labels.seeFile' | translate}}"
                  mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menuFile="matMenu">
            <ng-container *ngFor="let file of bufferIdInfo">
              <button mat-menu-item (click)="removeFile(file)">
                <mat-icon>close</mat-icon>
                <span style="font-size: 16px; font-weight: 500;">{{file.name}}</span>
              </button>
            </ng-container>
          </mat-menu>
          <button *ngIf="optionsMask.showFileSelection" style="float:left; color:{{this.dashColor}};"
                  title="{{'labels.uploadFile' | translate}}"
                  (click)="fileInput.click()"
                  mat-icon-button>
            <mat-icon [matBadge]="badgeBufferMain">cloud_upload</mat-icon>
          </button>
          <input type="file" (change)="showFileSelection($event)" hidden #fileInput id="fileUpload"
                 name="fileUpload"
                 multiple="multiple" accept="*"/>


        </ng-container>
      </div>
      <div style="width: 50%; margin: auto">
        <button mat-icon-button style = "color: {{this.apiService.dashColor}}; margin: auto" title = "{{'labels.processWorkflow' | translate}}" [matMenuTriggerFor]="commandMenu">
          <mat-icon>not_started</mat-icon>
        </button>
        <mat-menu #commandMenu="matMenu">
          <ng-container *ngFor = "let command of this.workflowEvents">
            <button mat-menu-item title = "{{command.workflowName}}" (click) = "this.startEvent(command.eventId)">
              <mat-icon>task_alt</mat-icon>
              <span>{{command.workflowName}}</span>
            </button>
          </ng-container>

        </mat-menu>

        <ng-container *ngIf="this.edit == true">
          <button *ngIf="optionsMask.showNotes" style="float:right; color:{{this.dashColor}};" title="Note"
                  (click)="showNotes()" mat-icon-button>
            <mat-icon>note</mat-icon>
          </button>
          <button *ngIf="optionsMask.showAttachments" style="float:right; color:{{this.dashColor}};"
                  title="{{'labels.attachments' | translate}}"
                  (click)="showAttachments()" mat-icon-button>
            <mat-icon>attach_file</mat-icon>
          </button>
          <button style="float:right; color:{{this.dashColor}};" title="Desk"
                  (click)="showDesk()" mat-icon-button>
            <mat-icon>computer</mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
    <hr class="riga" style="color: {{this.dashColor}};">
    <mat-card-content style="overflow: auto; height: 100%">
      <div style="width: 100%; height: 100%">
        <ng-container [ngSwitch]="this.whatToShow">
          <ng-container *ngSwitchCase="'default'">
            <form class="flexform" (input)="checkInputFormula()">
              <ng-container *ngFor="let field of this.fields">

                <ng-container [ngSwitch]="field.className"
                              *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"
                              style="height:fit-content; width: 100%;">
                  <!-- NumberFieldDTO -->
                  <mat-form-field *ngSwitchCase="'NumberFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                  appearance="outline">
                    <mat-label>{{field.description}}</mat-label>
                    <input ngModel [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           [id]="field.name"
                           [required]="field.required"
                           [disabled]="field.locked || this.edit == false"
                           [value]="field.value || ''"
                           maxlength="{{field.numMaxChar || 100}}"
                           matInput placeholder="{{field.description}}">
                  </mat-form-field>
                  <!-- DocumentDateFieldDTO -->
                  <mat-form-field *ngSwitchCase="'DocumentDateFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                  appearance="outline">
                    <mat-label>{{field.description}}</mat-label>
                    <input style="color:#29648A;" matInput [matDatepicker]="picker" onkeydown="return false;"
                           [value]="field.value || ''"
                           [id]="field.name" [required]="field.required"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           [disabled]="field.locked || this.edit == false">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker [disabled]="field.locked || this.edit == false" #picker
                                    color="primary"></mat-datepicker>
                  </mat-form-field>
                  <!-- SubjectFieldDTO -->
                  <mat-form-field *ngSwitchCase="'SubjectFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                  appearance="outline">
                    <mat-label>{{field.description}}</mat-label>
                    <input ngModel [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           [value]="field.value || ''"
                           [id]="field.name" [required]="field.required"
                           [disabled]="field.locked || this.edit == false"
                           maxlength="{{field.numMaxChar || 100}}" matInput
                           placeholder="{{field.description}}">
                  </mat-form-field>
                  <!-- ToFieldDTO -->
                  <mat-form-field *ngSwitchCase="'ToFieldDTO'"
                                  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }" appearance="outline">
                    <!--A-->
                    <mat-label>{{field.description}}</mat-label>
                    <mat-chip-list #chipList aria-label="MultiValue">
                      <div style="width: 100%; display: flex;">
                        <div style="width: 80%">
                          <mat-chip *ngFor="let element of this.mapAddressBookToSee.get(field.name)"
                                    [selectable]="false" [disabled]="field.locked || this.edit == false"
                                    [removable]="removable" (removed)="removeAddressBook(element, field.name)"
                                    [value]="element.id">
                            {{element.name}}
                            <mat-icon matChipRemove *ngIf="removable && !(field.locked)">cancel</mat-icon>
                          </mat-chip>
                        </div>
                        <div style="width: 20%; text-align: right">
                          <button matSuffix mat-icon-button (click)="openAddressBook(field)"
                                  style="color: {{this.apiService.dashColor}}"
                                  [disabled]="field.locked || this.edit == false">
                            <mat-icon>notes</mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-chip-list>
                  </mat-form-field>

                  <!-- FromFieldDTO -->
                  <mat-form-field *ngSwitchCase="'FromFieldDTO'"
                                  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }" appearance="outline">
                    <!--DA-->
                    <mat-label>{{field.description}}</mat-label>
                    <mat-chip-list #chipList aria-label="MultiValue">
                      <div style="width: 100%; display: flex;">
                        <div style="width: 80%">
                          <mat-chip *ngFor="let element of this.mapAddressBookToSee.get(field.name)"
                                    [selectable]="false" [disabled]="field.locked || this.edit == false"
                                    [removable]="removable" (removed)="removeAddressBook(element, field.name)"
                                    [value]="element.id">
                            {{element.name}}
                            <mat-icon matChipRemove *ngIf="removable && !(field.locked)">cancel</mat-icon>
                          </mat-chip>
                        </div>
                        <div style="width: 20%; text-align: right">
                          <button matSuffix mat-icon-button (click)="openAddressBook(field)"
                                  style="color: {{this.apiService.dashColor}}"
                                  [disabled]="field.locked || this.edit == false">
                            <mat-icon>notes</mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-chip-list>
                  </mat-form-field>

                  <!-- CcFieldDTO -->
                  <mat-form-field *ngSwitchCase="'CcFieldDTO'"
                                  [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}" appearance="outline">
                    <!--A-->
                    <mat-label>{{field.description}}</mat-label>
                    <mat-chip-list #chipList aria-label="MultiValue">
                      <div style="width: 100%; display: flex;">
                        <div style="width: 80%">
                          <mat-chip *ngFor="let element of this.mapAddressBookToSee.get(field.name)" [selectable]="false"
                                    [disabled]="field.locked || this.edit == false"
                                    [removable]="removable" (removed)="removeAddressBook(element, field.name)"
                                    [value]="element.id">
                            {{element.name}}
                            <mat-icon matChipRemove *ngIf="removable && !(field.locked)">cancel</mat-icon>
                          </mat-chip>

                        </div>
                        <div style="width: 20%; text-align: right">
                          <button matSuffix mat-icon-button (click)="openAddressBook(field)"
                                  style="color: {{this.apiService.dashColor}}" [disabled]="field.locked || this.edit == false">
                            <mat-icon>notes</mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-chip-list>

                  </mat-form-field>

                  <!-- BusinessUnitFieldDTO -->
                  <mat-form-field *ngSwitchCase="'BusinessUnitFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                  appearance="outline">
                    <!--Aoo-->
                    <mat-label>{{'labels.company' | translate}}</mat-label>
                    <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                                [required]="field.required"
                                [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                [disabled]="field.locked || this.edit == false">
                      <mat-option *ngFor="let aoo of nomiAoo" [value]="aoo.code">
                        {{aoo.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- DocumentTypeFieldDTO -->
                  <div *ngSwitchCase="'DocumentTypeFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }">


                    <mat-form-field class="form-field-docType" style="width: 80%"
                                    appearance="outline">
                      <mat-label>{{field.description}}</mat-label>
                      <mat-select [value]="field.value || ''" [id]="field.name"
                                  [required]="field.required"
                                  [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                  [disabled]="field.locked || this.edit == false"
                      >
                        <mat-option
                          (onSelectionChange)="documentChanged($event, documenType.documentType,documenType.type2,documenType.type3)"
                          *ngFor="let documenType of documenTypes"
                          [value]="documenType.id">
                          {{documenType.description}} - {{documenType.key}}
                        </mat-option>
                      </mat-select>

                    </mat-form-field>
                    <span>
                                    <button [disabled]="field.locked || this.edit == false" matSuffix mat-icon-button
                                            style="color: {{this.dashColor}};"
                                            (click)="opendocumentalClassDialog()">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                         </span>
                  </div>
                  <!-- OriginFieldDTO -->
                  <mat-form-field *ngSwitchCase="'OriginFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                  appearance="outline">
                    <!--Origine-->
                    <mat-label>{{field.description}}</mat-label>
                    <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                                [required]="field.required"
                                [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                [disabled]="field.locked || this.edit == false">
                      <mat-option *ngFor="let originField of originsFields"
                                  [value]="originField.value">
                        {{originField.description}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- StateFieldDTO -->
                  <mat-form-field *ngSwitchCase="'StateFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                  appearance="outline">
                    <!--Stato, dipende da documento-->
                    <mat-label>{{field.description}}</mat-label>
                    <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                                [required]="field.required"
                                [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                [disabled]="field.locked || this.edit == false">
                      <mat-option *ngFor="let stateField of stateFields" [value]="stateField.id">
                        {{stateField.description}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- BinderFieldDTO -->
                  <mat-form-field *ngSwitchCase="'BinderFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                  appearance="outline">
                    <!--Pratiche-->
                    <mat-label>{{field.description}}</mat-label>
                    <input ngModel [value]="field.value || ''"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           [id]="field.name" [required]="field.required"
                           [disabled]="field.locked || this.edit == false"
                           maxlength="{{field.numMaxChar || 100}}" matInput
                           placeholder="{{field.description}}">
                  </mat-form-field>

                  <!-- DocumentDateExpiredFieldDTO -->
                  <mat-form-field *ngSwitchCase="'DocumentDateExpiredFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                  appearance="outline">
                    <!--Scadenza-->
                    <mat-label>{{field.description}}</mat-label>
                    <input style="color:#29648A;" [value]="field.value || ''" matInput
                           [matDatepicker]="picker" onkeydown="return false;"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           [id]="field.name" [required]="field.required"
                           [disabled]="field.locked || this.edit == false" (click)="picker.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker [disabled]="field.locked || this.edit == false" #picker
                                    color="primary"></mat-datepicker>

                  </mat-form-field>

                  <!-- ImportantFieldDTO -->
                  <mat-form-field *ngSwitchCase="'ImportantFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                  appearance="outline">
                    <!--Campo "Importante"-->
                    <mat-label>{{field.description}}</mat-label>
                    <input ngModel [value]="field.value || ''"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           [id]="field.name" [required]="field.required"
                           [disabled]="field.locked || this.edit == false"
                           maxlength="{{field.numMaxChar || 100}}" matInput
                           placeholder="{{field.description}}">
                  </mat-form-field>

                  <!-- SendersFieldDTO -->
                  <mat-form-field *ngSwitchCase="'SendersFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                  appearance="outline">
                    <!--Altri da-->
                    <mat-label>{{field.description}}</mat-label>
                    <input ngModel [value]="field.value || ''"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           [id]="field.name" [required]="field.required"
                           [disabled]="field.locked || this.edit == false"
                           maxlength="{{field.numMaxChar || 100}}" matInput
                           placeholder="{{field.description}}">
                  </mat-form-field>

                  <!-- OriginalFieldDTO -->
                  <mat-form-field *ngSwitchCase="'OriginalFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                  appearance="outline">
                    <!--Originale-->
                    <mat-label>{{field.description}}</mat-label>
                    <input ngModel [value]="field.value || ''"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           [id]="field.name" [required]="field.required"
                           [disabled]="field.locked || this.edit == false"
                           maxlength="{{field.numMaxChar || 100}}" matInput
                           placeholder="{{field.description}}">
                  </mat-form-field>

                  <!-- AdditionalFieldGroupDTO -->
                  <div class="form-field-left" *ngSwitchCase="'AdditionalFieldGroupDTO'">
                    <h3
                      appearance="outline">
                      <!--Campo aggiuntivo "Generale"-->
                      <br>
                      <h1 style="color: {{this.dashColor}}">{{field.description}}<br>
                        <hr>
                      </h1>

                    </h3>
                  </div>
                  <!-- AdditionalFieldStringDTO -->
                  <ng-container *ngSwitchCase="'AdditionalFieldStringDTO'">
                    <ng-container *ngIf="field.description == 'Link' && this.edit == false" >
                      <a   [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                           target="_blank" href="{{dataMaskToSave[field.name]}}">{{dataMaskToSave[field.name]}}</a>
                    </ng-container>
                    <ng-container *ngIf="field.description != 'Link' || this.edit == true">
                      <mat-form-field   [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                        appearance="outline">
                        <!--Tipo documento-->
                        <mat-label>{{field.description}}</mat-label>
                        <ng-container *ngIf="field.numMaxChar > 400">
                                      <textarea ngModel [value]="field.value || ''"
                                                [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                [id]="field.name" [required]="field.required"
                                                [disabled]="field.locked || this.edit == false"
                                                maxlength="{{field.numMaxChar || 100}}" matInput rows="{{field.numRows}}" autosize
                                                style="resize: none;width:95%;"
                                                placeholder="{{field.description}}"></textarea>
                        </ng-container>
                        <ng-container *ngIf="field.numMaxChar <= 400">
                          <input ngModel [value]="field.value || ''"
                                 [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                 [id]="field.name" [required]="field.required"
                                 [disabled]="field.locked || this.edit == false"
                                 maxlength="{{field.numMaxChar || 100}}" matInput
                                 placeholder="{{field.description}}">
                        </ng-container>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>



                  <!-- AdditionalFieldTableDTO -->
                  <mat-form-field (click)="openTableDTO(field)"
                                  *ngSwitchCase="'AdditionalFieldTableDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                  appearance="outline">
                    <!--TableDTO-->
                    <mat-label>{{field.description}}</mat-label>
                    <input ngModel [value]="field.value || ''"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           [id]="field.name" [required]="field.required"
                           [disabled]="field.locked || this.edit == false"
                           maxlength="{{field.numMaxChar || 100}}" matInput
                           placeholder="{{field.description}}">
                    <button mat-button matSuffix mat-icon-button style="color: {{this.dashColor}};">
                      <mat-icon>table_chart</mat-icon>
                    </button>
                  </mat-form-field>

                  <!-- AdditionalFieldMultivalueDTO -->
                  <mat-form-field *ngSwitchCase="'AdditionalFieldMultivalueDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                  >
                    <!--MultiValueDTO-->
                    <mat-label>{{field.description}}</mat-label>
                    <mat-chip-list #chipList aria-label="MultiValue" [required]="field.required">
                      <div style="width: 100%; display: flex;">
                        <div style="width: 80%">
                          <mat-chip *ngFor="let element of this.mapMultiValueToSee.get(field.name)" [selectable]="false"
                                    [disabled]="field.locked || this.edit == false"
                                    [removable]="removable" (removed)="remove(element, field.name)">
                            {{element}}
                            <mat-icon matChipRemove *ngIf="removable && !(field.locked || this.edit == false)">cancel
                            </mat-icon>
                          </mat-chip>
                        </div>
                        <div style="width: 20%; text-align: right; height: 60px;">
                          <button matSuffix mat-icon-button (click)="openMultiValueDTO(field)"
                                  style="color: {{this.apiService.dashColor}}"
                                  [disabled]="field.locked || this.edit == false">
                            <mat-icon>notes</mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-chip-list>
                  </mat-form-field>
                  <div *ngSwitchCase="'InstructionFieldDTO'" style = "background-color: aliceblue;" [innerHtml] = "field.description" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}" appearance="outline" style="text-align: left; color: {{this.apiService.squareColor}}">


                  </div>
                  <mat-form-field *ngSwitchCase="'AdditionalFieldDoubleDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                  appearance="outline">
                    <mat-label>{{field.description}}</mat-label>
                    <input ngModel [value]="field.value || ''"
                           [id]="field.name" type="text" [required]="field.required"
                           [disabled]="field.locked || this.edit == false"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           maxlength="{{field.numMaxChar || 100}}" matInput
                           placeholder="{{field.description}}">
                  </mat-form-field>
                  <ng-container *ngSwitchCase="'AdditionalFieldBooleanDTO'">

                    <div style="text-align: left !important; margin-bottom: 2%; margin-top: 1%;"
                         [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                         appearance="outline">
                      <mat-slide-toggle [id]="field.name" style="color: {{this.apiService.dashColor}}" color="warn"
                                        [required]="field.required" (toggleChange)="this.checkFormula()"
                                        [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}" [disabled]="field.locked || this.edit == false"
                      >{{field.description}}</mat-slide-toggle>
                      <!--MultiValueDTO-->
                    </div>
                  </ng-container>
                  <!-- AdditionalFieldComboDTO -->
                  <ng-container *ngSwitchCase="'AdditionalFieldComboDTO'">
                    <ng-container *ngIf="field.limitToList">
                      <mat-form-field
                        [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                        appearance="outline">
                        <mat-label>{{field.description}}</mat-label>
                        <mat-select [id]="field.name" [value]="field.value || ''"
                                    [required]="field.required" (selectionChange)="this.checkFormula()"
                                    (selectionChange)="this.checkComboAssociations(field)"
                                    [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                    [disabled]="field.locked || this.edit == false">
                          <mat-option>--{{'labels.cancel' | translate}}--</mat-option>
                          <mat-option *ngFor="let value of this.comboValues[field.name]"
                                      [value]="value.keyField">
                            {{value.selectField}}
                          </mat-option>

                        </mat-select>

                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="!field.limitToList">

                      <mat-form-field style="width: 45%"
                                      [ngClass]="{
                                                         'form-field': field.columns !== 1,
                                                         'form-field-expanded': field.columns === 1
                                                      }"
                                      appearance="outline">
                        <mat-label>{{field.description}}</mat-label>
                        <input ngModel [value]="field.value || ''"
                               [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                               [id]="field.name" [required]="field.required"
                               [disabled]="field.locked || this.edit == false" [ngModelOptions]="{standalone: true}"
                               maxlength="{{field.numMaxChar || 100}}" matInput
                               placeholder="{{field.description}}" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete">
                          <mat-option *ngFor="let value of this.comboValues[field.name]"
                                      [value]="value.keyField">
                            {{value.selectField}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'AdditionalFieldDateTimeDTO'">
                    <mat-form-field *ngIf="field.value == '0001-01-01T00:00:00'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                    appearance="outline"
                    >
                      <!--AdditionalFieldDateTimeDTO-->
                      <mat-label>{{field.description}}</mat-label>
                      <input style="color:#29648A;" [value]="field.value || ''" matInput
                             [matDatepicker]="picker" onkeydown="return false;"
                             [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                             [id]="field.name" [required]="field.required" (dateChange)="this.checkInputFormula()"
                             [disabled]="field.locked || this.edit == false" (click)="picker.open()">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker disabled="{{field.locked || this.edit == false}}" #picker color="primary"></mat-datepicker>

                    </mat-form-field>
                    <mat-form-field *ngIf="field.value != '0001-01-01T00:00:00'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                    appearance="outline"
                    >
                      <!--AdditionalFieldDateTimeDTO-->
                      <mat-label>{{field.description}}</mat-label>
                      <input style="color:#29648A;" [value]="'' || ''" matInput
                             [matDatepicker]="picker" onkeydown="return false;"
                             [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                             [id]="field.name" [required]="field.required" (dateChange)="this.checkInputFormula()"
                             [disabled]="field.locked || this.edit == false" (click)="picker.open()">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker disabled="{{field.locked || this.edit == false}}" #picker color="primary"></mat-datepicker>

                    </mat-form-field>
                  </ng-container>
                  <!--AdditionalFieldIntDTO-->
                  <mat-form-field *ngSwitchCase="'AdditionalFieldIntDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                  appearance="outline">
                    <mat-label>{{field.description}}</mat-label>
                    <input ngModel [value]="field.value || ''"
                           [id]="field.name" type="number" [required]="field.required"
                           [disabled]="field.locked || this.edit == false"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           maxlength="{{field.numMaxChar || 100}}" matInput
                           placeholder="{{field.description}}">
                  </mat-form-field>

                  <!-- AdditionalFieldClasseDTO -->

                  <div class="classBox" *ngSwitchCase="'AdditionalFieldClasseDTO'">

                    <div>
                      <h4 style="float:left; margin-left: 2.5%; color: {{this.dashColor}};">{{field.description}} </h4>
                      <button *ngIf="mapClasseBookToSave.get(field.name).length > 0"
                              style="float:right; color:{{this.dashColor}};"
                              title="{{'labels.seeMasks' | translate}}"
                              (click)="openClassMasks(field)" mat-icon-button
                              aria-label="icon-button send">
                        <mat-icon [matBadge]="mapClasseBookToSave.get(field.name).length">
                          preview
                        </mat-icon>
                      </button>
                      <button style="float:right; color:{{this.dashColor}};"
                              title="{{'labels.insertMask' | translate}}"
                              (click)="insertMaskFromClassDTO(field)" mat-icon-button
                              aria-label="icon-button send">
                        <mat-icon>post_add</mat-icon>
                      </button>
                      <!--<button style="float:right; color:{{this.dashColor}};"
                              title="Cerca maschera"
                              (click)="searchMaskFromClassDTO(field)" mat-icon-button
                              aria-label="icon-button send">
                        <mat-icon>search</mat-icon>
                      </button>-->
                    </div>

                    <!--div class="z8 example-container">
                      <table mat-table [dataSource]="dataSourceClass">
                        <ng-container *ngFor="let disCol of displayedColumnsClass; let colIndex = index"
                                      matColumnDef="{{disCol}}">
                          <ng-container>
                            <th mat-header-cell *matHeaderCellDef
                                style="font-weight: 600; font-size:14px; color: {{this.dashColor}};">{{disCol}}</th>
                            <td mat-cell *matCellDef="let element">{{element[disCol]}}
                            </td>
                          </ng-container>
                          <mat-divider [vertical]="true"></mat-divider>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsClass"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsClass;"></tr>
                      </table>

                    </div-->
                  </div>
                  <mat-form-field *ngSwitchDefault  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }" appearance="outline">
                    <mat-label>{{field.description}}</mat-label>
                    <input matInput placeholder="{{field.description}}">
                  </mat-form-field>
                </ng-container>

              </ng-container>
            </form>
          </ng-container>
          <ng-container *ngSwitchCase="'note'">
            <div style="color: {{this.dashColor}}; text-align: left"><h1>{{'labels.notes' | translate}}: </h1>
              <div style="text-align: center">
                <ng-container *ngIf="this.notesList.length === 0">
                  {{'labels.noNote' | translate}}
                </ng-container>
                <div style="text-align: left;">
                  <mat-selection-list #viste [multiple]="false">
                    <ng-container *ngIf="this.notesList.length != 0">
                      <div *ngFor="let note of this.notesList">
                        <button style="float:right; color:{{this.squareColor}};"
                                title="{{'labels.deleteNote' | translate}}"
                                mat-button
                                aria-label="{{'labels.deleteNote' | translate}}" (click)=deleteNote(note.id)>
                          <mat-icon>delete</mat-icon>
                        </button>
                        <mat-list-item style="width: 80%;">
                          <div>
                            <b>{{note.userCompleteName}}</b><span
                            style="margin-left: 10px;">{{note.comment}}</span><span
                            style="margin-left: 10px; color: darkgray">{{this.apiService.TransformDataFormat(note.creationDate)}}</span>
                          </div>
                        </mat-list-item>
                      </div>
                    </ng-container>
                  </mat-selection-list>
                </div>
                <br>
                <hr>
                <div style="text-align: center; margin-top: 5%">
                  <button mat-stroked-button (click)="addNoteTrue()">{{this.addLabel}}</button>
                </div>
                <div *ngIf="this.showAddNote" style="margin-top: 5%">
                  <mat-form-field appearance="fill" style="width: 60%">
                    <mat-label>{{'labels.noteText' | translate}}</mat-label>
                    <textarea matInput required rows="3" [(ngModel)]="this.newNote"
                              placeholder="{{'labels.insertNote' | translate}}" type="text"></textarea>
                  </mat-form-field>
                  <br>
                  <div style="    display: flex; justify-content: center;">
                    <button mat-stroked-button (click)="saveNewNote()">{{'labels.save' | translate}}</button>
                  </div>
                </div>
                <br>
              </div>
            </div>
            <br>
          </ng-container>
          <ng-container *ngSwitchCase="'attachments'">
            <div style="color: {{this.dashColor}}; text-align: left"><h1>{{'labels.attachmentsList' | translate}}: </h1>
              <div style="text-align: center; color: {{this.apiService.dashColor}};">
                <ng-container *ngIf="this.attachments.length == 0">
                  {{'labels.noAttachmentsFound' | translate}}
                </ng-container>
                <div style="text-align: left; color: {{this.apiService.dashColor}};">
                  <mat-selection-list #viste [multiple]="false">
                    <ng-container *ngIf="this.attachments.length != 0">
                      <div *ngFor="let attack of this.attachments">
                        <button style="float:right; color:{{this.squareColor}};"
                                title="{{'labels.deleteAttached' | translate}}"
                                mat-button
                                aria-label="{{'labels.deleteAttached' | translate}}" (click)="deleteAttach(attack.id)">
                          <mat-icon>delete</mat-icon>
                        </button>
                        <mat-list-item
                          (click)="downloadDocForAttachment(attack.id, attack.originalname)"
                          style="width: 80%;">
                          <ng-container
                            *ngIf="(attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))=='pdf'">
                            <mat-icon>picture_as_pdf</mat-icon>
                          </ng-container>
                          <ng-container
                            *ngIf="(attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))=='docx'">
                            <mat-icon>description</mat-icon>
                          </ng-container>
                          <ng-container
                            *ngIf="(attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))=='doc'">
                            <mat-icon>article</mat-icon>
                          </ng-container>
                          <ng-container
                            *ngIf="(attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))=='xlsx'">
                            <mat-icon>ballot</mat-icon>
                          </ng-container>
                          <ng-container
                            *ngIf="(attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))=='csv'">
                            <mat-icon>dashboard</mat-icon>
                          </ng-container>
                          <ng-container
                            *ngIf="(attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))!='docx' && (attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))!='pdf' && (attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))!='doc' && (attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))!='xlsx' && (attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))!='csv'">
                            {{(attack.originalname.substr(attack.originalname.indexOf('.') + 1, attack.originalname.length))}}
                          </ng-container>
                          <span style="margin-left: 10px;">{{attack.originalname}}</span>
                          <span *ngIf="attack.comment != ''"
                                style="margin-left: 10px; color: {{this.dashColor}};">{{attack.comment}}</span>
                          <span
                            style="margin-left: 10px; color: darkgrey;">{{this.TransformDataFormatToShow(attack.importdate)}}</span>
                        </mat-list-item>
                      </div>
                    </ng-container>
                  </mat-selection-list>
                </div>
                <br>
                <hr>
                <div style="text-align: center; margin-top: 5%">
                  <button mat-stroked-button style="margin-right: 20px"
                          (click)="openAddExternalAttachmentDialog()">{{'labels.addAttachmentExt' | translate}}
                  </button>
                </div>
              </div>
            </div>
            <br>
          </ng-container>
        </ng-container>
      </div>
      <i aria-hidden="true"></i>
      <i aria-hidden="true"></i>

    </mat-card-content>
  </mat-card>
</div>

