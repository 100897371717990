<div>
  <button style="float: right;" title="{{'labels.save' | translate}}" (click)="closeDialog()" mat-icon-button color="warn" aria-label="icon-button save">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div style="text-align: center"><h2>{{'labels.numberRevision' | translate}}</h2>
    <mat-form-field>
        <input [(ngModel)]="revisionNumber" type="number" matInput name="value"
               placeholder="{{'labels.versioneNumber' | translate}}"
        >
    </mat-form-field>
    <br><br>
    <hr>
    <br>
    <button mat-stroked-button (click)="sendRevision()">{{'labels.send' | translate}}</button>
</div>
