import {Component, Inject, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {ApiService} from '../../api.service';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
    user = localStorage.getItem('username');
    dashColor: string;
    backColor: string;
    squareColor: string;
    cardColor: string;

    constructor(public translate: TranslateService, public dialog: MatDialog, private route: ActivatedRoute, private router: Router, public apiService: ApiService,
                iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
        iconRegistry.addSvgIcon(
            'LogOut',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/exit_to_app-24px.svg'));
        iconRegistry.addSvgIcon(
            'Home',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/home-24px.svg'));
    }

    ngOnInit(): void {
        this.dashColor = this.apiService.dashColor;
        this.backColor = this.apiService.backColor;
        this.squareColor = this.apiService.squareColor;
        this.cardColor = this.apiService.cardColor;
    }

    loginDocuSign(): void {
        localStorage.setItem('waitforcodedocu', 'true');
    }
    loginGoogle(): void {
        localStorage.setItem('waitforcodegoogle', 'true');
        window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?' +
            'scope=https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.profile&' +
            'access_type=offline&' +
            'include_granted_scopes=true&' +
            'response_type=code&' +
            'state=state_parameter_passthrough_value&' +
            'redirect_uri=' + this.apiService.googleURI + '&' +
            'client_id=' + this.apiService.googleApiId;

    }
    ClickLogOut(): void {

        this.router.navigate(['login']);
        this.apiService.setLoggedOut();

    }

    openIxFe(): void {
        this.router.navigate(['dashnav/ixfe'], {relativeTo: this.route});
    }

    ClickHome(): void {
        this.router.navigate(['dashboard/dashnav/desktop']);
    }

    openChangePasswordDialog(): void {
        const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
            width: '20%',
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
        });
    }
    openSettings(): void{
      this.apiService.isMenuClick = true;
      this.apiService.addPath(this.translate.instant('labels.settings'), 'dashboard/dashnav/settings', {});
      this.router.navigate(['dashnav/settings', {lbl: 'Impostazioni'}], {relativeTo: this.route});
    }
    openChangeUrlDialog(): void {
        const dialogRef = this.dialog.open(ChangeUrlDialogComponent, {
            width: '20%',
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
        });
    }

}

@Component({
    selector: 'app-changepassword',
    templateUrl: 'changePassword.html',
})
export class ChangePasswordDialogComponent {
    oldPassword = '';
    newPassword = '';
    confirmPassword = '';
    constrains: any;
    error: string;

    constructor(private translate: TranslateService, private snackBar: MatSnackBar, public apiService: ApiService,
                public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.apiService.getPasswordConstrains().subscribe(res => {
            this.constrains = res;
        });

    }

    checkErrors(): void {


      if (this.newPassword === this.oldPassword) {
        this.translate.get('advices.newPasswordSameAsOld').subscribe(res => {
          this.error = res;
        });
      } else if (this.newPassword !== this.confirmPassword) {
        this.translate.get('advices.passwordMustMatch').subscribe(res => {
          this.error = res;
        });
      } else {
        this.error = '';
      }
    }

    changePassword(): void {
        if (this.oldPassword !== '' && this.newPassword !== '' && this.confirmPassword !== '') {
            if (this.newPassword === this.oldPassword) {
              this.translate.get('advices.newPasswordSameAsOld').subscribe(res => {
                this.error = res;
              });
            } else if (this.newPassword !== this.confirmPassword) {
              this.translate.get('advices.passwordMustMatch').subscribe(res => {
                this.error = res;
              });
            } else {
                this.apiService.changePassword(this.oldPassword, this.newPassword).subscribe(res => {
                  this.translate.get('advices.passwordChangedSuccessfully').subscribe(result => {
                    this.snackBar.open(result, null, {
                      duration: 3000,
                      horizontalPosition: 'end',
                      verticalPosition: 'bottom',
                      panelClass: ['mat-toolbar', 'custom-success-bg']
                    });
                  });
                    this.closeDialog();
                }, errorLog => {
                    this.snackBar.open(errorLog.error.userMessage, null, {
                        duration: 5000,
                        horizontalPosition: 'end',
                        verticalPosition: 'bottom',
                        panelClass: ['mat-toolbar', 'custom-danger-bg']
                    });
                });
            }
        } else {
          this.translate.get('advices.allFieldsMustBeCompiled').subscribe(res => {
            this.error = res;
          });
        }
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}

@Component({
    selector: 'app-changeurl',
    templateUrl: 'changeUrl.html',
})
export class ChangeUrlDialogComponent {
    error: string;
    url = '';

    constructor(private snackBar: MatSnackBar, public apiService: ApiService,
                public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.url = this.apiService.currentURL;
    }

    saveUrl(): void {
        if (this.url !== '') {
            this.apiService.currentURL = this.url;
            localStorage.setItem('currentURL', this.url);
            this.dialogRef.close();
        } else {
            this.error = 'inserire un url!';
        }
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}
