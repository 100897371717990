import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {ApiService} from '../../api.service';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {Output, EventEmitter, Input} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {DatePipe} from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';
import {interval} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormGroup} from '@angular/forms';
import {BottomSheetOverview} from '../../dashboard/dashboard.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-defaulthome-v2',
  templateUrl: './default-home.component.html',
  styleUrls: ['./default-home.component.css']
})
export class DefaultHomeComponent implements OnInit {
  @Output() searchModelChange: EventEmitter<any> = new EventEmitter();
  label = this.activatedRoute.snapshot.paramMap.get('lbl');
  ora: number;
  panelOpenState = false;
  breakpoint: number;
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;
  Viewdesk: [];
  Maskdesk: [];
  Quikdesk: [];
  Profiledesk: [];
  defaultSelectTakBody: any;
  takBody: Array<any> = new Array<any>();
  Modelsdesk: [];
  Folderdesk: [];
  views: [];
  documentFields: Array<any> = new Array<any>();
  Task: [];
  value = '';
  activeDocNumber: string;
  TaskAttive: [];
  ColonneTask: [];
  sub: any;
  nTask = 0;
  todayDate = new Date();
  showFiller = false;
  allTasksOpen: boolean;
  priorityTasksOpen: boolean;
  expiredTasksOpen: boolean;
  searchModelMaschere: string;
  searchModelViews: string;
  valueRowHeight = 'fit';
  utente = localStorage.getItem('username');
  taskList: Array<any> = new Array<any>();
  tempDefaultTaskFields: Map<string, string> = new Map<string, string>();
  tempAdditionalTaskFields: Array<any> = new Array<any>();
  taskFieldsSettings: any;
  bodyRequest: any;
  filter = '';
  filterTags: Array<any> = new Array<any>();
  found: boolean;
  selectedRead = this.translate.instant('labels.all');
  selectedExpired = this.translate.instant('labels.all');
  selectedPriority = this.translate.instant('labels.all');
  typeOfTaskView = 'panel';
  masks: [];
  noDesktopElements = false;
  viuws: Array<any> = new Array<any>();
  clickableReload = true;
  orderBy = this.translate.instant('labels.startDate');
  orderDirection: string;
  user = localStorage.getItem('username');
  groupBy = 'DM_WORKFLOW_NOME';
  groups: Array<any> = new Array<any>();


  // tslint:disable-next-line:max-line-length
  constructor(public translate: TranslateService, public dialog: MatDialog, private snackBar: MatSnackBar, private datePipe: DatePipe, private route: ActivatedRoute,
              private router: Router, public apiService: ApiService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
              private activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet) {
    iconRegistry.addSvgIcon(
      'RicercaIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/search-24px.svg'));
    iconRegistry.addSvgIcon(
      'DoneIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/done-24px.svg'));
    iconRegistry.addSvgIcon(
      'VisteIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/preview-24px.svg'));
    iconRegistry.addSvgIcon(
      'MaschereIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/tab-24px.svg'));
    iconRegistry.addSvgIcon(
      'DetailsIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/visibility-24px.svg'));
  }

  ricercaClick(label: string): void {
    this.apiService.isMenuClick = true;
    this.apiService.addPath(label, 'dashboard/dashnav/ricerca', {});
    this.router.navigate(['dashboard/dashnav/ricerca', {lbl: label}]);
  }

  maschereClick(): void {
    const dialogRef = this.dialog.open(BottomSheetOverview, {
      width: '20%',

    });
    dialogRef.afterClosed().subscribe(result => {

    });

    /*this.apiService.isMenuClick = true;
    this.apiService.addPath(label, 'dashboard/dashnav/maschere', {id: '0'});
    this.router.navigate(['dashnav/maschere', {id: '0', lbl: label}], {relativeTo: this.route});*/
  }

  refreshTasks(): void {
    if (this.clickableReload) {
      this.clickableReload = false;
      this.apiService.getUserSettings().subscribe(settings => {
        this.apiService.userSettings = settings;
        if (settings.taskrepeater) {
          this.taskFieldsSettings = JSON.parse(settings.taskrepeater.gridSettings);
        }
        this.apiService.defaultSelect().subscribe(res => {
          this.generateDefaultTakBody();
          if (settings.taskrepeater) {
            this.taskFieldsSettings.columns.forEach(fieldSettings => {
              for (let i = 0; i < res.fields.length; i++) {
                if (res.fields[i].name === fieldSettings.name && fieldSettings.name !== 'ID_PROCESSO') {
                  this.addFieldToBody(res.fields[i]);
                  i = res.fields.length + 1;
                }
              }
            });
          } else {
            this.addFieldToBody({fieldType: 0, selected: true, name: 'NOMETASK', index: 0});
            this.addFieldToBody({fieldType: 0, selected: true, name: 'DATA_SCADENZA', index: 2});
          }
          this.apiService.postTasksIdv2(this.bodyRequest).subscribe(data => {
            // console.log(this.taskList);
            data.data.forEach((task) => {
              this.tempDefaultTaskFields = new Map<string, string>();
              this.tempAdditionalTaskFields = new Array<any>();
              let found = false;
              this.taskList.forEach(t => {
                data.columns.forEach((v, index) => {
                  if (v.id === 'ID') {
                    if (t.defaultFields.get('ID') === task[index]) {
                      found = true;
                    }
                  }
                });

              });
              if (found === false) {
                this.nTask++;
                task.forEach((value, index) => {
                  if (data.columns[index].id === 'NOMETASK' ||
                    data.columns[index].id === 'DATA_SCADENZA' ||
                    data.columns[index].id === 'ASSOCIA' ||
                    data.columns[index].id === 'DM_PROCESSI_COLORE' ||
                    data.columns[index].id === 'DM_PROFILE_DOCNUMBER' ||
                    data.columns[index].id === 'DM_TASKEXECUTE_IDUTENTE' ||
                    data.columns[index].id === 'ID' ||
                    data.columns[index].id === 'ID_PROCESSO' ||
                    data.columns[index].id === 'LETTO' ||
                    data.columns[index].id === 'OBBCOM' ||
                    data.columns[index].id === 'PSWREQUIRED' ||
                    data.columns[index].id === 'STATO' ||
                    data.columns[index].id === 'DM_PROFILE_AUTOREDESCRIPTION' ||
                    data.columns[index].id === 'DESCTASK' ||
                    data.columns[index].id === 'DM_WORKFLOW_NOME' ||
                    data.columns[index].id === 'DM_PROFILE_STATO') {
                    this.tempDefaultTaskFields.set(data.columns[index].id, value);
                  } else {
                    this.tempAdditionalTaskFields.push({label: data.columns[index].label, description: value});
                  }
                });
                this.taskList.push({
                  defaultFields: this.tempDefaultTaskFields,
                  additionalFields: this.tempAdditionalTaskFields,
                  disabledForFilter: false,
                  disabledForPriority: false,
                  disabledForExpired: false,
                  disabledForRead: false
                });
              }
            });
            // console.log(this.taskList);

            this.taskList.forEach((t, ind) => {
              let foundData = false;
              data.data.forEach(dat => {
                data.columns.forEach((v, index) => {
                  if (v.id === 'ID') {
                    if (t.defaultFields.get('ID') === dat[index]) {
                      foundData = true;
                    }
                  }
                });
              });
              if (!foundData) {
                this.taskList.splice(ind, 1);
                this.nTask--;
              }
            });
            this.filterTags = new Array<any>();
            if (this.taskList[0]) {
              this.taskList[0].additionalFields.forEach(aF => {
                this.filterTags.push({tag: aF.label, active: true});
              });

              for (const key of this.taskList[0].defaultFields.keys()) {
                this.filterTags.push({tag: key, active: true});
              }
            }
            // console.log(this.filterTags);
            this.groups = new Array<any>();
            let noState = false;
            this.taskList.forEach(task => {
              if (task.disabledForFilter === false) {
                if (task.defaultFields.get(this.groupBy)) {
                  let found = false;
                  this.groups.forEach(v => {
                    if (v === task.defaultFields.get(this.groupBy)) {
                      found = true;
                    }
                  });
                  if (!found) {
                    this.groups.push(task.defaultFields.get(this.groupBy));
                  }
                } else {
                  if (!noState) {
                    noState = true;
                    this.groups.push(this.translate.instant('labels.noDocument'));
                  }
                }
              }
            });
            this.groups.sort();
            // // console.log(data.data);
            /*if (this.nTask > 200) {
              this.typeOfTaskView = 'plain';
            } else {
              this.typeOfTaskView = 'panel';
            }*/

            this.ColonneTask = data.columns;
            this.clickableReload = true;
          });
        });
      }, errorLog => {

        this.apiService.defaultSelect().subscribe(res => {
          this.generateDefaultTakBody();

          this.addFieldToBody({fieldType: 0, selected: true, name: 'NOMETASK', index: 0});
          this.addFieldToBody({fieldType: 0, selected: true, name: 'DATA_SCADENZA', index: 2});

          this.apiService.postTasksIdv2(this.bodyRequest).subscribe(data => {
            data.data.forEach((task) => {
              this.tempDefaultTaskFields = new Map<string, string>();
              this.tempAdditionalTaskFields = new Array<any>();
              let found = false;
              this.taskList.forEach(t => {
                if (t.defaultFields.get('ID_PROCESSO') === task[11]) {
                  found = true;
                }
              });
              if (found === false) {
                this.nTask++;
                task.forEach((value, index) => {
                  if (data.columns[index].id === 'NOMETASK' ||
                    data.columns[index].id === 'DATA_SCADENZA' ||
                    data.columns[index].id === 'ASSOCIA' ||
                    data.columns[index].id === 'DM_PROCESSI_COLORE' ||
                    data.columns[index].id === 'DM_PROFILE_DOCNUMBER' ||
                    data.columns[index].id === 'DM_TASKEXECUTE_IDUTENTE' ||
                    data.columns[index].id === 'ID' ||
                    data.columns[index].id === 'ID_PROCESSO' ||
                    data.columns[index].id === 'LETTO' ||
                    data.columns[index].id === 'OBBCOM' ||
                    data.columns[index].id === 'PSWREQUIRED' ||
                    data.columns[index].id === 'STATO' ||
                    data.columns[index].id === 'DM_PROFILE_AUTOREDESCRIPTION' ||
                    data.columns[index].id === 'DESCTASK' ||
                    data.columns[index].id === 'DM_WORKFLOW_NOME' ||
                    data.columns[index].id === 'DM_PROFILE_STATO') {
                    this.tempDefaultTaskFields.set(data.columns[index].id, value);
                  } else {
                    this.tempAdditionalTaskFields.push({label: data.columns[index].label, description: value});
                  }
                });
                this.taskList.push({
                  defaultFields: this.tempDefaultTaskFields,
                  additionalFields: this.tempAdditionalTaskFields,
                  disabledForFilter: false,
                  disabledForPriority: false,
                  disabledForExpired: false,
                  disabledForRead: false
                });
              }
            });
            // console.log(this.taskList);

            this.filterTags = new Array<any>();
            if (this.taskList[0]) {
              this.taskList[0].additionalFields.forEach(aF => {
                this.filterTags.push({tag: aF.label, active: true});
              });


              for (const key of this.taskList[0].defaultFields.keys()) {
                this.filterTags.push({tag: key, active: true});
              }
            }
            // console.log(this.filterTags);
            this.taskList.forEach(task => {
              task.defaultFields.set('DESCTASK', task.defaultFields.get('DESCTASK').replace(/\n/g, '<br/>'));
            });
            // // console.log(data.data);

            /*if (this.nTask > 200) {
              this.typeOfTaskView = 'plain';
            } else {
              this.typeOfTaskView = 'panel';
            }*/
            this.ColonneTask = data.columns;
            this.clickableReload = true;
          });
          this.clickableReload = true;
        });
      });
    } else {
      this.clickableReload = true;
    }

  }

  readChange(): void {
    this.nTask = 0;
    this.taskList.forEach(task => {
      this.found = false;
      if (this.selectedRead === this.translate.instant('labels.all')) {
        this.found = true;
      } else if (task.defaultFields.get('LETTO').toString() === this.selectedRead) {
        this.found = true;
      }
      if (this.found === false) {
        task.disabledForRead = true;
      } else {
        this.nTask++;
        task.disabledForRead = false;
      }
    });
    if (this.nTask > 200) {
      this.typeOfTaskView = 'plain';
    } else {
      this.typeOfTaskView = 'panel';
    }
  }

  changeView(): void {
    if (this.typeOfTaskView === 'plain') {
      this.typeOfTaskView = 'panel';
    } else {
      this.typeOfTaskView = 'plain';
    }
  }

  expiredChange(): void {
    this.nTask = 0;
    this.taskList.forEach(task => {
      this.found = false;
      if (this.selectedExpired === this.translate.instant('labels.all')) {
        this.found = true;
      } else if (this.selectedExpired === this.translate.instant('labels.expired') && this.convertStringToDate(task.defaultFields.get('DATA_SCADENZA')) < this.todayDate) {
        this.found = true;
      } else if (this.selectedExpired === this.translate.instant('labels.noExpired') && this.convertStringToDate(task.defaultFields.get('DATA_SCADENZA')) > this.todayDate) {
        this.found = true;
      }
      if (this.found === false) {
        task.disabledForExpired = true;
      } else {
        this.nTask++;
        task.disabledForExpired = false;
      }
    });
    if (this.nTask > 200) {
      this.typeOfTaskView = 'plain';
    } else {
      this.typeOfTaskView = 'panel';
    }
  }

  priorityChange(): void {
    this.nTask = 0;
    this.taskList.forEach(task => {
      this.found = false;
      if (this.selectedPriority === this.translate.instant('labels.all')) {
        this.found = true;
      } else if (task.defaultFields.get('ASSOCIA').toString() === this.selectedPriority) {
        this.found = true;
      }
      if (this.found === false) {
        task.disabledForPriority = true;
      } else {
        this.nTask++;
        task.disabledForPriority = false;
      }
    });
    if (this.nTask > 200) {
      this.typeOfTaskView = 'plain';
    } else {
      this.typeOfTaskView = 'panel';
    }
  }

  filterChange(): void {
    if (this.filter !== null) {
      this.nTask = 0;
      this.taskList.forEach(task => {
        this.found = false;
        task.additionalFields.forEach(af => {
          this.filterTags.forEach(tag => {
            if (tag.tag === af.label && tag.active === true) {
              if (af.description !== null) {
                if (af.description.toLocaleLowerCase().includes(this.filter.toLocaleLowerCase())) {
                  this.found = true;
                }
              }
            }
          });
        });
        for (const entry of task.defaultFields.entries()) {
          this.filterTags.forEach(tag => {
            if (tag.tag === entry[0] && tag.active === true) {
              if (entry[1] !== null) {
                if (entry[1].toString().toLocaleLowerCase().includes(this.filter.toLocaleLowerCase())) {
                  this.found = true;
                }
              }
            }
          });
        }
        if (this.found === false && this.filter !== '') {
          task.disabledForFilter = true;
        } else {
          this.nTask++;
          task.disabledForFilter = false;
        }
      });
      if (this.nTask > 200) {
        this.typeOfTaskView = 'plain';
      } else {
        this.typeOfTaskView = 'panel';
      }
      this.groups = new Array<any>();
      let noState = false;
      this.taskList.forEach(task => {
        if (task.disabledForFilter === false) {
          if (task.defaultFields.get(this.groupBy)) {
            let found = false;
            this.groups.forEach(v => {
              if (v === task.defaultFields.get(this.groupBy)) {
                found = true;
              }
            });
            if (!found) {
              this.groups.push(task.defaultFields.get(this.groupBy));
            }
          } else {
            if (!noState) {
              noState = true;
              this.groups.push(this.translate.instant('labels.noDocument'));
            }
          }
        }
      });
      this.groups.sort();
    }
  }

  openFilterDialog(): void {


    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '40%',
      data: {tags: this.filterTags}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.resultFilter) {
        this.filterTags = result.resultFilter;
        this.filterChange();
      }
    });
  }

  addFieldToBody(field: any): void {
    field.selected = true;
    this.bodyRequest.select.fields.push(field);
  }

  generateDefaultTakBody(): void {
    this.bodyRequest = {
      select: {
        fields: [
          {fieldType: 0, selected: true, name: 'ID', index: 999},
          {fieldType: 0, selected: true, name: 'ID_PROCESSO', index: 999},
          {fieldType: 0, selected: true, name: 'ASSOCIA', index: 999},
          {fieldType: 0, selected: true, name: 'PSWREQUIRED', index: 999},
          {fieldType: 0, selected: true, name: 'LETTO', index: 999},
          {fieldType: 0, selected: true, name: 'OBBCOM', index: 999},
          {
            sqlSelectField: 'DM_PROFILE.STATO AS DM_PROFILE_STATO',
            toCalculate: false,
            index: 13,
            selected: true,
            fieldType: 0,
            orderBy: {
              direction: 0,
              index: 0
            },
            externalId: null,
            label: 'Stato',
            name: 'DM_PROFILE_STATO',
            userSelectionEnabled: true,
            userSelectionGroup: 0
          },
          {fieldType: 0, selected: true, name: 'DM_PROCESSI_COLORE', index: 999},
          {
            sqlSelectField: '{DM_PROFILE_AUTOREDESCRIPTION}',
            toCalculate: false,
            index: 8,
            selected: true,
            fieldType: 0,
            orderBy: {
              direction: 0,
              index: 0
            },
            externalId: null,
            label: 'Autore descrizione',
            name: 'DM_PROFILE_AUTOREDESCRIPTION',
            userSelectionEnabled: true,
            userSelectionGroup: 1
          },
          {fieldType: 0, selected: true, name: 'DM_PROFILE_DOCNUMBER', index: 999},
          {
            sqlSelectField: '{DM_TASKWORK_DESCTASK}',
            toCalculate: false,
            index: 19,
            selected: true,
            fieldType: 0,
            orderBy: {
              direction: 0,
              index: 0
            },
            externalId: null,
            label: 'Descrizione task',
            name: 'DESCTASK',
            userSelectionEnabled: true,
            userSelectionGroup: 1
          },
          {
            sqlSelectField: 'DM_TASKWORK.STATO',
            toCalculate: false,
            index: 2,
            selected: true,
            fieldType: 0,
            orderBy: {
              direction: 0,
              index: 0
            },
            externalId: null,
            label: 'Stato',
            name: 'STATO',
            userSelectionEnabled: true,
            userSelectionGroup: 1
          },
          {fieldType: 0, selected: true, name: 'DM_TASKEXECUTE_IDUTENTE', index: 999}
        ],
        maxItems: 0
      },
      taskworkIds: [],
      workFlowIds: []
    } as unknown as JSON;
  }

  updateSearchModelViste(value): void {
    this.searchModelViews = value;
    this.searchModelChange.emit(this.searchModelViews);
  }

  ngOnInit(): void {

    this.apiService.getViews().subscribe(datas => {
      this.viuws = datas;
    });
    // console.log(this.apiService.OAUTHCODE);
    this.apiService.getAllMasks().subscribe(data => {
      this.masks = data;
    });
    localStorage.setItem('authcodegoogle', this.apiService.AUTHCODE);
    localStorage.setItem('authcode', this.apiService.OAUTHCODE);
    if (localStorage.getItem('authcode')) {
      this.apiService.docusignGetAccessToken().subscribe(res => {
        // console.log(res);
        localStorage.setItem('access_token', res.access_token);
        this.apiService.docusignGetUserInfo().subscribe(userInfo => {

          this.apiService.DocuSignUserInfo = userInfo;
          this.apiService.IsDocuSignLogged = true;
          userInfo.accounts.forEach(v => {
            if (v.is_default) {
              this.apiService.DocuSignAccountId = v.account_id;
            }
          });
        });
      });
    } else {
      this.apiService.IsDocuSignLogged = false;
    }

    if (localStorage.getItem('authcodegoogle')) {
      this.apiService.googleGetAccessToken().subscribe(result => {
        console.log(result.access_token);
        localStorage.setItem('googleToken', result.access_token);
        this.apiService.googleGetUserInfo().subscribe(res => {
          console.log(res);
        });
      });
    }

    this.apiService.addPath(this.label, 'dashboard/dashnav/defaulthome', {});
    this.apiService.namePath = this.apiService.namePath.split('-')[0];
    this.sub = interval(5000)
      .subscribe((val) => {
        this.refreshTasks();
      });
    this.todayDate = new Date();
    // // console.log(this.todayDate);
    this.dashColor = this.apiService.dashColor;
    this.backColor = this.apiService.backColor;
    this.squareColor = this.apiService.squareColor;
    this.cardColor = this.apiService.cardColor;
    this.taskList = new Array<any>();
    this.apiService.getUserSettings().subscribe(settings => {
      this.apiService.userSettings = settings;
      if (settings.taskrepeater) {
        this.taskFieldsSettings = JSON.parse(settings.taskrepeater.gridSettings);
      }
      this.apiService.defaultSelect().subscribe(res => {
        this.generateDefaultTakBody();
        if (settings.taskrepeater) {
          this.taskFieldsSettings.columns.forEach(fieldSettings => {
            for (let i = 0; i < res.fields.length; i++) {
              if (res.fields[i].name === fieldSettings.name && fieldSettings.name !== 'ID_PROCESSO') {
                this.addFieldToBody(res.fields[i]);
                i = res.fields.length + 1;
              }
            }
          });
        } else {
          this.addFieldToBody({fieldType: 0, selected: true, name: 'NOMETASK', index: 0});
          this.addFieldToBody({fieldType: 0, selected: true, name: 'DATA_SCADENZA', index: 2});
        }
        this.apiService.postTasksIdv2(this.bodyRequest).subscribe(data => {
          console.log('entrato');
          data.data.forEach((task) => {
            this.tempDefaultTaskFields = new Map<string, string>();
            this.tempAdditionalTaskFields = new Array<any>();
            task.forEach((value, index) => {
              if (data.columns[index].id === 'NOMETASK' ||
                data.columns[index].id === 'DATA_SCADENZA' ||
                data.columns[index].id === 'ASSOCIA' ||
                data.columns[index].id === 'DM_PROCESSI_COLORE' ||
                data.columns[index].id === 'DM_PROFILE_DOCNUMBER' ||
                data.columns[index].id === 'DM_TASKEXECUTE_IDUTENTE' ||
                data.columns[index].id === 'ID' ||
                data.columns[index].id === 'ID_PROCESSO' ||
                data.columns[index].id === 'LETTO' ||
                data.columns[index].id === 'OBBCOM' ||
                data.columns[index].id === 'PSWREQUIRED' ||
                data.columns[index].id === 'STATO' ||
                data.columns[index].id === 'DM_PROFILE_AUTOREDESCRIPTION' ||
                data.columns[index].id === 'DESCTASK' ||
                data.columns[index].id === 'DM_WORKFLOW_NOME' ||
                data.columns[index].id === 'DM_PROFILE_STATO') {
                this.tempDefaultTaskFields.set(data.columns[index].id, value);
              } else {
                this.tempAdditionalTaskFields.push({label: data.columns[index].label, description: value});
              }
            });
            this.taskList.push({
              defaultFields: this.tempDefaultTaskFields,
              additionalFields: this.tempAdditionalTaskFields,
              disabledForFilter: false,
              disabledForPriority: false,
              disabledForExpired: false,
              disabledForRead: false
            });
          });
          // console.log(this.taskList);

          this.filterTags = new Array<any>();
          if (this.taskList[0]) {
            this.taskList[0].additionalFields.forEach(aF => {
              this.filterTags.push({tag: aF.label, active: true});
            });


            for (const key of this.taskList[0].defaultFields.keys()) {
              this.filterTags.push({tag: key, active: true});
            }
          }

          this.groups = new Array<any>();
          let noState = false;
          this.taskList.forEach(task => {
            if (task.disabledForFilter === false) {
              if (task.defaultFields.get(this.groupBy)) {
                let found = false;
                // console.log(task.defaultFields.get(this.groupBy));
                this.groups.forEach(v => {
                  if (v === task.defaultFields.get(this.groupBy)) {
                    found = true;
                  }
                });
                if (!found) {
                  this.groups.push(task.defaultFields.get(this.groupBy));
                }
              } else {
                if (!noState) {
                  noState = true;
                  this.groups.push(this.translate.instant('labels.noDocument'));
                }
              }
            }
          });
          this.groups.sort();
          this.TaskAttive = data.data;
          // // console.log(this.TaskAttive);
          this.TaskAttive.forEach(element => {
            this.nTask++;
          });
          if (this.nTask > 200) {
            this.typeOfTaskView = 'plain';
          } else {
            this.typeOfTaskView = 'panel';
          }
          this.ColonneTask = data.columns;
          console.log(this.taskList);
          this.sortTask(this.translate.instant('labels.startDate'), true, 'desc');
        });
      });
    }, errorLog => {

      this.apiService.defaultSelect().subscribe(res => {
        this.generateDefaultTakBody();

        this.addFieldToBody({fieldType: 0, selected: true, name: 'NOMETASK', index: 0});
        this.addFieldToBody({fieldType: 0, selected: true, name: 'DATA_SCADENZA', index: 2});

        this.apiService.postTasksIdv2(this.bodyRequest).subscribe(data => {
          data.data.forEach((task) => {
            this.tempDefaultTaskFields = new Map<string, string>();
            this.tempAdditionalTaskFields = new Array<any>();
            task.forEach((value, index) => {
              if (data.columns[index].id === 'NOMETASK' ||
                data.columns[index].id === 'DATA_SCADENZA' ||
                data.columns[index].id === 'ASSOCIA' ||
                data.columns[index].id === 'DM_PROCESSI_COLORE' ||
                data.columns[index].id === 'DM_PROFILE_DOCNUMBER' ||
                data.columns[index].id === 'DM_TASKEXECUTE_IDUTENTE' ||
                data.columns[index].id === 'ID' ||
                data.columns[index].id === 'ID_PROCESSO' ||
                data.columns[index].id === 'LETTO' ||
                data.columns[index].id === 'OBBCOM' ||
                data.columns[index].id === 'PSWREQUIRED' ||
                data.columns[index].id === 'STATO' ||
                data.columns[index].id === 'DM_PROFILE_AUTOREDESCRIPTION' ||
                data.columns[index].id === 'DESCTASK' ||
                data.columns[index].id === 'DM_WORKFLOW_NOME' ||
                data.columns[index].id === 'DM_PROFILE_STATO') {
                this.tempDefaultTaskFields.set(data.columns[index].id, value);
              } else {
                this.tempAdditionalTaskFields.push({label: data.columns[index].label, description: value});
              }
            });
            this.taskList.push({
              defaultFields: this.tempDefaultTaskFields,
              additionalFields: this.tempAdditionalTaskFields,
              disabledForFilter: false,
              disabledForPriority: false,
              disabledForExpired: false,
              disabledForRead: false
            });
          });
          // console.log(this.taskList);

          this.filterTags = new Array<any>();
          if (this.taskList[0]) {
            this.taskList[0].additionalFields.forEach(aF => {
              this.filterTags.push({tag: aF.label, active: true});
            });


            for (const key of this.taskList[0].defaultFields.keys()) {
              this.filterTags.push({tag: key, active: true});
            }
          }
          // console.log(this.filterTags);
          this.taskList.forEach(task => {
            task.defaultFields.set('DESCTASK', task.defaultFields.get('DESCTASK').replace(/\n/g, '<br/>'));
          });
          // // console.log(data.data);
          this.TaskAttive = data.data;
          // // console.log(this.TaskAttive);
          this.TaskAttive.forEach(element => {
            this.nTask++;
          });
          if (this.nTask > 200) {
            this.typeOfTaskView = 'plain';
          } else {
            this.typeOfTaskView = 'panel';
          }
          this.ColonneTask = data.columns;
        });
      });
    });
    this.breakpoint = (window.innerWidth <= 1080) ? 1 : 2;
    this.valueRowHeight = (window.innerWidth <= 1080) ? '1:1' : 'fit';
    this.ora = Date.now();
    this.apiService.getMasks().subscribe(data => {
      this.Maskdesk = data.masks;
      this.Viewdesk = data.views;
      this.Folderdesk = data.folders;
      this.Modelsdesk = data.models;
      this.Profiledesk = data.profiles;
      this.Quikdesk = data.quickSearches;
      if (this.Maskdesk.length === 0 && this.Viewdesk.length === 0 && this.Quikdesk.length === 0 &&
        this.Profiledesk.length === 0 && this.Modelsdesk.length === 0 && this.Folderdesk.length === 0) {
        this.noDesktopElements = true;
      } else {
        this.noDesktopElements = false;
      }
    });
    this.apiService.getViews().subscribe(datas => {
      this.views = datas;
    });
    if (this.apiService.homeReload) {
      this.apiService.homeReload = false;
    }
    // tslint:disable-next-line:max-line-length
    // @ts-ignore


  }

  countGroupItems(group: string): number {
    let num = 0;
    this.taskList.forEach(v => {
      if (v.defaultFields.get(this.groupBy) === group || (v.defaultFields.get(this.groupBy) === null && group === this.translate.instant('labels.noDocument'))) {
        num++;
      }
    });
    return num;
  }

  sortTask(field: string, isAdditional: boolean, order: string): void {
    let index;
    if (isAdditional) {
      if (this.taskList[0]) {
        if (this.taskList[0].additionalFields.length > 0) {
          this.taskList[0].additionalFields.forEach((v, ind) => {
            if (v.label === field) {
              index = ind;
            }
          });
          if (order === 'desc') {
            console.log(this.taskList);
            this.taskList = this.taskList.sort((a, b) => new Date(a.additionalFields[index].description)
            < new Date(b.additionalFields[index].description) ? 1 : new Date(a.additionalFields[index].description) > new Date(b.additionalFields[index].description) ? -1 : 0);
            this.orderDirection = 'desc';
          } else {
            this.taskList = this.taskList.sort((a, b) => new Date(a.additionalFields[index].description)
            < new Date(b.additionalFields[index].description) ? -1 : new Date(a.additionalFields[index].description) > new Date(b.additionalFields[index].description) ? 1 : 0);
            this.orderDirection = 'asc';
          }
        }
      }
    }


  }

  // tslint:disable-next-line:typedef
  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 1080) ? 1 : 2;
    this.valueRowHeight = (window.innerWidth <= 1080) ? '1:1' : 'fit';
  }

  // tslint:disable-next-line:typedef
  updateSearchModelMaschere(value) {
    this.searchModelMaschere = value;
    this.searchModelChange.emit(this.searchModelMaschere);
  }

  // tslint:disable-next-line:typedef
  clickMaschera(idMask: string) {
    this.router.navigate(['../maskDetails', {id: idMask, idTask: 0, path: 'defaulthome'}], {relativeTo: this.route});
  }


  clickTask(idt: string): void {
    this.router.navigate(['../dettaglitask', {id: idt, path: 'defaulthome'}], {relativeTo: this.route});
  }

  unreadTask(id: string): void {
    this.apiService.unread(id).subscribe(data => {
      this.snackBar.open(this.translate.instant('advices.setAsUnread'), null, {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-success-bg']
      });
    });
    this.refreshTasks();


  }

  convertStringToDate(data: string): Date {
    return new Date(data);

  }

  clickVista(idView: string): void {
    this.router.navigate(['../viewDetails', {id: idView, path: 'defaulthome'}], {relativeTo: this.route});
  }

  TransformDataFormat(Data: Date): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy, H:MM', '', 'en-GB');

  }

  TransformDataFormatToSee(Data: Date): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');

  }

  setPriority(id: string, priority: number): void {
    this.apiService.setPriority(id, priority).subscribe(data => {
      this.snackBar.open(this.translate.instant('advices.prioritySetCorrectly'), null, {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-success-bg']
      });
    });
    this.refreshTasks();
  }

  getColor(colorToConvert: number): string {
    const vbcolor = colorToConvert;
    const red = vbcolor % 256;
    const green = (vbcolor / 256) % 256;
    const blue = (vbcolor / 256 / 256) % 256;
    return '#' + this.componentToHex(Math.floor(red)) + this.componentToHex(Math.floor(green)) + this.componentToHex(Math.floor(blue));
  }

  componentToHex(c): any {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }
}

@Component({
  selector: 'app-filterdialog',
  templateUrl: 'filterDialog.html',
})
export class FilterDialogComponent {
  filterTags: Array<any> = new Array<any>();

  constructor(
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.filterTags = data.tags;
  }

  selectAll(): void {
    this.filterTags.forEach(tag => {
      tag.active = true;
    });
  }

  deselectAll(): void {
    this.filterTags.forEach(tag => {
      tag.active = false;
    });
  }

  saveDialog(): void {
    this.dialogRef.close({resultFilter: this.filterTags});
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

