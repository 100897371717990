<div class="center">
  <mat-card class="cardcomponentMasks" id="style-4" style="overflow-y: hidden">
    <!--<button style="float:left" title="Indietro" style="color: {{this.dashColor}}" (click)="goBack()" mat-icon-button
            aria-label="icon-button send">
        <mat-icon>keyboard_backspace</mat-icon>
    </button>-->
    <mat-form-field style="width: 50%; float: left">
      <mat-label>{{'labels.filter' | translate}}</mat-label>
      <input matInput [(ngModel)]="this.filter" (ngModelChange)="this.filterChange()">
      <button mat-button matSuffix mat-icon-button  style="color: {{this.dashColor}}"
              (click)="openFilterDialog()">
        <mat-icon>filter_list</mat-icon>
      </button>
      <button mat-button *ngIf="this.filter" style="color: {{this.dashColor}}" matSuffix mat-icon-button
              aria-label="{{'labels.cancel' | translate}}" (click)="this.filter=''; this.filterChange()">
        <mat-icon>close</mat-icon>
      </button>

    </mat-form-field>
    <button mat-stroked-button title="{{'labels.cancelSelection' | translate}}" *ngIf="numSelected > 0" (click)="this.cancelSelection()"
            style="float: left; margin-left: 2%; color: {{this.dashColor}}">
      Annulla selezione({{this.numSelected}})
    </button>
    <button mat-icon-button title="{{'labels.update' | translate}}" (click)="this.refreshView()" *ngIf="!this.idTask"
            style="float: right; color: {{this.dashColor}}">
      <mat-icon>autorenew</mat-icon>
    </button>
    <br>
    <hr class="riga" style="color: {{this.dashColor}}; width: 100%">
    <br>
    <mat-card-content style="height: 90%;">
      <ng-container *ngIf="noItems == false">
        <div class="example-container mat-elevation-z8 " id="style-4">

          <table mat-table [dataSource]="dataSource" style="text-align: start"
                 cdkDropList matSort
                 cdkDropListOrientation="horizontal"
                 (cdkDropListDropped)="drop($event)"
                 class="mat-elevation-z8" style="border-spacing: 0px !important;">

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->

            <ng-container *ngFor="let colonna of displayedColumns" matColumnDef="{{colonna}}">
              <ng-container>
                <ng-container
                  *ngIf="colonna != 'RELAZIONI,' + this.translate.instant('columns.relations') && colonna != '-1,' + this.translate.instant('columns.actions') && colonna != 'INOUT,' + this.translate.instant('columns.origin') && colonna != 'NOTE,' + this.translate.instant('columns.notes') && colonna != 'WORKFLOW,Workflow' && colonna != 'ALLEGATI,' + this.translate.instant('columns.attachments') && colonna != 'STATO,' + this.translate.instant('columns.state') && colonna != 'FORMATO,' + this.translate.instant('columns.format') && colonna != 'select' && colonna != this.translate.instant('labels.edit') && colonna != this.translate.instant('labels.preview')">
                  <ng-container *ngIf="colonna != 'selected'">
                    <th mat-header-cell style="background-color: white;" class="headers"
                        cdkDrag mat-sort-header
                        *matHeaderCellDef>{{(colonna.substr(colonna.indexOf(',') + 1, colonna.length))}}</th>
                  </ng-container>
                  <ng-container *ngIf="colonna == 'selected'">
                    <th mat-header-cell style="background-color: white; width: 0px; z-index: -100;"
                        cdkDrag mat-sort-header
                        *matHeaderCellDef>{{(colonna.substr(colonna.indexOf(',') + 1, colonna.length))}}</th>
                  </ng-container>
                </ng-container>
                <ng-container
                  *ngIf="colonna == 'RELAZIONI,' + this.translate.instant('columns.relations') || colonna == '-1,' + this.translate.instant('columns.actions') || colonna == 'INOUT,' + this.translate.instant('columns.origin') || colonna == 'NOTE,' + this.translate.instant('columns.notes') || colonna == 'WORKFLOW,Workflow' || colonna == 'ALLEGATI,' + this.translate.instant('columns.attachments') || colonna == 'STATO,' + this.translate.instant('columns.state') || colonna == 'FORMATO,' + this.translate.instant('columns.format') || colonna == this.translate.instant('labels.edit') || colonna == this.translate.instant('labels.preview')">
                  <th mat-header-cell class="headers"
                      style="background-color: white;"
                      cdkDrag mat-sort-header *matHeaderCellDef >
                    <ng-container
                      *ngIf="colonna != 'RELAZIONI,' + this.translate.instant('columns.relations') && colonna != 'INOUT,' + this.translate.instant('columns.origin') && colonna != 'NOTE,' + this.translate.instant('columns.notes') && colonna != 'WORKFLOW,Workflow' && colonna != 'ALLEGATI,' + this.translate.instant('columns.attachments') && colonna != 'FORMATO,' + this.translate.instant('columns.format') && colonna != 'STATO,' + this.translate.instant('columns.state') && colonna != 'select' && colonna != this.translate.instant('labels.edit') && colonna != this.translate.instant('labels.preview')">{{(colonna.substr(colonna.indexOf(',') + 1, colonna.length))}}</ng-container>
                    <ng-container *ngIf="colonna == 'FORMATO,' + this.translate.instant('columns.format')">
                      <mat-icon title="{{'labels.document' | translate}}">picture_as_pdf</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="colonna == 'STATO,' + this.translate.instant('columns.state')">
                      <mat-icon title="{{'labels.state' | translate}}">clear</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="colonna == 'ALLEGATI,' + this.translate.instant('columns.attachments')">
                      <mat-icon title="{{'labels.attachments' | translate}}">attachment</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="colonna == 'WORKFLOW,Workflow'">
                      <mat-icon title="Workflow">engineering</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="colonna == 'NOTE,' + this.translate.instant('columns.notes')">
                      <mat-icon title="Note">note</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="colonna == 'INOUT,' + this.translate.instant('columns.origin')">
                      <mat-icon title="{{'labels.origin' | translate}}">call_made</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="colonna == 'RELAZIONI,' + this.translate.instant('columns.relations')">
                      <mat-icon title="{{'labels.relationships' | translate}}">account_tree</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="colonna == this.translate.instant('labels.edit')">
                      {{'labels.edit' | translate}}
                    </ng-container>
                    <ng-container *ngIf="colonna == this.translate.instant('labels.preview')">
                      {{'labels.preview' | translate}}
                    </ng-container>
                  </th>
                </ng-container>
                <ng-container
                  *ngIf="colonna != 'REVISIONE,' + this.translate.instant('columns.version') && colonna != this.translate.instant('labels.preview') && colonna != this.translate.instant('labels.edit') && colonna != 'DOCNUMBER,System ID' && colonna != 'RELAZIONI,' + this.translate.instant('columns.relations') && colonna != '-1,' + this.translate.instant('columns.actions') && colonna != 'INOUT,' + this.translate.instant('columns.origin') && colonna != 'NOTE,' + this.translate.instant('columns.notes') && colonna != 'WORKFLOW,Workflow' && colonna != 'ALLEGATI,' + this.translate.instant('columns.attachments') && colonna != 'STATO,' + this.translate.instant('columns.state') && colonna != 'FORMATO,' + this.translate.instant('columns.format') && colonna != 'select'">
                  <ng-container *matCellDef="let element">
                    <td mat-cell
                        style="text-align: left; padding-right: 2px;"
                        (contextmenu)="open($event, element, element['DOCNUMBER,System ID']); $event. preventDefault();"
                        *ngIf="this.docId === element['DOCNUMBER,System ID']"
                        style="background-color: white !important;"
                        >
                      <nobr>
                        <!--<ng-container  #tooltip="matTooltip" matTooltip="{{element[colonna]}}"
                        [matTooltipPosition]="'above'" [matTooltipShowDelay]="500"
                        matTooltipHideDelay="100"
                                *ngIf="colonna.startsWith('DATA')">
                            {{element[colonna]}}
                        </ng-container>-->
                        <ng-container
                          *ngIf="colonna.startsWith(this.translate.instant('labels.preview')) == false && colonna.startsWith(this.translate.instant('labels.edit')) == false && colonna.startsWith('NOTE') == false && colonna.startsWith('ALLEGATI') == false && colonna.startsWith('RELAZIONI') == false && colonna.startsWith('STATO') == false && colonna.startsWith('-1') == false && colonna.startsWith('INOUT') == false && colonna.startsWith('REVISIONE') == false  && colonna.startsWith('DOCNUMBER') == false  && (colonna.substr(colonna.indexOf(',')+1, colonna.length)) != 'Workflow' && (colonna.substr(colonna.indexOf(',')+1, colonna.length)) != 'Formato'">
                          <ng-container
                            *ngIf="this.docId === element['DOCNUMBER,System ID'] && colonna.startsWith('REVISIONE') == false ">
                            <ng-container *ngFor="let field of this.fields">
                              <ng-container
                                *ngIf="(field.name.toLocaleLowerCase() === colonna.substr(0, colonna.indexOf(',')).toLocaleLowerCase()) || (field.name.toLocaleLowerCase() == 'protocollointerno' && colonna.substr(0, colonna.indexOf(',')).toLocaleLowerCase() == 'numero') ||
                                                                     (field.name.toLocaleLowerCase() == 'from' && colonna.substr(0, colonna.indexOf(',')).toLocaleLowerCase() == 'mittente') ||
                                                                      (field.name.toLocaleLowerCase() == 'to' && colonna.substr(0, colonna.indexOf(',')).toLocaleLowerCase() == 'destinatario')||
                                                                      (field.name.toLocaleLowerCase() == 'documenttype' && colonna.substr(0, colonna.indexOf(',')).toLocaleLowerCase() == 'classedoc')">
                                <ng-container [ngSwitch]="field.className"
                                              *ngIf="field.className != 'ProtocolDateFieldDTO'"
                                              style="height:fit-content; width: 100%;">
                                  <!-- NumberFieldDTO -->
                                  <mat-form-field *ngSwitchCase="'NumberFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                                  appearance="outline">
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           [id]="field.name"
                                           [required]="field.required"
                                           [disabled]="field.locked || this.edit == false"
                                           [value]="field.value || ''"
                                           maxlength="{{field.numMaxChar || 100}}"
                                           matInput placeholder="{{field.description}}">
                                  </mat-form-field>
                                  <!-- DocumentDateFieldDTO -->
                                  <mat-form-field *ngSwitchCase="'DocumentDateFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                                  appearance="outline">
                                    <mat-label>{{field.description}}</mat-label>
                                    <input style="color:#29648A;" matInput [matDatepicker]="picker" onkeydown="return false;"
                                           [value]="field.value || ''"
                                           [id]="field.name" [required]="field.required"
                                           [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           [disabled]="field.locked || this.edit == false">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker [disabled]="field.locked || this.edit == false" #picker
                                                    color="primary"></mat-datepicker>
                                  </mat-form-field>
                                  <!-- SubjectFieldDTO -->
                                  <mat-form-field *ngSwitchCase="'SubjectFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                                  appearance="outline">
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           [value]="field.value || ''"
                                           [id]="field.name" [required]="field.required"
                                           [disabled]="field.locked || this.edit == false"
                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                           placeholder="{{field.description}}">
                                  </mat-form-field>
                                  <!-- ToFieldDTO -->
                                  <mat-form-field *ngSwitchCase="'ToFieldDTO'"
                                                  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }" appearance="outline">
                                    <!--A-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <mat-chip-list #chipList aria-label="MultiValue" class="mat-chip-list-stacked">

                                      <mat-chip *ngFor="let element of this.mapAddressBookToSee.get(field.name)" [selectable]="false"
                                                [disabled]="field.locked || this.edit == false"
                                                [removable]="removable" (removed)="removeAddressBook(element, field.name)"
                                                [value]="element.id">
                                        {{element.name}}
                                        <mat-icon matChipRemove *ngIf="removable && !(field.locked)">cancel</mat-icon>
                                      </mat-chip>



                                    </mat-chip-list>
                                    <button matSuffix mat-icon-button (click)="openAddressBook(field)"
                                            style="color: {{this.apiService.dashColor}}" [disabled]="field.locked || this.edit == false">
                                      <mat-icon>notes</mat-icon>
                                    </button>
                                  </mat-form-field>

                                  <!-- FromFieldDTO -->
                                  <mat-form-field *ngSwitchCase="'FromFieldDTO'"
                                                  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }" appearance="outline">
                                    <!--DA-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <mat-chip-list #chipList aria-label="MultiValue" class="mat-chip-list-stacked">

                                          <mat-chip *ngFor="let element of this.mapAddressBookToSee.get(field.name)"
                                                    [selectable]="false" [disabled]="field.locked || this.edit == false"
                                                    [removable]="removable" (removed)="removeAddressBook(element, field.name)"
                                                    [value]="element.id">
                                            {{element.name}}
                                            <mat-icon matChipRemove *ngIf="removable && !(field.locked)">cancel</mat-icon>
                                          </mat-chip>





                                    </mat-chip-list>
                                    <button matSuffix mat-icon-button (click)="openAddressBook(field)"
                                            style="color: {{this.apiService.dashColor}}"
                                            [disabled]="field.locked || this.edit == false">
                                      <mat-icon>notes</mat-icon>
                                    </button>
                                  </mat-form-field>

                                  <!-- CcFieldDTO -->
                                  <mat-form-field *ngSwitchCase="'CcFieldDTO'"
                                                  [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}" appearance="outline">
                                    <!--A-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <mat-chip-list #chipList aria-label="MultiValue" class="mat-chip-list-stacked">

                                          <mat-chip *ngFor="let element of this.mapAddressBookToSee.get(field.name)" [selectable]="false"
                                                    [disabled]="field.locked || this.edit == false"
                                                    [removable]="removable" (removed)="removeAddressBook(element, field.name)"
                                                    [value]="element.id">
                                            {{element.name}}
                                            <mat-icon matChipRemove *ngIf="removable && !(field.locked)">cancel</mat-icon>
                                          </mat-chip>



                                    </mat-chip-list>
                                    <button matSuffix mat-icon-button (click)="openAddressBook(field)"
                                            style="color: {{this.apiService.dashColor}}" [disabled]="field.locked || this.edit == false">
                                      <mat-icon>notes</mat-icon>
                                    </button>
                                  </mat-form-field>

                                  <!-- BusinessUnitFieldDTO -->
                                  <mat-form-field *ngSwitchCase="'BusinessUnitFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                                  appearance="outline">
                                    <!--Aoo-->
                                    <mat-label>{{'labels.company' | translate}}</mat-label>
                                    <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                                                [required]="field.required"
                                                [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                [disabled]="field.locked || this.edit == false">
                                      <mat-option *ngFor="let aoo of nomiAoo" [value]="aoo.code">
                                        {{aoo.name}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>

                                  <!-- DocumentTypeFieldDTO -->
                                  <div *ngSwitchCase="'DocumentTypeFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }">


                                    <mat-form-field class="form-field-docType" style="width: 80%"
                                                    appearance="outline">
                                      <mat-label>{{field.description}}</mat-label>
                                      <mat-select [value]="field.value || ''" [id]="field.name"
                                                  [required]="field.required"
                                                  [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                  [disabled]="field.locked || this.edit == false"
                                      >
                                        <mat-option
                                          (onSelectionChange)="documentChanged($event, documenType.documentType,documenType.type2,documenType.type3)"
                                          *ngFor="let documenType of documenTypes"
                                          [value]="documenType.id">
                                          {{documenType.description}} - {{documenType.key}}
                                        </mat-option>
                                      </mat-select>

                                    </mat-form-field>
                                    <span>
                                    <button [disabled]="field.locked || this.edit == false" matSuffix mat-icon-button
                                            style="color: {{this.dashColor}};"
                                            (click)="opendocumentalClassDialog()">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                         </span>
                                  </div>
                                  <!-- OriginFieldDTO -->
                                  <mat-form-field *ngSwitchCase="'OriginFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                                  appearance="outline">
                                    <!--Origine-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                                                [required]="field.required"
                                                [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                [disabled]="field.locked || this.edit == false">
                                      <mat-option *ngFor="let originField of originsFields"
                                                  [value]="originField.value">
                                        {{originField.description}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>

                                  <!-- StateFieldDTO -->
                                  <mat-form-field *ngSwitchCase="'StateFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                                  appearance="outline">
                                    <!--Stato, dipende da documento-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                                                [required]="field.required"
                                                [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                [disabled]="field.locked || this.edit == false">
                                      <mat-option *ngFor="let stateField of stateFields" [value]="stateField.id">
                                        {{stateField.description}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>

                                  <!-- BinderFieldDTO -->
                                  <mat-form-field *ngSwitchCase="'BinderFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                                  appearance="outline">
                                    <!--Pratiche-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [value]="field.value || ''"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           [id]="field.name" [required]="field.required"
                                           [disabled]="field.locked || this.edit == false"
                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                           placeholder="{{field.description}}">
                                  </mat-form-field>

                                  <!-- DocumentDateExpiredFieldDTO -->
                                  <mat-form-field *ngSwitchCase="'DocumentDateExpiredFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                                  appearance="outline">
                                    <!--Scadenza-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <input style="color:#29648A;" [value]="field.value || ''" matInput
                                           [matDatepicker]="picker" onkeydown="return false;"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           [id]="field.name" [required]="field.required"
                                           [disabled]="field.locked || this.edit == false" (click)="picker.open()">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker [disabled]="field.locked || this.edit == false" #picker
                                                    color="primary"></mat-datepicker>

                                  </mat-form-field>

                                  <!-- ImportantFieldDTO -->
                                  <mat-form-field *ngSwitchCase="'ImportantFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                                  appearance="outline">
                                    <!--Campo "Importante"-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [value]="field.value || ''"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           [id]="field.name" [required]="field.required"
                                           [disabled]="field.locked || this.edit == false"
                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                           placeholder="{{field.description}}">
                                  </mat-form-field>

                                  <!-- SendersFieldDTO -->
                                  <mat-form-field *ngSwitchCase="'SendersFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                                  appearance="outline">
                                    <!--Altri da-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [value]="field.value || ''"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           [id]="field.name" [required]="field.required"
                                           [disabled]="field.locked || this.edit == false"
                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                           placeholder="{{field.description}}">
                                  </mat-form-field>

                                  <!-- OriginalFieldDTO -->
                                  <mat-form-field *ngSwitchCase="'OriginalFieldDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                                  appearance="outline">
                                    <!--Originale-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [value]="field.value || ''"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           [id]="field.name" [required]="field.required"
                                           [disabled]="field.locked || this.edit == false"
                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                           placeholder="{{field.description}}">
                                  </mat-form-field>

                                  <!-- AdditionalFieldGroupDTO -->
                                  <div class="form-field-left" *ngSwitchCase="'AdditionalFieldGroupDTO'">
                                    <h3
                                      appearance="outline">
                                      <!--Campo aggiuntivo "Generale"-->
                                      <br>
                                      <h1 style="color: {{this.dashColor}}">{{field.description}}<br>
                                        <hr>
                                      </h1>

                                    </h3>
                                  </div>
                                  <!-- AdditionalFieldStringDTO -->
                                  <ng-container *ngSwitchCase="'AdditionalFieldStringDTO'">
                                    <ng-container *ngIf="field.description == 'Link' && this.edit == false" >
                                      <a   [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                           target="_blank" href="{{dataMaskToSave[field.name]}}">{{dataMaskToSave[field.name]}}</a>
                                    </ng-container>
                                    <ng-container *ngIf="field.description != 'Link' || this.edit == true">
                                      <mat-form-field   [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                                        appearance="outline">
                                        <!--Tipo documento-->
                                        <mat-label>{{field.description}}</mat-label>
                                        <ng-container *ngIf="field.numMaxChar > 400">
                                      <textarea ngModel [value]="field.value || ''"
                                                [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                [id]="field.name" [required]="field.required"
                                                [disabled]="field.locked || this.edit == false"
                                                maxlength="{{field.numMaxChar || 100}}" matInput rows="{{field.numRows}}" autosize
                                                style="resize: none;width:95%;"
                                                placeholder="{{field.description}}"></textarea>
                                        </ng-container>
                                        <ng-container *ngIf="field.numMaxChar <= 400">
                                          <input ngModel [value]="field.value || ''"
                                                 [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                 [id]="field.name" [required]="field.required"
                                                 [disabled]="field.locked || this.edit == false"
                                                 maxlength="{{field.numMaxChar || 100}}" matInput
                                                 placeholder="{{field.description}}">
                                        </ng-container>
                                      </mat-form-field>
                                    </ng-container>
                                  </ng-container>



                                  <!-- AdditionalFieldTableDTO -->
                                  <mat-form-field (click)="openTableDTO(field)"
                                                  *ngSwitchCase="'AdditionalFieldTableDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                                  appearance="outline">
                                    <!--TableDTO-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [value]="field.value || ''"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           [id]="field.name" [required]="field.required"
                                           [disabled]="field.locked || this.edit == false"
                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                           placeholder="{{field.description}}">
                                    <button mat-button matSuffix mat-icon-button style="color: {{this.dashColor}};">
                                      <mat-icon>table_chart</mat-icon>
                                    </button>
                                  </mat-form-field>

                                  <!-- AdditionalFieldMultivalueDTO -->
                                  <mat-form-field *ngSwitchCase="'AdditionalFieldMultivalueDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                  >
                                    <!--MultiValueDTO-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <mat-chip-list #chipList aria-label="MultiValue" [required]="field.required">
                                      <div style="width: 100%; display: flex;">
                                        <div style="width: 80%">
                                          <mat-chip *ngFor="let element of this.mapMultiValueToSee.get(field.name)" [selectable]="false"
                                                    [disabled]="field.locked || this.edit == false"
                                                    [removable]="removable" (removed)="remove(element, field.name)">
                                            {{element}}
                                            <mat-icon matChipRemove *ngIf="removable && !(field.locked || this.edit == false)">cancel
                                            </mat-icon>
                                          </mat-chip>
                                        </div>
                                        <div style="width: 20%; text-align: right; height: 60px;">
                                          <button matSuffix mat-icon-button (click)="openMultiValueDTO(field)"
                                                  style="color: {{this.apiService.dashColor}}"
                                                  [disabled]="field.locked || this.edit == false">
                                            <mat-icon>notes</mat-icon>
                                          </button>
                                        </div>
                                      </div>
                                    </mat-chip-list>
                                  </mat-form-field>
                                  <div *ngSwitchCase="'InstructionFieldDTO'"  [ngClass]="{
                                         'form-field': field.columns !== 1,
                                         'form-field-expanded': field.columns === 1
                                      }" appearance="outline" style = "text-align: left; color: {{this.apiService.squareColor}}" >
                                    <br><p [innerHTML] = "field.description"></p>
                                  </div>
                                  <mat-form-field *ngSwitchCase="'AdditionalFieldDoubleDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                                  appearance="outline">
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [value]="field.value || ''"
                                           [id]="field.name" type="text" [required]="field.required"
                                           [disabled]="field.locked || this.edit == false"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                           placeholder="{{field.description}}">
                                  </mat-form-field>
                                  <ng-container *ngSwitchCase="'AdditionalFieldBooleanDTO'">

                                    <div style="text-align: left !important; margin-bottom: 2%; margin-top: 1%;"
                                         [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                         appearance="outline">
                                      <mat-slide-toggle [id]="field.name" style="color: {{this.apiService.dashColor}}" color="warn"
                                                        [required]="field.required" (toggleChange)="this.checkFormula()"
                                                        [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}" [disabled]="field.locked || this.edit == false"
                                      >{{field.description}}</mat-slide-toggle>
                                      <!--MultiValueDTO-->
                                    </div>
                                  </ng-container>
                                  <!-- AdditionalFieldComboDTO -->
                                  <ng-container *ngSwitchCase="'AdditionalFieldComboDTO'">
                                    <ng-container *ngIf="field.limitToList">
                                      <mat-form-field
                                        [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                        appearance="outline">
                                        <mat-label>{{field.description}}</mat-label>
                                        <mat-select [id]="field.name" [value]="field.value || ''"
                                                    [required]="field.required" (selectionChange)="this.checkFormula()"
                                                    (selectionChange)="this.checkComboAssociations(field)"
                                                    [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                    [disabled]="field.locked || this.edit == false">
                                          <mat-option>--{{'labels.cancel' | translate}}--</mat-option>
                                          <mat-option *ngFor="let value of this.comboValues[field.name]"
                                                      [value]="value.keyField">
                                            {{value.selectField}}
                                          </mat-option>

                                        </mat-select>

                                      </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngIf="!field.limitToList">

                                      <mat-form-field style="width: 45%"
                                                      [ngClass]="{
                                                         'form-field': field.columns !== 1,
                                                         'form-field-expanded': field.columns === 1
                                                      }"
                                                      appearance="outline">
                                        <mat-label>{{field.description}}</mat-label>
                                        <input ngModel [value]="field.value || ''"
                                               [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                               [id]="field.name" [required]="field.required"
                                               [disabled]="field.locked || this.edit == false" [ngModelOptions]="{standalone: true}"
                                               maxlength="{{field.numMaxChar || 100}}" matInput
                                               placeholder="{{field.description}}" [matAutocomplete]="auto">
                                        <mat-autocomplete #auto="matAutocomplete">
                                          <mat-option *ngFor="let value of this.comboValues[field.name]"
                                                      [value]="value.keyField">
                                            {{value.selectField}}
                                          </mat-option>
                                        </mat-autocomplete>
                                      </mat-form-field>
                                    </ng-container>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="'AdditionalFieldDateTimeDTO'">
                                    <mat-form-field *ngIf="field.value == '0001-01-01T00:00:00'" [ngClass]="{
                                             'form-field': field.columns !== 1,
                                             'form-field-expanded': field.columns === 1
                                          }"
                                                    appearance="outline"
                                    >
                                      <!--AdditionalFieldDateTimeDTO-->
                                      <mat-label>{{field.description}}</mat-label>
                                      <input style="color:#29648A;" [value]="field.value || ''" matInput
                                             [matDatepicker]="picker" onkeydown="return false;"
                                             [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                             [id]="field.name" [required]="field.required" (dateChange)="this.checkInputFormula()"
                                             [disabled]="field.locked || this.edit == false" (click)="picker.open()">
                                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                      <mat-datepicker disabled="{{field.locked || this.edit == false}}" #picker color="primary"></mat-datepicker>

                                    </mat-form-field>
                                    <mat-form-field *ngIf="field.value != '0001-01-01T00:00:00'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                                    appearance="outline"
                                    >
                                      <!--AdditionalFieldDateTimeDTO-->
                                      <mat-label>{{field.description}}</mat-label>
                                      <input style="color:#29648A;" [value]="'' || ''" matInput
                                             [matDatepicker]="picker" onkeydown="return false;"
                                             [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                             [id]="field.name" [required]="field.required" (dateChange)="this.checkInputFormula()"
                                             [disabled]="field.locked || this.edit == false" (click)="picker.open()">
                                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                      <mat-datepicker disabled="{{field.locked || this.edit == false}}" #picker color="primary"></mat-datepicker>

                                    </mat-form-field>
                                  </ng-container>
                                  <!--AdditionalFieldIntDTO-->
                                  <mat-form-field *ngSwitchCase="'AdditionalFieldIntDTO'"  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                                  appearance="outline">
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [value]="field.value || ''"
                                           [id]="field.name" type="number" [required]="field.required"
                                           [disabled]="field.locked || this.edit == false"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                           placeholder="{{field.description}}">
                                  </mat-form-field>

                                  <!-- AdditionalFieldClasseDTO -->

                                  <div class="classBox" *ngSwitchCase="'AdditionalFieldClasseDTO'">

                                    <div>
                                      <h4 style="float:left; margin-left: 2.5%; color: {{this.dashColor}};">{{field.description}} </h4>
                                      <button *ngIf="mapClasseBookToSave.get(field.name).length > 0"
                                              style="float:right; color:{{this.dashColor}};"
                                              title="{{'labels.seeMasks' | translate}}"
                                              (click)="openClassMasks(field)" mat-icon-button
                                              aria-label="icon-button send">
                                        <mat-icon [matBadge]="mapClasseBookToSave.get(field.name).length">
                                          preview
                                        </mat-icon>
                                      </button>
                                      <button style="float:right; color:{{this.dashColor}};"
                                              title="{{'labels.insertMask' | translate}}"
                                              (click)="insertMaskFromClassDTO(field)" mat-icon-button
                                              aria-label="icon-button send">
                                        <mat-icon>post_add</mat-icon>
                                      </button>
                                      <!--<button style="float:right; color:{{this.dashColor}};"
                                              title="Cerca maschera"
                                              (click)="searchMaskFromClassDTO(field)" mat-icon-button
                                              aria-label="icon-button send">
                                        <mat-icon>search</mat-icon>
                                      </button>-->
                                    </div>

                                    <!--div class="z8 example-container">
                                      <table mat-table [dataSource]="dataSourceClass">
                                        <ng-container *ngFor="let disCol of displayedColumnsClass; let colIndex = index"
                                                      matColumnDef="{{disCol}}">
                                          <ng-container>
                                            <th mat-header-cell *matHeaderCellDef
                                                style="font-weight: 600; font-size:14px; color: {{this.dashColor}};">{{disCol}}</th>
                                            <td mat-cell *matCellDef="let element">{{element[disCol]}}
                                            </td>
                                          </ng-container>
                                          <mat-divider [vertical]="true"></mat-divider>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsClass"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsClass;"></tr>
                                      </table>

                                    </div-->
                                  </div>
                                  <mat-form-field *ngSwitchDefault  [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }" appearance="outline">
                                    <mat-label>{{field.description}}</mat-label>
                                    <input matInput placeholder="{{field.description}}">
                                  </mat-form-field>
                                </ng-container>
                              </ng-container>
                            </ng-container>

                          </ng-container>
                          <ng-container
                            *ngIf="this.docId !== element['DOCNUMBER,System ID']">
                            <ng-container *ngIf = "checkIfDate(element[colonna])">
                              {{this.TransformDataFormat(element[colonna])}}
                            </ng-container>
                            <ng-container *ngIf = "!checkIfDate(element[colonna])">
                              {{element[colonna]}}
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </nobr>
                    </td>
                    <td mat-cell
                        style="text-align: left; padding-right: 2px;"
                        (contextmenu)="open($event, element, element['DOCNUMBER,System ID']); $event. preventDefault();"
                         *ngIf="this.docId !== element['DOCNUMBER,System ID']"
                        >
                      <nobr>
                        <!--<ng-container #tooltip="matTooltip" matTooltip="{{element[colonna]}}"
                        [matTooltipPosition]="'above'" [matTooltipShowDelay]="500"
                        matTooltipHideDelay="100"
                                *ngIf="colonna.startsWith('DATA')">
                            {{element[colonna]}}
                        </ng-container>-->
                        <ng-container
                          *ngIf="colonna.startsWith(this.translate.instant('labels.preview')) == false && colonna.startsWith(this.translate.instant('labels.edit')) == false && colonna.startsWith('NOTE') == false && colonna.startsWith('ALLEGATI') == false && colonna.startsWith('RELAZIONI') == false && colonna.startsWith('REVISIONE') == false && colonna.startsWith('STATO') == false && colonna.startsWith('-1') == false && colonna.startsWith('INOUT') == false && colonna.startsWith('DOCNUMBER') == false  && (colonna.substr(colonna.indexOf(',')+1, colonna.length)) != 'Workflow' && (colonna.substr(colonna.indexOf(',')+1, colonna.length)) != 'Formato'">
                          <ng-container
                            *ngIf="this.docId !== element['DOCNUMBER,System ID']">
                            <ng-container *ngIf = "checkIfDate(element[colonna])">
                              {{this.TransformDataFormat(element[colonna])}}
                            </ng-container>
                            <ng-container *ngIf = "!checkIfDate(element[colonna])">
                              {{element[colonna]}}
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </nobr>
                    </td>
                  </ng-container>
                </ng-container>

                <!--  COMANDI -->
                <ng-container
                  *ngIf="(colonna == 'REVISIONE,' + this.translate.instant('columns.version') || colonna == 'DOCNUMBER,System ID' || colonna == this.translate.instant('labels.edit')   || colonna == this.translate.instant('labels.preview')  || colonna == 'RELAZIONI,' + this.translate.instant('columns.relations') || colonna == '-1,' + this.translate.instant('columns.actions') || colonna == 'INOUT,' + this.translate.instant('columns.origin') || colonna == 'NOTE,' + this.translate.instant('columns.notes') || colonna == 'WORKFLOW,Workflow' || colonna == 'ALLEGATI,' + this.translate.instant('columns.attachments') || colonna == 'STATO,' + this.translate.instant('columns.state') || colonna == 'FORMATO,' + this.translate.instant('columns.format'))">
                  <ng-container *matCellDef="let element">
                    <td mat-cell style="text-align: left"
                        *ngIf="this.docId !== element['DOCNUMBER,System ID']"
                        (contextmenu)="open($event, element, element['DOCNUMBER,System ID']); $event. preventDefault();">

                      <ng-container [ngSwitch]="colonna">
                        <ng-container
                          *ngSwitchCase="'STATO,' + this.translate.instant('columns.state')">
                          <button mat-icon-button>
                            <mat-icon title="{{element[colonna]}}">
                              {{this.apiService.getState(element[colonna])}}
                            </mat-icon>
                          </button>
                        </ng-container>
                        <ng-container
                          *ngSwitchCase="'INOUT,' + this.translate.instant('columns.origin')">
                          <button mat-icon-button>
                            <ng-container *ngIf="element[colonna] == '0'">
                              <mat-icon title="{{'labels.exit' | translate}}">call_made</mat-icon>
                            </ng-container>
                            <ng-container *ngIf="element[colonna] == '1'">
                              <mat-icon title="{{'labels.enter' | translate}}">call_received</mat-icon>
                            </ng-container>
                            <ng-container *ngIf="element[colonna] == '2'">
                              <mat-icon title="{{'labels.internal' | translate}}">swap_horizontal</mat-icon>
                            </ng-container>
                          </button>
                        </ng-container>
                        <ng-container
                          *ngSwitchCase="'ALLEGATI,' + this.translate.instant('columns.attachments')">
                          <ng-container *ngIf="element[colonna] != '0'">
                            <button mat-icon-button style="color: {{this.squareColor}};"
                                    (click)="openShowAttachmentsDialog(element['DOCNUMBER,System ID'])">
                              <mat-icon>attachment</mat-icon>
                            </button>
                          </ng-container>
                          <ng-container *ngIf="element[colonna] == '0'">
                            <button mat-icon-button
                                    (click)="openShowAttachmentsDialog(element['DOCNUMBER,System ID'])">
                              <mat-icon>attachment</mat-icon>
                            </button>
                          </ng-container>
                        </ng-container>
                        <ng-container
                          *ngSwitchCase="this.translate.instant('labels.edit')">
                          <ng-container *ngIf="this.docId === element['DOCNUMBER,System ID']">
                            <div style="display: flex">
                              <button mat-icon-button style="color: {{this.squareColor}};"
                                      (click)="saveMask()"
                                      (click)="dontSelectWhenClicked(element['DOCNUMBER,System ID'])"
                                      title="{{'labels.save' | translate}}"
                              >
                                <mat-icon>save</mat-icon>
                              </button>
                              <button mat-icon-button style="color: {{this.squareColor}};"
                                      (click)="endEditProfile()"
                                      (click)="dontSelectWhenClicked(element['DOCNUMBER,System ID'])"
                              >
                                <mat-icon>close</mat-icon>
                              </button>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="this.docId !== element['DOCNUMBER,System ID']">
                            <button mat-icon-button style="color: {{this.squareColor}};"
                                    (click)="editProfile(element['DOCNUMBER,System ID'])"
                                    (click)="dontSelectWhenClicked(element['DOCNUMBER,System ID'])"
                            >
                              <mat-icon>edit</mat-icon>
                            </button>
                          </ng-container>

                        </ng-container>
                        <ng-container
                          *ngSwitchCase="'REVISIONE,' + this.translate.instant('columns.version')">
                          <button mat-button title="{{'labels.openRevisions' | translate}}" [disabled]="element[colonna] == 0"
                                  (click)="openRevisions(element['DOCNUMBER,System ID'])"
                          >
                            <span>{{element[colonna]}}</span>
                          </button>
                        </ng-container>
                        <ng-container
                          *ngSwitchCase="this.translate.instant('labels.preview')">
                          <button mat-icon-button style="color: {{this.squareColor}};"
                                  (click)="openPreview(element['DOCNUMBER,System ID'], element['REVISIONE,' + this.translate.instant('columns.version')])"
                                  title="{{'labels.previewDocument' | translate}}"
                          >
                            <mat-icon>preview</mat-icon>
                          </button>


                        </ng-container>
                        <ng-container
                          *ngSwitchCase="'WORKFLOW,Workflow'">
                          <ng-container *ngIf="(element[colonna]) == 1">
                            <button mat-icon-button
                                    (click)="goToProcess(element['DOCNUMBER,System ID'])">
                              <mat-icon title="{{'labels.inProgress' | translate}}">engineering</mat-icon>
                            </button>
                            <br>
                          </ng-container>
                          <ng-container *ngIf="(element[colonna]) == 2">
                            <button mat-icon-button
                                    (click)="goToProcess(element['DOCNUMBER,System ID'])">
                              <mat-icon title="{{'labels.concluded' | translate}}">more_horiz</mat-icon>
                            </button>
                            <br>
                          </ng-container>
                          <ng-container *ngIf="(element[colonna]) == 0">
                            <button mat-icon-button
                                    (click)="goToProcess(element['DOCNUMBER,System ID'])">
                              <b>Concluso</b>
                              <mat-icon title="{{'labels.waiting' | translate}}">done_all</mat-icon>
                            </button>
                            <br>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'FORMATO,' + this.translate.instant('columns.format')">
                          <ng-container
                            *ngIf="(element[colonna] != '')">
                            <button mat-icon-button
                                    (click)="downloadDoc(element['DOCNUMBER,System ID'])"
                                    (click)="dontSelectWhenClicked(element['DOCNUMBER,System ID'])">
                              <ng-container
                                *ngIf="element[colonna]=='pdf'">
                                <mat-icon title="{{element[colonna]}}"
                                          style="color: darkred;">
                                  picture_as_pdf
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna] =='PDF'">
                                <mat-icon title="{{element[colonna]}}"
                                          style="color: darkred;">
                                  picture_as_pdf
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna] =='Pdf'">
                                <mat-icon title="{{element[colonna]}}"
                                          style="color: darkred;">
                                  picture_as_pdf
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='docx'">
                                <mat-icon title="{{element[colonna]}}"
                                          style="color: darkblue;">
                                  description
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='doc'">
                                <mat-icon title="{{element[colonna]}}"
                                          style="color: darkblue;">
                                  article
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='xlsx'">
                                <mat-icon title="{{element[colonna]}}"
                                          style="color: darkgreen;">
                                  ballot
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='csv'">
                                <mat-icon title="{{element[colonna]}}"
                                          style="color: darkgreen;">
                                  dashboard
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='eml'">
                                <mat-icon title="{{element[colonna]}}"
                                          style="color: black;">
                                  email
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='msg'">
                                <mat-icon title="{{element[colonna]}}">email</mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='zip'">
                                <mat-icon title="{{element[colonna]}}">topic</mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='jpg'">
                                <mat-icon title="{{element[colonna]}}">insert_photo
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='p7m'">
                                <mat-icon title="{{element[colonna]}}">mode</mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='xml'">
                                <mat-icon title="{{element[colonna]}}">code</mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='png' || element[colonna]=='PNG'">
                                <mat-icon title="{{element[colonna]}}">insert_photo
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]!='eml' && element[colonna]!='PNG' && element[colonna]!='png' && element[colonna]!='xml' && element[colonna]!='p7m' && element[colonna]!='Pdf' && element[colonna]!='PDF' && element[colonna]!='zip' && element[colonna]!='jpg' && element[colonna]!='msg' && element[colonna]!='docx' && element[colonna]!='pdf' && element[colonna]!='doc' && element[colonna]!='xlsx' && element[colonna]!='csv'">
                                {{element[colonna]}}
                              </ng-container>
                            </button>
                          </ng-container>
                        </ng-container>
                        <ng-container
                          *ngSwitchCase="'NOTE,' + this.translate.instant('columns.notes')">
                          <ng-container *ngIf="element[colonna] != null">
                            <button mat-icon-button style="color: {{this.squareColor}};"
                                    (click)="openProfileNotesDialog(element['DOCNUMBER,System ID'])">
                              <mat-icon>note</mat-icon>
                            </button>
                          </ng-container>
                          <ng-container *ngIf="element[colonna] == null">
                            <button mat-icon-button
                                    (click)="openProfileNotesDialog(element['DOCNUMBER,System ID'])">
                              <mat-icon>note</mat-icon>
                            </button>
                          </ng-container>
                        </ng-container>

                        <ng-container
                          *ngSwitchCase="'RELAZIONI,' + this.translate.instant('columns.relations')">
                          <button *ngIf="element[colonna] == '1'" mat-icon-button
                                  (click)="dontSelectWhenClicked(element['DOCNUMBER,System ID'])"
                                  (click)="openRelationsDialog(element['DOCNUMBER,System ID'])">
                            <mat-icon title="{{'labels.relationships' | translate}}">account_tree</mat-icon>
                          </button>
                        </ng-container>
                        <ng-container
                          *ngSwitchCase="'DOCNUMBER,System ID'">
                          <button mat-button title="{{'labels.goProfile' | translate}}"
                                  (click)="goToProfile(element['DOCNUMBER,System ID'])"
                                  (click)="dontSelectWhenClicked(element['DOCNUMBER,System ID'])">
                            <span>{{element[colonna]}}</span>
                          </button>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <ng-container *ngIf = "checkIfDate(element[colonna])">
                            {{this.TransformDataFormat(element[colonna])}}
                          </ng-container>
                          <ng-container *ngIf = "!checkIfDate(element[colonna])">
                            {{element[colonna]}}
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </td>
                    <td mat-cell style="text-align: left"
                        style="background-color: white !important"
                        *ngIf="this.docId === element['DOCNUMBER,System ID']"
                        (contextmenu)="open($event, element, element['DOCNUMBER,System ID']); $event. preventDefault();">

                      <ng-container [ngSwitch]="colonna">
                        <ng-container
                          *ngSwitchCase="'STATO,' + this.translate.instant('columns.state')">
                          <button mat-icon-button>
                            <mat-icon title="{{element[colonna]}}">
                              {{this.apiService.getState(element[colonna])}}
                            </mat-icon>
                          </button>
                        </ng-container>
                        <ng-container
                          *ngSwitchCase="'INOUT,' + this.translate.instant('columns.origin')">
                          <button mat-icon-button>
                            <ng-container *ngIf="element[colonna] == '0'">
                              <mat-icon title="{{'labels.exit' | translate}}">call_made</mat-icon>
                            </ng-container>
                            <ng-container *ngIf="element[colonna] == '1'">
                              <mat-icon title="{{'labels.enter' | translate}}">call_received</mat-icon>
                            </ng-container>
                            <ng-container *ngIf="element[colonna] == '2'">
                              <mat-icon title="{{'labels.internal' | translate}}">swap_horizontal</mat-icon>
                            </ng-container>
                          </button>
                        </ng-container>
                        <ng-container
                          *ngSwitchCase="'ALLEGATI,' + this.translate.instant('columns.attachments')">
                          <ng-container *ngIf="element[colonna] != '0'">
                            <button mat-icon-button style="color: {{this.squareColor}};"
                                    (click)="openShowAttachmentsDialog(element['DOCNUMBER,System ID'])">
                              <mat-icon>attachment</mat-icon>
                            </button>
                          </ng-container>
                          <ng-container *ngIf="element[colonna] == '0'">
                            <button mat-icon-button
                                    (click)="openShowAttachmentsDialog(element['DOCNUMBER,System ID'])">
                              <mat-icon>attachment</mat-icon>
                            </button>
                          </ng-container>
                        </ng-container>
                        <ng-container
                          *ngSwitchCase="this.translate.instant('labels.edit')">
                          <ng-container *ngIf="this.docId === element['DOCNUMBER,System ID']">
                            <div style="display: flex">
                              <button mat-icon-button style="color: {{this.squareColor}};"
                                      (click)="saveMask()"
                                      (click)="dontSelectWhenClicked(element['DOCNUMBER,System ID'])"
                                      title="{{'labels.save' | translate}}"
                              >
                                <mat-icon>save</mat-icon>
                              </button>
                              <button mat-icon-button style="color: {{this.squareColor}};"
                                      (click)="endEditProfile()"
                                      (click)="dontSelectWhenClicked(element['DOCNUMBER,System ID'])"
                              >
                                <mat-icon>close</mat-icon>
                              </button>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="this.docId !== element['DOCNUMBER,System ID']">
                            <button mat-icon-button style="color: {{this.squareColor}};"
                                    (click)="editProfile(element['DOCNUMBER,System ID'])"
                                    (click)="dontSelectWhenClicked(element['DOCNUMBER,System ID'])"
                            >
                              <mat-icon>edit</mat-icon>
                            </button>
                          </ng-container>

                        </ng-container>
                        <ng-container
                          *ngSwitchCase="this.translate.instant('labels.preview')">
                          <button mat-icon-button style="color: {{this.squareColor}};"
                                  (click)="openPreview(element['DOCNUMBER,System ID'], element['REVISIONE,' + this.translate.instant('columns.version')])"
                                  title="{{'labels.previewDocument' | translate}}"
                          >
                            <mat-icon>preview</mat-icon>
                          </button>


                        </ng-container>
                        <ng-container
                          *ngSwitchCase="'WORKFLOW,Workflow'">
                          <ng-container *ngIf="(element[colonna]) == 1">
                            <button mat-icon-button
                                    (click)="goToProcess(element['DOCNUMBER,System ID'])">
                              <mat-icon title="{{'labels.inProgress' | translate}}">engineering</mat-icon>
                            </button>
                            <br>
                          </ng-container>
                          <ng-container *ngIf="(element[colonna]) == 2">
                            <button mat-icon-button
                                    (click)="goToProcess(element['DOCNUMBER,System ID'])">
                              <mat-icon title="{{'labels.concluded' | translate}}">more_horiz</mat-icon>
                            </button>
                            <br>
                          </ng-container>
                          <ng-container *ngIf="(element[colonna]) == 0">
                            <button mat-icon-button
                                    (click)="goToProcess(element['DOCNUMBER,System ID'])">
                              <b>Concluso</b>
                              <mat-icon title="{{'labels.waiting' | translate}}">done_all</mat-icon>
                            </button>
                            <br>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'FORMATO,' + this.translate.instant('columns.format')">
                          <ng-container
                            *ngIf="(element[colonna] != '')">
                            <button mat-icon-button
                                    (click)="downloadDoc(element['DOCNUMBER,System ID'])"
                                    (click)="dontSelectWhenClicked(element['DOCNUMBER,System ID'])">
                              <ng-container
                                *ngIf="element[colonna]=='pdf'">
                                <mat-icon title="{{element[colonna]}}"
                                          style="color: darkred;">
                                  picture_as_pdf
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna] =='PDF'">
                                <mat-icon title="{{element[colonna]}}"
                                          style="color: darkred;">
                                  picture_as_pdf
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna] =='Pdf'">
                                <mat-icon title="{{element[colonna]}}"
                                          style="color: darkred;">
                                  picture_as_pdf
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='docx'">
                                <mat-icon title="{{element[colonna]}}"
                                          style="color: darkblue;">
                                  description
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='doc'">
                                <mat-icon title="{{element[colonna]}}"
                                          style="color: darkblue;">
                                  article
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='xlsx'">
                                <mat-icon title="{{element[colonna]}}"
                                          style="color: darkgreen;">
                                  ballot
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='csv'">
                                <mat-icon title="{{element[colonna]}}"
                                          style="color: darkgreen;">
                                  dashboard
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='eml'">
                                <mat-icon title="{{element[colonna]}}"
                                          style="color: black;">
                                  email
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='msg'">
                                <mat-icon title="{{element[colonna]}}">email</mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='zip'">
                                <mat-icon title="{{element[colonna]}}">topic</mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='jpg'">
                                <mat-icon title="{{element[colonna]}}">insert_photo
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='p7m'">
                                <mat-icon title="{{element[colonna]}}">mode</mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='xml'">
                                <mat-icon title="{{element[colonna]}}">code</mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]=='png' || element[colonna]=='PNG'">
                                <mat-icon title="{{element[colonna]}}">insert_photo
                                </mat-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="element[colonna]!='eml' && element[colonna]!='PNG' && element[colonna]!='png' && element[colonna]!='xml' && element[colonna]!='p7m' && element[colonna]!='Pdf' && element[colonna]!='PDF' && element[colonna]!='zip' && element[colonna]!='jpg' && element[colonna]!='msg' && element[colonna]!='docx' && element[colonna]!='pdf' && element[colonna]!='doc' && element[colonna]!='xlsx' && element[colonna]!='csv'">
                                {{element[colonna]}}
                              </ng-container>
                            </button>
                          </ng-container>
                        </ng-container>
                        <ng-container
                          *ngSwitchCase="'NOTE,' + this.translate.instant('columns.notes')">
                          <ng-container *ngIf="element[colonna] != null">
                            <button mat-icon-button style="color: {{this.squareColor}};"
                                    (click)="openProfileNotesDialog(element['DOCNUMBER,System ID'])">
                              <mat-icon>note</mat-icon>
                            </button>
                          </ng-container>
                          <ng-container *ngIf="element[colonna] == null">
                            <button mat-icon-button
                                    (click)="openProfileNotesDialog(element['DOCNUMBER,System ID'])">
                              <mat-icon>note</mat-icon>
                            </button>
                          </ng-container>
                        </ng-container>

                        <ng-container
                          *ngSwitchCase="'RELAZIONI,' + this.translate.instant('columns.relations')">
                          <button *ngIf="element[colonna] == '1'" mat-icon-button
                                  (click)="dontSelectWhenClicked(element['DOCNUMBER,System ID'])"
                                  (click)="openRelationsDialog(element['DOCNUMBER,System ID'])">
                            <mat-icon title="{{'labels.relationships' | translate}}">account_tree</mat-icon>
                          </button>
                        </ng-container>
                        <ng-container
                          *ngSwitchCase="'REVISIONE,' + this.translate.instant('columns.version')">
                          <button mat-button title="{{'labels.openRevisions' | translate}}"
                                  (click)="openRevisions(element['DOCNUMBER,System ID'])"
                          >
                            <span>{{element[colonna]}}</span>
                          </button>
                        </ng-container>
                        <ng-container
                          *ngSwitchCase="'DOCNUMBER,System ID'">
                          <button mat-button title="{{'labels.goProfile' | translate}}"
                                  (click)="goToProfile(element['DOCNUMBER,System ID'])"
                                  (click)="dontSelectWhenClicked(element['DOCNUMBER,System ID'])">
                            <span>{{element[colonna]}}</span>
                          </button>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <ng-container *ngIf = "checkIfDate(element[colonna])">
                            {{this.TransformDataFormat(element[colonna])}}
                          </ng-container>
                          <ng-container *ngIf = "!checkIfDate(element[colonna])">
                            {{element[colonna]}}
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </td>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"
                style="height: 45px;"></tr>
            <ng-container *matRowDef="let row; columns: displayedColumns;">
              <tr mat-row [ngClass]="getClass(row.selected)"
                  style="height: 25px !important; max-height: 25px !important;"
                  (click)="clickedRow($event, row, row['DOCNUMBER,System ID'])"
                  (dblclick)="DBclickedRow($event, row, row['DOCNUMBER,System ID'])"></tr>
            </ng-container>

          </table>
          <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]="[25, 50, 75, 100, 150, 200]"
                         showFirstLastButtons style="float: right"></mat-paginator>
        </div>
      </ng-container>
      <ng-container *ngIf="noItems == true">
        <h1 style="color: {{this.dashColor}}">{{'labels.noResult' | translate }}</h1>
      </ng-container>
    </mat-card-content>

  </mat-card>

</div>
<ng-template #userMenu let-user>
  <section class="user-menu">
    <div style="text-align: center; display: contents;">
      <h3 style="margin-bottom: 0px;">
        <b style="color: black" *ngIf="this.numSelected == 0">{{'labels.profile' | translate}}: {{this.selectedDocN}}</b>
      </h3>
      <h4 style="color: {{this.dashColor}};" *ngIf="this.numSelected > 0">N.
        {{'labels.selections' | translate}}: {{this.numSelected}}</h4>
    </div>
    <hr>
    <ng-container *ngIf="this.numSelected < 2">
      <ng-container *ngFor="let command of this.commands.details">
        <ng-container [ngSwitch]="command.elementType">
          <!-- Apri -->
          <button mat-menu-item *ngSwitchCase="-1"
                  (click)="addToTask(this.selectedDocN)">
            <mat-icon>add</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Apri -->
          <button mat-menu-item *ngSwitchCase="1"
                  (click)="downloadDoc(this.selectedDocN)">
            <mat-icon>launch</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Elimina documento -->
          <!-- TODO ELIMINA DOCUMENTO  -->
          <button mat-menu-item (click) = "this.deleteDocument(this.selectedDocN)" *ngSwitchCase="5">
            <mat-icon>clear</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Processo di workflow singolo -->
          <button mat-menu-item *ngSwitchCase="81"
                  (click)="this.startEvent(command.elementId, this.selectedDocN)">
            <mat-icon>developer_board</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Task attivo -->
          <!-- TODO Task attivo  -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="46">
            <mat-icon>settings_suggest</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Mostra processi per il documento -->
          <!-- TODO Mostra processi per il documento  -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="77">
            <mat-icon>visibility</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Avvia nuovo processo di workflow -->
          <!-- TODO Avvia nuovo processo di workflow  -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="76">
            <mat-icon>playlist_add_check</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Cartella -->
          <ng-container>
            <button mat-menu-item
                    *ngSwitchCase="70" (click)="enableFolder(command.id)">
              <mat-icon>folder_open</mat-icon>
              {{command.label}}
              <ng-container *ngFor="let folder of this.folders">
                <ng-container *ngIf="folder.id == command.id && folder.enabled == true">
                  <mat-icon style="float: right">keyboard_arrow_down</mat-icon>
                </ng-container>
                <ng-container *ngIf="folder.id == command.id && folder.enabled == false">
                  <mat-icon style="float: right">keyboard_arrow_right</mat-icon>
                </ng-container>
              </ng-container>
            </button>
            <ng-container *ngFor="let folder of this.folders">
              <ng-container *ngIf="folder.id == command.id && folder.enabled == true">
                <ng-container *ngFor="let child of command.childs">
                  <ng-container [ngSwitch]="child.elementType">
                    <!-- Processo di workflow singolo -->
                    <button mat-menu-item *ngSwitchCase="81" style="margin-left: 10px;"
                            (click)="this.startEvent(child.elementId, this.selectedDocN)">
                      <mat-icon>developer_board</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Task attivo -->
                    <!-- TODO TASK ATTIVO -->
                    <button mat-menu-item *ngSwitchCase="46" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>settings_suggest</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Mostra processi per il documento -->
                    <!-- TODO Mostra processi per il documento -->
                    <button mat-menu-item *ngSwitchCase="77" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>visibility</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Avvia nuovo processo di workflow -->
                    <!-- TODO Avvia nuovo processo di workflow -->
                    <button mat-menu-item *ngSwitchCase="76" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>playlist_add_check</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Apri -->
                    <button mat-menu-item *ngSwitchCase="1" style="margin-left: 10px;"
                            (click)="downloadDoc(this.selectedDocN)">
                      <mat-icon>launch</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Elimina documento -->
                    <!-- TODO Elimina documento -->
                    <button mat-menu-item *ngSwitchCase="5" (click) = "this.deleteDocument(this.selectedDocN)"
                            style="margin-left: 10px;">
                      <mat-icon>clear</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Modifica documento -->
                    <button mat-menu-item [matMenuTriggerFor]="modifica" *ngSwitchCase="11"
                            style="margin-left: 10px;"
                            style="color: black">
                      <mat-icon>create</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <mat-menu #modifica="matMenu">
                      <button mat-menu-item
                              (click)="openEditFileDialog(this.selectedDocN)"
                              style="color: black">
                        <mat-icon>attach_file</mat-icon>
                      <span>{{'labels.fromFile' | translate }}</span>
                      </button>
                      <button mat-menu-item
                              (click)="openEditRevisionDialog(this.selectedDocN)">
                        <mat-icon>skip_previous</mat-icon>
                        <span>{{'labels.toRevised' | translate}}</span>
                      </button>
                    </mat-menu>
                    <!-- Profilo -->
                    <button mat-menu-item *ngSwitchCase="2" style="margin-left: 10px;"
                            (click)="goToProfile(this.selectedDocN)">
                      <mat-icon>subject</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Duplica profilo -->
                    <!-- TODO Duplica profilo -->
                    <button mat-menu-item *ngSwitchCase="3" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>content_copy</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Esporta CSV -->
                    <button mat-menu-item *ngSwitchCase="82" style="margin-left: 10px;"
                            (click)="exportToCSV(this.selectedDocN)">
                      <mat-icon>call_made</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Elenco allegati -->
                    <button mat-menu-item *ngSwitchCase="7" style="margin-left: 10px;"
                            (click)="openShowAttachmentsDialog(this.selectedDocN)">
                      <mat-icon>attach_file</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Elenco note -->
                    <button mat-menu-item *ngSwitchCase="6" style="margin-left: 10px;"
                            (click)="openProfileNotesDialog(this.selectedDocN)">
                      <mat-icon>note</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Elenco revisioni -->
                    <!-- TODO Elenco revisioni -->
                    <button mat-menu-item *ngSwitchCase="10"
                            style="margin-left: 10px;" (click)="openRevisions(this.selectedDocN)">
                      <mat-icon>rate_review</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Separatore -->
                    <hr *ngSwitchCase="71" style="margin-left: 10px;">
                    <!-- CheckOut -->
                    <!-- TODO CheckOut -->
                    <button mat-menu-item *ngSwitchCase="12" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>first_page</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- CheckIn -->
                    <!-- TODO CheckIn -->
                    <button mat-menu-item *ngSwitchCase="13" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>input</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Fascicoli che contengono il documento -->
                    <!-- TODO Fascicoli che contengono il documento -->
                    <button mat-menu-item *ngSwitchCase="16" (click) = "this.containFolder(this.selectedDocN)"
                            style="margin-left: 10px;">
                      <mat-icon>pageview</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Associazioni che contengono il documento -->
                    <!-- TODO Associazioni che contengono il documento -->
                    <button mat-menu-item *ngSwitchCase="18" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>swap_horiz</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Mostra relazioni -->
                    <button mat-menu-item *ngSwitchCase="21" style="margin-left: 10px;"
                            (click)="openRelationsDialog(this.selectedDocN)">
                      <mat-icon>account_tree</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Inserisci in fascicolo -->
                    <!-- TODO Inserisci in fascicolo -->
                    <button mat-menu-item *ngSwitchCase="15" (click) = "this.addToFolder(this.selectedDocN)"
                            style="margin-left: 10px;">
                      <mat-icon>create_new_folder</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Inserisci in associazione -->
                    <!-- TODO Inserisci in associazione -->
                    <button mat-menu-item *ngSwitchCase="17" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>insert_link</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Nuova relazione manuale -->
                    <!-- TODO Nuova relazione manuale -->
                    <button mat-menu-item *ngSwitchCase="19" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>mediation</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          <!-- Modifica documento -->
          <button mat-menu-item [matMenuTriggerFor]="modifica" *ngSwitchCase="11"
                  style="color: black">
            <mat-icon>create</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <mat-menu #modifica="matMenu">
            <button mat-menu-item
                    (click)="openEditFileDialog(this.selectedDocN)"
                    style="color: black">
              <mat-icon>attach_file</mat-icon>
              <span>{{'labels.fromFile' | translate}}</span>
            </button>
            <button mat-menu-item
                    (click)="openEditRevisionDialog(this.selectedDocN)">
              <mat-icon>skip_previous</mat-icon>
              <span>{{'labels.toRevised' | translate}}</span>
            </button>
          </mat-menu>
          <!-- Profilo -->
          <button mat-menu-item *ngSwitchCase="2"
                  (click)="goToProfile(this.selectedDocN)">
            <mat-icon>subject</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Duplica profilo -->
          <!-- TODO Duplica profilo -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="3">
            <mat-icon>content_copy</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Esporta CSV -->
          <button mat-menu-item *ngSwitchCase="82"
                  (click)="exportToCSV(this.selectedDocN)">
            <mat-icon>call_made</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Elenco allegati -->
          <button mat-menu-item *ngSwitchCase="7"
                  (click)="openShowAttachmentsDialog(this.selectedDocN)">
            <mat-icon>attach_file</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Elenco note -->
          <button mat-menu-item *ngSwitchCase="6"
                  (click)="openProfileNotesDialog(this.selectedDocN)">
            <mat-icon>note</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Elenco revisioni -->
          <!-- TODO Elenco revisioni -->
          <button mat-menu-item (click)="openRevisions(this.selectedDocN)" *ngSwitchCase="10">
            <mat-icon>rate_review</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Separatore -->
          <hr *ngSwitchCase="71">
          <!-- CheckOut -->
          <!-- TODO CheckOut -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="12">
            <mat-icon>first_page</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- CheckIn -->
          <!-- TODO CheckIn -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="13">
            <mat-icon>input</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Fascicoli che contengono il documento -->
          <!-- TODO Fascicoli che contengono il documento -->
          <button mat-menu-item (click) = "this.containFolder(this.selectedDocN)" *ngSwitchCase="16">
            <mat-icon>pageview</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Associazioni che contengono il documento -->
          <!-- TODO Associazioni che contengono il documento -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="18">
            <mat-icon>swap_horiz</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Mostra relazioni -->
          <button mat-menu-item *ngSwitchCase="21"
                  (click)="openRelationsDialog(this.selectedDocN)">
            <mat-icon>account_tree</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Inserisci in fascicolo -->
          <!-- TODO Inserisci in fascicolo -->
          <button mat-menu-item (click) = "this.addToFolder(this.selectedDocN)" *ngSwitchCase="15">
            <mat-icon>create_new_folder</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Inserisci in associazione -->
          <!-- TODO Inserisci in associazione -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="17">
            <mat-icon>insert_link</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Nuova relazione manuale -->
          <!-- TODO Nuova relazione manuale -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="19">
            <mat-icon>mediation</mat-icon>
            <span>{{command.label}}</span>
          </button>
        </ng-container>
      </ng-container>
      <!-- TODO Copia -->
      <!--<button mat-menu-item [disabled]="true">
        <mat-icon>content_copy</mat-icon>
        <span>Copia</span>
      </button>-->
      <ng-container *ngIf="this.apiService.IsDocuSignLogged">
        <hr>
        <button mat-menu-item
                (click)="signDoc(this.selectedDocN)">
          <mat-icon>send</mat-icon>
          <span>{{'labels.sendDocusign' | translate}}</span>
        </button>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="this.numSelected > 1">
      <ng-container *ngFor="let command of this.commands.details">
        <ng-container [ngSwitch]="command.elementType">
          <!-- Apri -->
          <button mat-menu-item *ngSwitchCase="1"
                  (click)="downloadDoc(this.selectedDocN)">
            <mat-icon>launch</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Elimina documento -->
          <!-- TODO Elimina documento -->
          <button mat-menu-item (click) = "this.deleteDocument(this.selectedDocN)" *ngSwitchCase="5">
            <mat-icon>clear</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Processo di workflow singolo -->
          <button mat-menu-item *ngSwitchCase="81"
                  (click)="this.startEvent(command.elementId, this.selectedDocN)">
            <mat-icon>developer_board</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Avvia nuovo processo di workflow -->
          <!-- TODO Avvia nuovo processo di workflow -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="76">
            <mat-icon>playlist_add_check</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Cartella -->
          <ng-container>
            <button mat-menu-item
                    *ngSwitchCase="70" (click)="enableFolder(command.id)">
              <mat-icon>folder_open</mat-icon>
              {{command.label}}
              <ng-container *ngFor="let folder of this.folders">
                <ng-container *ngIf="folder.id == command.id && folder.enabled == true">
                  <mat-icon style="float: right">keyboard_arrow_down</mat-icon>
                </ng-container>
                <ng-container *ngIf="folder.id == command.id && folder.enabled == false">
                  <mat-icon style="float: right">keyboard_arrow_right</mat-icon>
                </ng-container>
              </ng-container>
            </button>
            <ng-container *ngFor="let folder of this.folders">
              <ng-container *ngIf="folder.id == command.id && folder.enabled == true">
                <ng-container *ngFor="let child of command.childs">
                  <ng-container [ngSwitch]="child.elementType">
                    <!-- Processo di workflow singolo -->
                    <button mat-menu-item *ngSwitchCase="81" style="margin-left: 10px;"
                            (click)="this.startEvent(child.elementId, this.selectedDocN)">
                      <mat-icon>developer_board</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Avvia nuovo processo di workflow -->
                    <!-- TODO Avvia nuovo processo di workflow -->
                    <button mat-menu-item [disabled]="true" *ngSwitchCase="76"
                            style="margin-left: 10px;">
                      <mat-icon>playlist_add_check</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Apri -->
                    <button mat-menu-item *ngSwitchCase="1" style="margin-left: 10px;"
                            (click)="downloadDoc(this.selectedDocN)">
                      <mat-icon>launch</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Elimina documento -->
                    <!-- TODO Elimina documento -->
                    <button mat-menu-item *ngSwitchCase="5" (click) = "this.deleteDocument(this.selectedDocN)"
                            style="margin-left: 10px;">
                      <mat-icon>clear</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Esporta CSV -->
                    <button mat-menu-item *ngSwitchCase="82" style="margin-left: 10px;"
                            (click)="exportToCSV(this.selectedDocN)">
                      <mat-icon>call_made</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Separatore -->
                    <hr *ngSwitchCase="71" style="margin-left: 10px;">
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>

          <!-- Esporta CSV -->
          <button mat-menu-item *ngSwitchCase="82"
                  (click)="exportToCSV(this.selectedDocN)">
            <mat-icon>call_made</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Separatore -->
          <hr *ngSwitchCase="71">
        </ng-container>
      </ng-container>
    </ng-container>

  </section>
</ng-template>
