<div>
    <button style="float: right;" title="{{'labels.close' | translate}}" (click)="closeDialog()" mat-icon-button color="warn" aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div style="text-align: center"><h2>{{'labels.attachments' | translate}}</h2>
    <ng-container *ngIf="this.attachments.length == 0">
      {{'labels.noAttachmentsFound' | translate}}
    </ng-container>
    <ng-container *ngIf="this.attachments.length != 0">
        {{this.attachments.length}} {{'labels.attachmentsFound' | translate}}:
        <div *ngFor="let attack of this.attachments">
            <mat-list-item>
                {{attack.originalname}}
            </mat-list-item>
        </div>
    </ng-container>
    <br>
    <hr>
    <br>
    <button mat-stroked-button>{{'buttons.send' | translate}}</button>
</div>
