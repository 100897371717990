import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DatePipe} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from './api.service';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {MatBottomSheet} from '@angular/material/bottom-sheet';

// @ts-ignore
@Injectable({
  providedIn: 'root'
})
export class TasksJSON {
  defaultTakBody: JSON = {
    select: {
      fields: [
        {
          sqlSelectField: 'DM_TASKWORK.ID',
          toCalculate: false,
          index: 5,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'ID',
          name: 'ID',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.SENDERS AS DM_PROFILE_SENDERS',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Altri Da',
          name: 'DM_PROFILE_SENDERS',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.ID_PROCESSO',
          toCalculate: false,
          index: 7,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Processo',
          name: 'ID_PROCESSO',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.DATAPRESAINCARICO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data presa in carico',
          name: 'DATAPRESAINCARICO',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.NOTE',
          toCalculate: false,
          index: 14,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Note',
          name: 'NOTE',
          userSelectionEnabled: true,
          userSelectionGroup: 0
        },
        {
          sqlSelectField: 'DM_TASKWORK.ALLEGATI',
          toCalculate: false,
          index: 15,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Allegati',
          name: 'ALLEGATI',
          userSelectionEnabled: true,
          userSelectionGroup: 0
        },
        {
          sqlSelectField: 'DM_TASKWORK.NUMDOC AS DOCNUMBER',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'System ID',
          name: 'DOCNUMBER',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.REVISIONE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Revisione',
          name: 'REVISIONE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.DATAINIZIO',
          toCalculate: false,
          index: 16,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data inizio task',
          name: 'DATAINIZIO',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_TASKWORK_NOMETASK}',
          toCalculate: false,
          index: 18,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome task',
          name: 'NOMETASK',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_TASKWORK_DESCTASK}',
          toCalculate: false,
          index: 19,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Descrizione task',
          name: 'DESCTASK',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.UTENTE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'UTENTE',
          name: 'UTENTE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.CONCLUSO',
          toCalculate: false,
          index: 11,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'CONCLUSO',
          name: 'CONCLUSO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.STATO',
          toCalculate: false,
          index: 2,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Stato',
          name: 'STATO',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.DATA_SCADENZA',
          toCalculate: false,
          index: 17,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data scadenza task',
          name: 'DATA_SCADENZA',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.ABILITAEXPORT',
          toCalculate: false,
          index: 10,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'ABILITAEXPORT',
          name: 'ABILITAEXPORT',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.ID_NODO',
          toCalculate: false,
          index: 9,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'ID_NODO',
          name: 'ID_NODO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.ISTRUZIONI',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'ISTRUZIONI',
          name: 'ISTRUZIONI',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.SELECTUSER',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'SELECTUSER',
          name: 'SELECTUSER',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.INPUTNEWDOC',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'INPUTNEWDOC',
          name: 'INPUTNEWDOC',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.INPUT_TYPE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'INPUT_TYPE',
          name: 'INPUT_TYPE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.EDITPROFILO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'EDITPROFILO',
          name: 'EDITPROFILO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.INSERTFOLDER',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'INSERTFOLDER',
          name: 'INSERTFOLDER',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.ASSOCIA',
          toCalculate: false,
          index: 1,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Priorità',
          name: 'ASSOCIA',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.MODULO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'MODULO',
          name: 'MODULO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.TIPOTASK',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TIPOTASK',
          name: 'TIPOTASK',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.MODESEC',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'MODESEC',
          name: 'MODESEC',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.NOTIFICAMAIL',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'NOTIFICAMAIL',
          name: 'NOTIFICAMAIL',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.AVANZATASK',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'AVANZATASK',
          name: 'AVANZATASK',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.PSWREQUIRED',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'PSWREQUIRED',
          name: 'PSWREQUIRED',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.NEWMEMO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'NEWMEMO',
          name: 'NEWMEMO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.LETTO',
          toCalculate: false,
          index: 12,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Letto',
          name: 'LETTO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.COMASINCRONO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'COMASINCRONO',
          name: 'COMASINCRONO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.COPIAEDIT',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'COPIAEDIT',
          name: 'COPIAEDIT',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'CASE WHEN DM_TASKWORK.OBBCOM IS NULL THEN 0 ELSE CASE WHEN DM_TASKWORK.OBBCOM = 1 THEN 1 ELSE CASE WHEN DM_TASKWORK.DATAPRESAINCARICO IS NOT NULL THEN 2 ELSE 0 END END\tEND AS OBBCOM',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Presa in carico',
          name: 'OBBCOM',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.SCADTASK',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'SCADTASK',
          name: 'SCADTASK',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.ATTIVATASK',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'ATTIVATASK',
          name: 'ATTIVATASK',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_TASKWORK_DESCFIGURE}',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DESCFIGURE',
          name: 'DESCFIGURE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_TASKWORK_DESCVARIABILI}',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DESCVARIABILI',
          name: 'DESCVARIABILI',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.ID_ORG',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'ID_ORG',
          name: 'ID_ORG',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.ID_DELEGA',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'ID_DELEGA',
          name: 'ID_DELEGA',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.TASKAPERTO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TASKAPERTO',
          name: 'TASKAPERTO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.ESITO',
          toCalculate: false,
          index: 3,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Esito',
          name: 'ESITO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.TASKDIGRUPPO',
          toCalculate: false,
          index: 4,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TASKDIGRUPPO',
          name: 'TASKDIGRUPPO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.TIPO1',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TIPO1',
          name: 'TIPO1',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.TIPO2',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TIPO2',
          name: 'TIPO2',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.TIPO3',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TIPO3',
          name: 'TIPO3',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.SELECTDOC',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'SELECTDOC',
          name: 'SELECTDOC',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_WORKFLOW_DETTAGLIO}',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_WORKFLOW_DETTAGLIO',
          name: 'DM_WORKFLOW_DETTAGLIO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_WORKFLOW.NOME AS DM_WORKFLOW_NOME',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome workflow',
          name: 'DM_WORKFLOW_NOME',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_WORKFLOW.FILEFLW AS DM_WORKFLOW_FILEFLW',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_WORKFLOW_FILEFLW',
          name: 'DM_WORKFLOW_FILEFLW',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_WORKFLOW.PERCORSO AS DM_WORKFLOW_PERCORSO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_WORKFLOW_PERCORSO',
          name: 'DM_WORKFLOW_PERCORSO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_WORKFLOW_DESCRIZIONE}',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_WORKFLOW_DESCRIZIONE',
          name: 'DM_WORKFLOW_DESCRIZIONE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_WORKFLOW.STATO AS DM_WORKFLOW_STATO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_WORKFLOW_STATO',
          name: 'DM_WORKFLOW_STATO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_WORKFLOW.ORGANIGRAMMA AS DM_WORKFLOW_ORGANIGRAMMA',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_WORKFLOW_ORGANIGRAMMA',
          name: 'DM_WORKFLOW_ORGANIGRAMMA',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_WORKFLOW.AOO AS DM_WORKFLOW_AOO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_WORKFLOW_AOO',
          name: 'DM_WORKFLOW_AOO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_WORKFLOW.COLORE AS DM_WORKFLOW_COLORE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_WORKFLOW_COLORE',
          name: 'DM_WORKFLOW_COLORE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_WORKFLOW.DOCNUMBER AS DM_WORKFLOW_DOCNUMBER',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_WORKFLOW_DOCNUMBER',
          name: 'DM_WORKFLOW_DOCNUMBER',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROCESSI.NOTE AS DM_PROCESSI_NOTE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROCESSI_NOTE',
          name: 'DM_PROCESSI_NOTE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROCESSI.ALLEGATI AS DM_PROCESSI_ALLEGATI',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROCESSI_ALLEGATI',
          name: 'DM_PROCESSI_ALLEGATI',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROCESSI.STATO AS DM_PROCESSI_STATO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROCESSI_STATO',
          name: 'DM_PROCESSI_STATO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROCESSI.WORKFLOW AS DM_PROCESSI_WORKFLOW',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROCESSI_WORKFLOW',
          name: 'DM_PROCESSI_WORKFLOW',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROCESSI.INIZIO AS DM_PROCESSI_INIZIO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROCESSI_INIZIO',
          name: 'DM_PROCESSI_INIZIO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROCESSI.CONCLUSO AS DM_PROCESSI_CONCLUSO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROCESSI_CONCLUSO',
          name: 'DM_PROCESSI_CONCLUSO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROCESSI.DATA_SCADENZA AS DM_PROCESSI_DATA_SCADENZA',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROCESSI_DATA_SCADENZA',
          name: 'DM_PROCESSI_DATA_SCADENZA',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROCESSI.COLORE AS DM_PROCESSI_COLORE',
          toCalculate: false,
          index: 0,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Colore workflow',
          name: 'DM_PROCESSI_COLORE',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_PROCESSI_DESCRIZIONE}',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Descrizione processo',
          name: 'DM_PROCESSI_DESCRIZIONE',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_TASKDOC_DESCRIZIONE}',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKDOC_DESCRIZIONE',
          name: 'DM_TASKDOC_DESCRIZIONE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKDOC.INPUTNEWDOC AS DM_TASKDOC_INPUTNEWDOC',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKDOC_INPUTNEWDOC',
          name: 'DM_TASKDOC_INPUTNEWDOC',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKDOC.DOCOBBLIGATORIO AS DM_TASKDOC_DOCOBBLIGATORIO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKDOC_DOCOBBLIGATORIO',
          name: 'DM_TASKDOC_DOCOBBLIGATORIO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKDOC.TIPO1 AS DM_TASKDOC_TIPO1',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKDOC_TIPO1',
          name: 'DM_TASKDOC_TIPO1',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKDOC.TIPO2 AS DM_TASKDOC_TIPO2',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKDOC_TIPO2',
          name: 'DM_TASKDOC_TIPO2',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKDOC.TIPO3 AS DM_TASKDOC_TIPO3',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKDOC_TIPO3',
          name: 'DM_TASKDOC_TIPO3',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKDOC.OP_ESEGUITA AS DM_TASKDOC_OP_ESEGUITA',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKDOC_OP_ESEGUITA',
          name: 'DM_TASKDOC_OP_ESEGUITA',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKDOC.INPUT_TYPE AS DM_TASKDOC_INPUT_TYPE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKDOC_INPUT_TYPE',
          name: 'DM_TASKDOC_INPUT_TYPE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.DOCNUMBER AS DM_PROFILE_DOCNUMBER',
          toCalculate: false,
          index: 21,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'System ID',
          name: 'DM_PROFILE_DOCNUMBER',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.DOCNAME AS DM_PROFILE_DOCNAME',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Oggetto',
          name: 'DM_PROFILE_DOCNAME',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.DOCUMENTTYPE AS DM_PROFILE_DOCUMENTTYPE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_DOCUMENTTYPE',
          name: 'DM_PROFILE_DOCUMENTTYPE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.TIPO2 AS DM_PROFILE_TIPO2',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_TIPO2',
          name: 'DM_PROFILE_TIPO2',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.TIPO3 AS DM_PROFILE_TIPO3',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_TIPO3',
          name: 'DM_PROFILE_TIPO3',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.PATH AS DM_PROFILE_PATH',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_PATH',
          name: 'DM_PROFILE_PATH',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.FILENAME AS DM_PROFILE_FILENAME',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_FILENAME',
          name: 'DM_PROFILE_FILENAME',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERO AS DM_PROFILE_NUMERO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Numero',
          name: 'DM_PROFILE_NUMERO',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.DATADOC AS DM_PROFILE_DATADOC',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data documento',
          name: 'DM_PROFILE_DATADOC',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.CC AS DM_PROFILE_CC',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'CC',
          name: 'DM_PROFILE_CC',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.PROFILATO AS DM_PROFILE_PROFILATO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_PROFILATO',
          name: 'DM_PROFILE_PROFILATO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.CREATION_DATE AS DM_PROFILE_CREATION_DATE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data registrazione',
          name: 'DM_PROFILE_CREATION_DATE',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.IMPORTANTE AS DM_PROFILE_IMPORTANTE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Importante',
          name: 'DM_PROFILE_IMPORTANTE',
          userSelectionEnabled: true,
          userSelectionGroup: 0
        },
        {
          sqlSelectField: 'DM_PROFILE.REVISIONE AS DM_PROFILE_REVISIONE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Revisione',
          name: 'DM_PROFILE_REVISIONE',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.AUTORE AS DM_PROFILE_AUTORE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Autore',
          name: 'DM_PROFILE_AUTORE',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.NOTE AS DM_PROFILE_NOTE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_NOTE',
          name: 'DM_PROFILE_NOTE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.PROTOCOLLO AS DM_PROFILE_PROTOCOLLO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Protocollo Pa',
          name: 'DM_PROFILE_PROTOCOLLO',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.ANNO AS DM_PROFILE_ANNO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Anno Protocollo Pa',
          name: 'DM_PROFILE_ANNO',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.BLOCCATO AS DM_PROFILE_BLOCCATO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_BLOCCATO',
          name: 'DM_PROFILE_BLOCCATO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.STATO AS DM_PROFILE_STATO',
          toCalculate: false,
          index: 13,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Stato',
          name: 'DM_PROFILE_STATO',
          userSelectionEnabled: true,
          userSelectionGroup: 0
        },
        {
          sqlSelectField: 'DM_PROFILE.INOUT AS DM_PROFILE_INOUT',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_INOUT',
          name: 'DM_PROFILE_INOUT',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.SCADENZA AS DM_PROFILE_SCADENZA',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Scadenza',
          name: 'DM_PROFILE_SCADENZA',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.FLAG AS DM_PROFILE_FLAG',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_FLAG',
          name: 'DM_PROFILE_FLAG',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.WORKFLOW AS DM_PROFILE_WORKFLOW',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_WORKFLOW',
          name: 'DM_PROFILE_WORKFLOW',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.ORIGINALE AS DM_PROFILE_ORIGINALE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome file',
          name: 'DM_PROFILE_ORIGINALE',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.DATAFILE AS DM_PROFILE_DATAFILE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_DATAFILE',
          name: 'DM_PROFILE_DATAFILE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.GESTREV AS DM_PROFILE_GESTREV',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_GESTREV',
          name: 'DM_PROFILE_GESTREV',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.DEVICE AS DM_PROFILE_DEVICE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_DEVICE',
          name: 'DM_PROFILE_DEVICE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.ALLEGATI AS DM_PROFILE_ALLEGATI',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_ALLEGATI',
          name: 'DM_PROFILE_ALLEGATI',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.OPENDOC AS DM_PROFILE_OPENDOC',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_OPENDOC',
          name: 'DM_PROFILE_OPENDOC',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.ASSOCIAZIONI AS DM_PROFILE_ASSOCIAZIONI',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_ASSOCIAZIONI',
          name: 'DM_PROFILE_ASSOCIAZIONI',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.IMPRONTA AS DM_PROFILE_IMPRONTA',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_IMPRONTA',
          name: 'DM_PROFILE_IMPRONTA',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.EMERGENZA AS DM_PROFILE_EMERGENZA',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_EMERGENZA',
          name: 'DM_PROFILE_EMERGENZA',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.AOO AS DM_PROFILE_AOO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Aoo',
          name: 'DM_PROFILE_AOO',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.WEBVISIBLE AS DM_PROFILE_WEBVISIBLE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_WEBVISIBLE',
          name: 'DM_PROFILE_WEBVISIBLE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.ISAOS AS DM_PROFILE_ISAOS',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_ISAOS',
          name: 'DM_PROFILE_ISAOS',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.INVOICE AS DM_PROFILE_INVOICE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_INVOICE',
          name: 'DM_PROFILE_INVOICE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.SCADAOS AS DM_PROFILE_SCADAOS',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_SCADAOS',
          name: 'DM_PROFILE_SCADAOS',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.ETICHETTACD AS DM_PROFILE_ETICHETTACD',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_ETICHETTACD',
          name: 'DM_PROFILE_ETICHETTACD',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.ETI_READER AS DM_PROFILE_ETI_READER',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_ETI_READER',
          name: 'DM_PROFILE_ETI_READER',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.DATAPROT AS DM_PROFILE_DATAPROT',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_DATAPROT',
          name: 'DM_PROFILE_DATAPROT',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_PROFILE_PRATICHE}',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_PRATICHE',
          name: 'DM_PROFILE_PRATICHE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_PROFILE_STATOPROCDESC}',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Stato esteso',
          name: 'DM_PROFILE_STATOPROCDESC',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.MITTENTE AS DM_PROFILE_MITTENTE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Mittente',
          name: 'DM_PROFILE_MITTENTE',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.DESTINATARIO AS DM_PROFILE_DESTINATARIO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Destinatario',
          name: 'DM_PROFILE_DESTINATARIO',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_FASCICOLI.NOME AS DM_FASCICOLI_NOME',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome fascicolo',
          name: 'DM_FASCICOLI_NOME',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKEXECUTE.IDUtente AS DM_TASKEXECUTE_IDUTENTE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKEXECUTE_IDUTENTE',
          name: 'DM_TASKEXECUTE_IDUTENTE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKEXECUTE.AOO AS DM_TASKEXECUTE_AOO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKEXECUTE_AOO',
          name: 'DM_TASKEXECUTE_AOO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKEXECUTE.UtenteCompleto AS DM_TASKEXECUTE_UTENTECOMPLETO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKEXECUTE_UTENTECOMPLETO',
          name: 'DM_TASKEXECUTE_UTENTECOMPLETO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKEXECUTE.Gruppo_Utente AS DM_TASKEXECUTE_GRUPPOUTENTE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKEXECUTE_GRUPPOUTENTE',
          name: 'DM_TASKEXECUTE_GRUPPOUTENTE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKEXECUTE.Esito_Task AS DM_TASKEXECUTE_ESITO_TASK',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKEXECUTE_ESITO_TASK',
          name: 'DM_TASKEXECUTE_ESITO_TASK',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKEXECUTE.METODO AS DM_TASKEXECUTE_METODO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKEXECUTE_METODO',
          name: 'DM_TASKEXECUTE_METODO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKEXECUTE.DATA AS DM_TASKEXECUTE_DATA',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKEXECUTE_DATA',
          name: 'DM_TASKEXECUTE_DATA',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKEXECUTE.NOTATASK AS DM_TASKEXECUTE_NOTATASK',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKEXECUTE_NOTATASK',
          name: 'DM_TASKEXECUTE_NOTATASK',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKEXECUTE.INELABORAZIONE AS DM_TASKEXECUTE_INELABORAZIONE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKEXECUTE_INELABORAZIONE',
          name: 'DM_TASKEXECUTE_INELABORAZIONE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_UTENTI.DESCRIPTION AS DM_UTENTI_DESCRIPTION',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Utente',
          name: 'DM_UTENTI_DESCRIPTION',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_UTENTI.AOO AS DM_UTENTI_AOO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_UTENTI_AOO',
          name: 'DM_UTENTI_AOO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_PROFILE_AUTOREDESCRIPTION}',
          toCalculate: false,
          index: 8,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Autore descrizione',
          name: 'DM_PROFILE_AUTOREDESCRIPTION',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.TASKTYPEEXTERNALID',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TASKTYPEEXTERNALID',
          name: 'TASKTYPEEXTERNALID',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.RIASSEGNAMODE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'RIASSEGNAMODE',
          name: 'RIASSEGNAMODE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.AUTOASSIGNMODE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'AUTOASSIGNMODE',
          name: 'AUTOASSIGNMODE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO19_24 AS DM_PROFILE_COMBO19_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Classe tipologica materiale',
          name: 'DM_PROFILE_COMBO19_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO20_24 AS DM_PROFILE_COMBO20_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Destinazione',
          name: 'DM_PROFILE_COMBO20_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC21_24 AS DM_PROFILE_NUMERIC21_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Prezzo',
          name: 'DM_PROFILE_NUMERIC21_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC22_24 AS DM_PROFILE_NUMERIC22_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Quantità',
          name: 'DM_PROFILE_NUMERIC22_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO23_24 AS DM_PROFILE_COMBO23_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Unità di misuta',
          name: 'DM_PROFILE_COMBO23_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA24_25 AS DM_PROFILE_DATA24_25',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Inizio noleggio',
          name: 'DM_PROFILE_DATA24_25',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC25_25 AS DM_PROFILE_NUMERIC25_25',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Durate (mesi)',
          name: 'DM_PROFILE_NUMERIC25_25',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA26_25 AS DM_PROFILE_DATA26_25',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Scadenza',
          name: 'DM_PROFILE_DATA26_25',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC27_25 AS DM_PROFILE_NUMERIC27_25',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Canone',
          name: 'DM_PROFILE_NUMERIC27_25',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC28_26 AS DM_PROFILE_NUMERIC28_26',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Importo',
          name: 'DM_PROFILE_NUMERIC28_26',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO29_27 AS DM_PROFILE_COMBO29_27',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Numero preventivo',
          name: 'DM_PROFILE_COMBO29_27',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA35_29 AS DM_PROFILE_DATA35_29',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data di scadenza',
          name: 'DM_PROFILE_DATA35_29',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC36_29 AS DM_PROFILE_NUMERIC36_29',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Rinnovo (mesi)',
          name: 'DM_PROFILE_NUMERIC36_29',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO34_29 AS DM_PROFILE_COMBO34_29',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipologia',
          name: 'DM_PROFILE_COMBO34_29',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA32_30 AS DM_PROFILE_DATA32_30',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data di scadenza',
          name: 'DM_PROFILE_DATA32_30',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC33_30 AS DM_PROFILE_NUMERIC33_30',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Rinnovo (mesi)',
          name: 'DM_PROFILE_NUMERIC33_30',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO31_30 AS DM_PROFILE_COMBO31_30',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipologia',
          name: 'DM_PROFILE_COMBO31_30',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA49_30 AS DM_PROFILE_DATA49_30',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data avviso',
          name: 'DM_PROFILE_DATA49_30',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC50_30 AS DM_PROFILE_NUMERIC50_30',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Giorni di preavviso',
          name: 'DM_PROFILE_NUMERIC50_30',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO30_28 AS DM_PROFILE_COMBO30_28',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Riferimento Offerta',
          name: 'DM_PROFILE_COMBO30_28',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC38_37 AS DM_PROFILE_NUMERIC38_37',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ore',
          name: 'DM_PROFILE_NUMERIC38_37',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO39_37 AS DM_PROFILE_TESTO39_37',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Materiale',
          name: 'DM_PROFILE_TESTO39_37',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO42_38 AS DM_PROFILE_COMBO42_38',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipologia',
          name: 'DM_PROFILE_COMBO42_38',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC40_39 AS DM_PROFILE_NUMERIC40_39',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tempo di lavoro (h)',
          name: 'DM_PROFILE_NUMERIC40_39',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.CHECK41_39 AS DM_PROFILE_CHECK41_39',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Lavoro concluso',
          name: 'DM_PROFILE_CHECK41_39',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC45_41 AS DM_PROFILE_NUMERIC45_41',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ore',
          name: 'DM_PROFILE_NUMERIC45_41',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO48_42 AS DM_PROFILE_COMBO48_42',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipologia documento subappalto',
          name: 'DM_PROFILE_COMBO48_42',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TABLE37_33 AS DM_PROFILE_TABLE37_33',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Cantiere',
          name: 'DM_PROFILE_TABLE37_33',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC43_41 AS DM_PROFILE_NUMERIC43_41',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Codice Cantiere',
          name: 'DM_PROFILE_NUMERIC43_41',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TABLE46_42 AS DM_PROFILE_TABLE46_42',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Subappalto',
          name: 'DM_PROFILE_TABLE46_42',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC47_42 AS DM_PROFILE_NUMERIC47_42',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Codice subappalto',
          name: 'DM_PROFILE_NUMERIC47_42',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TABLE44_41 AS DM_PROFILE_TABLE44_41',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Fornitore',
          name: 'DM_PROFILE_TABLE44_41',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO70_58 AS DM_PROFILE_COMBO70_58',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Numerazione',
          name: 'DM_PROFILE_COMBO70_58',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO67_59 AS DM_PROFILE_COMBO67_59',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipo di documento',
          name: 'DM_PROFILE_COMBO67_59',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO63_58 AS DM_PROFILE_COMBO63_58',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Cliente',
          name: 'DM_PROFILE_COMBO63_58',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA64_58 AS DM_PROFILE_DATA64_58',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data attivazione utenza',
          name: 'DM_PROFILE_DATA64_58',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA65_58 AS DM_PROFILE_DATA65_58',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Check fee pagato',
          name: 'DM_PROFILE_DATA65_58',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA66_58 AS DM_PROFILE_DATA66_58',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data scadenza contratto',
          name: 'DM_PROFILE_DATA66_58',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.CHECK68_60 AS DM_PROFILE_CHECK68_60',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Gettone pagato',
          name: 'DM_PROFILE_CHECK68_60',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC71_60 AS DM_PROFILE_NUMERIC71_60',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Numerazione',
          name: 'DM_PROFILE_NUMERIC71_60',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.CHECK72_60 AS DM_PROFILE_CHECK72_60',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Utenza Attivata',
          name: 'DM_PROFILE_CHECK72_60',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.CHECK75_60 AS DM_PROFILE_CHECK75_60',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Fee Pagato dopo 1 mese',
          name: 'DM_PROFILE_CHECK75_60',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA60_56 AS DM_PROFILE_DATA60_56',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data inizio ferie',
          name: 'DM_PROFILE_DATA60_56',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA61_56 AS DM_PROFILE_DATA61_56',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data fine ferie',
          name: 'DM_PROFILE_DATA61_56',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO62_56 AS DM_PROFILE_COMBO62_56',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Richiedente',
          name: 'DM_PROFILE_COMBO62_56',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO73_61 AS DM_PROFILE_COMBO73_61',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Responsabile',
          name: 'DM_PROFILE_COMBO73_61',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA74_61 AS DM_PROFILE_DATA74_61',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data vaglio',
          name: 'DM_PROFILE_DATA74_61',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        }
      ],
      maxItems: 0
    }
  } as unknown as JSON;
  TakBody: JSON = {
    select: {
      fields: [
        {
          sqlSelectField: 'DM_TASKWORK.ID',
          toCalculate: false,
          index: 5,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'ID',
          name: 'ID',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.SENDERS AS DM_PROFILE_SENDERS',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Altri Da',
          name: 'DM_PROFILE_SENDERS',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.ID_PROCESSO',
          toCalculate: false,
          index: 7,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Processo',
          name: 'ID_PROCESSO',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.DATAPRESAINCARICO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data presa in carico',
          name: 'DATAPRESAINCARICO',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.NOTE',
          toCalculate: false,
          index: 14,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Note',
          name: 'NOTE',
          userSelectionEnabled: true,
          userSelectionGroup: 0
        },


        {
          sqlSelectField: 'DM_TASKWORK.ALLEGATI',
          toCalculate: false,
          index: 15,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Allegati',
          name: 'ALLEGATI',
          userSelectionEnabled: true,
          userSelectionGroup: 0
        },
        {
          sqlSelectField: 'DM_TASKWORK.NUMDOC AS DOCNUMBER',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'System ID',
          name: 'DOCNUMBER',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.REVISIONE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Revisione',
          name: 'REVISIONE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.DATAINIZIO',
          toCalculate: false,
          index: 16,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data inizio task',
          name: 'DATAINIZIO',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_TASKWORK_NOMETASK}',
          toCalculate: false,
          index: 18,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome task',
          name: 'NOMETASK',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_TASKWORK_DESCTASK}',
          toCalculate: false,
          index: 19,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Descrizione task',
          name: 'DESCTASK',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.UTENTE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'UTENTE',
          name: 'UTENTE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.CONCLUSO',
          toCalculate: false,
          index: 11,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'CONCLUSO',
          name: 'CONCLUSO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.STATO',
          toCalculate: false,
          index: 2,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Stato',
          name: 'STATO',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.DATA_SCADENZA',
          toCalculate: false,
          index: 17,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data scadenza task',
          name: 'DATA_SCADENZA',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.ABILITAEXPORT',
          toCalculate: false,
          index: 10,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'ABILITAEXPORT',
          name: 'ABILITAEXPORT',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.ID_NODO',
          toCalculate: false,
          index: 9,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'ID_NODO',
          name: 'ID_NODO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.ISTRUZIONI',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'ISTRUZIONI',
          name: 'ISTRUZIONI',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.SELECTUSER',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'SELECTUSER',
          name: 'SELECTUSER',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.INPUTNEWDOC',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'INPUTNEWDOC',
          name: 'INPUTNEWDOC',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.INPUT_TYPE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'INPUT_TYPE',
          name: 'INPUT_TYPE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.EDITPROFILO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'EDITPROFILO',
          name: 'EDITPROFILO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.INSERTFOLDER',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'INSERTFOLDER',
          name: 'INSERTFOLDER',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.ASSOCIA',
          toCalculate: false,
          index: 1,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Priorità',
          name: 'ASSOCIA',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.MODULO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'MODULO',
          name: 'MODULO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.TIPOTASK',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TIPOTASK',
          name: 'TIPOTASK',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.MODESEC',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'MODESEC',
          name: 'MODESEC',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.NOTIFICAMAIL',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'NOTIFICAMAIL',
          name: 'NOTIFICAMAIL',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.AVANZATASK',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'AVANZATASK',
          name: 'AVANZATASK',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.PSWREQUIRED',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'PSWREQUIRED',
          name: 'PSWREQUIRED',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.NEWMEMO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'NEWMEMO',
          name: 'NEWMEMO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.LETTO',
          toCalculate: false,
          index: 12,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Letto',
          name: 'LETTO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.COMASINCRONO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'COMASINCRONO',
          name: 'COMASINCRONO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.COPIAEDIT',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'COPIAEDIT',
          name: 'COPIAEDIT',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'CASE WHEN DM_TASKWORK.OBBCOM IS NULL THEN 0 ELSE CASE WHEN DM_TASKWORK.OBBCOM = 1 THEN 1 ELSE CASE WHEN DM_TASKWORK.DATAPRESAINCARICO IS NOT NULL THEN 2 ELSE 0 END END\tEND AS OBBCOM',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Presa in carico',
          name: 'OBBCOM',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.SCADTASK',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'SCADTASK',
          name: 'SCADTASK',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.ATTIVATASK',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'ATTIVATASK',
          name: 'ATTIVATASK',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_TASKWORK_DESCFIGURE}',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DESCFIGURE',
          name: 'DESCFIGURE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_TASKWORK_DESCVARIABILI}',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DESCVARIABILI',
          name: 'DESCVARIABILI',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.ID_ORG',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'ID_ORG',
          name: 'ID_ORG',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.ID_DELEGA',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'ID_DELEGA',
          name: 'ID_DELEGA',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.TASKAPERTO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TASKAPERTO',
          name: 'TASKAPERTO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.ESITO',
          toCalculate: false,
          index: 3,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Esito',
          name: 'ESITO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.TASKDIGRUPPO',
          toCalculate: false,
          index: 4,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TASKDIGRUPPO',
          name: 'TASKDIGRUPPO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.TIPO1',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TIPO1',
          name: 'TIPO1',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.TIPO2',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TIPO2',
          name: 'TIPO2',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.TIPO3',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TIPO3',
          name: 'TIPO3',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.SELECTDOC',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'SELECTDOC',
          name: 'SELECTDOC',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_WORKFLOW_DETTAGLIO}',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_WORKFLOW_DETTAGLIO',
          name: 'DM_WORKFLOW_DETTAGLIO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_WORKFLOW.NOME AS DM_WORKFLOW_NOME',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome workflow',
          name: 'DM_WORKFLOW_NOME',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_WORKFLOW.FILEFLW AS DM_WORKFLOW_FILEFLW',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_WORKFLOW_FILEFLW',
          name: 'DM_WORKFLOW_FILEFLW',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_WORKFLOW.PERCORSO AS DM_WORKFLOW_PERCORSO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_WORKFLOW_PERCORSO',
          name: 'DM_WORKFLOW_PERCORSO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_WORKFLOW_DESCRIZIONE}',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_WORKFLOW_DESCRIZIONE',
          name: 'DM_WORKFLOW_DESCRIZIONE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_WORKFLOW.STATO AS DM_WORKFLOW_STATO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_WORKFLOW_STATO',
          name: 'DM_WORKFLOW_STATO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_WORKFLOW.ORGANIGRAMMA AS DM_WORKFLOW_ORGANIGRAMMA',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_WORKFLOW_ORGANIGRAMMA',
          name: 'DM_WORKFLOW_ORGANIGRAMMA',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_WORKFLOW.AOO AS DM_WORKFLOW_AOO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_WORKFLOW_AOO',
          name: 'DM_WORKFLOW_AOO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_WORKFLOW.COLORE AS DM_WORKFLOW_COLORE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_WORKFLOW_COLORE',
          name: 'DM_WORKFLOW_COLORE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_WORKFLOW.DOCNUMBER AS DM_WORKFLOW_DOCNUMBER',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_WORKFLOW_DOCNUMBER',
          name: 'DM_WORKFLOW_DOCNUMBER',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROCESSI.NOTE AS DM_PROCESSI_NOTE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROCESSI_NOTE',
          name: 'DM_PROCESSI_NOTE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROCESSI.ALLEGATI AS DM_PROCESSI_ALLEGATI',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROCESSI_ALLEGATI',
          name: 'DM_PROCESSI_ALLEGATI',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROCESSI.STATO AS DM_PROCESSI_STATO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROCESSI_STATO',
          name: 'DM_PROCESSI_STATO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROCESSI.WORKFLOW AS DM_PROCESSI_WORKFLOW',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROCESSI_WORKFLOW',
          name: 'DM_PROCESSI_WORKFLOW',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROCESSI.INIZIO AS DM_PROCESSI_INIZIO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROCESSI_INIZIO',
          name: 'DM_PROCESSI_INIZIO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROCESSI.CONCLUSO AS DM_PROCESSI_CONCLUSO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROCESSI_CONCLUSO',
          name: 'DM_PROCESSI_CONCLUSO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROCESSI.DATA_SCADENZA AS DM_PROCESSI_DATA_SCADENZA',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROCESSI_DATA_SCADENZA',
          name: 'DM_PROCESSI_DATA_SCADENZA',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROCESSI.COLORE AS DM_PROCESSI_COLORE',
          toCalculate: false,
          index: 0,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Colore workflow',
          name: 'DM_PROCESSI_COLORE',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_PROCESSI_DESCRIZIONE}',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Descrizione processo',
          name: 'DM_PROCESSI_DESCRIZIONE',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_TASKDOC_DESCRIZIONE}',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKDOC_DESCRIZIONE',
          name: 'DM_TASKDOC_DESCRIZIONE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKDOC.INPUTNEWDOC AS DM_TASKDOC_INPUTNEWDOC',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKDOC_INPUTNEWDOC',
          name: 'DM_TASKDOC_INPUTNEWDOC',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKDOC.DOCOBBLIGATORIO AS DM_TASKDOC_DOCOBBLIGATORIO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKDOC_DOCOBBLIGATORIO',
          name: 'DM_TASKDOC_DOCOBBLIGATORIO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKDOC.TIPO1 AS DM_TASKDOC_TIPO1',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKDOC_TIPO1',
          name: 'DM_TASKDOC_TIPO1',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKDOC.TIPO2 AS DM_TASKDOC_TIPO2',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKDOC_TIPO2',
          name: 'DM_TASKDOC_TIPO2',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKDOC.TIPO3 AS DM_TASKDOC_TIPO3',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKDOC_TIPO3',
          name: 'DM_TASKDOC_TIPO3',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKDOC.OP_ESEGUITA AS DM_TASKDOC_OP_ESEGUITA',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKDOC_OP_ESEGUITA',
          name: 'DM_TASKDOC_OP_ESEGUITA',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKDOC.INPUT_TYPE AS DM_TASKDOC_INPUT_TYPE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKDOC_INPUT_TYPE',
          name: 'DM_TASKDOC_INPUT_TYPE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.DOCNUMBER AS DM_PROFILE_DOCNUMBER',
          toCalculate: false,
          index: 21,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'System ID',
          name: 'DM_PROFILE_DOCNUMBER',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.DOCNAME AS DM_PROFILE_DOCNAME',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Oggetto',
          name: 'DM_PROFILE_DOCNAME',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.DOCUMENTTYPE AS DM_PROFILE_DOCUMENTTYPE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_DOCUMENTTYPE',
          name: 'DM_PROFILE_DOCUMENTTYPE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.TIPO2 AS DM_PROFILE_TIPO2',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_TIPO2',
          name: 'DM_PROFILE_TIPO2',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.TIPO3 AS DM_PROFILE_TIPO3',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_TIPO3',
          name: 'DM_PROFILE_TIPO3',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.PATH AS DM_PROFILE_PATH',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_PATH',
          name: 'DM_PROFILE_PATH',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.FILENAME AS DM_PROFILE_FILENAME',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_FILENAME',
          name: 'DM_PROFILE_FILENAME',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERO AS DM_PROFILE_NUMERO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Numero',
          name: 'DM_PROFILE_NUMERO',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.DATADOC AS DM_PROFILE_DATADOC',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data documento',
          name: 'DM_PROFILE_DATADOC',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.CC AS DM_PROFILE_CC',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'CC',
          name: 'DM_PROFILE_CC',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.PROFILATO AS DM_PROFILE_PROFILATO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_PROFILATO',
          name: 'DM_PROFILE_PROFILATO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.CREATION_DATE AS DM_PROFILE_CREATION_DATE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data registrazione',
          name: 'DM_PROFILE_CREATION_DATE',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.IMPORTANTE AS DM_PROFILE_IMPORTANTE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Importante',
          name: 'DM_PROFILE_IMPORTANTE',
          userSelectionEnabled: true,
          userSelectionGroup: 0
        },
        {
          sqlSelectField: 'DM_PROFILE.REVISIONE AS DM_PROFILE_REVISIONE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Revisione',
          name: 'DM_PROFILE_REVISIONE',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.AUTORE AS DM_PROFILE_AUTORE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Autore',
          name: 'DM_PROFILE_AUTORE',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.NOTE AS DM_PROFILE_NOTE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_NOTE',
          name: 'DM_PROFILE_NOTE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.PROTOCOLLO AS DM_PROFILE_PROTOCOLLO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Protocollo Pa',
          name: 'DM_PROFILE_PROTOCOLLO',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.ANNO AS DM_PROFILE_ANNO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Anno Protocollo Pa',
          name: 'DM_PROFILE_ANNO',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.BLOCCATO AS DM_PROFILE_BLOCCATO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_BLOCCATO',
          name: 'DM_PROFILE_BLOCCATO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.STATO AS DM_PROFILE_STATO',
          toCalculate: false,
          index: 13,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Stato',
          name: 'DM_PROFILE_STATO',
          userSelectionEnabled: true,
          userSelectionGroup: 0
        },
        {
          sqlSelectField: 'DM_PROFILE.INOUT AS DM_PROFILE_INOUT',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_INOUT',
          name: 'DM_PROFILE_INOUT',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.SCADENZA AS DM_PROFILE_SCADENZA',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Scadenza',
          name: 'DM_PROFILE_SCADENZA',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.FLAG AS DM_PROFILE_FLAG',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_FLAG',
          name: 'DM_PROFILE_FLAG',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.WORKFLOW AS DM_PROFILE_WORKFLOW',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_WORKFLOW',
          name: 'DM_PROFILE_WORKFLOW',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.ORIGINALE AS DM_PROFILE_ORIGINALE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome file',
          name: 'DM_PROFILE_ORIGINALE',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.DATAFILE AS DM_PROFILE_DATAFILE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_DATAFILE',
          name: 'DM_PROFILE_DATAFILE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.GESTREV AS DM_PROFILE_GESTREV',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_GESTREV',
          name: 'DM_PROFILE_GESTREV',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.DEVICE AS DM_PROFILE_DEVICE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_DEVICE',
          name: 'DM_PROFILE_DEVICE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.ALLEGATI AS DM_PROFILE_ALLEGATI',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_ALLEGATI',
          name: 'DM_PROFILE_ALLEGATI',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.OPENDOC AS DM_PROFILE_OPENDOC',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_OPENDOC',
          name: 'DM_PROFILE_OPENDOC',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.ASSOCIAZIONI AS DM_PROFILE_ASSOCIAZIONI',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_ASSOCIAZIONI',
          name: 'DM_PROFILE_ASSOCIAZIONI',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.IMPRONTA AS DM_PROFILE_IMPRONTA',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_IMPRONTA',
          name: 'DM_PROFILE_IMPRONTA',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.EMERGENZA AS DM_PROFILE_EMERGENZA',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_EMERGENZA',
          name: 'DM_PROFILE_EMERGENZA',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.AOO AS DM_PROFILE_AOO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Aoo',
          name: 'DM_PROFILE_AOO',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.WEBVISIBLE AS DM_PROFILE_WEBVISIBLE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_WEBVISIBLE',
          name: 'DM_PROFILE_WEBVISIBLE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.ISAOS AS DM_PROFILE_ISAOS',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_ISAOS',
          name: 'DM_PROFILE_ISAOS',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.INVOICE AS DM_PROFILE_INVOICE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_INVOICE',
          name: 'DM_PROFILE_INVOICE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.SCADAOS AS DM_PROFILE_SCADAOS',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_SCADAOS',
          name: 'DM_PROFILE_SCADAOS',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.ETICHETTACD AS DM_PROFILE_ETICHETTACD',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_ETICHETTACD',
          name: 'DM_PROFILE_ETICHETTACD',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.ETI_READER AS DM_PROFILE_ETI_READER',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_ETI_READER',
          name: 'DM_PROFILE_ETI_READER',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.DATAPROT AS DM_PROFILE_DATAPROT',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_DATAPROT',
          name: 'DM_PROFILE_DATAPROT',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_PROFILE_PRATICHE}',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_PROFILE_PRATICHE',
          name: 'DM_PROFILE_PRATICHE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_PROFILE_STATOPROCDESC}',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Stato esteso',
          name: 'DM_PROFILE_STATOPROCDESC',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.MITTENTE AS DM_PROFILE_MITTENTE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Mittente',
          name: 'DM_PROFILE_MITTENTE',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.DESTINATARIO AS DM_PROFILE_DESTINATARIO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Destinatario',
          name: 'DM_PROFILE_DESTINATARIO',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_FASCICOLI.NOME AS DM_FASCICOLI_NOME',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome fascicolo',
          name: 'DM_FASCICOLI_NOME',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKEXECUTE.IDUtente AS DM_TASKEXECUTE_IDUTENTE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKEXECUTE_IDUTENTE',
          name: 'DM_TASKEXECUTE_IDUTENTE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKEXECUTE.AOO AS DM_TASKEXECUTE_AOO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKEXECUTE_AOO',
          name: 'DM_TASKEXECUTE_AOO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKEXECUTE.UtenteCompleto AS DM_TASKEXECUTE_UTENTECOMPLETO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKEXECUTE_UTENTECOMPLETO',
          name: 'DM_TASKEXECUTE_UTENTECOMPLETO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKEXECUTE.Gruppo_Utente AS DM_TASKEXECUTE_GRUPPOUTENTE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKEXECUTE_GRUPPOUTENTE',
          name: 'DM_TASKEXECUTE_GRUPPOUTENTE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKEXECUTE.Esito_Task AS DM_TASKEXECUTE_ESITO_TASK',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKEXECUTE_ESITO_TASK',
          name: 'DM_TASKEXECUTE_ESITO_TASK',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKEXECUTE.METODO AS DM_TASKEXECUTE_METODO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKEXECUTE_METODO',
          name: 'DM_TASKEXECUTE_METODO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKEXECUTE.DATA AS DM_TASKEXECUTE_DATA',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKEXECUTE_DATA',
          name: 'DM_TASKEXECUTE_DATA',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKEXECUTE.NOTATASK AS DM_TASKEXECUTE_NOTATASK',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKEXECUTE_NOTATASK',
          name: 'DM_TASKEXECUTE_NOTATASK',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKEXECUTE.INELABORAZIONE AS DM_TASKEXECUTE_INELABORAZIONE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_TASKEXECUTE_INELABORAZIONE',
          name: 'DM_TASKEXECUTE_INELABORAZIONE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_UTENTI.DESCRIPTION AS DM_UTENTI_DESCRIPTION',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Utente',
          name: 'DM_UTENTI_DESCRIPTION',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_UTENTI.AOO AS DM_UTENTI_AOO',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DM_UTENTI_AOO',
          name: 'DM_UTENTI_AOO',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: '{DM_PROFILE_AUTOREDESCRIPTION}',
          toCalculate: false,
          index: 8,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Autore descrizione',
          name: 'DM_PROFILE_AUTOREDESCRIPTION',
          userSelectionEnabled: true,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.TASKTYPEEXTERNALID',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TASKTYPEEXTERNALID',
          name: 'TASKTYPEEXTERNALID',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.RIASSEGNAMODE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'RIASSEGNAMODE',
          name: 'RIASSEGNAMODE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_TASKWORK.AUTOASSIGNMODE',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'AUTOASSIGNMODE',
          name: 'AUTOASSIGNMODE',
          userSelectionEnabled: false,
          userSelectionGroup: 1
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO374_9 AS DM_PROFILE_COMBO374_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ruolo del Fornitore',
          name: 'DM_PROFILE_COMBO374_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO168_9 AS DM_PROFILE_COMBO168_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ruolo dell\'AOO',
          name: 'DM_PROFILE_COMBO168_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI202_9 AS DM_PROFILE_MULTI202_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Standard applicati',
          name: 'DM_PROFILE_MULTI202_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO219_9 AS DM_PROFILE_TESTO219_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Subfornitori dichiarati',
          name: 'DM_PROFILE_TESTO219_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI169_15 AS DM_PROFILE_MULTI169_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Contitolari',
          name: 'DM_PROFILE_MULTI169_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO172_15 AS DM_PROFILE_TESTO172_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Titolari autonomi',
          name: 'DM_PROFILE_TESTO172_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO183_15 AS DM_PROFILE_TESTO183_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Archivio Cartaceo : Locazione',
          name: 'DM_PROFILE_TESTO183_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI193_16 AS DM_PROFILE_MULTI193_16',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Titolare del trattamento',
          name: 'DM_PROFILE_MULTI193_16',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO321_16 AS DM_PROFILE_COMBO321_16',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Servizi',
          name: 'DM_PROFILE_COMBO321_16',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO195_15 AS DM_PROFILE_TESTO195_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Processo: Descrizione',
          name: 'DM_PROFILE_TESTO195_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI271_16 AS DM_PROFILE_MULTI271_16',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Interessati',
          name: 'DM_PROFILE_MULTI271_16',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI167_15 AS DM_PROFILE_MULTI167_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Responsabili trattamento',
          name: 'DM_PROFILE_MULTI167_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI170_15 AS DM_PROFILE_MULTI170_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Autorizzati',
          name: 'DM_PROFILE_MULTI170_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI174_15 AS DM_PROFILE_MULTI174_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Sistemi Utilizzati',
          name: 'DM_PROFILE_MULTI174_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI180_15 AS DM_PROFILE_MULTI180_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Misure di sicurezza adottate',
          name: 'DM_PROFILE_MULTI180_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO182_15 AS DM_PROFILE_COMBO182_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Archivio Cartaceo',
          name: 'DM_PROFILE_COMBO182_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO185_15 AS DM_PROFILE_COMBO185_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Paese Destinazione',
          name: 'DM_PROFILE_COMBO185_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO309_17 AS DM_PROFILE_COMBO309_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipo Data Breach',
          name: 'DM_PROFILE_COMBO309_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO256_17 AS DM_PROFILE_COMBO256_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipo di notifica',
          name: 'DM_PROFILE_COMBO256_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI288_17 AS DM_PROFILE_MULTI288_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: '*Dati personali trattati',
          name: 'DM_PROFILE_MULTI288_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI290_17 AS DM_PROFILE_MULTI290_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: '*Interessato (Titolare)',
          name: 'DM_PROFILE_MULTI290_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI291_17 AS DM_PROFILE_MULTI291_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: '*Interessato (Resp.)',
          name: 'DM_PROFILE_MULTI291_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI292_17 AS DM_PROFILE_MULTI292_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: '*Sistemi Utilizzati',
          name: 'DM_PROFILE_MULTI292_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI294_17 AS DM_PROFILE_MULTI294_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: '*Misure di sicurezza adottate',
          name: 'DM_PROFILE_MULTI294_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO247_17 AS DM_PROFILE_COMBO247_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Natura della violazione',
          name: 'DM_PROFILE_COMBO247_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO160_15 AS DM_PROFILE_COMBO160_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Impatto: Valore **',
          name: 'DM_PROFILE_COMBO160_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO262_17 AS DM_PROFILE_COMBO262_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Violazione ancora in corso?',
          name: 'DM_PROFILE_COMBO262_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA263_17 AS DM_PROFILE_DATA263_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data inizio violazione',
          name: 'DM_PROFILE_DATA263_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA264_17 AS DM_PROFILE_DATA264_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data fine violazione',
          name: 'DM_PROFILE_DATA264_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI346_17 AS DM_PROFILE_MULTI346_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TRATTAMENTI REG.TITOLARE',
          name: 'DM_PROFILE_MULTI346_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI347_17 AS DM_PROFILE_MULTI347_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TRATTAMENTI REG.RESPONSABILE',
          name: 'DM_PROFILE_MULTI347_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO308_18 AS DM_PROFILE_TESTO308_18',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Motivazione della decisione',
          name: 'DM_PROFILE_TESTO308_18',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI317_19 AS DM_PROFILE_MULTI317_19',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipo di garanzia se il Paese di destinazione è extra UE',
          name: 'DM_PROFILE_MULTI317_19',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO525_20 AS DM_PROFILE_TESTO525_20',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Dati importati',
          name: 'DM_PROFILE_TESTO525_20',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO526_20 AS DM_PROFILE_COMBO526_20',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Vuoi caricare da file?',
          name: 'DM_PROFILE_COMBO526_20',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO388_21 AS DM_PROFILE_COMBO388_21',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ubicazione',
          name: 'DM_PROFILE_COMBO388_21',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO386_21 AS DM_PROFILE_COMBO386_21',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipologia di Cloud',
          name: 'DM_PROFILE_COMBO386_21',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO387_21 AS DM_PROFILE_COMBO387_21',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipologia di servizi Cloud',
          name: 'DM_PROFILE_COMBO387_21',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO389_21 AS DM_PROFILE_COMBO389_21',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Gestione in carico a',
          name: 'DM_PROFILE_COMBO389_21',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO176_22 AS DM_PROFILE_COMBO176_22',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipologia',
          name: 'DM_PROFILE_COMBO176_22',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC177_22 AS DM_PROFILE_NUMERIC177_22',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Livello Misure di Sicurezza',
          name: 'DM_PROFILE_NUMERIC177_22',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO178_22 AS DM_PROFILE_TESTO178_22',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Categoria',
          name: 'DM_PROFILE_TESTO178_22',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO235_22 AS DM_PROFILE_TESTO235_22',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Misure di sicurezza',
          name: 'DM_PROFILE_TESTO235_22',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO318_22 AS DM_PROFILE_TESTO318_22',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Modalità di Adozione',
          name: 'DM_PROFILE_TESTO318_22',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO236_22 AS DM_PROFILE_COMBO236_22',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Stato implementazione',
          name: 'DM_PROFILE_COMBO236_22',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO237_22 AS DM_PROFILE_TESTO237_22',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Piano di miglioramento',
          name: 'DM_PROFILE_TESTO237_22',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO238_22 AS DM_PROFILE_TESTO238_22',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Responsabile',
          name: 'DM_PROFILE_TESTO238_22',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA306_22 AS DM_PROFILE_DATA306_22',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data scadenza piano miglioramento',
          name: 'DM_PROFILE_DATA306_22',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO597_22 AS DM_PROFILE_COMBO597_22',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Livello Misure di Sicurezza',
          name: 'DM_PROFILE_COMBO597_22',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO603_22 AS DM_PROFILE_TESTO603_22',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Descrizione Breve Misura di Sicurezza',
          name: 'DM_PROFILE_TESTO603_22',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO604_22 AS DM_PROFILE_TESTO604_22',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Codice Misura di Sicurezza',
          name: 'DM_PROFILE_TESTO604_22',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TABLE315_23 AS DM_PROFILE_TABLE315_23',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Sistema ( x ricerca )',
          name: 'DM_PROFILE_TABLE315_23',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO173_21 AS DM_PROFILE_TESTO173_21',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome Sistema',
          name: 'DM_PROFILE_TESTO173_21',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO175_21 AS DM_PROFILE_COMBO175_21',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Categoria Sistema',
          name: 'DM_PROFILE_COMBO175_21',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO190_21 AS DM_PROFILE_TESTO190_21',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ubicazione',
          name: 'DM_PROFILE_TESTO190_21',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO234_21 AS DM_PROFILE_TESTO234_21',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Funzione del sistema',
          name: 'DM_PROFILE_TESTO234_21',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO311_23 AS DM_PROFILE_COMBO311_23',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Criticità Sistema : Valore',
          name: 'DM_PROFILE_COMBO311_23',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO312_23 AS DM_PROFILE_TESTO312_23',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Criticità Sistema : Descrizione',
          name: 'DM_PROFILE_TESTO312_23',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO313_23 AS DM_PROFILE_TESTO313_23',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Criticità Sistema : Livello Sicurezza',
          name: 'DM_PROFILE_TESTO313_23',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TABLE332_23 AS DM_PROFILE_TABLE332_23',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Misure Sicurezza',
          name: 'DM_PROFILE_TABLE332_23',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO210_9 AS DM_PROFILE_TESTO210_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Amministratori di Sistema Interni',
          name: 'DM_PROFILE_TESTO210_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO320_23 AS DM_PROFILE_TESTO320_23',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Amministratori di Sistema Esterni',
          name: 'DM_PROFILE_TESTO320_23',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO364_24 AS DM_PROFILE_COMBO364_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipo Nomina',
          name: 'DM_PROFILE_COMBO364_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO203_9 AS DM_PROFILE_TESTO203_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Breve descrizione del servizio',
          name: 'DM_PROFILE_TESTO203_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA367_24 AS DM_PROFILE_DATA367_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data Invio',
          name: 'DM_PROFILE_DATA367_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA368_24 AS DM_PROFILE_DATA368_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data Ultimo Sollecito ',
          name: 'DM_PROFILE_DATA368_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO369_24 AS DM_PROFILE_TESTO369_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Note',
          name: 'DM_PROFILE_TESTO369_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA370_24 AS DM_PROFILE_DATA370_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data Conferma',
          name: 'DM_PROFILE_DATA370_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TABLE384_24 AS DM_PROFILE_TABLE384_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ragione Sociale Cliente (Se Sub-Responsabile)',
          name: 'DM_PROFILE_TABLE384_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO518_24 AS DM_PROFILE_TESTO518_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Indirizzo Cliente',
          name: 'DM_PROFILE_TESTO518_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO519_24 AS DM_PROFILE_TESTO519_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'CAP Cliente',
          name: 'DM_PROFILE_TESTO519_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO520_24 AS DM_PROFILE_TESTO520_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Città Cliente',
          name: 'DM_PROFILE_TESTO520_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO521_24 AS DM_PROFILE_COMBO521_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Provincia Cliente',
          name: 'DM_PROFILE_COMBO521_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO522_24 AS DM_PROFILE_COMBO522_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Stato Cliente',
          name: 'DM_PROFILE_COMBO522_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO142_13 AS DM_PROFILE_COMBO142_13',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TAB Codice',
          name: 'DM_PROFILE_COMBO142_13',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO141_13 AS DM_PROFILE_TESTO141_13',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TAB-Descrizione',
          name: 'DM_PROFILE_TESTO141_13',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO156_13 AS DM_PROFILE_TESTO156_13',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TAB Valore Alfabetico',
          name: 'DM_PROFILE_TESTO156_13',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC151_14 AS DM_PROFILE_NUMERIC151_14',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'TAB Valore Numerico',
          name: 'DM_PROFILE_NUMERIC151_14',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO570_28 AS DM_PROFILE_TESTO570_28',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Descrizione Inglese',
          name: 'DM_PROFILE_TESTO570_28',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO375_30 AS DM_PROFILE_COMBO375_30',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Categoria',
          name: 'DM_PROFILE_COMBO375_30',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO390_35 AS DM_PROFILE_COMBO390_35',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Raggruppamento ',
          name: 'DM_PROFILE_COMBO390_35',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO372_35 AS DM_PROFILE_COMBO372_35',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome MODELLO Base',
          name: 'DM_PROFILE_COMBO372_35',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO385_35 AS DM_PROFILE_COMBO385_35',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Livello di Sicurezza(Nomina)',
          name: 'DM_PROFILE_COMBO385_35',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC158_15 AS DM_PROFILE_NUMERIC158_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Criticità Dato: Valore',
          name: 'DM_PROFILE_NUMERIC158_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC153_15 AS DM_PROFILE_NUMERIC153_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Criticità Finalità: Valore ',
          name: 'DM_PROFILE_NUMERIC153_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC159_15 AS DM_PROFILE_NUMERIC159_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Impatto: Valore Calcolato',
          name: 'DM_PROFILE_NUMERIC159_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO456_32 AS DM_PROFILE_COMBO456_32',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Necessaria DPIA',
          name: 'DM_PROFILE_COMBO456_32',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO371_35 AS DM_PROFILE_COMBO371_35',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipo Documento',
          name: 'DM_PROFILE_COMBO371_35',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TABLE329_9 AS DM_PROFILE_TABLE329_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ricerca Cod.Anagrafica',
          name: 'DM_PROFILE_TABLE329_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC338_31 AS DM_PROFILE_NUMERIC338_31',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Riferimento ID',
          name: 'DM_PROFILE_NUMERIC338_31',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO164_9 AS DM_PROFILE_TESTO164_9',
          toCalculate: false,
          index: 0,
          selected: true,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ragione Sociale',
          name: 'DM_PROFILE_TESTO164_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TABLE392_42 AS DM_PROFILE_TABLE392_42',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ricerca Codice Cliente',
          name: 'DM_PROFILE_TABLE392_42',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO348_30 AS DM_PROFILE_TESTO348_30',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Alias',
          name: 'DM_PROFILE_TESTO348_30',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO166_9 AS DM_PROFILE_COMBO166_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Persona Fisica/Giuridica',
          name: 'DM_PROFILE_COMBO166_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO325_9 AS DM_PROFILE_TESTO325_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Cod.Fiscale',
          name: 'DM_PROFILE_TESTO325_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO201_9 AS DM_PROFILE_COMBO201_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Status del rapporto',
          name: 'DM_PROFILE_COMBO201_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO349_30 AS DM_PROFILE_TESTO349_30',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome Contatto',
          name: 'DM_PROFILE_TESTO349_30',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO356_9 AS DM_PROFILE_TESTO356_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Telefono',
          name: 'DM_PROFILE_TESTO356_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO357_9 AS DM_PROFILE_TESTO357_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Cellulare',
          name: 'DM_PROFILE_TESTO357_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO352_30 AS DM_PROFILE_TESTO352_30',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Altra E-Mail',
          name: 'DM_PROFILE_TESTO352_30',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO376_24 AS DM_PROFILE_COMBO376_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Lingua',
          name: 'DM_PROFILE_COMBO376_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI284_17 AS DM_PROFILE_MULTI284_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Trattamenti Coinvolti - Elenco',
          name: 'DM_PROFILE_MULTI284_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO407_44 AS DM_PROFILE_TESTO407_44',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DPO n. di telefono',
          name: 'DM_PROFILE_TESTO407_44',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO355_9 AS DM_PROFILE_COMBO355_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Livello di Sicurezza richiesto',
          name: 'DM_PROFILE_COMBO355_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO319_10 AS DM_PROFILE_COMBO319_10',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tempistiche per la notifica di un data breach',
          name: 'DM_PROFILE_COMBO319_10',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA207_9 AS DM_PROFILE_DATA207_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data ultimo audit',
          name: 'DM_PROFILE_DATA207_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO536_24 AS DM_PROFILE_TESTO536_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'AOO PIVA',
          name: 'DM_PROFILE_TESTO536_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO537_24 AS DM_PROFILE_TESTO537_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'AOO Indirizzo',
          name: 'DM_PROFILE_TESTO537_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO538_24 AS DM_PROFILE_TESTO538_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'AOO CAP',
          name: 'DM_PROFILE_TESTO538_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO539_24 AS DM_PROFILE_COMBO539_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'AOO Provincia',
          name: 'DM_PROFILE_COMBO539_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO532_44 AS DM_PROFILE_COMBO532_44',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ruolo del cliente',
          name: 'DM_PROFILE_COMBO532_44',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO540_24 AS DM_PROFILE_COMBO540_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'AOO Stato',
          name: 'DM_PROFILE_COMBO540_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO541_24 AS DM_PROFILE_TESTO541_24',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'AOO Città',
          name: 'DM_PROFILE_TESTO541_24',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO192_10 AS DM_PROFILE_TESTO192_10',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Codice',
          name: 'DM_PROFILE_TESTO192_10',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO599_44 AS DM_PROFILE_TESTO599_44',
          toCalculate: false,
          index: 0,
          selected: true,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ragione Sociale Cliente',
          name: 'DM_PROFILE_TESTO599_44',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TABLE343_31 AS DM_PROFILE_TABLE343_31',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Cod.Trattamento',
          name: 'DM_PROFILE_TABLE343_31',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO358_31 AS DM_PROFILE_TESTO358_31',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Interessati',
          name: 'DM_PROFILE_TESTO358_31',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO566_39 AS DM_PROFILE_TESTO566_39',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data processing Activity',
          name: 'DM_PROFILE_TESTO566_39',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO567_39 AS DM_PROFILE_TESTO567_39',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Personal Data',
          name: 'DM_PROFILE_TESTO567_39',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO568_39 AS DM_PROFILE_TESTO568_39',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data Subject',
          name: 'DM_PROFILE_TESTO568_39',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO569_39 AS DM_PROFILE_TESTO569_39',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data Retention',
          name: 'DM_PROFILE_TESTO569_39',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO395_46 AS DM_PROFILE_TESTO395_46',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome del software',
          name: 'DM_PROFILE_TESTO395_46',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO403_46 AS DM_PROFILE_COMBO403_46',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Categorie di software',
          name: 'DM_PROFILE_COMBO403_46',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO396_46 AS DM_PROFILE_TESTO396_46',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Scopo del software',
          name: 'DM_PROFILE_TESTO396_46',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO398_47 AS DM_PROFILE_COMBO398_47',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome Sistema',
          name: 'DM_PROFILE_COMBO398_47',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI399_47 AS DM_PROFILE_MULTI399_47',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Software presenti',
          name: 'DM_PROFILE_MULTI399_47',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO453_47 AS DM_PROFILE_TESTO453_47',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Livello medio di sicurezza',
          name: 'DM_PROFILE_TESTO453_47',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO454_47 AS DM_PROFILE_TESTO454_47',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Misure di sicurezza sui software',
          name: 'DM_PROFILE_TESTO454_47',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO455_47 AS DM_PROFILE_TESTO455_47',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Misure di sicurezza sul sistema',
          name: 'DM_PROFILE_TESTO455_47',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO463_47 AS DM_PROFILE_TESTO463_47',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'ID-SISAPP (Servizi)',
          name: 'DM_PROFILE_TESTO463_47',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO409_49 AS DM_PROFILE_TESTO409_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome',
          name: 'DM_PROFILE_TESTO409_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO410_49 AS DM_PROFILE_TESTO410_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Cognome',
          name: 'DM_PROFILE_TESTO410_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO411_49 AS DM_PROFILE_TESTO411_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Luogo di nascita',
          name: 'DM_PROFILE_TESTO411_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA412_49 AS DM_PROFILE_DATA412_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data di nascita',
          name: 'DM_PROFILE_DATA412_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO413_49 AS DM_PROFILE_TESTO413_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Comune',
          name: 'DM_PROFILE_TESTO413_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO161_15 AS DM_PROFILE_COMBO161_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Fonte dei dati',
          name: 'DM_PROFILE_COMBO161_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO322_9 AS DM_PROFILE_TESTO322_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'E-mail/PEC',
          name: 'DM_PROFILE_TESTO322_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO414_49 AS DM_PROFILE_TESTO414_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Precisazioni',
          name: 'DM_PROFILE_TESTO414_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO415_49 AS DM_PROFILE_TESTO415_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Luogo ',
          name: 'DM_PROFILE_TESTO415_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA416_49 AS DM_PROFILE_DATA416_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Richiesta pervenuta in data',
          name: 'DM_PROFILE_DATA416_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO184_15 AS DM_PROFILE_COMBO184_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Trasferimento all\'Estero',
          name: 'DM_PROFILE_COMBO184_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO417_49 AS DM_PROFILE_TESTO417_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Origine dei dati',
          name: 'DM_PROFILE_TESTO417_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO418_49 AS DM_PROFILE_COMBO418_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Processo decisionale automatizzato',
          name: 'DM_PROFILE_COMBO418_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO419_49 AS DM_PROFILE_COMBO419_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Operazione richiesta',
          name: 'DM_PROFILE_COMBO419_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI421_49 AS DM_PROFILE_MULTI421_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Motivazione della richiesta',
          name: 'DM_PROFILE_MULTI421_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO605_49 AS DM_PROFILE_TESTO605_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ulteriori operazioni richieste',
          name: 'DM_PROFILE_TESTO605_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO422_49 AS DM_PROFILE_TESTO422_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Inserire ulteriori dettagli della motivazione',
          name: 'DM_PROFILE_TESTO422_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO423_49 AS DM_PROFILE_TESTO423_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Riferimenti del soggetto al quale verranno\ntrasmessi i dati (in caso di richiesta di \nportabilità)',
          name: 'DM_PROFILE_TESTO423_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO469_49 AS DM_PROFILE_TESTO469_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipologia documento',
          name: 'DM_PROFILE_TESTO469_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO470_49 AS DM_PROFILE_TESTO470_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Estremi del documento',
          name: 'DM_PROFILE_TESTO470_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO471_49 AS DM_PROFILE_TESTO471_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ulteriori dettagli (in caso di processo decisionale automatizzato)',
          name: 'DM_PROFILE_TESTO471_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO474_49 AS DM_PROFILE_TESTO474_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ulteriori dettagli sulla motivazione',
          name: 'DM_PROFILE_TESTO474_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO476_49 AS DM_PROFILE_TESTO476_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ulteriori informazioni richieste',
          name: 'DM_PROFILE_TESTO476_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA477_49 AS DM_PROFILE_DATA477_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data della risposta',
          name: 'DM_PROFILE_DATA477_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI482_49 AS DM_PROFILE_MULTI482_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Motivazioni del diniego',
          name: 'DM_PROFILE_MULTI482_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO483_49 AS DM_PROFILE_COMBO483_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Esistenza dei trattamenti nei confronti dell\'interessato',
          name: 'DM_PROFILE_COMBO483_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO484_49 AS DM_PROFILE_COMBO484_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Approvazione',
          name: 'DM_PROFILE_COMBO484_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO554_49 AS DM_PROFILE_TESTO554_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Descrizione della richiesta',
          name: 'DM_PROFILE_TESTO554_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO341_32 AS DM_PROFILE_TESTO341_32',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Codice Trattamento',
          name: 'DM_PROFILE_TESTO341_32',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO425_50 AS DM_PROFILE_TESTO425_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome del progetto e descrizione',
          name: 'DM_PROFILE_TESTO425_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO145_15 AS DM_PROFILE_TESTO145_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Processo: Owner (Ruolo Aziendale)',
          name: 'DM_PROFILE_TESTO145_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA426_50 AS DM_PROFILE_DATA426_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data di redazione',
          name: 'DM_PROFILE_DATA426_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI155_15 AS DM_PROFILE_MULTI155_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Dati personali trattati',
          name: 'DM_PROFILE_MULTI155_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO427_50 AS DM_PROFILE_TESTO427_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Descrizione del progetto',
          name: 'DM_PROFILE_TESTO427_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO428_50 AS DM_PROFILE_TESTO428_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Modalità di raccolta dati',
          name: 'DM_PROFILE_TESTO428_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO429_50 AS DM_PROFILE_TESTO429_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Modalità di conservazione dati',
          name: 'DM_PROFILE_TESTO429_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI394_32 AS DM_PROFILE_MULTI394_32',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Fonte dei dati',
          name: 'DM_PROFILE_MULTI394_32',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO430_50 AS DM_PROFILE_TESTO430_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Condivisione dei dati',
          name: 'DM_PROFILE_TESTO430_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO431_50 AS DM_PROFILE_TESTO431_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Descrizione rischi per \nl\'interessato',
          name: 'DM_PROFILE_TESTO431_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI432_50 AS DM_PROFILE_MULTI432_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipologia dati raccolti',
          name: 'DM_PROFILE_MULTI432_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO433_50 AS DM_PROFILE_TESTO433_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Frequenza conservazione',
          name: 'DM_PROFILE_TESTO433_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA365_30 AS DM_PROFILE_DATA365_30',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data Inizio Validità',
          name: 'DM_PROFILE_DATA365_30',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA366_30 AS DM_PROFILE_DATA366_30',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data Fine Validità',
          name: 'DM_PROFILE_DATA366_30',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO434_50 AS DM_PROFILE_TESTO434_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Numero individui soggetti al \nmonitoraggio',
          name: 'DM_PROFILE_TESTO434_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO435_50 AS DM_PROFILE_TESTO435_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Area geografica coperta dal \ntrattamento',
          name: 'DM_PROFILE_TESTO435_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI154_15 AS DM_PROFILE_MULTI154_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Interessati del trattamento',
          name: 'DM_PROFILE_MULTI154_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO436_50 AS DM_PROFILE_TESTO436_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Categorie di interessati a rischio',
          name: 'DM_PROFILE_TESTO436_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO437_50 AS DM_PROFILE_TESTO437_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Descrizione del rapporto con gli\ninteressati',
          name: 'DM_PROFILE_TESTO437_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO438_50 AS DM_PROFILE_TESTO438_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Modalità per informare \nl\'interessato',
          name: 'DM_PROFILE_TESTO438_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO439_50 AS DM_PROFILE_COMBO439_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'L\'interessato può disattivare il \nsistema',
          name: 'DM_PROFILE_COMBO439_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO440_50 AS DM_PROFILE_TESTO440_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Se SI, descrivere le modalità',
          name: 'DM_PROFILE_TESTO440_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO441_50 AS DM_PROFILE_TESTO441_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Se NO, descrivere quali modalità\nsono adottate per l\'esercizio dei\ndiritti dell\'interessato',
          name: 'DM_PROFILE_TESTO441_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO442_50 AS DM_PROFILE_TESTO442_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Finalità del trattamento',
          name: 'DM_PROFILE_TESTO442_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO443_50 AS DM_PROFILE_TESTO443_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Obiettivi di business da\nraggiungere',
          name: 'DM_PROFILE_TESTO443_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO444_50 AS DM_PROFILE_TESTO444_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Descrivere come e quando\nvengono consultati gli interessati',
          name: 'DM_PROFILE_TESTO444_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO445_50 AS DM_PROFILE_TESTO445_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Descrizione principio di \nminimizzazione',
          name: 'DM_PROFILE_TESTO445_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO446_50 AS DM_PROFILE_TESTO446_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Come viene fornita l\'informativa?',
          name: 'DM_PROFILE_TESTO446_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO447_50 AS DM_PROFILE_TESTO447_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Misure a supporto degli interessati',
          name: 'DM_PROFILE_TESTO447_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO448_50 AS DM_PROFILE_TESTO448_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Descrivere le modalità di verifica della compliance dei\nResponsabili del trattamento \n(D.P.O.)',
          name: 'DM_PROFILE_TESTO448_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI465_32 AS DM_PROFILE_MULTI465_32',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Sistema utilizzato per il trattamento',
          name: 'DM_PROFILE_MULTI465_32',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO449_50 AS DM_PROFILE_TESTO449_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Analisi dei risultati della DPIA',
          name: 'DM_PROFILE_TESTO449_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO450_50 AS DM_PROFILE_COMBO450_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Considerazioni',
          name: 'DM_PROFILE_COMBO450_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI205_9 AS DM_PROFILE_MULTI205_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Paese di destinazione',
          name: 'DM_PROFILE_MULTI205_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO451_50 AS DM_PROFILE_COMBO451_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Consultazione parti interessate',
          name: 'DM_PROFILE_COMBO451_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI564_39 AS DM_PROFILE_MULTI564_39',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'ID Archivi Cartacei',
          name: 'DM_PROFILE_MULTI564_39',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO466_50 AS DM_PROFILE_TESTO466_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Scopo del trattamento',
          name: 'DM_PROFILE_TESTO466_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO467_50 AS DM_PROFILE_TESTO467_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Obiettivi di business da raggiungere:',
          name: 'DM_PROFILE_TESTO467_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO490_50 AS DM_PROFILE_TESTO490_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Rischio Complessivo Medio',
          name: 'DM_PROFILE_TESTO490_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC494_50 AS DM_PROFILE_NUMERIC494_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Rischio complessivo medio numerico',
          name: 'DM_PROFILE_NUMERIC494_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO544_50 AS DM_PROFILE_TESTO544_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Durata della conservazione',
          name: 'DM_PROFILE_TESTO544_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO598_50 AS DM_PROFILE_COMBO598_50',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DPIA approvata?',
          name: 'DM_PROFILE_COMBO598_50',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO303_20 AS DM_PROFILE_TESTO303_20',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Cognome',
          name: 'DM_PROFILE_TESTO303_20',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO304_20 AS DM_PROFILE_TESTO304_20',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome',
          name: 'DM_PROFILE_TESTO304_20',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI498_51 AS DM_PROFILE_MULTI498_51',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ambito',
          name: 'DM_PROFILE_MULTI498_51',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI499_51 AS DM_PROFILE_MULTI499_51',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ruolo del nominato',
          name: 'DM_PROFILE_MULTI499_51',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA496_20 AS DM_PROFILE_DATA496_20',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data della nomina ',
          name: 'DM_PROFILE_DATA496_20',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO510_20 AS DM_PROFILE_TESTO510_20',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'E-mail',
          name: 'DM_PROFILE_TESTO510_20',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO457_52 AS DM_PROFILE_COMBO457_52',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: '\nProbabilità di danno',
          name: 'DM_PROFILE_COMBO457_52',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO458_52 AS DM_PROFILE_COMBO458_52',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Gravità del danno',
          name: 'DM_PROFILE_COMBO458_52',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC478_52 AS DM_PROFILE_NUMERIC478_52',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Probabilità di danno Numerico',
          name: 'DM_PROFILE_NUMERIC478_52',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC479_52 AS DM_PROFILE_NUMERIC479_52',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Gravità di danno Numerico',
          name: 'DM_PROFILE_NUMERIC479_52',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC480_52 AS DM_PROFILE_NUMERIC480_52',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Rischio complessivo Numerico',
          name: 'DM_PROFILE_NUMERIC480_52',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO481_52 AS DM_PROFILE_TESTO481_52',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Rischio Complessivo',
          name: 'DM_PROFILE_TESTO481_52',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO489_53 AS DM_PROFILE_COMBO489_53',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipo di documento',
          name: 'DM_PROFILE_COMBO489_53',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO500_54 AS DM_PROFILE_COMBO500_54',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Trattamento Condiviso',
          name: 'DM_PROFILE_COMBO500_54',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO462_19 AS DM_PROFILE_TESTO462_19',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ragione sociale Contitolare',
          name: 'DM_PROFILE_TESTO462_19',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TABLE508_56 AS DM_PROFILE_TABLE508_56',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Trattamento autorizzato',
          name: 'DM_PROFILE_TABLE508_56',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO501_54 AS DM_PROFILE_TESTO501_54',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Dati del trattamento',
          name: 'DM_PROFILE_TESTO501_54',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO502_54 AS DM_PROFILE_TESTO502_54',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Interessati dal trattamento',
          name: 'DM_PROFILE_TESTO502_54',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TABLE511_52 AS DM_PROFILE_TABLE511_52',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Rischio',
          name: 'DM_PROFILE_TABLE511_52',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO512_52 AS DM_PROFILE_TESTO512_52',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Codice rischio',
          name: 'DM_PROFILE_TESTO512_52',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI513_57 AS DM_PROFILE_MULTI513_57',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Misure di sicurezza da implementare',
          name: 'DM_PROFILE_MULTI513_57',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO514_57 AS DM_PROFILE_COMBO514_57',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Effetto sul rischio',
          name: 'DM_PROFILE_COMBO514_57',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO515_57 AS DM_PROFILE_COMBO515_57',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Rischio residuo',
          name: 'DM_PROFILE_COMBO515_57',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO516_57 AS DM_PROFILE_COMBO516_57',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Misure approvate',
          name: 'DM_PROFILE_COMBO516_57',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TABLE527_58 AS DM_PROFILE_TABLE527_58',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ragione Sociale Fornitore sub-responsabile',
          name: 'DM_PROFILE_TABLE527_58',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO528_58 AS DM_PROFILE_TESTO528_58',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Trattamenti del Fornitore',
          name: 'DM_PROFILE_TESTO528_58',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO529_58 AS DM_PROFILE_TESTO529_58',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Interessati dai Trattamenti',
          name: 'DM_PROFILE_TESTO529_58',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO530_58 AS DM_PROFILE_TESTO530_58',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Dati dei Trattamenti',
          name: 'DM_PROFILE_TESTO530_58',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC535_59 AS DM_PROFILE_NUMERIC535_59',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Docnumber riferimento trattamento',
          name: 'DM_PROFILE_NUMERIC535_59',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO144_15 AS DM_PROFILE_COMBO144_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Processo',
          name: 'DM_PROFILE_COMBO144_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO335_32 AS DM_PROFILE_TESTO335_32',
          toCalculate: false,
          index: 0,
          selected: true,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome del Trattamento',
          name: 'DM_PROFILE_TESTO335_32',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO149_15 AS DM_PROFILE_COMBO149_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Legittimo Interesse',
          name: 'DM_PROFILE_COMBO149_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO157_15 AS DM_PROFILE_COMBO157_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Criticità dato',
          name: 'DM_PROFILE_COMBO157_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO150_15 AS DM_PROFILE_COMBO150_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Modalità di raccolta del consenso',
          name: 'DM_PROFILE_COMBO150_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO152_15 AS DM_PROFILE_COMBO152_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Criticità Finalità',
          name: 'DM_PROFILE_COMBO152_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO547_59 AS DM_PROFILE_TESTO547_59',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Fonte dei dati',
          name: 'DM_PROFILE_TESTO547_59',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO162_15 AS DM_PROFILE_COMBO162_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Formato',
          name: 'DM_PROFILE_COMBO162_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO163_15 AS DM_PROFILE_COMBO163_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Periodo di conservazione',
          name: 'DM_PROFILE_COMBO163_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO171_15 AS DM_PROFILE_COMBO171_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Cancellazione: Automatica',
          name: 'DM_PROFILE_COMBO171_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO165_15 AS DM_PROFILE_TESTO165_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Cancellazione: Descrizione Modalità',
          name: 'DM_PROFILE_TESTO165_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO186_15 AS DM_PROFILE_COMBO186_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipo Garanzia se il Paese è extra UE',
          name: 'DM_PROFILE_COMBO186_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO556_65 AS DM_PROFILE_TESTO556_65',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Identificativo Archivio',
          name: 'DM_PROFILE_TESTO556_65',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO557_65 AS DM_PROFILE_COMBO557_65',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Location',
          name: 'DM_PROFILE_COMBO557_65',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO558_65 AS DM_PROFILE_TESTO558_65',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Sede di (Se Interno):',
          name: 'DM_PROFILE_TESTO558_65',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO559_65 AS DM_PROFILE_TESTO559_65',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Piano (Se Interno):',
          name: 'DM_PROFILE_TESTO559_65',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO560_65 AS DM_PROFILE_TESTO560_65',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ufficio (Se Interno):',
          name: 'DM_PROFILE_TESTO560_65',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TABLE561_65 AS DM_PROFILE_TABLE561_65',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Fornitore (Se Esterno):',
          name: 'DM_PROFILE_TABLE561_65',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI563_65 AS DM_PROFILE_MULTI563_65',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Rischi',
          name: 'DM_PROFILE_MULTI563_65',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO406_46 AS DM_PROFILE_TESTO406_46',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Livello di sicurezza applicato',
          name: 'DM_PROFILE_TESTO406_46',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC486_46 AS DM_PROFILE_NUMERIC486_46',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Numero misure di sicurezza',
          name: 'DM_PROFILE_NUMERIC486_46',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC488_46 AS DM_PROFILE_NUMERIC488_46',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Livello sicurezza reale',
          name: 'DM_PROFILE_NUMERIC488_46',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI404_46 AS DM_PROFILE_MULTI404_46',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Codice misure di sicurezza implementate',
          name: 'DM_PROFILE_MULTI404_46',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO495_46 AS DM_PROFILE_TESTO495_46',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Misure di sicurezza applicate:',
          name: 'DM_PROFILE_TESTO495_46',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI571_66 AS DM_PROFILE_MULTI571_66',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Certificazioni applicate',
          name: 'DM_PROFILE_MULTI571_66',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA239_17 AS DM_PROFILE_DATA239_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data di rilevazione dell\'evento',
          name: 'DM_PROFILE_DATA239_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO305_17 AS DM_PROFILE_COMBO305_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ora di rilevazione dell\'evento',
          name: 'DM_PROFILE_COMBO305_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO278_17 AS DM_PROFILE_TESTO278_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Modalità con la quale si è venuti a conoscenza della violazione',
          name: 'DM_PROFILE_TESTO278_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO241_17 AS DM_PROFILE_TESTO241_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Breve descrizione della violazione',
          name: 'DM_PROFILE_TESTO241_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO576_68 AS DM_PROFILE_COMBO576_68',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'E\' un data breach?',
          name: 'DM_PROFILE_COMBO576_68',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO408_49 AS DM_PROFILE_COMBO408_49',
          toCalculate: false,
          index: 0,
          selected: true,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ragione Sociale AOO',
          name: 'DM_PROFILE_COMBO408_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO577_68 AS DM_PROFILE_TESTO577_68',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Trattamenti Registro Responsabile',
          name: 'DM_PROFILE_TESTO577_68',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO578_68 AS DM_PROFILE_TESTO578_68',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Trattamenti Registro Titolare',
          name: 'DM_PROFILE_TESTO578_68',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO233_9 AS DM_PROFILE_TESTO233_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Partita Iva',
          name: 'DM_PROFILE_TESTO233_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO196_9 AS DM_PROFILE_TESTO196_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Indirizzo',
          name: 'DM_PROFILE_TESTO196_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO276_9 AS DM_PROFILE_TESTO276_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Cap',
          name: 'DM_PROFILE_TESTO276_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO198_9 AS DM_PROFILE_TESTO198_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Città',
          name: 'DM_PROFILE_TESTO198_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO302_9 AS DM_PROFILE_COMBO302_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Provincia',
          name: 'DM_PROFILE_COMBO302_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO277_9 AS DM_PROFILE_COMBO277_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Stato',
          name: 'DM_PROFILE_COMBO277_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO281_17 AS DM_PROFILE_COMBO281_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: '*La violazione coinvolge interessati di altri Paesi dello Spazio Economico Europeo?',
          name: 'DM_PROFILE_COMBO281_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO596_64 AS DM_PROFILE_TESTO596_64',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Email',
          name: 'DM_PROFILE_TESTO596_64',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI581_69 AS DM_PROFILE_MULTI581_69',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Natura della violazione',
          name: 'DM_PROFILE_MULTI581_69',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO248_17 AS DM_PROFILE_COMBO248_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Causa della violazione',
          name: 'DM_PROFILE_COMBO248_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI249_17 AS DM_PROFILE_MULTI249_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Possibili conseguenze della violazione - In caso di perdita di confidenzialità:',
          name: 'DM_PROFILE_MULTI249_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO582_69 AS DM_PROFILE_TESTO582_69',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Possibili conseguenze della violazione - In caso di perdita di confidenzialità: Altro',
          name: 'DM_PROFILE_TESTO582_69',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI274_17 AS DM_PROFILE_MULTI274_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Possibili conseguenze della violazione - In caso di perdita di integrità:',
          name: 'DM_PROFILE_MULTI274_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO583_69 AS DM_PROFILE_TESTO583_69',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Possibili conseguenze della violazione - In caso di perdita di integrità: Altro:',
          name: 'DM_PROFILE_TESTO583_69',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI275_17 AS DM_PROFILE_MULTI275_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Possibili conseguenze della violazione - In caso di perdita di disponibilità: ',
          name: 'DM_PROFILE_MULTI275_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO584_69 AS DM_PROFILE_TESTO584_69',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Possibili conseguenze della violazione - In caso di perdita di disponibilità: Altro:',
          name: 'DM_PROFILE_TESTO584_69',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO243_17 AS DM_PROFILE_TESTO243_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ulteriori considerazioni sulle possibili conseguenze',
          name: 'DM_PROFILE_TESTO243_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI252_17 AS DM_PROFILE_MULTI252_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Danno',
          name: 'DM_PROFILE_MULTI252_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO585_69 AS DM_PROFILE_COMBO585_69',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Gravità della violazione',
          name: 'DM_PROFILE_COMBO585_69',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI253_17 AS DM_PROFILE_MULTI253_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Potenziali effetti negativi per gli interessati',
          name: 'DM_PROFILE_MULTI253_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO254_17 AS DM_PROFILE_TESTO254_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Misure tecniche e organizzative per prevenire violazioni future',
          name: 'DM_PROFILE_TESTO254_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO255_17 AS DM_PROFILE_COMBO255_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Comunicazione al Garante',
          name: 'DM_PROFILE_COMBO255_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO257_17 AS DM_PROFILE_COMBO257_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Comunico la violazione agli interessati?',
          name: 'DM_PROFILE_COMBO257_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO279_17 AS DM_PROFILE_TESTO279_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Non sarà comunicata perché: ',
          name: 'DM_PROFILE_TESTO279_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO258_17 AS DM_PROFILE_COMBO258_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'La violazione è stata comunicata agli interessati?',
          name: 'DM_PROFILE_COMBO258_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC259_17 AS DM_PROFILE_NUMERIC259_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Numero di interessati a cui è stata comunicata la violazione',
          name: 'DM_PROFILE_NUMERIC259_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO260_17 AS DM_PROFILE_TESTO260_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Canale utilizzato per la comunicazione ',
          name: 'DM_PROFILE_TESTO260_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.DATA261_17 AS DM_PROFILE_DATA261_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Data violazione',
          name: 'DM_PROFILE_DATA261_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO265_17 AS DM_PROFILE_TESTO265_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ulteriori informazioni circa le date in cui è avvenuta la violazione',
          name: 'DM_PROFILE_TESTO265_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO266_17 AS DM_PROFILE_TESTO266_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'In caso di notifica oltre le 72 ore, quali sono i motivi del ritardo?',
          name: 'DM_PROFILE_TESTO266_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO267_17 AS DM_PROFILE_COMBO267_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'La violazione è stata notificata ad altre autorità di controllo?',
          name: 'DM_PROFILE_COMBO267_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO268_17 AS DM_PROFILE_COMBO268_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'La violazione è stata notificata ad altri organismi di vigilanza o di controllo in virtù di ulteriori disposizioni normative?',
          name: 'DM_PROFILE_COMBO268_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO269_17 AS DM_PROFILE_COMBO269_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'E’ stata effettuata una segnalazione all’autorità giudiziaria o di polizia?',
          name: 'DM_PROFILE_COMBO269_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO280_17 AS DM_PROFILE_TESTO280_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Numero notifica fascicolo',
          name: 'DM_PROFILE_TESTO280_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.MULTI587_69 AS DM_PROFILE_MULTI587_69',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipo di violazione',
          name: 'DM_PROFILE_MULTI587_69',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO590_69 AS DM_PROFILE_TESTO590_69',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome',
          name: 'DM_PROFILE_TESTO590_69',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO591_69 AS DM_PROFILE_TESTO591_69',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Cognome',
          name: 'DM_PROFILE_TESTO591_69',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO592_69 AS DM_PROFILE_TESTO592_69',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'E-amil',
          name: 'DM_PROFILE_TESTO592_69',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO593_69 AS DM_PROFILE_TESTO593_69',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'recapito telefonico',
          name: 'DM_PROFILE_TESTO593_69',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO594_69 AS DM_PROFILE_TESTO594_69',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Funzione rivestita',
          name: 'DM_PROFILE_TESTO594_69',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO595_69 AS DM_PROFILE_TESTO595_69',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Numero di protocollo del DPO',
          name: 'DM_PROFILE_TESTO595_69',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO273_15 AS DM_PROFILE_TESTO273_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Impatto',
          name: 'DM_PROFILE_TESTO273_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TABLE555_49 AS DM_PROFILE_TABLE555_49',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nome del Trattamento',
          name: 'DM_PROFILE_TABLE555_49',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO549_59 AS DM_PROFILE_TESTO549_59',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Paese di destinazione',
          name: 'DM_PROFILE_TESTO549_59',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO550_61 AS DM_PROFILE_TESTO550_61',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Responsabili del trattamento (sub-resp)',
          name: 'DM_PROFILE_TESTO550_61',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO551_60 AS DM_PROFILE_TESTO551_60',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Responsabili del trattamento',
          name: 'DM_PROFILE_TESTO551_60',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO552_60 AS DM_PROFILE_TESTO552_60',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Contitolari',
          name: 'DM_PROFILE_TESTO552_60',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO586_61 AS DM_PROFILE_TESTO586_61',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Titolari del Trattamento',
          name: 'DM_PROFILE_TESTO586_61',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.NUMERIC588_71 AS DM_PROFILE_NUMERIC588_71',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Docnumber registro',
          name: 'DM_PROFILE_NUMERIC588_71',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO572_70 AS DM_PROFILE_COMBO572_70',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'La violazione riguarda la tua area?',
          name: 'DM_PROFILE_COMBO572_70',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO573_70 AS DM_PROFILE_TESTO573_70',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Informazioni preliminari',
          name: 'DM_PROFILE_TESTO573_70',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO589_75 AS DM_PROFILE_TESTO589_75',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Titolare del Trattamento',
          name: 'DM_PROFILE_TESTO589_75',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO542_59 AS DM_PROFILE_TESTO542_59',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Interessati dal Trattamenti',
          name: 'DM_PROFILE_TESTO542_59',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO543_59 AS DM_PROFILE_TESTO543_59',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Dati del Trattamento',
          name: 'DM_PROFILE_TESTO543_59',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO282_17 AS DM_PROFILE_COMBO282_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: '*La violazione coinvolge interessati di Paesi non appartenenti allo SpazioEconomico Europeo?',
          name: 'DM_PROFILE_COMBO282_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO283_17 AS DM_PROFILE_TESTO283_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: '*Ulteriori dettagli circa le categorie di interessati',
          name: 'DM_PROFILE_TESTO283_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO285_17 AS DM_PROFILE_COMBO285_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: '*Ruolo ricoperto dall\'azienda',
          name: 'DM_PROFILE_COMBO285_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO548_59 AS DM_PROFILE_TESTO548_59',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Sistemi utilizzati',
          name: 'DM_PROFILE_TESTO548_59',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO565_59 AS DM_PROFILE_TESTO565_59',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Archivi Cartacei',
          name: 'DM_PROFILE_TESTO565_59',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO579_69 AS DM_PROFILE_TESTO579_69',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Misure di sicurezza adottate sui sistemi',
          name: 'DM_PROFILE_TESTO579_69',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO580_69 AS DM_PROFILE_TESTO580_69',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Misure di sicurezza adottate per l\'archivio cartaceo',
          name: 'DM_PROFILE_TESTO580_69',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO242_17 AS DM_PROFILE_TESTO242_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Descrizione dell\'incidente di sicurezza alla base della violazione',
          name: 'DM_PROFILE_TESTO242_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO245_17 AS DM_PROFILE_COMBO245_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Volume dei dati personali oggetto di violazione',
          name: 'DM_PROFILE_COMBO245_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO246_17 AS DM_PROFILE_COMBO246_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Numero (anche approssimativo) di interessati coinvolti nella violazione',
          name: 'DM_PROFILE_COMBO246_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO244_17 AS DM_PROFILE_TESTO244_17',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Misure tecniche e organizzative adottate per porre rimedio alla violazione',
          name: 'DM_PROFILE_TESTO244_17',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO227_10 AS DM_PROFILE_TESTO227_10',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DPO Ragione Sociale o Nome e Cognome',
          name: 'DM_PROFILE_TESTO227_10',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO230_10 AS DM_PROFILE_TESTO230_10',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'DPO E-mail',
          name: 'DM_PROFILE_TESTO230_10',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO143_15 AS DM_PROFILE_COMBO143_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Area',
          name: 'DM_PROFILE_COMBO143_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TABLE600_76 AS DM_PROFILE_TABLE600_76',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Utente',
          name: 'DM_PROFILE_TABLE600_76',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO601_76 AS DM_PROFILE_COMBO601_76',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Tipologia di Utente per Task',
          name: 'DM_PROFILE_COMBO601_76',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TABLE606_77 AS DM_PROFILE_TABLE606_77',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Trattamento',
          name: 'DM_PROFILE_TABLE606_77',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO336_32 AS DM_PROFILE_COMBO336_32',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ruolo dell\'AOO',
          name: 'DM_PROFILE_COMBO336_32',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO147_15 AS DM_PROFILE_TESTO147_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Trattamento: Descrizione Breve',
          name: 'DM_PROFILE_TESTO147_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO148_15 AS DM_PROFILE_COMBO148_15',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Base giuridica',
          name: 'DM_PROFILE_COMBO148_15',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.TESTO359_31 AS DM_PROFILE_TESTO359_31',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Dati',
          name: 'DM_PROFILE_TESTO359_31',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        },
        {
          sqlSelectField: 'DM_PROFILE.COMBO204_9 AS DM_PROFILE_COMBO204_9',
          toCalculate: false,
          index: 0,
          selected: false,
          fieldType: 2,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Trasferimento dati Extra AEE?',
          name: 'DM_PROFILE_COMBO204_9',
          userSelectionEnabled: true,
          userSelectionGroup: 2
        }
      ],
      maxItems: 0
    }
  } as unknown as JSON;

}
