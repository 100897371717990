<style>
  .material-symbols-outlined {
    font-variation-settings: 'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
  }
</style>

<ng-template
  #monthCellTemplate
  let-day="day"
  let-openDay="openDay"
  let-locale="locale"
  let-tooltipPlacement="tooltipPlacement"
  let-highlightDay="highlightDay"
  let-unhighlightDay="unhighlightDay"
  let-eventClicked="eventClicked"
>
  <div
    class="fill-height"
    [contextMenu]="basicMenu"
    [contextMenuSubject]="day.date"
  >
    <div class="cal-cell-top" style="text-align: left !important;">

      <!--<div style="width: 100%; display: flex" *ngIf="this.isEventSetForUser(day.date)">
        <mat-icon>done</mat-icon>
        <span style="margin: 2%">{{this.getEventType(day.date)}}</span>
      </div>-->
      <hr style="color: {{this.apiService.dashColor}}; margin: 0">
      <div style="width:100%; text-align: left" *ngIf = "false">
        <span *ngIf="this.getNumInterventi(day.events) > 0" style="margin: 2%"
        >Interventi: {{ this.getNumInterventi(day.events) }}</span
        ></div>


      <span class="cal-day-number"
      >{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span
      >

    </div>

    <div class="cal-events">
      <ng-container *ngFor="let event of day.events">
        <div
          *ngIf="event.id == 'schedulazione' && this.hideFeriePermessiVar == true  && (this.selectedTecnico == '' || event.tecnico == this.selectedTecnico)"
          class="cal-event"
          style="background-color: {{event.extraColor}} !important;
             border-color: {{event.extraColor}} !important;
             color: #fff !important;" matTooltipPosition = "above"
          (mouseenter)="highlightDay.emit({event: event})" [matTooltipShowDelay]="0"
          (mouseleave)="unhighlightDay.emit({event: event})" matTooltip = "Inizio: {{this.TransformDataFormatToShow(event.start)}} Fine: {{this.TransformDataFormatToShow(event.end)}} - {{event.hourNumber}} Ore"
          (click)="$event.stopPropagation(); eventClicked.emit({event: event, sourceEvent: day})"
        ><h4 style = "text-align: left;" class = "h4event">{{event.title}}</h4></div>
      </ng-container>
      <div style="width: 100%"></div>
      <ng-container *ngFor="let event of day.events">
        <div
          *ngIf="event.id == 'ferie' && this.hideFeriePermessiVar == false && (this.selectedTecnico == '' || event.tecnico == this.selectedTecnico)"
          class="cal-event"
          style="background-color: {{event.extraColor}} !important; border-radius: 0% !important; text-align: left;
             border-color: {{event.extraColor}} !important;
             color: #fff !important;"  matTooltipPosition = "above"
          (mouseenter)="highlightDay.emit({event: event})"
          (mouseleave)="unhighlightDay.emit({event: event})"
          matTooltip = "{{event.title}}" [matTooltipShowDelay]="0"
          (click)="$event.stopPropagation(); eventClicked.emit({event: event})"
        ><h4 style = "text-align: left;" class = "h4event">{{event.title}}</h4><p *ngIf="event.hour" style="text-align: left; margin-top: 5px; margin-bottom: 0px">
          Ora: {{event.hour}}</p></div>
      </ng-container>

    </div>
  </div>
</ng-template>


<context-menu #basicMenu style="width: 100% !important;" (open)="this.setDayOpened($event)">
  <ng-template contextMenuItem (execute)="this.addIntervent()" passive="true">
    Data checklist: {{this.TransformDataFormatToShow(this.clickedDate)}}
  </ng-template>
  <ng-template contextMenuItem (execute)="this.addIntervent()">
    <button mat-button style="width: 100%;color: {{this.apiService.dashColor}}"> Aggiungi checklist</button>
  </ng-template>
</context-menu>

<div class="center">
  <mat-card class="cardcomponentMasks" id="style-4">
    <h3 class="titles" style="color:{{this.apiService.dashColor}}; text-align: left">

      <mat-card-title>

        <p>
          <button mat-icon-button [matMenuTriggerFor]="menu" style=" width: 30px;">
            <mat-icon>settings</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="this.hideFeriePermessi()">
              <mat-icon *ngIf="!this.hideFeriePermessiVar">visibility_off</mat-icon>
              <mat-icon *ngIf="this.hideFeriePermessiVar">visibility</mat-icon>
              <span *ngIf="!this.hideFeriePermessiVar">Nascondi ferie/permessi</span>
              <span *ngIf="this.hideFeriePermessiVar">Mostra ferie/permessi</span>
            </button>

          </mat-menu>
          <b>Checklist progetto:</b></p>

      </mat-card-title>
    </h3>
    <!--<hr class="riga" style="color:{{this.apiService.dashColor}}; margin-bottom: 1% !important">-->

    <mat-card-content><!--hai cliccato la maschera con id: {{this.id}}-->
      <div style="heigth: 5%; width: 100%; display: flex; margin-bottom: 1%; flex-wrap: wrap;" *ngIf="this.hideFeriePermessiVar == true">
        <ng-container *ngFor="let tec of this.dataLegenda">
          <div style="display: flex; ">
            <button mat-button (click)="this.showTecnico(tec.nome)">
              <mat-icon class="material-symbols-outlined" style="color: {{tec.colore}}; width: 30px;">
                circle
              </mat-icon>
              <span style="width: fit-content; margin: auto;"><b
                *ngIf="this.selectedTecnico.toLowerCase() == tec.nome.toLowerCase()">{{tec.nome}}</b><span
                *ngIf="this.selectedTecnico.toLowerCase() != tec.nome.toLowerCase()">{{tec.nome}}</span></span>
            </button>
          </div>
        </ng-container>
      </div>
      <div *ngIf = "this.selectedTecnico != ''" style = "width: 100%; text-align:left">
        <b style = "color: {{this.apiService.dashColor}}">{{this.selectedTecnico}} impegno totale: {{this.impegnoTotale}} ore</b>
      </div>
      <hr class="riga" style="color:{{this.apiService.dashColor}};">
      <div style="display: flex">
        <div style="width: 40%; text-align: left">
          <h1>{{ viewDate | calendarDate:(view + 'ViewTitle'):'it' }}</h1>
        </div>

        <div style="width: 60%; text-align: right">
          <div class="btn btn-primary"
               style="background-color: {{this.apiService.dashColor}}; border-color: {{this.apiService.dashColor}}"
               mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate">
            Indietro
          </div>
          <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
            Oggi
          </div>
          <div class="btn btn-primary"
               style="background-color: {{this.apiService.dashColor}}; border-color: {{this.apiService.dashColor}}"
               mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">
            Avanti
          </div>
        </div>


      </div>
      <mwl-calendar-month-view
        id="calendario"
        [viewDate]="viewDate"
        (dayClicked)="cellClicked($event)"
        (eventClicked)="this.eventClick($event)"
        [events]="events"
        [refresh]="refresh"
        [locale]="locale"
        [weekStartsOn]="weekStartsOn"
        [cellTemplate]="monthCellTemplate"
        [weekendDays]="weekendDays">
      </mwl-calendar-month-view>
    </mat-card-content>
    <div style="text-align: left;">

    </div>

  </mat-card>
</div>

