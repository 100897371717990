<div class="center">
  <mat-card class="cardcomponentMasks" id="style-4">
    <mat-card-content>
      <mat-tab-group style="width: 100%; " dynamicHeight mat-align-tabs="center">
        <mat-tab label="Stile"><br>
          <h2 style="color: {{this.apiService.dashColor}}">Scegliere lo stile</h2>
          <button mat-icon-button style="color: {{this.apiService.dashColor}}" (click)="openAddColorDialog()">
            <mat-icon>add</mat-icon>
          </button>
          <mat-form-field>
            <mat-label>Colori</mat-label>
            <mat-select [(ngModel)]="selectedOption">
              <mat-option *ngFor="let dato of dati" [value]="dato.id" [id]="dato.id">
                {{dato.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-icon-button style="color: {{this.apiService.dashColor}}" (click)="deleteTheme()">
            <mat-icon>delete</mat-icon>
          </button>
          <ng-container *ngIf="this.user.toLowerCase() == 'admin'">
            <br>
            <h2 style="color: {{this.apiService.dashColor}}">Scegliere logo</h2>
            <button type="button" mat-raised-button (click)="fileInput.click()"
                    style="color: {{this.apiService.squareColor}}">Inserire logo
            </button>
            <input type="file" (change)="fileChangeEvent($event)" hidden #fileInput id="fileUpload"
                   name="fileUpload"
                   multiple="multiple" accept="*"/><br><br>
            <h3>{{this.fileinserito}}</h3>

          </ng-container>
          <hr>
          <br>
          <button mat-stroked-button (click)="onClick()" style="color: black">Salva</button>
        </mat-tab>

        <mat-tab label="Layout" *ngIf="isSystem"><br>
          <h2 style="color: {{this.apiService.dashColor}}">Voci menu attive:</h2>
          <div style="text-align: center; display: flex; justify-content: center">
            <mat-chip-list aria-label="Menu" *ngFor="let chip of this.details">
              <mat-chip *ngIf="chip.active">{{chip.label}}
                <mat-icon matChipRemove (click)="remove(chip)">
                  cancel
                </mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>
          <h2 style="margin-top: 4%;color: {{this.apiService.dashColor}}">Voci menu da aggiungere:</h2>
          <div style="text-align: center; display: flex; justify-content: center">
            <mat-chip-list aria-label="Menu" *ngFor="let chip of this.details">
              <mat-chip *ngIf="!chip.active">{{chip.label}}
                <mat-icon matChipRemove (click)="add(chip)">
                  add
                </mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>
          <br><br>
          <button mat-stroked-button (click)="saveMenu()" style="color: black">Salva</button>

        </mat-tab>
        <!--<mat-tab label="Filtri"><br>
            <p style="color: {{this.apiService.dashColor}}">Filtri maschere, viste coming soon...</p>
        </mat-tab>
        <mat-tab label="Voci menu aggiuntive"><br>
            <div style = "width: 100%; text-align: center; color: {{this.apiService.dashColor}};">
                <h2>Aggiungi una voce di menu personalizzata</h2>
            </div>
            <div style="width: 100%; text-align: center">
                <mat-form-field appearance="outline" style="width: 60%">
                    <mat-label>Nome voce menu</mat-label>
                    <input ngModel
                           [(ngModel)]="this.menuLabel"
                           maxlength="50" matInput
                           placeholder="Inserire la voce di menu">
                </mat-form-field>
                <mat-form-field appearance="outline" style="width: 60%">
                    <mat-label>Corpo menu</mat-label>
                    <textarea ngModel
                              [(ngModel)]="this.menuBody"
                              maxlength="10000" matInput rows = "8"
                              placeholder="Inserire il codice del corpo"></textarea>
                </mat-form-field>

            </div>
            <div style = "width: 100%">
                <button mat-stroked-button  style="color: black">Salva</button>
            </div>
        </mat-tab>-->
      </mat-tab-group>

    </mat-card-content>
  </mat-card>
</div>
