import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../api.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {
  isOn: boolean;
  img: string;
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;

  constructor(private http: HttpClient, public apiService: ApiService) {
  } // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!api public!!!!!!!!!!!!!!!!!!!!!!!!!!!

  ngOnInit(): void {
    this.apiService.getLogo().subscribe(data => {
        this.img = 'data:image/png;base64,' + data[0].logo;
        /*this.http.get('assets/Assets.json').subscribe(logo => {
          let jsonData: any;
          jsonData = logo;
          this.img = jsonData.LOGO;
        });*/
      }
    );
    this.apiService.getBetUserSetting().subscribe(data => {
      this.dashColor = data[0].dash_color;
      this.backColor = data[0].back_color;
      this.squareColor = data[0].square_color;
      this.cardColor = data[0].card_color;
      this.apiService.dashColor = this.dashColor;
      this.apiService.backColor = this.backColor;
      this.apiService.squareColor = this.squareColor;
      this.apiService.cardColor = this.cardColor;
      }
    );
  }

}
