<div class="center">
  <mat-card class="cardcomponentMasks" id="style-4">
    <!--<button style="float:left" title="Indietro" style="color: {{this.dashColor}}" (click)="goBack()" mat-icon-button
            aria-label="icon-button send">
        <mat-icon>keyboard_backspace</mat-icon>
    </button>-->
    <mat-form-field style="width: 50%; float: left">
      <mat-label>Filtra</mat-label>
      <input matInput [(ngModel)]="this.filter" (ngModelChange)="this.filterChange()">
      <button mat-button matSuffix mat-icon-button aria-label="Filtri" style="color: {{this.dashColor}}"
              (click)="openFilterDialog()">
        <mat-icon>filter_list</mat-icon>
      </button>
      <button mat-button *ngIf="this.filter" style="color: {{this.dashColor}}" matSuffix mat-icon-button
              aria-label="Cancella" (click)="this.filter=''; this.filterChange()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <button mat-stroked-button title="Annulla selezione" *ngIf="numSelected > 0" (click)="this.cancelSelection()"
            style="float: left; margin-left: 2%; color: {{this.dashColor}}">
      Annulla selezione({{this.numSelected}})
    </button>
    <!--<button mat-icon-button title="Aggiorna" (click)="this.refreshView()" style="float: right">
      <mat-icon>autorenew</mat-icon>
    </button>-->
    <div style="display: flex; justify-content: flex-end;">
      <div>
        <mat-button-toggle-group #group="matButtonToggleGroup">
          <mat-button-toggle value="Figli" aria-label="Figli" [checked]="true" (click)="this.childClick()">
            <mat-icon>south</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="Padri" aria-label="Padri" (click)="this.sonClick()">
            <mat-icon>north</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="Raggruppamento per classe" aria-label="Raggruppamento per classe"
                             (click)="this.classClick()">
            <mat-icon>format_list_bulleted</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>


    <br>
    <hr class="riga" style="color: {{this.dashColor}}; width: 100%">
    <br>
    <mat-card-content style="height: 88%;">
      <ng-container *ngIf="noItems == false">
        <div class="example-container mat-elevation-z8 " id="style-4">

          <table mat-table [dataSource]="dataSource" style="text-align: start"
                 cdkDropList
                 cdkDropListOrientation="horizontal"
                 (cdkDropListDropped)="drop($event)"
                 class="mat-elevation-z8">

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->

            <ng-container *ngFor="let colonna of displayedColumns" matColumnDef="{{colonna}}">

              <ng-container>
                <ng-container
                  *ngIf="colonna != 'expand' && colonna != 'RELAZIONI,Relazioni' && colonna != '-1,Azioni' && colonna != 'INOUT,Origine' && colonna != 'NOTE,Note' && colonna != 'WORKFLOW,Workflow' && colonna != 'ALLEGATI,Allegati' && colonna != 'STATO,Stato' && colonna != 'FORMATO,Formato' && colonna != 'select' && colonna != 'selected'">
                  <ng-container *ngIf="colonna != 'DOCNUMBER,System ID'">
                    <th mat-header-cell style="background-color: white;" class="headers"
                        cdkDrag
                        *matHeaderCellDef>{{(colonna.substr(colonna.indexOf(',') + 1, colonna.length))}}</th>
                  </ng-container>
                  <ng-container *ngIf="colonna == 'DOCNUMBER,System ID'">
                    <th mat-header-cell style="background-color: white; width: 0px; z-index: -100;"
                        cdkDrag
                        *matHeaderCellDef>{{(colonna.substr(colonna.indexOf(',') + 1, colonna.length))}}</th>
                  </ng-container>
                </ng-container>
                <ng-container
                  *ngIf="colonna == 'RELAZIONI,Relazioni' || colonna == '-1,Azioni' || colonna == 'INOUT,Origine' || colonna == 'NOTE,Note' || colonna == 'WORKFLOW,Workflow' || colonna == 'ALLEGATI,Allegati' || colonna == 'STATO,Stato' || colonna == 'FORMATO,Formato'">
                  <th mat-header-cell class="headers"
                      style="width: 65px !important; background-color: white;"
                      cdkDrag *matHeaderCellDef>
                    <ng-container
                      *ngIf="colonna != 'expand' && colonna != 'INOUT,Origine' && colonna != 'NOTE,Note' && colonna != 'WORKFLOW,Workflow' && colonna != 'ALLEGATI,Allegati' && colonna != 'FORMATO,Formato' && colonna != 'STATO,Stato' && colonna != 'select'">{{(colonna.substr(colonna.indexOf(',') + 1, colonna.length))}}</ng-container>

                    <ng-container *ngIf="colonna == 'FORMATO,Formato'">
                      <mat-icon title="Documento">picture_as_pdf</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="colonna == 'STATO,Stato'">
                      <mat-icon title="Stato">clear</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="colonna == 'ALLEGATI,Allegati'">
                      <mat-icon title="Allegati">attachment</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="colonna == 'WORKFLOW,Workflow'">
                      <mat-icon title="Workflow">engineering</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="colonna == 'NOTE,Note'">
                      <mat-icon title="Note">note</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="colonna == 'INOUT,Origine'">
                      <mat-icon title="Origine">call_made</mat-icon>
                    </ng-container>
                  </th>
                </ng-container>
                <ng-container *ngIf="colonna == 'expand'">
                  <th mat-header-cell class="headers"
                      style="width: 85px !important; background-color: white;"
                      cdkDrag *matHeaderCellDef>
                    <ng-container *ngIf="colonna == 'expand'">
                      <button mat-mini-fab (click)="generalExpandClicked()"  style="float: left; color: black">
                        <mat-icon *ngIf="this.generalExpanded" title="Espandi">expand_more</mat-icon>
                        <mat-icon *ngIf="!this.generalExpanded" title="Espandi">expand_less</mat-icon>
                      </button>
                    </ng-container>
                  </th>
                </ng-container>
                <ng-container
                  *ngIf="colonna != 'expand' && colonna != 'RELAZIONI,Relazioni' && colonna != '-1,Azioni' && colonna != 'INOUT,Origine' && colonna != 'NOTE,Note' && colonna != 'WORKFLOW,Workflow' && colonna != 'ALLEGATI,Allegati' && colonna != 'STATO,Stato' && colonna != 'FORMATO,Formato' && colonna != 'select'">
                  <td mat-cell *matCellDef="let element" style="text-align: center"
                      (contextmenu)="open($event, element, element['DOCNUMBER,System ID']); $event. preventDefault();">
                    <ng-container *ngIf="(colonna.startsWith('-1'))">
                      <button mat-icon-button [matMenuTriggerFor]="menu"
                              title="Azioni del profilo">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item>
                          <mat-icon>content_copy</mat-icon>
                          <span>Copia</span>
                        </button>
                        <hr>
                        <button mat-menu-item
                                (click)="goToProfile(element['DOCNUMBER,System ID'])">
                          <mat-icon>subject</mat-icon>
                          <span>Profilo</span>
                        </button>
                        <button mat-menu-item>
                          <mat-icon>clear</mat-icon>
                          <span>Elimina documento</span>
                        </button>
                        <button mat-menu-item>
                          <mat-icon>input</mat-icon>
                          <span>CheckIn</span>
                        </button>
                        <button mat-menu-item
                                (click)="downloadDoc(element['DOCNUMBER,System ID'])">
                          <mat-icon>launch</mat-icon>
                          <span>Apri</span>
                        </button>
                        <button mat-menu-item
                                (click)="exportToCSV(element['DOCNUMBER,System ID'])">
                          <mat-icon>call_made</mat-icon>
                          <span>Esporta csv</span>
                        </button>
                        <button mat-menu-item [matMenuTriggerFor]="modifica"
                                style="color: black">
                          <mat-icon>create</mat-icon>
                          <span>Modifica documento</span>
                        </button>
                        <mat-menu #modifica="matMenu">
                          <button mat-menu-item
                                  (click)="openEditFileDialog(element['DOCNUMBER,System ID'])"
                                  style="color: black">
                            <mat-icon>attach_file</mat-icon>
                            <span>Da file</span>
                          </button>
                          <button mat-menu-item
                                  (click)="openEditRevisionDialog(element['DOCNUMBER,System ID'])">
                            <mat-icon>skip_previous</mat-icon>
                            <span>Da revisionare</span>
                          </button>
                        </mat-menu>

                        <button mat-menu-item
                                (click)="openShowAttachmentsDialog(element['DOCNUMBER,System ID'])">
                          <mat-icon>attach_file</mat-icon>
                          <span>Elenco allegati</span>
                        </button>
                        <button mat-menu-item>
                          <mat-icon>note</mat-icon>
                          <span>Elenco note</span>
                        </button>
                        <button mat-menu-item>
                          <mat-icon>rate_review</mat-icon>
                          <span>Elenco revisioni</span>
                        </button>
                        <hr>
                        <button mat-menu-item>
                          <mat-icon>first_page</mat-icon>
                          <span>CheckOut</span>
                        </button>
                        <hr>
                        <button mat-menu-item>
                          <mat-icon>pageview</mat-icon>
                          <span>Fascicoli che contengono il documento</span>
                        </button>
                        <button mat-menu-item>
                          <mat-icon>swap_horiz</mat-icon>
                          <span>Associazioni che contengono il documento</span>
                        </button>
                        <button mat-menu-item
                                (click)="openRelationsDialog(element['DOCNUMBER,System ID'])">
                          <mat-icon>account_tree</mat-icon>
                          <span>Mostra relazioni</span>
                        </button>
                        <hr>
                        <button mat-menu-item>
                          <mat-icon>create_new_folder</mat-icon>
                          <span>Inserisci in fascicoli</span>
                        </button>
                        <button mat-menu-item>
                          <mat-icon>insert_link</mat-icon>
                          <span>Inserisci in associazione</span>
                        </button>
                        <button mat-menu-item>
                          <mat-icon>mediation</mat-icon>
                          <span>Nuova relazione manuale</span>
                        </button>
                        <ng-container *ngIf="this.apiService.IsDocuSignLogged">
                          <hr>
                          <button mat-menu-item
                                  (click)="signDoc(element['DOCNUMBER,System ID'])">
                            <mat-icon>send</mat-icon>
                            <span>Invia con DocuSign</span>
                          </button>
                        </ng-container>
                      </mat-menu>

                    </ng-container>
                    <ng-container
                      *ngIf="(colonna.startsWith('STATO'))">
                      <button mat-button>
                        <ng-container [ngSwitch]="element[colonna]">
                          <mat-icon title="Aperto" *ngSwitchCase="'Aperto'">
                            launch
                          </mat-icon>
                          <mat-icon title="Valido" *ngSwitchCase="'Valido'">
                            done
                          </mat-icon>
                          <mat-icon title="Chiuso" *ngSwitchCase="'Chiuso'">
                            block
                          </mat-icon>
                          <mat-icon title="Profilato" *ngSwitchCase="'Profilato'">
                            folder
                          </mat-icon>
                          <mat-icon title="Approvato" *ngSwitchCase="'Approvato'">
                            offline_pin
                          </mat-icon>
                          <mat-icon title="Rifiutato" *ngSwitchCase="'Rifiutato'">
                            clear
                          </mat-icon>
                          <mat-icon title="{{element[colonna]}}" *ngSwitchDefault>
                            timeline
                          </mat-icon>
                        </ng-container>
                      </button>
                    </ng-container>
                    <ng-container
                      *ngIf="(colonna.startsWith('DOCNUMBER'))">
                      <button mat-button (click)="goToProfile(element['DOCNUMBER,System ID'])">
                        {{element['DOCNUMBER,System ID']}}
                      </button>
                    </ng-container>
                    <ng-container
                      *ngIf="(colonna.startsWith('INOUT'))">
                      <button mat-button>
                        <ng-container *ngIf="element[colonna] == '0'">
                          <mat-icon title="Uscita">call_made</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="element[colonna] == '1'">
                          <mat-icon title="Entrata">call_received</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="element[colonna] == '2'">
                          <mat-icon title="Interno">swap_horizontal</mat-icon>
                        </ng-container>
                      </button>
                    </ng-container>
                    <ng-container
                      *ngIf="(colonna.startsWith('ALLEGATI'))">
                      <ng-container *ngIf="element[colonna] != '0'">
                        <button mat-button matBadgeSize="small" matBadge="{{element[colonna]}}"
                                (click)="openShowAttachmentsDialog(element['DOCNUMBER,System ID'])">
                          <mat-icon>attachment</mat-icon>
                        </button>
                      </ng-container>
                      <ng-container *ngIf="element[colonna] == '0'">
                        <button mat-button
                                (click)="openShowAttachmentsDialog(element['DOCNUMBER,System ID'])">
                          <mat-icon>attachment</mat-icon>
                        </button>
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="((colonna.substr(colonna.indexOf(',')+1, colonna.length)) == 'Workflow')">
                      <ng-container *ngIf="(element[colonna]) == 1">
                        <button mat-button
                                (click)="goToProcess(element['DOCNUMBER,System ID'])">
                          <mat-icon title="In corso">engineering</mat-icon>
                        </button>
                        <br>
                      </ng-container>
                      <ng-container *ngIf="(element[colonna]) == 2">
                        <button mat-button
                                (click)="goToProcess(element['DOCNUMBER,System ID'])">
                          <mat-icon title="Concluso">more_horiz</mat-icon>
                        </button>
                        <br>
                      </ng-container>
                      <ng-container *ngIf="(element[colonna]) == 0">
                        <button mat-button
                                (click)="goToProcess(element['DOCNUMBER,System ID'])">
                          <b>Concluso</b>
                          <mat-icon title="In attesa">done_all</mat-icon>
                        </button>
                        <br>
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="((colonna.substr(colonna.indexOf(',')+1, colonna.length)) == 'Formato' && element[colonna] != '')">
                      <button mat-button (click)="downloadDoc(element['DOCNUMBER,System ID'])">
                        <ng-container
                          *ngIf="element[colonna]=='pdf'" style="color: darkred;">
                          <mat-icon title="{{element[colonna]}}">picture_as_pdf</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna] =='PDF'" style="color: darkred;">
                          <mat-icon title="{{element[colonna]}}">picture_as_pdf</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna] =='Pdf'" style="color: darkred;">
                          <mat-icon title="{{element[colonna]}}">picture_as_pdf</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='docx'" style="color: darkblue;">
                          <mat-icon title="{{element[colonna]}}">description</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='doc'" style="color: darkblue;">
                          <mat-icon title="{{element[colonna]}}">article</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='xlsx'" style="color: darkgreen;">
                          <mat-icon title="{{element[colonna]}}">ballot</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='csv'" style="color: darkgreen;">
                          <mat-icon title="{{element[colonna]}}">dashboard</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='msg'">
                          <mat-icon title="{{element[colonna]}}">email</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='zip'">
                          <mat-icon title="{{element[colonna]}}">topic</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='jpg'">
                          <mat-icon title="{{element[colonna]}}">insert_photo</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='png'">
                          <mat-icon title="{{element[colonna]}}">insert_photo</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='p7m'">
                          <mat-icon title="{{element[colonna]}}">mode</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]!='p7m' && element[colonna]!='Pdf' && element[colonna]!='PDF' && element[colonna]!='zip' && element[colonna]!='jpg' && element[colonna]!='msg' && element[colonna]!='docx' && element[colonna]!='pdf' && element[colonna]!='doc' && element[colonna]!='xlsx' && element[colonna]!='csv'">
                          {{element[colonna]}}
                        </ng-container>
                      </button>

                    </ng-container>
                    <ng-container
                      *ngIf="colonna.startsWith('NOTE')">
                      <ng-container *ngIf="element[colonna] == 'S'">
                        <button mat-button>
                          <mat-icon>note</mat-icon>
                        </button>
                      </ng-container>
                      <ng-container *ngIf="element[colonna] != 'S'">
                        <button mat-button>
                          <mat-icon>note</mat-icon>
                        </button>
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="colonna.startsWith('DATA')">
                      {{element[colonna]}}
                    </ng-container>
                    <ng-container
                      *ngIf="colonna.startsWith('RELAZIONI')">
                      <button *ngIf="element[colonna] == '1'" mat-button
                              (click)="openRelationsDialog(element['DOCNUMBER,System ID'])">
                        <mat-icon title="Relazioni">account_tree</mat-icon>
                      </button>
                    </ng-container>
                    <ng-container
                      *ngIf="colonna.startsWith('NOTE') == false && colonna.startsWith('ALLEGATI') == false && colonna.startsWith('RELAZIONI') == false && colonna.startsWith('STATO') == false && colonna.startsWith('-1') == false && colonna.startsWith('INOUT') == false && colonna.startsWith('DATA') == false && colonna.startsWith('DOCNUMBER') == false  && (colonna.substr(colonna.indexOf(',')+1, colonna.length)) != 'Workflow' && (colonna.substr(colonna.indexOf(',')+1, colonna.length)) != 'Formato'">{{element[colonna]}} </ng-container>
                  </td>
                </ng-container>


                <!--  COMANDI -->
                <ng-container
                  *ngIf="(colonna == 'expand' || colonna == 'RELAZIONI,Relazioni' || colonna == '-1,Azioni' || colonna == 'INOUT,Origine' || colonna == 'NOTE,Note' || colonna == 'WORKFLOW,Workflow' || colonna == 'ALLEGATI,Allegati' || colonna == 'STATO,Stato' || colonna == 'FORMATO,Formato')">
                  <td mat-cell *matCellDef="let element" style="text-align: center"
                      (contextmenu)="open($event, element, element['DOCNUMBER,System ID']); $event. preventDefault();">
                    <ng-container *ngIf="(colonna.startsWith('-1'))">
                      <button mat-icon-button [matMenuTriggerFor]="menu"
                              title="Azioni del profilo">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item>
                          <mat-icon>content_copy</mat-icon>
                          <span>Copia</span>
                        </button>
                        <hr>
                        <button mat-menu-item
                                (click)="goToProfile(element['DOCNUMBER,System ID'])">
                          <mat-icon>subject</mat-icon>
                          <span>Profilo</span>
                        </button>
                        <button mat-menu-item>
                          <mat-icon>clear</mat-icon>
                          <span>Elimina documento</span>
                        </button>
                        <button mat-menu-item>
                          <mat-icon>input</mat-icon>
                          <span>CheckIn</span>
                        </button>
                        <button mat-menu-item
                                (click)="exportToCSV(element['DOCNUMBER,System ID'])">
                          <mat-icon>call_made</mat-icon>
                          <span>Esporta csv</span>
                        </button>
                        <button mat-menu-item
                                (click)="downloadDoc(element['DOCNUMBER,System ID'])">
                          <mat-icon>launch</mat-icon>
                          <span>Apri</span>
                        </button>
                        <button mat-menu-item [matMenuTriggerFor]="modifica"
                                style="color: black">
                          <mat-icon>create</mat-icon>
                          <span>Modifica documento</span>
                        </button>
                        <mat-menu #modifica="matMenu">
                          <button mat-menu-item
                                  (click)="openEditFileDialog(element['DOCNUMBER,System ID'])"
                                  style="color: black">
                            <mat-icon>attach_file</mat-icon>
                            <span>Da file</span>
                          </button>
                          <button mat-menu-item
                                  (click)="openEditRevisionDialog(element['DOCNUMBER,System ID'])">
                            <mat-icon>skip_previous</mat-icon>
                            <span>Da revisionare</span>
                          </button>
                        </mat-menu>

                        <button mat-menu-item
                                (click)="openShowAttachmentsDialog(element['DOCNUMBER,System ID'])">
                          <mat-icon>attach_file</mat-icon>
                          <span>Elenco allegati</span>
                        </button>
                        <button mat-menu-item
                                (click)="openProfileNotesDialog(element['DOCNUMBER,System ID'])">
                          <mat-icon>note</mat-icon>
                          <span>Elenco note</span>
                        </button>
                        <button mat-menu-item>
                          <mat-icon>rate_review</mat-icon>
                          <span>Elenco revisioni</span>
                        </button>
                        <hr>
                        <button mat-menu-item>
                          <mat-icon>first_page</mat-icon>
                          <span>CheckOut</span>
                        </button>
                        <hr>
                        <button mat-menu-item>
                          <mat-icon>pageview</mat-icon>
                          <span>Fascicoli che contengono il documento</span>
                        </button>
                        <button mat-menu-item>
                          <mat-icon>swap_horiz</mat-icon>
                          <span>Associazioni che contengono il documento</span>
                        </button>
                        <button mat-menu-item
                                (click)="openRelationsDialog(element['DOCNUMBER,System ID'])">
                          <mat-icon>account_tree</mat-icon>
                          <span>Mostra relazioni</span>
                        </button>
                        <hr>
                        <button mat-menu-item>
                          <mat-icon>create_new_folder</mat-icon>
                          <span>Inserisci in fascicoli</span>
                        </button>
                        <button mat-menu-item>
                          <mat-icon>insert_link</mat-icon>
                          <span>Inserisci in associazione</span>
                        </button>
                        <button mat-menu-item>
                          <mat-icon>mediation</mat-icon>
                          <span>Nuova relazione manuale</span>
                        </button>
                        <ng-container *ngIf="this.apiService.IsDocuSignLogged">
                          <hr>
                          <button mat-menu-item
                                  (click)="signDoc(element['DOCNUMBER,System ID'])">
                            <mat-icon>send</mat-icon>
                            <span>Invia con DocuSign</span>
                          </button>
                        </ng-container>
                      </mat-menu>

                    </ng-container>
                    <ng-container
                      *ngIf="(colonna.startsWith('STATO'))">
                      <button mat-icon-button>
                        <mat-icon title="{{element[colonna]}}">
                          {{this.apiService.getState(element[colonna])}}
                        </mat-icon>
                      </button>
                    </ng-container>
                    <ng-container
                      *ngIf="(colonna.startsWith('DOCNUMBER'))">
                      <button mat-icon-button
                              (click)="goToProfile(element['DOCNUMBER,System ID'])">
                        {{element['DOCNUMBER,System ID']}}
                      </button>
                    </ng-container>
                    <ng-container
                      *ngIf="(colonna.startsWith('expand'))">
                      <ng-container *ngIf="element['level'] === 0">
                        <button mat-mini-fab style="float: left; color: black">
                          <mat-icon *ngIf="element['DOCNUMBER,System ID'] && this.generalExpanded === true"
                                    title="Espandi" (click)="this.generalExpandClicked()" (click) = "dontSelectWhenClicked(element['DOCNUMBER,System ID'])">expand_more
                          </mat-icon>
                          <mat-icon *ngIf="!element['DOCNUMBER,System ID'] || this.generalExpanded === false"
                                    title="Espandi" (click)="this.generalExpandClicked()" (click) = "dontSelectWhenClicked(element['DOCNUMBER,System ID'])">expand_less
                          </mat-icon>
                        </button>
                      </ng-container>
                      <ng-container *ngIf="element['level'] !== 0">
                        <button mat-mini-fab style="float: right; color: black" *ngIf = "element['level'] !== this.maxLevel">
                          <mat-icon *ngIf="element['DOCNUMBER,System ID'] && this.generalExpanded === true"
                                    title="Espandi" (click) = "dontSelectWhenClicked(element['DOCNUMBER,System ID'])">expand_more
                          </mat-icon>
                          <mat-icon *ngIf="!element['DOCNUMBER,System ID'] || this.generalExpanded === false"
                                    title="Espandi" (click) = "dontSelectWhenClicked(element['DOCNUMBER,System ID'])">expand_less
                          </mat-icon>
                        </button>
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="(colonna.startsWith('INOUT'))">
                      <button mat-icon-button>
                        <ng-container *ngIf="element[colonna] == '0'">
                          <mat-icon title="Uscita">call_made</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="element[colonna] == '1'">
                          <mat-icon title="Entrata">call_received</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="element[colonna] == '2'">
                          <mat-icon title="Interno">swap_horizontal</mat-icon>
                        </ng-container>
                      </button>
                    </ng-container>
                    <ng-container
                      *ngIf="(colonna.startsWith('ALLEGATI'))">
                      <ng-container *ngIf="element[colonna] != '0'">
                        <button mat-icon-button matBadgeSize="small"
                                matBadge="{{element[colonna]}}"
                                (click)="openShowAttachmentsDialog(element['DOCNUMBER,System ID'])" (click) = "dontSelectWhenClicked(element['DOCNUMBER,System ID'])">
                          <mat-icon>attachment</mat-icon>
                        </button>
                      </ng-container>
                      <ng-container *ngIf="element[colonna] == '0'">
                        <button mat-icon-button
                                (click)="openShowAttachmentsDialog(element['DOCNUMBER,System ID'])" (click) = "dontSelectWhenClicked(element['DOCNUMBER,System ID'])">
                          <mat-icon>attachment</mat-icon>
                        </button>
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="((colonna.substr(colonna.indexOf(',')+1, colonna.length)) == 'Workflow')">
                      <ng-container *ngIf="(element[colonna]) == 1">
                        <button mat-icon-button
                                (click)="goToProcess(element['DOCNUMBER,System ID'])">
                          <mat-icon title="In corso">engineering</mat-icon>
                        </button>
                        <br>
                      </ng-container>
                      <ng-container *ngIf="(element[colonna]) == 2">
                        <button mat-icon-button
                                (click)="goToProcess(element['DOCNUMBER,System ID'])">
                          <mat-icon title="Concluso">more_horiz</mat-icon>
                        </button>
                        <br>
                      </ng-container>
                      <ng-container *ngIf="(element[colonna]) == 0">
                        <button mat-icon-button
                                (click)="goToProcess(element['DOCNUMBER,System ID'])">
                          <b>Concluso</b>
                          <mat-icon title="In attesa">done_all</mat-icon>
                        </button>
                        <br>
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="((colonna.substr(colonna.indexOf(',')+1, colonna.length)) == 'Formato' && element[colonna] != '')">
                      <button mat-icon-button
                              (click)="downloadDoc(element['DOCNUMBER,System ID'])">
                        <ng-container
                          *ngIf="element[colonna]=='pdf'">
                          <mat-icon title="{{element[colonna]}}" style="color: darkred;">
                            picture_as_pdf
                          </mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna] =='PDF'">
                          <mat-icon title="{{element[colonna]}}" style="color: darkred;">
                            picture_as_pdf
                          </mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna] =='Pdf'">
                          <mat-icon title="{{element[colonna]}}" style="color: darkred;">
                            picture_as_pdf
                          </mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='docx'">
                          <mat-icon title="{{element[colonna]}}" style="color: darkblue;">
                            description
                          </mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='doc'">
                          <mat-icon title="{{element[colonna]}}" style="color: darkblue;">
                            article
                          </mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='xlsx'">
                          <mat-icon title="{{element[colonna]}}" style="color: darkgreen;">
                            ballot
                          </mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='csv'">
                          <mat-icon title="{{element[colonna]}}" style="color: darkgreen;">
                            dashboard
                          </mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='msg'">
                          <mat-icon title="{{element[colonna]}}">email</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='zip'">
                          <mat-icon title="{{element[colonna]}}">topic</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='jpg'">
                          <mat-icon title="{{element[colonna]}}">insert_photo</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='p7m'">
                          <mat-icon title="{{element[colonna]}}">mode</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='xml'">
                          <mat-icon title="{{element[colonna]}}">code</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='png' || element[colonna]=='PNG'">
                          <mat-icon title="{{element[colonna]}}">insert_photo</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]!='PNG' && element[colonna]!='png' && element[colonna]!='xml' && element[colonna]!='p7m' && element[colonna]!='Pdf' && element[colonna]!='PDF' && element[colonna]!='zip' && element[colonna]!='jpg' && element[colonna]!='msg' && element[colonna]!='docx' && element[colonna]!='pdf' && element[colonna]!='doc' && element[colonna]!='xlsx' && element[colonna]!='csv'">
                          {{element[colonna]}}
                        </ng-container>
                      </button>
                    </ng-container>
                    <ng-container
                      *ngIf="colonna.startsWith('NOTE')">
                      <ng-container *ngIf="element[colonna] != null">
                        <button mat-icon-button matBadgeSize="small"
                                matBadge="{{element[colonna]}}"
                                (click)="openProfileNotesDialog(element['DOCNUMBER,System ID'])" (click) = "dontSelectWhenClicked(element['DOCNUMBER,System ID'])">
                          <mat-icon>note</mat-icon>
                        </button>
                      </ng-container>
                      <ng-container *ngIf="element[colonna] == null">
                        <button mat-icon-button
                                (click)="openProfileNotesDialog(element['DOCNUMBER,System ID'])" (click) = "dontSelectWhenClicked(element['DOCNUMBER,System ID'])">
                          <mat-icon>note</mat-icon>
                        </button>
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="colonna.startsWith('DATA')">
                      {{element[colonna]}}
                    </ng-container>
                    <ng-container
                      *ngIf="colonna.startsWith('RELAZIONI')">
                      <button *ngIf="element[colonna] == '1'" mat-icon-button
                              (click)="openRelationsDialog(element['DOCNUMBER,System ID'])">
                        <mat-icon title="Relazioni">account_tree</mat-icon>
                      </button>
                    </ng-container>
                    <ng-container
                      *ngIf="colonna.startsWith('expand') == false && colonna.startsWith('NOTE') == false && colonna.startsWith('ALLEGATI') == false && colonna.startsWith('RELAZIONI') == false && colonna.startsWith('STATO') == false && colonna.startsWith('-1') == false && colonna.startsWith('INOUT') == false && colonna.startsWith('DATA') == false && colonna.startsWith('DOCNUMBER') == false  && (colonna.substr(colonna.indexOf(',')+1, colonna.length)) != 'Workflow' && (colonna.substr(colonna.indexOf(',')+1, colonna.length)) != 'Formato'">{{element[colonna]}} </ng-container>
                  </td>
                </ng-container>
              </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"
                style="height: 60px;"></tr>
            <ng-container *matRowDef="let row; columns: displayedColumns;">
              <tr mat-row [ngClass]="getClass(row.selected)"
                  [class.hidden]='row.level !== 0 && this.generalExpanded === false'
                  style="height: 58px !important;"
                  (click)="clickedRow($event, row, row['DOCNUMBER,System ID'])"
                  (dblclick)="DBclickedRow($event, row, row['DOCNUMBER,System ID'])"></tr>
            </ng-container>
          </table>
          <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]="[9, 18, 27, 36, 50, 100]"
                         showFirstLastButtons style="float: right"></mat-paginator>
        </div>
      </ng-container>
      <ng-container *ngIf="noItems == true">
        <h1 style="color: {{this.dashColor}}">Nessun risultato trovato</h1>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>
<ng-template #userMenu let-user>
  <section class="user-menu">
    <div style="text-align: center; display: contents;">
      <h3 style="margin-bottom: 0px;">
        <b style="color: black" *ngIf="this.numSelected == 0">Profilo: {{this.selectedDocN}}</b>
      </h3>
      <h4 style="color: {{this.dashColor}};" *ngIf="this.numSelected > 0">N.
        selezionati: {{this.numSelected}}</h4>
    </div>
    <hr>
    <ng-container *ngIf="this.numSelected < 2">
      <ng-container *ngFor="let command of this.commands.details">
        <ng-container [ngSwitch]="command.elementType">
          <!-- Apri -->
          <button mat-menu-item *ngSwitchCase="1"
                  (click)="downloadDoc(this.selectedDocN)">
            <mat-icon>launch</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Elimina documento -->
          <!-- TODO ELIMINA DOCUMENTO  -->
          <button mat-menu-item (click) = "this.deleteDocument(this.selectedDocN)"*ngSwitchCase="5">
            <mat-icon>clear</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Processo di workflow singolo -->
          <button mat-menu-item *ngSwitchCase="81"
                  (click)="this.startEvent(command.elementId, this.selectedDocN)">
            <mat-icon>developer_board</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Task attivo -->
          <!-- TODO Task attivo  -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="46">
            <mat-icon>settings_suggest</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Mostra processi per il documento -->
          <!-- TODO Mostra processi per il documento  -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="77">
            <mat-icon>visibility</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Avvia nuovo processo di workflow -->
          <!-- TODO Avvia nuovo processo di workflow  -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="76">
            <mat-icon>playlist_add_check</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Cartella -->
          <ng-container>
            <button mat-menu-item
                    *ngSwitchCase="70" (click)="enableFolder(command.id)">
              <mat-icon>folder_open</mat-icon>
              {{command.label}}
              <ng-container *ngFor="let folder of this.folders">
                <ng-container *ngIf="folder.id == command.id && folder.enabled == true">
                  <mat-icon style="float: right">keyboard_arrow_down</mat-icon>
                </ng-container>
                <ng-container *ngIf="folder.id == command.id && folder.enabled == false">
                  <mat-icon style="float: right">keyboard_arrow_right</mat-icon>
                </ng-container>
              </ng-container>
            </button>
            <ng-container *ngFor="let folder of this.folders">
              <ng-container *ngIf="folder.id == command.id && folder.enabled == true">
                <ng-container *ngFor="let child of command.childs">
                  <ng-container [ngSwitch]="child.elementType">
                    <!-- Processo di workflow singolo -->
                    <button mat-menu-item *ngSwitchCase="81" style="margin-left: 10px;"
                            (click)="this.startEvent(child.elementId, this.selectedDocN)">
                      <mat-icon>developer_board</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Task attivo -->
                    <!-- TODO TASK ATTIVO -->
                    <button mat-menu-item *ngSwitchCase="46" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>settings_suggest</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Mostra processi per il documento -->
                    <!-- TODO Mostra processi per il documento -->
                    <button mat-menu-item *ngSwitchCase="77" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>visibility</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Avvia nuovo processo di workflow -->
                    <!-- TODO Avvia nuovo processo di workflow -->
                    <button mat-menu-item *ngSwitchCase="76" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>playlist_add_check</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Apri -->
                    <button mat-menu-item *ngSwitchCase="1" style="margin-left: 10px;"
                            (click)="downloadDoc(this.selectedDocN)">
                      <mat-icon>launch</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Elimina documento -->
                    <!-- TODO Elimina documento -->
                    <button mat-menu-item *ngSwitchCase="5" (click) = "this.deleteDocument(this.selectedDocN)"
                            style="margin-left: 10px;">
                      <mat-icon>clear</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Modifica documento -->
                    <button mat-menu-item [matMenuTriggerFor]="modifica" *ngSwitchCase="11"
                            style="margin-left: 10px;"
                            style="color: black">
                      <mat-icon>create</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <mat-menu #modifica="matMenu">
                      <button mat-menu-item
                              (click)="openEditFileDialog(this.selectedDocN)"
                              style="color: black">
                        <mat-icon>attach_file</mat-icon>
                        <span>Da file</span>
                      </button>
                      <button mat-menu-item
                              (click)="openEditRevisionDialog(this.selectedDocN)">
                        <mat-icon>skip_previous</mat-icon>
                        <span>Da revisionare</span>
                      </button>
                    </mat-menu>
                    <!-- Profilo -->
                    <button mat-menu-item *ngSwitchCase="2" style="margin-left: 10px;"
                            (click)="goToProfile(this.selectedDocN)">
                      <mat-icon>subject</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Duplica profilo -->
                    <!-- TODO Duplica profilo -->
                    <button mat-menu-item *ngSwitchCase="3" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>content_copy</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Esporta CSV -->
                    <button mat-menu-item *ngSwitchCase="82" style="margin-left: 10px;"
                            (click)="exportToCSV(this.selectedDocN)">
                      <mat-icon>call_made</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Elenco allegati -->
                    <button mat-menu-item *ngSwitchCase="7" style="margin-left: 10px;"
                            (click)="openShowAttachmentsDialog(this.selectedDocN)">
                      <mat-icon>attach_file</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Elenco note -->
                    <button mat-menu-item *ngSwitchCase="6" style="margin-left: 10px;"
                            (click)="openProfileNotesDialog(this.selectedDocN)">
                      <mat-icon>note</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Elenco revisioni -->
                    <!-- TODO Elenco revisioni -->
                    <button mat-menu-item *ngSwitchCase="10"
                            style="margin-left: 10px;" (click)="openRevisions(this.selectedDocN)">
                      <mat-icon>rate_review</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Separatore -->
                    <hr *ngSwitchCase="71" style="margin-left: 10px;">
                    <!-- CheckOut -->
                    <!-- TODO CheckOut -->
                    <button mat-menu-item *ngSwitchCase="12" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>first_page</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- CheckIn -->
                    <!-- TODO CheckIn -->
                    <button mat-menu-item *ngSwitchCase="13" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>input</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Fascicoli che contengono il documento -->
                    <!-- TODO Fascicoli che contengono il documento -->
                    <button mat-menu-item *ngSwitchCase="16" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>pageview</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Associazioni che contengono il documento -->
                    <!-- TODO Associazioni che contengono il documento -->
                    <button mat-menu-item *ngSwitchCase="18" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>swap_horiz</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Mostra relazioni -->
                    <button mat-menu-item *ngSwitchCase="21" style="margin-left: 10px;"
                            (click)="openRelationsDialog(this.selectedDocN)">
                      <mat-icon>account_tree</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Inserisci in fascicolo -->
                    <!-- TODO Inserisci in fascicolo -->
                    <button mat-menu-item *ngSwitchCase="15" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>create_new_folder</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Inserisci in associazione -->
                    <!-- TODO Inserisci in associazione -->
                    <button mat-menu-item *ngSwitchCase="17" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>insert_link</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Nuova relazione manuale -->
                    <!-- TODO Nuova relazione manuale -->
                    <button mat-menu-item *ngSwitchCase="19" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>mediation</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          <!-- Modifica documento -->
          <button mat-menu-item [matMenuTriggerFor]="modifica" *ngSwitchCase="11"
                  style="color: black">
            <mat-icon>create</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <mat-menu #modifica="matMenu">
            <button mat-menu-item
                    (click)="openEditFileDialog(this.selectedDocN)"
                    style="color: black">
              <mat-icon>attach_file</mat-icon>
              <span>Da file</span>
            </button>
            <button mat-menu-item
                    (click)="openEditRevisionDialog(this.selectedDocN)">
              <mat-icon>skip_previous</mat-icon>
              <span>Da revisionare</span>
            </button>
          </mat-menu>
          <!-- Profilo -->
          <button mat-menu-item *ngSwitchCase="2"
                  (click)="goToProfile(this.selectedDocN)">
            <mat-icon>subject</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Duplica profilo -->
          <!-- TODO Duplica profilo -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="3">
            <mat-icon>content_copy</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Esporta CSV -->
          <button mat-menu-item *ngSwitchCase="82"
                  (click)="exportToCSV(this.selectedDocN)">
            <mat-icon>call_made</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Elenco allegati -->
          <button mat-menu-item *ngSwitchCase="7"
                  (click)="openShowAttachmentsDialog(this.selectedDocN)">
            <mat-icon>attach_file</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Elenco note -->
          <button mat-menu-item *ngSwitchCase="6"
                  (click)="openProfileNotesDialog(this.selectedDocN)">
            <mat-icon>note</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Elenco revisioni -->
          <!-- TODO Elenco revisioni -->
          <button mat-menu-item (click)="openRevisions(this.selectedDocN)" *ngSwitchCase="10">
            <mat-icon>rate_review</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Separatore -->
          <hr *ngSwitchCase="71">
          <!-- CheckOut -->
          <!-- TODO CheckOut -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="12">
            <mat-icon>first_page</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- CheckIn -->
          <!-- TODO CheckIn -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="13">
            <mat-icon>input</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Fascicoli che contengono il documento -->
          <!-- TODO Fascicoli che contengono il documento -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="16">
            <mat-icon>pageview</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Associazioni che contengono il documento -->
          <!-- TODO Associazioni che contengono il documento -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="18">
            <mat-icon>swap_horiz</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Mostra relazioni -->
          <button mat-menu-item *ngSwitchCase="21"
                  (click)="openRelationsDialog(this.selectedDocN)">
            <mat-icon>account_tree</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Inserisci in fascicolo -->
          <!-- TODO Inserisci in fascicolo -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="15">
            <mat-icon>create_new_folder</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Inserisci in associazione -->
          <!-- TODO Inserisci in associazione -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="17">
            <mat-icon>insert_link</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Nuova relazione manuale -->
          <!-- TODO Nuova relazione manuale -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="19">
            <mat-icon>mediation</mat-icon>
            <span>{{command.label}}</span>
          </button>
        </ng-container>
      </ng-container>
      <!-- TODO Copia -->
      <button mat-menu-item [disabled]="true">
        <mat-icon>content_copy</mat-icon>
        <span>Copia</span>
      </button>
      <ng-container *ngIf="this.apiService.IsDocuSignLogged">
        <hr>
        <button mat-menu-item
                (click)="signDoc(this.selectedDocN)">
          <mat-icon>send</mat-icon>
          <span>Invia con DocuSign</span>
        </button>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="this.numSelected > 1">
      <ng-container *ngFor="let command of this.commands.details">
        <ng-container [ngSwitch]="command.elementType">
          <!-- Apri -->
          <button mat-menu-item *ngSwitchCase="1"
                  (click)="downloadDoc(this.selectedDocN)">
            <mat-icon>launch</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Elimina documento -->
          <!-- TODO Elimina documento -->
          <button mat-menu-item  (click) = "this.deleteDocument(this.selectedDocN)" *ngSwitchCase="5">
            <mat-icon>clear</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Processo di workflow singolo -->
          <button mat-menu-item *ngSwitchCase="81"
                  (click)="this.startEvent(command.elementId, this.selectedDocN)">
            <mat-icon>developer_board</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Avvia nuovo processo di workflow -->
          <!-- TODO Avvia nuovo processo di workflow -->
          <button mat-menu-item [disabled]="true" *ngSwitchCase="76">
            <mat-icon>playlist_add_check</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Cartella -->
          <ng-container>
            <button mat-menu-item
                    *ngSwitchCase="70" (click)="enableFolder(command.id)">
              <mat-icon>folder_open</mat-icon>
              {{command.label}}
              <ng-container *ngFor="let folder of this.folders">
                <ng-container *ngIf="folder.id == command.id && folder.enabled == true">
                  <mat-icon style="float: right">keyboard_arrow_down</mat-icon>
                </ng-container>
                <ng-container *ngIf="folder.id == command.id && folder.enabled == false">
                  <mat-icon style="float: right">keyboard_arrow_right</mat-icon>
                </ng-container>
              </ng-container>
            </button>
            <ng-container *ngFor="let folder of this.folders">
              <ng-container *ngIf="folder.id == command.id && folder.enabled == true">
                <ng-container *ngFor="let child of command.childs">
                  <ng-container [ngSwitch]="child.elementType">
                    <!-- Processo di workflow singolo -->
                    <button mat-menu-item *ngSwitchCase="81" style="margin-left: 10px;"
                            (click)="this.startEvent(child.elementId, this.selectedDocN)">
                      <mat-icon>developer_board</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Avvia nuovo processo di workflow -->
                    <!-- TODO Avvia nuovo processo di workflow -->
                    <button mat-menu-item [disabled]="true" *ngSwitchCase="76"
                            style="margin-left: 10px;">
                      <mat-icon>playlist_add_check</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Apri -->
                    <button mat-menu-item *ngSwitchCase="1" style="margin-left: 10px;"
                            (click)="downloadDoc(this.selectedDocN)">
                      <mat-icon>launch</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Elimina documento -->
                    <!-- TODO Elimina documento -->
                    <button mat-menu-item *ngSwitchCase="5" [disabled]="true"
                            style="margin-left: 10px;">
                      <mat-icon>clear</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Esporta CSV -->
                    <button mat-menu-item *ngSwitchCase="82" style="margin-left: 10px;"
                            (click)="exportToCSV(this.selectedDocN)">
                      <mat-icon>call_made</mat-icon>
                      <span>{{child.label}}</span>
                    </button>
                    <!-- Separatore -->
                    <hr *ngSwitchCase="71" style="margin-left: 10px;">
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>

          <!-- Esporta CSV -->
          <button mat-menu-item *ngSwitchCase="82"
                  (click)="exportToCSV(this.selectedDocN)">
            <mat-icon>call_made</mat-icon>
            <span>{{command.label}}</span>
          </button>
          <!-- Separatore -->
          <hr *ngSwitchCase="71">
        </ng-container>
      </ng-container>
    </ng-container>

  </section>
</ng-template>
