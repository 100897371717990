import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ApiService} from '../../api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BodyFieldToSave} from '../../bodyFieldToSave';
import {DatePipe} from '@angular/common';
import {DialogMultiDTOComponent} from '../../additionalDialog/dialogMultiDTOComponent';
import {DialogTableDTOComponent} from '../../additionalDialog/dialogTableDTOComponent';
import {DialogAddressBookComponent} from '../../additionalDialog/dialogAddressBookComponent';
import {DialogComboDTOComponent} from '../../additionalDialog/dialogComboDTOComponents';
import {DialogMaskComponent} from '../../additionalDialog/dialogMaskComponents';
import {DialogClassViewDTOComponentDTOComponent} from '../../additionalDialog/dialogClassViewDTOComponent';
import {DialogSearchDocComponent} from '../../additionalDialog/dialogSearchDocComponents';
import {CommentoDialogComponent} from '../dettaglitask/dettaglitask.component';
import {DialogDocumentalClassComponent} from '../../additionalDialog/dialogDocumentalClass';
import {ProgressSpinnerDialogComponent} from '../../additionalDialog/progress-spinner-dialog/progress-spinner-dialog.component';
import {Observable} from 'rxjs';
import {DialogAddressBookV2Component} from '../../additionalDialog/dialog-address-book-v2/dialog-address-book-v2.component';

export interface SelectedRowsTableDTO {
  selected: [];
}

export interface SelectedRowsMultiDTO {
  selected: [];
}


@Component({
  selector: 'app-ricerca',
  templateUrl: './ricerca.component.html',
  styleUrls: ['./ricerca.component.css']
})
export class RicercaComponent implements OnInit {
  id = this.activatedRoute.snapshot.paramMap.get('id');
  idTask = this.activatedRoute.snapshot.paramMap.get('idTask');
  operationId = this.activatedRoute.snapshot.paramMap.get('operationId');
  path = this.activatedRoute.snapshot.paramMap.get('path');
  firstnodepath = this.activatedRoute.snapshot.paramMap.get('firstnodepath');
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;
  maskName: string;
  splitMaskName: string;
  splitMaskOrder: string;
  splitMaskCat: string;
  classProfileType: string;
  classProfileName: string;
  classProfileValue: string;
  fields: Array<any> = new Array<any>();
  nomiAoo: Array<any> = new Array<any>();
  originsFields: Array<any> = new Array<any>();
  documenTypes: Array<any> = new Array<any>();
  stateFields: Array<any> = new Array<any>();
  toFields: Array<any> = new Array<any>();
  FromFields: Array<any> = new Array<any>();
  Row: [];
  breakpoint: number;
  fieldDataSource: Array<object> = [];
  multiValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  tableValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  comboValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  addressBookSelected: Map<string, string[]> = new Map<string, string[]>();
  addressBookSelectedToShow: any;
  fieldsOptions: any = {};
  fieldName = '';
  keyField = '';
  mapMultiValueToSave: Map<string, object[]> = new Map<string, object[]>();
  mapTableValueToSave: any;
  tableAssociationField: object = {};
  comboAssociationField: object = {};
  mapTableValueToSee: Map<string, string> = new Map<string, string>();
  mapAddressBookToSee: Map<string, string> = new Map<string, string>();
  mapClasseBookToSave: Map<string, Array<string>> = new Map<string, Array<string>>();
  // CLASSE BOX
  ELEMENT_DATA_CLASS: Array<object> = new Array<object>();
  arrayToObjectClass: { [index: string]: { content: string } } = {};
  dataSourceClass: MatTableDataSource<object>;
  displayedColumnsClass: Array<string> = new Array<string>();
  docNumberClasseboxTemp: Array<string> = new Array<string>();
  optionsMask: any = {};
  dataMaskToSave: any = {};
  dataValueAssociations: any = {};
  validationCheck: boolean;
  jsonToSendArxivar: any = {};
  fileListMain: FileList;
  fileMain: File;
  twDocumentOperationId: string;
  formDataMain: FormData;
  bufferIdInfo: Array<any> = new Array<any>();
  fileInfo: any = {name: '', buffer: ''};
  badgeBufferMain: number;
  arrayToObject: { [index: string]: { content: string } } = {};
  fieldsNoAdditional: Array<any> = new Array<any>();
  additionalFields: Array<any> = new Array<any>();
  idContact: string;
  mapAddressBookSave: Array<any> = new Array<any>();
  users: Array<any> = new Array<any>();
  workFlow: Array<any> = new Array<any>();
  menuNameFields: Array<any> = new Array<any>();   // 0 = campi di profilo, 1 = campi di sistema, 2 = Avanzate
  isLoading = false;
  observable: any;

  // chip list
  mapMultiValueToSee: Map<string, any> = new Map<string, any>();
  multiValuteDTOSelectedToShow: Array<any> = new Array<any>();
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  comboValues: any = {};
  // tslint:disable-next-line:max-line-length variable-name
  constructor(public dialog: MatDialog, private route: ActivatedRoute, private router: Router, iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer, public apiService: ApiService,
              private activatedRoute: ActivatedRoute, private snackBar: MatSnackBar,
              private bodyFieldToSave: BodyFieldToSave, private datePipe: DatePipe) {


    iconRegistry.addSvgIcon(
      'BackIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/keyboard_backspace-24px.svg'));
  }


  showProgressSpinnerUntilExecuted(observable: Observable<any>): void {
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    const subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        // handle response
        console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        // handle error
        dialogRef.close();
      }
    );
  }

  myObservable(observer): void {
    setTimeout(() => {
      observer.next('done waiting for 5 sec');
      observer.complete();
    }, 500);
  }

  ngOnInit(): void {
    this.apiService.namePath = this.apiService.namePath.split('-')[0];
    this.menuNameFields = [
      {name: 'Aoo', group: 0},
      {name: 'DocumentType', group: 0},
      {name: 'Stato', group: 0},
      {name: 'Origine', group: 1},
      {name: 'DocName', group: 0},
      {name: 'DataDoc', group: 0},
      {name: 'DataDoc_NrGiorni', group: 0},
      {name: 'From', group: 0},
      {name: 'To', group: 0},
      {name: 'Scadenza', group: 0},
      {name: 'Numero', group: 0},
      {name: 'Creation_Date', group: 1},
      {name: 'Creation_Date_NrGiorni', group: 1},
      {name: 'Originale', group: 1},
      {name: 'WorkFlow', group: 1},
      {name: 'FileSize', group: 1},
      {name: 'DatiEnteProtocollo', group: 4},
      {name: 'DatiEnteDataProt', group: 4},
      {name: 'DatiEnteUfficio', group: 4},
      {name: 'DatiEntePersona', group: 4},
      {name: 'DatiEnteSpedizione', group: 4},
      {name: 'VsRif', group: 4},
      {name: 'AllegatoPA', group: 4},
      {name: 'DocNumber', group: 0},
      {name: 'Revisione', group: 0},
      {name: 'Protocollo', group: 0},
      {name: 'Anno', group: 4},
      {name: 'DataProt', group: 0},
      {name: 'Sostitutiva', group: 2},
      {name: 'Eti_Reader', group: 4},
      {name: 'EtichettaCd', group: 4},
      {name: 'Dm_Allegati_Doc.NomeOriginale', group: 2},
      {name: 'Dm_Allegati_Doc.Commento', group: 2},
      {name: 'Dm_Note.Commenti', group: 2},
      {name: 'DM_STAMPSINSTANCE_APPLIED', group: 2},
      {name: 'Autore', group: 1},
      {name: 'Dm_Testo.Testo', group: 0}
    ];
    this.badgeBufferMain = this.bufferIdInfo.length;
    this.dashColor = this.apiService.dashColor;
    this.backColor = this.apiService.backColor;
    this.squareColor = this.apiService.squareColor;
    this.cardColor = this.apiService.cardColor;
    this.breakpoint = (window.innerWidth <= 600) ? 4 : 6;
    this.apiService.getAoo().subscribe(data => {
      this.nomiAoo = data;
    });
    this.apiService.getOrigins().subscribe(data => {
      this.originsFields = data;
      // console.log(this.originsFields);
    });
    this.apiService.getUsers().subscribe(data => {
      this.users = data;
      // console.log(this.users);
    });
    /*this.apiService.getDocumentType(this.id).subscribe(data => {
        this.documenTypes = data;
    });*/
    this.apiService.getStates().subscribe(data => {
      this.stateFields = data;
    });
    this.workFlow.push({name: 'In corso', id: 1}, {name: 'Conclusi', id: 2}, {name: 'Tutti', id: 3}, {name: 'Mai', id: 4});
    this.apiService.getSearchFields().subscribe(data => {
      console.log(data);
      data[0].fields = data[0].fields.sort((a, b) => {
        return a.order - b.order;
      });
      this.apiService.getDocTypeMode('1').subscribe(datas => {
        // console.log(datas);
        datas.forEach(v => {
          this.documenTypes.push(v);
        });

      });
      this.fields = data[0].fields;

      console.log(this.fields);

      this.fields.forEach(value => {

        // // console.log(value);
        if (value.name.toLowerCase().startsWith('data')) {
          this.fieldsOptions[value.name] = 'uguale';
        } else {
          this.fieldsOptions[value.name] = '';
        }
        if (value.name.startsWith('COMBO')) {
          this.comboValues[value.name] = this.getValues(value.name, 'COMBO');
        }
        if (value.name.startsWith('TABLE')) {
          this.comboValues[value.name] = this.getValues(value.name, 'TABLE');
        }
      });

      this.apiService.getAllDocTypes().subscribe(dat => {
        dat.forEach(v => {
          if (v.isLeaf) {
            this.documenTypes.push(v);
          }
        });
      });
    });
  }

  remove(v: string, fieldName: string): void {
    this.multiValuteDTOSelectedToShow = new Array<any>();
    this.dataMaskToSave[fieldName].forEach((value, index) => {
      if (value === v) {
        this.dataMaskToSave[fieldName].splice(index, 1);
      }
    });
    this.dataMaskToSave[fieldName].forEach((value) => {
      this.multiValuteDTOSelectedToShow.push(value);
    });
    this.mapMultiValueToSee.set(fieldName, this.multiValuteDTOSelectedToShow);
  }
  setFieldOptions(fieldName: string, option: string): void {
    this.fieldsOptions[fieldName] = option;
  }
  documentChanged(event: any, tipo1: string, tipo2: string, tipo3: string): void {
    // console.log(this.dataMaskToSave);
    // console.log(this.fields);
    if (event.isUserInput) {
      this.apiService.additionalSearches(tipo1, tipo2, tipo3).subscribe(data => {
        this.fieldsNoAdditional = new Array<any>();
        this.fields.forEach(v => {
          if (v.isAdditional === false) {
            this.fieldsNoAdditional.push(v);
          }
        });
        this.additionalFields = data;
        this.additionalFields.forEach(v => {
          this.fieldsNoAdditional.push(v);
        });
        this.fields = this.fieldsNoAdditional;
        this.fields.forEach(value => {

          // // console.log(value);
          if (value.name.toLowerCase().startsWith('data')) {
            this.fieldsOptions[value.name] = 'uguale';
          } else {
            this.fieldsOptions[value.name] = '';
          }
          if (value.name.startsWith('COMBO')) {
            this.comboValues[value.name] = this.getValues(value.name, 'COMBO');
          }
          if (value.name.startsWith('TABLE')) {
            this.comboValues[value.name] = this.getValues(value.name, 'TABLE');
          }
        });
        // console.log(this.fields);
        // console.log(this.dataMaskToSave);
      });
    }
  }

  getValues(name: string, type: string): Array<string> {
    let partialValues = [];
    const values = [];
    this.apiService.getComboValues(name).subscribe(data => {
      // // console.log(data);
      data.dataSource.forEach((val) => {

        if (type === 'TABLE') {
          val.columns.forEach((v, index) => {
            partialValues.push(v.value + ',');

          });
          values.push(partialValues);
          partialValues = [];
        } else {
          values.push(val.columns[0].value);
        }
      });
    });
    // // console.log(values);
    return values;
  }

  removeAddressBook(v: any, fieldName: string): void {
    this.addressBookSelectedToShow = [];
    console.log(this.dataMaskToSave);
    console.log(this.fields);
    const tempSelected = [];
    if (fieldName === 'To') {

      this.dataMaskToSave[fieldName].forEach((value, index) => {
        if (value.contactId === v.id) {
          this.dataMaskToSave[fieldName].splice(index, 1);
        }
      });
      this.dataMaskToSave[fieldName].forEach((value) => {
        this.addressBookSelectedToShow.push({name: value.society, id: value.contactId});
        tempSelected.push(value.contactId);
      });

    } else {
      this.dataMaskToSave[fieldName].value = {};
    }
    this.mapAddressBookToSee.set(fieldName, this.addressBookSelectedToShow);
    this.addressBookSelected.set(fieldName, tempSelected);
  }

  cleanField(name: string): void {
    this.dataMaskToSave[name] = '';
    this.fields.forEach(val => {
      if (val.name === name) {
        val.value = '';
      }
    });
  }

  cleanFields(): void {
    this.ngOnInit();
  }

  cleanFieldContact(name: string): void {
    this.dataMaskToSave[name] = '';
    this.fields.forEach(val => {
      if (val.name === name) {
        val.value = '';
      }
    });
    this.mapAddressBookToSee.set(name, '');
    this.addressBookSelected = new Map<string, string[]>();
  }

  openMultiValueDTO(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
        // console.log(results);
        this.fieldDataSource = new Array<object>();
        if (results.dataSource != null) {
          results.dataSource.forEach(item => {
            this.fieldDataSource.push(item.columns);
          });
        } else {
          // EMPTY MULTI VALUE
        }
        const dialogRef = this.dialog.open(DialogMultiDTOComponent, {
          id: 'style-4',
          width: '40%',
          height: '60%',
          data: {
            additionalFieldMultiDTO: this.fieldDataSource,
            fieldName: field.name,
            multiValueToSelected: this.multiValuteDTOSelected,
            selectField: results.selectField
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result.dataResult === 'delete') {
            this.multiValuteDTOSelectedToShow = new Array<any>();
            this.dataMaskToSave[this.fieldName] = '';
            this.mapMultiValueToSee.set(this.fieldName, '');
            this.multiValuteDTOSelected.set(this.fieldName, []);
          }
          this.multiValuteDTOSelectedToShow = new Array<any>();
          if (result.dataResult !== false) {
            result.dataResult.forEach(item => {
              this.multiValuteDTOSelectedToShow.push(item);
            });
            this.dataMaskToSave[this.fieldName] = result.dataResult;
            this.mapMultiValueToSave.set(this.fieldName, result.dataToSave); // non serve probabilmente
            this.multiValuteDTOSelected.set(this.fieldName, result.dataResult); // non serve probabilmente
            this.mapMultiValueToSee.set(this.fieldName, this.multiValuteDTOSelectedToShow);
          }
          this.getAllProfileValue();
        });
      });
    }
  }

  openTableDTO(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
        this.tableAssociationField = {};
        this.tableAssociationField = Object.keys(results.associations)
          .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
        this.fieldDataSource = new Array<object>();
        if (results.dataSource != null) {
          this.keyField = results.keyField;
          results.dataSource.forEach(item => {
            this.fieldDataSource.push(item.columns);
          });
        } else {
          // EMPTY MULTI VALUE
        }
        const dialogRef = this.dialog.open(DialogTableDTOComponent, {
          id: 'style-4',
          width: '100%',
          height: '95%',
          data: {
            additionalFieldTableDTO: this.fieldDataSource,
            fieldName: field.name,
            tableValueToSelected: this.tableValuteDTOSelected,
            selectField: results.selectField
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result.dataResult !== false) {
            this.mapTableValueToSave = result.dataResult;
            this.fields.forEach((item, index) => {
              if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                this.fields[index].value = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
                this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
              }
              if (item.name === this.fieldName) {
                this.fields[index].value = result.dataResult[this.keyField];
                this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.keyField];
              }
            });
          }
          this.getAllProfileValue();
        });
      });
    }
  }

  openAddressBook(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.SearchAllAddressBook().subscribe(results => {
        this.apiService.viewDataToShow = results;
        let name = field.name;
        if (field.description === 'Anagrafica cliente') {
          // name = 'From';
        } else {

        }
        const dialogRef = this.dialog.open(DialogAddressBookV2Component, {
          id: 'style-4',
          width: '80%',
          height: '95%',
          data: {
            fieldName: name,
            addressBookSelected: this.addressBookSelected,
            selectField: 'DM_RUBRICA_RAGIONE_SOCIALE',

          }
        });


        dialogRef.afterClosed().subscribe(result => {
          this.mapAddressBookSave = new Array<any>();
          let fromFieldValue = new Object();
          this.addressBookSelectedToShow = [];
          // this.addressBookSelected = new Map<string, string[]>();
          let type: string;
          switch (this.fieldName) {
            case 'To':
              type = '0';
              break;
            case 'From':
              type = '1';
              break;
            case 'Cc':
              type = '2';
              break;
            case 'Senders':
              type = '3';
              break;
          }
          result.dataResult.forEach((val, index) => {

            this.idContact = val;
            this.apiService.getContactAddressBook(this.idContact, type).subscribe(res => {
              // // console.log(res);
              if (type === '0') {
                this.mapAddressBookSave.push(res);
              } else {
                fromFieldValue = res;
                this.dataMaskToSave[this.fieldName] = fromFieldValue;
              }
              if (index === result.dataResult.length - 1) {
                if (result.dataResult !== false) {
                  result.contactsNames.forEach(item => {
                    this.addressBookSelectedToShow.push({name: item, id: val});
                  });
                  if (type === '0') {
                    this.dataMaskToSave[this.fieldName] = this.mapAddressBookSave;
                  } else {
                    this.dataMaskToSave[this.fieldName] = fromFieldValue;
                  }
                  this.addressBookSelected.set(this.fieldName, result.dataResult);
                  this.mapAddressBookToSee.set(this.fieldName, this.addressBookSelectedToShow);
                }

              }
            });
          });
        });
      });
    }
  }


  openComboValueDTO(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
        this.comboAssociationField = {};
        this.comboAssociationField = Object.keys(results.associations)
          .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
        this.fieldDataSource = new Array<object>();
        if (results.dataSource != null) {
          this.keyField = results.keyField;
          results.dataSource.forEach(item => {
            // console.log(item);
            this.fieldDataSource.push(item.columns[0]);
          });
        } else {
          // EMPTY MULTI VALUE
        }
        const dialogRef = this.dialog.open(DialogComboDTOComponent, {
          id: 'style-4',
          width: '100%',
          height: '95%',
          data: {
            additionalFieldComboDTO: this.fieldDataSource,
            fieldName: field.name,
            comboValueToSelected: this.comboValuteDTOSelected,
            selectField: results.selectField
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result.dataResult !== false) {
            this.fields.forEach((item, index) => {
              if (this.comboAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                this.fields[index].value = result.dataResult[this.comboAssociationField[item.name.toLowerCase()]];
                this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
              }
              if (item.name === this.fieldName) {
                this.fields[index].value = result.dataResult[this.keyField];
                this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.keyField];
              }
            });
          }
          this.getAllProfileValue();
        });
      });
    }
  }

  insertMaskFromClassDTO(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      const dialogRef = this.dialog.open(DialogMaskComponent, {
        width: '100%',
        height: 'fit-content',
        data: {
          id: field.insertMaskId,
          fieldName: field.name,
          dataMask: this.dataMaskToSave,
          dataAssociations: this.dataValueAssociations
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        // // console.log(result);
        if (result.hasOwnProperty('docNumber')) {
          this.docNumberClasseboxTemp = new Array<string>();
          this.docNumberClasseboxTemp = this.mapClasseBookToSave.get(result.fieldClassName);
          this.docNumberClasseboxTemp.push(result.docNumber);
          this.mapClasseBookToSave.set(result.fieldClassName, this.docNumberClasseboxTemp);
          this.arrayToObject = {};
          this.apiService.getProfileDetailsClass(result.docNumber).subscribe(callResults => {
            callResults.fields.forEach(fieldClass => {
              this.displayedColumnsClass.forEach(colum => {
                if (colum === fieldClass.description) {
                  this.arrayToObject[colum] = fieldClass.value;
                }
              });
            });
            this.ELEMENT_DATA_CLASS.push(this.arrayToObject);
            this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
            // // console.log(this.ELEMENT_DATA_CLASS);
          });
        }
      });
    }
  }

  openClassMasks(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      const dialogRef = this.dialog.open(DialogClassViewDTOComponentDTOComponent, {
        width: '100%',
        height: 'fit-content',
        data: {
          classField: field,
          mapClassValue: this.mapClasseBookToSave.get(field.name)
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        // // console.log(this.mapClasseBookToSave.get(result.fieldName));
      });
    }
  }

  searchMaskFromClassDTO(field: any): void {
    if (!field.locked) {
      const dialogRef = this.dialog.open(DialogSearchDocComponent, {
        width: '100%',
        height: 'fit-content',
        data: {
          classField: field
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        //// // console.log(this.mapClasseBookToSave.get(result.fieldName));
        // // console.log(res);
        res.dataResult.forEach(result => {
          this.docNumberClasseboxTemp = new Array<string>();
          // // console.log(this.mapClasseBookToSave);
          this.docNumberClasseboxTemp = this.mapClasseBookToSave.get(result.fieldName);
          // // console.log(this.docNumberClasseboxTemp);

          this.docNumberClasseboxTemp.push(result.docNumber);
          this.mapClasseBookToSave.set(result.fieldName, this.docNumberClasseboxTemp);
          this.arrayToObject = {};
          this.apiService.getProfileDetailsClass(result.docNumber).subscribe(callResults => {
            callResults.fields.forEach(fieldClass => {
              this.displayedColumnsClass.forEach(colum => {
                if (colum === fieldClass.description) {
                  this.arrayToObject[colum] = fieldClass.value;
                }
              });
            });
            this.ELEMENT_DATA_CLASS.push(this.arrayToObject);
            this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
            // // console.log(this.ELEMENT_DATA_CLASS);
          });
        });
      });
    }
  }

  onResize(event): void {
    this.breakpoint = (event.target.innerWidth <= 600) ? 4 : 6;
  }

  showFileSelection(event): void {
    this.fileInfo = {name: '', buffer: ''};
    this.fileListMain = event.target.files;
    if (this.fileListMain.length > 0) {
      this.fileMain = this.fileListMain[0];
      this.formDataMain = new FormData();
      this.formDataMain.append('file', this.fileMain, this.fileMain.name);
      this.apiService.openBuffer(this.formDataMain).subscribe(result => {
        this.fileInfo.name = this.fileMain.name;
        this.fileInfo.buffer = result[0];
        this.bufferIdInfo.push(this.fileInfo);
        this.badgeBufferMain = this.bufferIdInfo.length;
      });
    }
  }

  showAttachments(): void {
  }

  showNotes(): void {
  }

  removeFile(file: any): void {
    this.apiService.deleteBuffer(file).subscribe(result => {
      if (result) {
        this.snackBar.open('File ' + file.name + ' eliminato!', null, {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });
        this.bufferIdInfo.forEach((item, index) => {
          if (item.buffer === file.buffer) {
            this.bufferIdInfo.splice(index, 1);
          }
        });
        this.badgeBufferMain = this.bufferIdInfo.length;
      }
    });
  }

  saveMask(): void {
    this.isLoading = true;
    this.observable = new Observable(this.myObservable);
    this.showProgressSpinnerUntilExecuted(this.observable);

    /* this.validationCheck = true;
     this.fields.forEach(field => {
         if (field.visible) {
             if (field.required) {
                 // console.log(this.dataMaskToSave);
                 if ((field.value === '' || field.value == null)
                     && (this.dataMaskToSave[field.name] === '' || this.dataMaskToSave[field.name] == null)) {
                     this.snackBar.open('Il campo ' + field.description + ' è obbligatorio', null, {
                         duration: 5000,
                         horizontalPosition: 'end',
                         verticalPosition: 'bottom',
                         panelClass: ['mat-toolbar', 'custom-danger-bg']
                     });
                     this.validationCheck = false;
                 }
             }
         }
     });
     if (this.validationCheck) {

     }*/
    this.buildDataMask();
  }

  buildDataMask(): void {
    if (this.bufferIdInfo.length > 0) {
      this.jsonToSendArxivar = {
        searchFilterDtoList: [
          {
            daAAndOr: 0,
            description: 'Ricerca 1',
            fields: []
          }
        ]
      };
    } else {
      this.jsonToSendArxivar = {
        searchFilterDtoList: [
          {
            daAAndOr: 0,
            description: 'Ricerca 1',
            fields: []
          }
        ],
        selectFilterDto: {
          fields: [
            {
              fieldType: 0,
              selected: true,
              name: 'NOTE',
              index: 0,
              orderBy: {
                direction: 0,
                index: 0
              }
            },
            {
              fieldType: 0,
              selected: true,
              name: 'ALLEGATI',
              index: 2,
              orderBy: {
                direction: 0,
                index: 0
              }
            },
            {
              fieldType: 0,
              selected: true,
              name: 'STATO',
              index: 3,
              orderBy: {
                direction: 0,
                index: 0
              }
            },
            {
              fieldType: 0,
              selected: true,
              name: 'WORKFLOW',
              index: 1,
              orderBy: {
                direction: 0,
                index: 0
              }
            },
            {
              fieldType: 0,
              selected: true,
              name: 'FORMATO',
              index: 4,
              orderBy: {
                direction: 0,
                index: 0
              }
            },
            {
              fieldType: 0,
              selected: true,
              name: 'DOCNAME',
              index: 8,
              orderBy: {
                direction: 0,
                index: 0
              }
            },
            {
              fieldType: 0,
              selected: true,
              name: 'DATADOC',
              index: 11,
              orderBy: {
                direction: 0,
                index: 0
              }
            },
            {
              fieldType: 0,
              selected: true,
              name: 'MITTENTE',
              index: 5,
              orderBy: {
                direction: 0,
                index: 0
              }
            },
            {
              fieldType: 0,
              selected: true,
              name: 'DESTINATARIO',
              index: 6,
              orderBy: {
                direction: 0,
                index: 0
              }
            },
            {
              fieldType: 0,
              selected: true,
              name: 'CC',
              index: 7,
              orderBy: {
                direction: 0,
                index: 0
              }
            },
            /*{
              fieldType: 2,
              selected: true, // true
              name: 'TESTO44_14', // TESTO13_5 - pubblico  TESTO44_qualcosa - privato protocollo specifico
              index: 10,
              orderBy: {
                direction: 1,
                index: 0
              }
            },*/
            {
              fieldType: 0,
              selected: true,
              name: 'DOCNUMBER',
              index: 999,
              orderBy: null
            },
            {
              fieldType: 0,
              selected: true,
              name: 'REVISIONE',
              index: 9,
              orderBy: {
                direction: 0,
                index: 0
              }
            },
            {
              fieldType: 0,
              selected: false,
              name: 'AOO',
              index: 999,
              orderBy: null
            },
            {
              fieldType: 0,
              selected: false,
              name: 'ORIGINALE',
              index: 999,
              orderBy: null
            },
            {
              fieldType: 0,
              selected: false,
              name: 'CLASSEDOC',
              index: 999,
              orderBy: null
            },
            {
              fieldType: 0,
              selected: false,
              name: 'WFVERSIONSTATE',
              index: 999,
              orderBy: null
            }
          ],
          maxItems: 0
        }
      };
    }console.log(this.dataMaskToSave);
    this.fields.forEach((field, index) => {
      if (field.className === 'FieldBaseForSearchAooDto' && this.dataMaskToSave[field.name] !== undefined) {
        this.nomiAoo.forEach(v => {
          if (v.code === this.dataMaskToSave[field.name]) {
            this.fields[index].valore1 = v;
            this.fields[index].value = this.dataMaskToSave[field.name];
            this.fields[index].operator = this.fields[index].defaultOperator;
          }
        });
      } else if (field.className === 'FieldBaseForSearchDateTimeDto' && this.dataMaskToSave[field.name + 'valore1']) {

        field.operator = 7;
        field.valore1 = this.TransformDataFormatToSave(this.dataMaskToSave[field.name + 'valore1']);
        if (this.dataMaskToSave[field.name + 'valore2']) {
          field.valore2 = this.TransformDataFormatToSave(this.dataMaskToSave[field.name + 'valore2']) + 'T23:59:59+01:00';
        } else {
          field.valore2 = field.valore1 + 'T23:59:59+01:00';
        }
      } else if (field.className === 'FieldBaseForSearchContactDto' && this.dataMaskToSave[field.name] !== undefined) {
        // console.log(this.dataMaskToSave[field.name]);
        this.fields[index].valore1.modality = 1;
        this.fields[index].valore1.objs = this.dataMaskToSave[this.fieldName];
        if (this.fieldName === 'From') {
          this.fields[index].valore1.values = [];
          this.fields[index].valore1.values.push(this.dataMaskToSave[this.fieldName].contactId);
        } else {
          if (this.dataMaskToSave[this.fieldName]){
            this.dataMaskToSave[this.fieldName].forEach(val => {
              this.fields[index].valore1.values = [];
              this.fields[index].valore1.values.push(val.contactId);
            });
          }
        }
        this.fields[index].operator = this.fields[index].defaultOperator;
      } else if (field.className === 'FieldBaseForSearchDocumentTypeDto' && this.dataMaskToSave[field.name] !== '') {
        this.documenTypes.forEach(v => {
          if (v.id === this.dataMaskToSave[field.name]) {
            this.fields[index].value = v.id;
            this.fields[index].valore1 = v;
            this.fields[index].operator = this.fields[index].defaultOperator;
          }
        });
      } else {
        if (this.dataMaskToSave[field.name] !== undefined && this.dataMaskToSave[field.name] !== '') {
          this.fields[index].valore1 = this.dataMaskToSave[field.name];
          this.fields[index].operator = this.fields[index].defaultOperator;
        }
      }


      // // // console.log(this.dataMaskToSave[field.name]);

      // // // console.log(field);
      this.jsonToSendArxivar.searchFilterDtoList[0].fields.push(this.fields[index]);
    });
    console.log(this.jsonToSendArxivar);
    this.apiService.viewBody = this.jsonToSendArxivar;
    this.apiService.doSearch(this.jsonToSendArxivar).subscribe(results => {
      this.apiService.viewDataToShow = results;
      this.isLoading = false;
      this.router.navigate(['../view', {firstnodepath: 'ricerca'}], {relativeTo: this.route});
    }, errorLog => {
      this.isLoading = false;
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 7000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });

  }

  TransformDataFormatToSave(Data: string): string {

    if (Data === '') {
      return '0001-01-01T00:00:00';
    } else {
      return this.datePipe.transform(Data, 'yyyy-MM-dd', '', 'en-GB');
    }
  }

  TransformDataFormatToSave2(Data: string): string {

    if (Data === '') {
      return '0001-01-01T00:00:00';
    } else {
      return this.datePipe.transform(Data, 'yyyy-MM-ddEEEEEh:mm:ssZZZZZ', '', 'en-GB');
    }


  }

  getAllProfileValue(): void {
    this.fields.forEach((val, index) => {
      if (val.className === 'AdditionalFieldComboDTO' || val.className === 'AdditionalFieldTableDTO' || val.className === 'AdditionalFieldMultivalueDTO') {
        this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
          if (results.dataSource.length === 0) {
            this.fields[index].value = '';
            this.dataMaskToSave[this.fields[index].name] = '';
          }
        });
      }
    });
  }

  aooChanged(): void {
    this.apiService.getDocTypeModeWithAoo('0', this.dataMaskToSave.Aoo).subscribe(data => {
      this.documenTypes = new Array<any>();
      data.forEach(v => {
        if (v.isLeaf) {
          this.documenTypes.push(v);
        }
      });
    });
  }

  opendocumentalClassDialog(): void {
    this.apiService.classDocMode = '0';
    this.apiService.classDocAoo = this.dataMaskToSave.Aoo;
    const dialogRef = this.dialog.open(DialogDocumentalClassComponent, {
      width: '40%',
      height: '80%',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result) {
        this.fields.forEach(v => {
          if (v.name === 'DocumentType') {
            this.dataMaskToSave[v.name] = Number(result.docClassId);
            v.value = Number(result.docClassId);
            this.documentChanged({isUserInput: true}, result.docType, result.type1, result.type2);
          }
        });
      }
    });
  }

  getJsonFields(): any {
    if (this.bufferIdInfo.length > 0) {
      this.jsonToSendArxivar = {
        document: {
          fileNames: [],
          bufferIds: []
        },
        fields: []
      };
      this.bufferIdInfo.forEach(file => {
        this.jsonToSendArxivar.document.fileNames.push(file.name);
        this.jsonToSendArxivar.document.bufferIds.push(file.buffer);
      });
    } else {
      this.jsonToSendArxivar = {
        fields: []
      };
    }
    // console.log(this.dataMaskToSave);
    this.fields.forEach((field, index) => {
      if (field.visible) {
        // // console.log(this.dataMaskToSave);
        if (field.className === 'FieldBaseForSearchDateTimeDto') {
          this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
        } else {
          this.fields[index].value = this.dataMaskToSave[field.name];
        }


        // // // console.log(this.dataMaskToSave[field.name]);

        // // // console.log(field);
      }
      this.jsonToSendArxivar.fields.push(this.fields[index]);
    });
    return this.jsonToSendArxivar;
  }

  goBack(): void {
    if (this.idTask !== '0') {
      this.router.navigate(['../dettaglitask', {id: this.idTask, path: this.firstnodepath}], {relativeTo: this.route});
    } else {
      this.router.navigate(['../' + this.path + ''], {relativeTo: this.route});
    }
  }
}
