import {RouteReuseStrategy} from '@angular/router/';
import {ActivatedRouteSnapshot, DetachedRouteHandle} from '@angular/router';
import {ApiService} from './api.service';
import {Injectable} from '@angular/core';

@Injectable()
export class CacheRouteReuseStrategy implements RouteReuseStrategy {
  storedRouteHandles = new Map<string, DetachedRouteHandle>();
  allowRetriveCache = {
    fascicoli: true,
    fascicolo: true,
    view: true,
    profile: true,
    viewDetails: true,
    defaulthome: true,
    ricerca: true,
    rubrica: true,
    dettaglitask: true,
    maskDetails: true
  };

  constructor(public apiService: ApiService) {

  }

  shouldReuseRoute(before: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // console.log(this.apiService.namePathV2);

    if (this.getPath(before) === 'fascicoli' && this.getPath(curr) === 'redirectfolder') {
      this.allowRetriveCache.fascicoli = true;
    } else {
      this.allowRetriveCache.fascicoli = false;
    }
    if (this.getPath(before) === 'fascicolo' && this.getPath(curr) === 'redirectfolder') {
      this.allowRetriveCache.fascicolo = true;
    } else {
      this.allowRetriveCache.fascicolo = false;
    }
    if (this.getPath(before) === 'view' && this.getPath(curr) === 'redirect') {
      this.allowRetriveCache.view = true;
    } else {
      this.allowRetriveCache.view = false;
    }
    if (this.getPath(before) === 'rubrica' && this.getPath(curr) === 'redirect') {
      this.allowRetriveCache.rubrica = true;
    } else {
      this.allowRetriveCache.rubrica = false;
    }
    if (this.getPath(before) === 'profile' && this.getPath(curr) === 'redirect') {
      this.allowRetriveCache.profile = false;  // Disattivato
    } else {
      this.allowRetriveCache.profile = false;
    }
    if (this.getPath(before) === 'viewDetails' && this.getPath(curr) === 'redirect') {
      this.allowRetriveCache.viewDetails = true;
    } else {
      this.allowRetriveCache.viewDetails = false;
    }
    if (this.getPath(before) === 'defaulthome' && this.getPath(curr) === 'redirect') {
      this.allowRetriveCache.defaulthome = true;
    } else {
      this.allowRetriveCache.defaulthome = false;
    }
    if (this.getPath(before) === 'ricerca' && this.getPath(curr) === 'redirect') {
      this.allowRetriveCache.ricerca = false;
    } else {
      this.allowRetriveCache.ricerca = false;
    }
    if (this.getPath(before) === 'dettaglitask' && this.getPath(curr) === 'redirect') {
      this.allowRetriveCache.dettaglitask = true;
    } else {
      this.allowRetriveCache.dettaglitask = false;
    }

    if (before.url[0]) {
      if (this.getPath(before) === 'maskDetails' && this.getPath(curr) === 'redirect' && before.url[0].parameters.idTask !== '0' && !curr.params.current.includes('maskDetails')) {
        this.allowRetriveCache.maskDetails = true;
      } else {
        this.allowRetriveCache.maskDetails = false;
      }
    }else{
      this.allowRetriveCache.maskDetails = false;
    }

    return before.routeConfig === curr.routeConfig;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPath(route);
    if (!this.apiService.isMenuClick) {
      if (this.allowRetriveCache.fascicoli) {
        return this.storedRouteHandles.has(this.getPath(route));
      }
      if (this.allowRetriveCache.fascicolo) {
        return this.storedRouteHandles.has(this.getPath(route));
      }
      if (this.allowRetriveCache.view) {
        return this.storedRouteHandles.has(this.getPath(route));
      }
      if (this.allowRetriveCache.profile) {
        return this.storedRouteHandles.has(this.getPath(route));
      }
      if (this.allowRetriveCache.viewDetails) {
        return this.storedRouteHandles.has(this.getPath(route));
      }
      if (this.allowRetriveCache.defaulthome) {
        return this.storedRouteHandles.has(this.getPath(route));
      }
      if (this.allowRetriveCache.ricerca) {
        return this.storedRouteHandles.has(this.getPath(route));
      }
      if (this.allowRetriveCache.rubrica) {
        return this.storedRouteHandles.has(this.getPath(route));
      }
      if (this.allowRetriveCache.dettaglitask) {
        return this.storedRouteHandles.has(this.getPath(route));
      }
      if (this.allowRetriveCache.maskDetails) {
        return this.storedRouteHandles.has(this.getPath(route));
      }
    }
    return false;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPath(route);
    if (this.allowRetriveCache.hasOwnProperty(path)) {
      return true;
    }
    return false;
  }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
    this.storedRouteHandles.set(this.getPath(route), detachedTree);
  }

  private getPath(route: ActivatedRouteSnapshot): string {
    if (route.routeConfig !== null && route.routeConfig.path !== null) {
      return route.routeConfig.path;
    }
    return '';
  }
}
