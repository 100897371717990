<!--<button style="float:left" title="Indietro" style="color: {{this.dashColor}}" (click)="goBack()" mat-icon-button
        aria-label="icon-button send">
    <mat-icon>keyboard_backspace</mat-icon>
</button>-->
<mat-form-field style="width: 50%; float: left">
  <mat-label>{{'labels.filter' | translate}}</mat-label>
  <input matInput [(ngModel)]="this.filter" (ngModelChange)="this.filterChange()">
  <button mat-button matSuffix mat-icon-button aria-label="Filtri" style="color: {{this.dashColor}}"
          (click)="openFilterDialog()">
    <mat-icon>filter_list</mat-icon>
  </button>
  <button mat-button *ngIf="this.filter" style="color: {{this.dashColor}}" matSuffix mat-icon-button
          (click)="this.filter=''; this.filterChange()">
    <mat-icon>close</mat-icon>
  </button>

</mat-form-field>
<mat-form-field appearance="legacy" style="float: left; margin-left: 2%; color: {{this.dashColor}}">
  <mat-label>{{'labels.filterCategory' | translate}}</mat-label>
  <mat-select (selectionChange)="this.filterChange()" [(ngModel)]="this.selectedCategory" [ngModelOptions]="{standalone: true}">
    <ng-container *ngFor="let category of this.categories">
      <mat-option value="{{category}}">{{category}}</mat-option>
    </ng-container>
    <mat-option value="-" *ngIf = "!this.enabled">{{'labels.noFilter' | translate}}</mat-option>
  </mat-select>
</mat-form-field>
<button mat-stroked-button title="{{'labels.cancelSelection' | translate}}" *ngIf="numSelected > 0" (click)="this.cancelSelection()"
        style="float: left; margin-left: 2%;width: 10%; color: {{this.dashColor}}">
  Annulla({{this.numSelected}})
</button>
<button style="float: right; color: red;" title="{{'labels.close' | translate}}" (click)="closeDialogNoSave()" mat-icon-button
        aria-label="icon-button save">
  <mat-icon>close</mat-icon>
</button>
<button style="float: right; color:{{this.squareColor}};" title="{{'labels.saveAndClose' | translate}}" (click)="closeDialog()" mat-icon-button
        aria-label="icon-button save">
  <mat-icon>save</mat-icon>
</button>
<button mat-icon-button title="{{'labels.update' | translate}}" (click)="this.refreshView()" style="float: right; color: {{this.dashColor}}">
  <mat-icon>autorenew</mat-icon>
</button>
<button style="float:right; color:{{this.dashColor}};" title="{{'labels.new' | translate}}" (click)="addContact()" mat-icon-button
        aria-label="icon-button send">
  <mat-icon>add</mat-icon>
</button>
<br>
<hr class="riga" style="color: {{this.dashColor}}; width: 100%">
<br>
<mat-card-content style="height: 86%; overflow-y: hidden">
  <ng-container *ngIf="noItems == false">
    <div class="example-container mat-elevation-z8 " id="style-4">

      <table mat-table [dataSource]="dataSource" style="text-align: start"
             cdkDropList matSort
             cdkDropListOrientation="horizontal"
             (cdkDropListDropped)="drop($event)"
             class="mat-elevation-z8" style="border-spacing: 0px !important;">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->

        <ng-container *ngFor="let colonna of displayedColumns" matColumnDef="{{colonna}}">
          <ng-container>
            <ng-container
              *ngIf="colonna != 'RELAZIONI,Relazioni' && colonna != '-1,Azioni' && colonna != 'INOUT,Origine' && colonna != 'NOTE,Note' && colonna != 'WORKFLOW,Workflow' && colonna != 'ALLEGATI,Allegati' && colonna != 'STATO,Stato' && colonna != 'FORMATO,Formato' && colonna != 'select'">
              <ng-container *ngIf="colonna != 'DOCNUMBER,System ID' &&  colonna != 'selected'">
                <th mat-header-cell style="background-color: white; padding-left: 0px;" class="headers"
                    cdkDrag mat-sort-header
                    *matHeaderCellDef>{{(colonna.substr(colonna.indexOf(',') + 1, colonna.length))}}</th>
              </ng-container>
              <ng-container *ngIf="colonna == 'DOCNUMBER,System ID' || colonna == 'selected'">
                <th mat-header-cell style="background-color: white; width: 0px; z-index: -100;"
                    cdkDrag mat-sort-header
                    *matHeaderCellDef>{{(colonna.substr(colonna.indexOf(',') + 1, colonna.length))}}</th>
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="colonna == 'RELAZIONI,Relazioni' || colonna == '-1,Azioni' || colonna == 'INOUT,Origine' || colonna == 'NOTE,Note' || colonna == 'WORKFLOW,Workflow' || colonna == 'ALLEGATI,Allegati' || colonna == 'STATO,Stato' || colonna == 'FORMATO,Formato'">
              <th mat-header-cell class="headers"
                  style="width: 65px !important; background-color: white;"
                  cdkDrag mat-sort-header *matHeaderCellDef>
                <ng-container
                  *ngIf="colonna != 'INOUT,Origine' && colonna != 'NOTE,Note' && colonna != 'WORKFLOW,Workflow' && colonna != 'ALLEGATI,Allegati' && colonna != 'FORMATO,Formato' && colonna != 'STATO,Stato' && colonna != 'select'">{{(colonna.substr(colonna.indexOf(',') + 1, colonna.length))}}</ng-container>
                <ng-container *ngIf="colonna == 'FORMATO,Formato'">
                  <mat-icon title="Documento">picture_as_pdf</mat-icon>
                </ng-container>
                <ng-container *ngIf="colonna == 'STATO,Stato'">
                  <mat-icon title="Stato">clear</mat-icon>
                </ng-container>
                <ng-container *ngIf="colonna == 'ALLEGATI,Allegati'">
                  <mat-icon title="Allegati">attachment</mat-icon>
                </ng-container>
                <ng-container *ngIf="colonna == 'WORKFLOW,Workflow'">
                  <mat-icon title="Workflow">engineering</mat-icon>
                </ng-container>
                <ng-container *ngIf="colonna == 'NOTE,Note'">
                  <mat-icon title="Note">note</mat-icon>
                </ng-container>
                <ng-container *ngIf="colonna == 'INOUT,Origine'">
                  <mat-icon title="Origine">call_made</mat-icon>
                </ng-container>
              </th>
            </ng-container>
            <ng-container
              *ngIf="colonna != 'RELAZIONI,Relazioni' && colonna != '-1,Azioni' && colonna != 'INOUT,Origine' && colonna != 'NOTE,Note' && colonna != 'WORKFLOW,Workflow' && colonna != 'ALLEGATI,Allegati' && colonna != 'STATO,Stato' && colonna != 'FORMATO,Formato' && colonna != 'select'">
              <td mat-cell *matCellDef="let element" id = "style-5"
                  style="text-align: left;  padding-left: 2px;padding-right: 2px;"
                  (contextmenu)="open($event, element, element['DOCNUMBER,System ID']); $event. preventDefault();"
                  #tooltip="matTooltip"
                  matTooltip="{{element[colonna]}}"
                  [matTooltipPosition]="'above'" [matTooltipShowDelay]="500"
                  matTooltipHideDelay="100">
                <nobr>
                  <ng-container
                    *ngIf="colonna.startsWith('DATA')">
                    {{element[colonna]}}
                  </ng-container>
                  <ng-container
                    *ngIf="colonna.startsWith('NOTE') == false && colonna.startsWith('ALLEGATI') == false && colonna.startsWith('RELAZIONI') == false && colonna.startsWith('STATO') == false && colonna.startsWith('-1') == false && colonna.startsWith('INOUT') == false && colonna.startsWith('DATA') == false && colonna.startsWith('DOCNUMBER') == false  && (colonna.substr(colonna.indexOf(',')+1, colonna.length)) != 'Workflow' && (colonna.substr(colonna.indexOf(',')+1, colonna.length)) != 'Formato'">
                    {{element[colonna]}}
                  </ng-container>
                </nobr>
              </td>
            </ng-container>


            <!--  COMANDI -->
            <ng-container
              *ngIf="(colonna == 'RELAZIONI,Relazioni' || colonna == '-1,Azioni' || colonna == 'INOUT,Origine' || colonna == 'NOTE,Note' || colonna == 'WORKFLOW,Workflow' || colonna == 'ALLEGATI,Allegati' || colonna == 'STATO,Stato' || colonna == 'FORMATO,Formato')">
              <td mat-cell *matCellDef="let element" style="text-align: left" id = "style-5"
                  (contextmenu)="open($event, element, element['DOCNUMBER,System ID']); $event. preventDefault();">

                <ng-container [ngSwitch]="colonna">
                  <ng-container
                    *ngSwitchCase="'STATO,Stato'">
                    <button mat-icon-button>
                      <mat-icon title="{{element[colonna]}}">
                        {{this.apiService.getState(element[colonna])}}
                      </mat-icon>
                    </button>
                  </ng-container>
                  <ng-container
                    *ngSwitchCase="'INOUT,Origine'">
                    <button mat-icon-button>
                      <ng-container *ngIf="element[colonna] == '0'">
                        <mat-icon title="Uscita">call_made</mat-icon>
                      </ng-container>
                      <ng-container *ngIf="element[colonna] == '1'">
                        <mat-icon title="Entrata">call_received</mat-icon>
                      </ng-container>
                      <ng-container *ngIf="element[colonna] == '2'">
                        <mat-icon title="Interno">swap_horizontal</mat-icon>
                      </ng-container>
                    </button>
                  </ng-container>
                  <ng-container
                    *ngSwitchCase="'ALLEGATI,Allegati'">
                    <ng-container *ngIf="element[colonna] != '0'">
                      <button mat-icon-button style="color: {{this.squareColor}};"
                              (click)="openShowAttachmentsDialog(element['DOCNUMBER,System ID'])">
                        <mat-icon>attachment</mat-icon>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="element[colonna] == '0'">
                      <button mat-icon-button
                              (click)="openShowAttachmentsDialog(element['DOCNUMBER,System ID'])">
                        <mat-icon>attachment</mat-icon>
                      </button>
                    </ng-container>
                  </ng-container>
                  <ng-container
                    *ngSwitchCase="'WORKFLOW,Workflow'">
                    <ng-container *ngIf="(element[colonna]) == 1">
                      <button mat-icon-button
                              (click)="goToProcess(element['DOCNUMBER,System ID'])">
                        <mat-icon title="In corso">engineering</mat-icon>
                      </button>
                      <br>
                    </ng-container>
                    <ng-container *ngIf="(element[colonna]) == 2">
                      <button mat-icon-button
                              (click)="goToProcess(element['DOCNUMBER,System ID'])">
                        <mat-icon title="Concluso">more_horiz</mat-icon>
                      </button>
                      <br>
                    </ng-container>
                    <ng-container *ngIf="(element[colonna]) == 0">
                      <button mat-icon-button
                              (click)="goToProcess(element['DOCNUMBER,System ID'])">
                        <b>Concluso</b>
                        <mat-icon title="In attesa">done_all</mat-icon>
                      </button>
                      <br>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'FORMATO,Formato'">
                    <ng-container
                      *ngIf="(element[colonna] != '')">
                      <button mat-icon-button
                              (click)="downloadDoc(element['DOCNUMBER,System ID'])">
                        <ng-container
                          *ngIf="element[colonna]=='pdf'">
                          <mat-icon title="{{element[colonna]}}"
                                    style="color: darkred;">
                            picture_as_pdf
                          </mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna] =='PDF'">
                          <mat-icon title="{{element[colonna]}}"
                                    style="color: darkred;">
                            picture_as_pdf
                          </mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna] =='Pdf'">
                          <mat-icon title="{{element[colonna]}}"
                                    style="color: darkred;">
                            picture_as_pdf
                          </mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='docx'">
                          <mat-icon title="{{element[colonna]}}"
                                    style="color: darkblue;">
                            description
                          </mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='doc'">
                          <mat-icon title="{{element[colonna]}}"
                                    style="color: darkblue;">
                            article
                          </mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='xlsx'">
                          <mat-icon title="{{element[colonna]}}"
                                    style="color: darkgreen;">
                            ballot
                          </mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='csv'">
                          <mat-icon title="{{element[colonna]}}"
                                    style="color: darkgreen;">
                            dashboard
                          </mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='msg'">
                          <mat-icon title="{{element[colonna]}}">email</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='zip'">
                          <mat-icon title="{{element[colonna]}}">topic</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='jpg'">
                          <mat-icon title="{{element[colonna]}}">insert_photo
                          </mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='p7m'">
                          <mat-icon title="{{element[colonna]}}">mode</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='xml'">
                          <mat-icon title="{{element[colonna]}}">code</mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]=='png' || element[colonna]=='PNG'">
                          <mat-icon title="{{element[colonna]}}">insert_photo
                          </mat-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="element[colonna]!='PNG' && element[colonna]!='png' && element[colonna]!='xml' && element[colonna]!='p7m' && element[colonna]!='Pdf' && element[colonna]!='PDF' && element[colonna]!='zip' && element[colonna]!='jpg' && element[colonna]!='msg' && element[colonna]!='docx' && element[colonna]!='pdf' && element[colonna]!='doc' && element[colonna]!='xlsx' && element[colonna]!='csv'">
                          {{element[colonna]}}
                        </ng-container>
                      </button>
                    </ng-container>
                  </ng-container>
                  <ng-container
                    *ngSwitchCase="'NOTE,Note'">
                    <ng-container *ngIf="element[colonna] != null">
                      <button mat-icon-button style="color: {{this.squareColor}};"
                              (click)="openProfileNotesDialog(element['DOCNUMBER,System ID'])">
                        <mat-icon>note</mat-icon>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="element[colonna] == null">
                      <button mat-icon-button
                              (click)="openProfileNotesDialog(element['DOCNUMBER,System ID'])">
                        <mat-icon>note</mat-icon>
                      </button>
                    </ng-container>
                  </ng-container>

                  <ng-container
                    *ngSwitchCase="'RELAZIONI,Relazioni'">
                    <button *ngIf="element[colonna] == '1'" mat-icon-button
                            (click)="openRelationsDialog(element['DOCNUMBER,System ID'])">
                      <mat-icon title="Relazioni">account_tree</mat-icon>
                    </button>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    {{element[colonna]}}
                  </ng-container>
                </ng-container>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"
            style="height: 35px;"></tr>
        <ng-container *matRowDef="let row; columns: displayedColumns;">
          <tr mat-row [ngClass]="getClass(row.selected)"
              style="height: 50px !important; max-height: 50px !important;"
              (click)="DBclickedRow($event, row, row['ID,' + this.translate.instant('labels.contactId')], row['DM_RUBRICA_RAGIONE_SOCIALE,' + this.translate.instant('labels.companyName')])"></tr>
        </ng-container>

      </table>
      <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]="[40, 80, 120, 160, 200]"
                     showFirstLastButtons style="float: right"></mat-paginator>
    </div>
  </ng-container>
  <ng-container *ngIf="noItems == true">
    <h1 style="color: {{this.dashColor}}">Nessun risultato trovato</h1>
  </ng-container>
</mat-card-content>


<ng-template #userMenu let-user>
  <section class="user-menu">

  </section>
</ng-template>
