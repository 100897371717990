import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SelectedRowsMultiDTO} from '../VociMenu/maskDetails/maskDetails.component';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {ApiService} from '../api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogMaskComponent} from './dialogMaskComponents';
import {DialogProfileComponent} from './dialogProfileComponents';
import {BodyFieldToSave} from '../bodyFieldToSave';
import {DatePipe} from '@angular/common';
import {MatSort, MatSortable} from '@angular/material/sort';
import {
  EditFromFileProfileComponent,
  EditRevisionProfileComponent, ProfileNotesComponent, RevisionsComponent,
  ShowAttachmentsDialogComponent,
  SignerDetailsComponent
} from '../MenuItemsV2/view/view.component';
import * as fileSaver from 'file-saver';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-dialogclassviewdto',
  styleUrls: ['classDTO-css.css'],
  templateUrl: 'dialog-content-classViewDTO.html'
})

export class DialogClassViewDTOComponentDTOComponent implements OnInit, AfterViewInit {
  ELEMENT_DATA: Array<object> = new Array<object>();
  arrayToObject: { [index: string]: { content: string } } = {};
  dataSource: MatTableDataSource<object>;
  docNumbers: Array<string> = new Array<string>();
  displayedColumns: Array<string> = new Array<string>();
  squareColor: string;
  field: any;
  find = false;
  systemIdObject: any = {systemId: ''};
  commands: any;
  folders: Array<any> = new Array<any>();
  buildResult: Array<string> = new Array<string>();
  Result: Array<any> = new Array<any>();
  constructor(public translate: TranslateService, public dialogRef: MatDialogRef<DialogClassViewDTOComponentDTOComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService, private snackBar: MatSnackBar, private route: ActivatedRoute, private router: Router,
              public dialog: MatDialog, private datePipe: DatePipe) {
    this.field = data.classField;
    this.docNumbers = data.mapClassValue;
    this.displayedColumns = new Array<string>();
    this.squareColor = this.apiService.squareColor;
  }
  @ViewChild(MatSort) sort: MatSort;
  ngAfterViewInit(): void {
    this.sort.sort(({id: 'DOCNUMBER,System ID', start: 'desc'}) as MatSortable);
    this.dataSource.sort = this.sort;
  }
  ngOnInit(): void {
    this.folders = this.apiService.commandFolders;
    this.commands = this.apiService.profileCommands;
    this.displayedColumns = new Array<string>();
    this.field.columns.forEach(item => {
      this.displayedColumns.push(item.name);
    });

    /*this.docNumbers.forEach(systemId => {
      this.dataSource = new MatTableDataSource<object>();
      this.ELEMENT_DATA = new Array<object>();
      this.apiService.getProfileDetailsClass(systemId).subscribe(callResults => {
        this.arrayToObject = {};
        callResults.fields.forEach(fieldClass => {
          this.systemIdObject = {systemId: ''};
          this.displayedColumns.forEach(colum => {
            if (colum === fieldClass.name) {
              console.log(fieldClass);
              if (fieldClass.className === 'AdditionalFieldDateTimeDTO'){
                this.arrayToObject[colum] = this.TransformDataFormatToShow(fieldClass.value);
              }else{
                this.arrayToObject[colum] = fieldClass.value;
              }
            }
          });
        });
        this.systemIdObject.systemId = systemId;
        this.arrayToObject.Azioni = this.systemIdObject.systemId;
        this.ELEMENT_DATA.push(this.arrayToObject);
        console.log(this.ELEMENT_DATA);
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      });
    });*/
    this.Result = new Array<any>();
    this.displayedColumns = new Array<string>();
    this.buildResult = new Array<string>();

    this.squareColor = this.apiService.squareColor;

    // this.displayedColumns.push('select');     SELEZIONE MULTIPLA
    // this.displayedColumns.push('selected');
    console.log(this.apiService.viewDataToShow);
    this.apiService.viewDataToShow.columns.forEach(val => {
      if (val.visible || val.id === 'DOCNUMBER') {

        this.displayedColumns.push(val.id + ',' + val.label);
      }
    });

    this.displayedColumns.push('Azioni');
    this.apiService.viewDataToShow.data.forEach(res => {
      this.buildResult = new Array<string>();

      this.apiService.viewDataToShow.columns.forEach((item, index) => {
        if (item.columnType === 'DateTime') {
          res[index] = this.TransformDataFormat(res[index]);
        }
        this.buildResult[this.apiService.viewDataToShow.columns[index].id + ',' +
        this.apiService.viewDataToShow.columns[index].label] = res[index];
        if (this.apiService.viewDataToShow.columns[index].id === 'DOCNUMBER'){
          this.buildResult['Azioni'] = res[index];
        }
      });


      this.Result.push(this.buildResult);
      // // // console.log(this.buildResult);

    });
    /*this.Result.forEach(resRow => {
         if (resRow['ALLEGATI,Allegati'] === '1') {
             this.apiService.docAttachments(resRow['DOCNUMBER,System ID']).subscribe(val => {
                 resRow['ALLEGATI,Allegati'] = val.length;
             });
         }
         if (resRow['NOTE,Note'] === 'S') {
             this.apiService.getNotes(resRow['DOCNUMBER,System ID']).subscribe(val => {
                 resRow['NOTE,Note'] = val.length;
             });
         }
     });*/
    this.dataSource = new MatTableDataSource(this.Result);
    this.ngAfterViewInit();
  }
  TransformDataFormat(Data: string): string {
    Data = Data.replace(' 00:00:00', '');
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-US');
  }

  enableFolder(id): void {
    this.folders.forEach(fol => {
      if (fol.id === id) {
        if (fol.enabled) {
          fol.enabled = false;
        } else {
          fol.enabled = true;
        }
      }
    });
  }

  startEvent(eventId: string, docN: number): void {
      this.apiService.getWorkflowEvents([docN]).subscribe(res => {
        res.forEach(v => {
          if (v.eventId.toString() === eventId) {
            this.apiService.canStartEventByDocnumber(docN.toString()).subscribe(result => {
              if (result) {
                this.apiService.startWorkflow(docN.toString(), eventId).subscribe(r => {

                  this.snackBar.open(this.translate.instant('advices.workflowStarted'), null, {
                    duration: 4000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    panelClass: ['mat-toolbar', 'custom-success-bg']
                  });
                });
              }
            });
          }
        });
      });
  }
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  TransformDataFormatToShow(Data: string): any {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');
  }
  closeDialog(): void {
    this.dialogRef.close({dataResult: false});
  }
  signDoc(Id: string): void {
    let name = '';
    this.apiService.getProfileDetailsClass(Id).subscribe(result => {
      name = result.profileInfo.fileName;
      // // console.log(result);
      this.apiService.downloadDocument(Id).subscribe(response => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        const self = this;
        // tslint:disable-next-line:only-arrow-functions
        reader.onloadend = function(): void {
          let base64data = reader.result.toString();
          base64data = base64data.substr(base64data.indexOf(',') + 1);
          const dialogRef = self.dialog.open(SignerDetailsComponent, {
            width: '20%',
            data: {}
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res.signerEmail) {
              self.apiService.docusignSendEnvelope(base64data, name,
                name.substr(name.lastIndexOf('.') + 1), res.signerEmail, res.signerName).subscribe(resu => {
                // // console.log(resu);
                self.snackBar.open('Operazione effettuata con successo!', null, {
                  duration: 4000,
                  horizontalPosition: 'end',
                  verticalPosition: 'bottom',
                  panelClass: ['mat-toolbar', 'custom-success-bg']
                });
              });
            } else {
              self.snackBar.open('Operazione annullata', null, {
                duration: 4000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom',
                panelClass: ['mat-toolbar', 'custom-danger-bg']
              });
            }
          });
        };
        // tslint:disable-next-line:no-unused-expression
      }, errorLog => {
        this.snackBar.open(this.translate.instant('advices.noDocumentAssociated'), null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    }, errorLog => {
      this.snackBar.open(this.translate.instant('advices.noDocumentAssociated'), null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  downloadDoc(Id: string): void {
      let name = '';
      this.apiService.getProfileDetailsClass(Id).subscribe(result => {
        name = result.profileInfo.fileName;
        // // // console.log(name);
        this.apiService.downloadDocument(Id).subscribe(response => {
          const blob = new Blob([response], {type: 'application/octet-stream'});
          fileSaver.saveAs(blob, name);
          // tslint:disable-next-line:no-unused-expression
        }, errorLog => {
          this.snackBar.open(this.translate.instant('advices.noDocumentAssociated'), null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      }, errorLog => {
        this.snackBar.open(this.translate.instant('advices.noDocumentAssociated'), null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
  }
  openEditFileDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(EditFromFileProfileComponent, {
      width: '20%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }
  openShowAttachmentsDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(ShowAttachmentsDialogComponent, {
      width: '60%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }
  openRevisions(docNumber: string): void {
    const dialogRef = this.dialog.open(RevisionsComponent, {
      width: '60%',
      panelClass: 'custom-dialog-container',
      data: {docN: docNumber}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  openProfileNotesDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(ProfileNotesComponent, {
      width: '50%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }
  openEditRevisionDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(EditRevisionProfileComponent, {
      width: '20%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }
  deleteDocument(docN: string): void{
    this.apiService.deleteProfile(docN).subscribe(res => {
      this.ngOnInit();
      this.snackBar.open(this.translate.instant('advices.profileDeleted'), null, {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-warning-bg']
      });
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }
  editField(element: any): void {
    const dialogRef = this.dialog.open(DialogProfileComponent, {
      width: '80%',
      height: '80%',
      data: {
        systemId: element.Azioni
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  deleteField(element: any): void {
    this.docNumbers.forEach((item, index) => {
      if (item === element.Azioni) {
        this.docNumbers.splice(index, 1);
      }
    });
    this.snackBar.open(this.translate.instant('labels.element') + ' ' + element.Azioni + ' ' + this.translate.instant('labels.element') + '!', null, {
      duration: 5000,
      horizontalPosition: 'end',
verticalPosition: 'bottom',
      panelClass: ['mat-toolbar', 'custom-success-bg']
    });
    this.dialogRef.close({fieldName: this.field.name});
  }
}
