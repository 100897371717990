import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../api.service';
import {Output, EventEmitter, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {Router, ParamMap} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-dettaglirubrica',
    templateUrl: './dettaglirubrica.component.html',
    styleUrls: ['./dettaglirubrica.component.css']
})
export class DettaglirubricaComponent implements OnInit {
    breakpoint: number;
    breakpointnum: number;
    datiRubrica: any;
    nomiAoo: any;
    nomicategorierubrica: Array<any> = new Array<any>();
    nomeRubrica = '';
    Organizzazione = '';
    Rubrica: number;
    Codice = '';
    Azienda = '';
    Categoria = '';
    Appunti = '';
    Attivo = '';
    Tel = '';
    Fax = '';
    Cel = '';
    Indirizzo = '';
    Localita = '';
    Provincia = '';
    Cap = '';
    Email = '';
    AziendaNome = '';
    Nazione = '';
    CodiceFiscale = '';
    PartitaIva = '';
    RubricaNome = '';
    PrioritaNome = '';
    dashColor = '';
    backColor = '';
    squareColor = '';
    cardColor = '';
    id = this.activatedRoute.snapshot.paramMap.get('id');
    cat = this.activatedRoute.snapshot.paramMap.get('cat');
    new = this.activatedRoute.snapshot.paramMap.get('new');
    isFromDialog = this.activatedRoute.snapshot.paramMap.get('isFromDialog');

    // tslint:disable-next-line:max-line-length
    constructor(public translate: TranslateService, private snackBar: MatSnackBar, private route: ActivatedRoute, private router: Router, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, public apiService: ApiService, private activatedRoute: ActivatedRoute) {


    }

    ngOnInit(): void {
        this.RubricaNome = '';
        this.dashColor = this.apiService.dashColor;
        this.backColor = this.apiService.backColor;
        this.squareColor = this.apiService.squareColor;
        this.cardColor = this.apiService.cardColor;
        this.breakpoint = (window.innerWidth <= 600) ? 2 : 2;
        this.breakpointnum = (window.innerWidth <= 600) ? 1 : 3;
        if (this.new !== 'new') {
            this.apiService.getAddressBookDetail(this.id).subscribe(data => {
                this.Organizzazione = data.businessName;
                this.nomeRubrica = data.businessName;
                this.apiService.namePath += ' - ' + this.nomeRubrica;
                this.apiService.addPath(this.nomeRubrica, 'dashboard/dashnav/dettaglirubrica', {
                    id: this.id,
                    cat: this.cat, new: this.new, isFromDialog: this.isFromDialog
                });
                this.datiRubrica = data;
                // console.log(data);
                this.Rubrica = data.addressBookCategoryId;
                // tslint:disable-next-line:no-shadowed-variable
                this.apiService.getAddressBookCategry().subscribe(data => {

                    data.forEach(element => {
                        // console.log(element);
                        if (this.cat === 'pub') {
                            if (element.id > 0 && element.type === 0) {
                                // console.log(this.nomicategorierubrica);
                                this.nomicategorierubrica.push({addressBook: element.addressBook, id: element.id});
                                this.RubricaNome += '-' + (element.addressBook);
                            }
                        }
                        if (this.cat === 'pri') {
                            if (element.id > 0 && element.type === 1) {
                                this.nomicategorierubrica.push({addressBook: element.addressBook, id: element.id});
                                this.RubricaNome += '-' + (element.addressBook);
                            }
                        }
                        if (this.cat === 'int') {
                            if (element.id === 0) {
                                // console.log(this.nomicategorierubrica);
                                this.nomicategorierubrica.push({addressBook: element.addressBook, id: element.id});
                                this.RubricaNome += '-' + (element.addressBook);
                            }
                        }
                        if (this.cat === 'new') {
                            if (element.id > 0 && (element.type === 1 || element.type === 0)) {
                                this.nomicategorierubrica.push({addressBook: element.addressBook, id: element.id});
                                this.RubricaNome += '-' + (element.addressBook);
                            }
                        }

                    });

                });
                this.Azienda = data.addressBookBusinessUnitCode;
                // tslint:disable-next-line:no-shadowed-variable
                this.apiService.getAoo().subscribe(data => {
                    this.nomiAoo = data;
                    data.forEach(element => {
                        if (element.code === this.Azienda) {
                            this.AziendaNome = (element.name as string);
                        }
                    });
                });
                // this.Categoria = data.class ??????

                switch (data.priority) {
                    case 0:
                        this.PrioritaNome = 'No invio';
                        break;
                    case 1:
                        this.PrioritaNome = 'Solo Fax';
                        break;
                    case 2:
                        this.PrioritaNome = 'Solo Email';
                        break;
                    case 3:
                        this.PrioritaNome = 'Fax/Email';
                        break;
                    case 4:
                        this.PrioritaNome = 'Email/Fax';
                        break;
                    case 5:
                        this.PrioritaNome = 'Fax+Email';
                        break;
                    case 6:
                        this.PrioritaNome = 'Solo Stampa';
                        break;
                }

                this.Appunti = data.addressBookNote;
                // tslint:disable-next-line:triple-equals
                if (data.state === 1) {
                    this.Attivo = 'true';
                } else {
                    this.Attivo = 'false';
                }
                // console.log(this.Attivo);
                this.Tel = data.phoneNumber;
                this.Fax = data.fax;
                this.Cel = data.cellPhone;
                this.Codice = data.externalCode;
                this.Indirizzo = data.address;
                this.Localita = data.location;
                this.Provincia = data.province;
                this.Nazione = data.country;
                this.Cap = data.postalCode;
                this.Email = data.email;
                this.CodiceFiscale = data.fiscalCode;
                this.PartitaIva = data.vatNumber;
            });
        } else {
            this.apiService.namePath += ' - ' + this.translate.instant('labels.newContact');
            this.apiService.addPath(this.translate.instant('labels.newContact'), 'dashboard/dashnav/dettaglirubrica', {
                id: this.id,
                cat: this.cat, new: this.new, isFromDialog: this.isFromDialog
            });
            this.apiService.newContact().subscribe(data => {
                this.datiRubrica = data;
                // tslint:disable-next-line:no-shadowed-variable
                this.apiService.getAddressBookCategry().subscribe(data => {

                    data.forEach(element => {
                        if (this.cat === 'new') {
                            if (element.id > 0 && (element.type === 1 || element.type === 0)) {
                                if (element.type === 1) {
                                    this.nomicategorierubrica.push({addressBook: 'Rubrica privata/' + element.addressBook, id: element.id});
                                } else {
                                    this.nomicategorierubrica.push({
                                        addressBook: 'Rubrica pubblica/' + element.addressBook,
                                        id: element.id
                                    });
                                }
                            }
                        }

                    });

                });
                this.Azienda = data.addressBookBusinessUnitCode;
                // tslint:disable-next-line:no-shadowed-variable
                this.apiService.getAoo().subscribe(data => {
                    this.nomiAoo = data;
                    data.forEach(element => {
                        if (element.code === this.Azienda) {
                            this.AziendaNome = (element.name as string);
                        }
                    });
                });
                // this.Categoria = data.class ??????

                switch (data.priority) {
                    case 0:
                        this.PrioritaNome = 'No invio';
                        break;
                    case 1:
                        this.PrioritaNome = 'Solo Fax';
                        break;
                    case 2:
                        this.PrioritaNome = 'Solo Email';
                        break;
                    case 3:
                        this.PrioritaNome = 'Fax/Email';
                        break;
                    case 4:
                        this.PrioritaNome = 'Email/Fax';
                        break;
                    case 5:
                        this.PrioritaNome = 'Fax+Email';
                        break;
                    case 6:
                        this.PrioritaNome = 'Solo Stampa';
                        break;
                }

            });
        }

    }

    goBack(): void {
        this.router.navigate(['../rubrica'], {relativeTo: this.route});
    }

    save(): void {
        this.datiRubrica.addressBookCategoryId = this.Rubrica;
        this.datiRubrica.businessName = this.Organizzazione;
        this.datiRubrica.externalCode = this.Codice;
        this.nomiAoo.forEach(element => {
            if (element.name === this.AziendaNome) {
                this.datiRubrica.addressBookBusinessUnitCode = (element.code);
            }
        });
        switch (this.PrioritaNome) {
            case 'No invio':
                this.datiRubrica.priority = 0;
                break;
            case 'Solo Fax':
                this.datiRubrica.priority = 1;
                break;
            case 'Solo Email':
                this.datiRubrica.priority = 2;
                break;
            case 'Fax/Email':
                this.datiRubrica.priority = 3;
                break;
            case 'Email/Fax':
                this.datiRubrica.priority = 4;
                break;
            case 'Fax+Email':
                this.datiRubrica.priority = 5;
                break;
            case 'Solo Stampa':
                this.datiRubrica.priority = 6;
                break;
        }
        this.datiRubrica.addressBookNote = this.Appunti;
        if (this.Attivo === 'true') {
            this.datiRubrica.state = 1;
        } else {
            this.datiRubrica.state = 0;
        }
        this.datiRubrica.phoneNumber = this.Tel;
        this.datiRubrica.cellPhone = this.Cel;
        this.datiRubrica.fax = this.Fax;
        this.datiRubrica.address = this.Indirizzo;
        this.datiRubrica.location = this.Localita;
        this.datiRubrica.province = this.Provincia;
        this.datiRubrica.postalCode = this.Cap;
        this.datiRubrica.email = this.Email;
        this.datiRubrica.country = this.Nazione;
        this.datiRubrica.fiscalCode = this.CodiceFiscale;
        this.datiRubrica.vatNumber = this.PartitaIva;

        // console.log(this.datiRubrica);
        if (this.new !== 'new') {
            this.apiService.putAddressBook(this.datiRubrica, this.id).subscribe(data => {
                // console.log(data);
                this.snackBar.open(this.translate.instant('advices.profileUpdated'), null, {
                    duration: 3000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    panelClass: ['mat-toolbar', 'custom-success-bg']
                });
                this.ngOnInit();
            });
        } else {
            this.apiService.postAddressBook(this.datiRubrica).subscribe(data => {
                // console.log(data);
                this.snackBar.open(this.translate.instant('advices.contactAdded'), null, {
                    duration: 3000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    panelClass: ['mat-toolbar', 'custom-success-bg']
                });
                if (this.isFromDialog) {
                    this.pathClick(this.apiService.previousSelectedPath.route, this.apiService.previousSelectedPath.params);
                } else {
                    this.goBack();
                }

            });
        }

    }
    pathClick(path: string, params: any): void {
        this.apiService.isMenuClick = false;
        this.apiService.namePathV2.forEach((v, index) => {
            if (v.route === path && v.params === params) {
                v.active = true;
                this.apiService.namePathV2.push(v);
                this.apiService.namePathV2.splice(index, 1);
            } else {
                v.active = false;
            }
        });
        this.apiService.tempParamsForRedirect = {destination: path, parameters: params};
        this.router.navigate(['dashboard/dashnav/redirect']);
    }
    onResize(event): void {
        this.breakpoint = (event.target.innerWidth <= 600) ? 2 : 4;

    }

    onResizenum(event): void {
        this.breakpointnum = (event.target.innerWidth <= 600) ? 1 : 3;
    }
}
