<div style = "height: 20%">
  <button style="float: right;" title="{{'labels.close' | translate}}" (click)="closeDialog()" mat-icon-button color="warn"
          aria-label="icon-button save">
    <mat-icon>close</mat-icon>
  </button></div>
<div style="text-align: center"><h2>{{'labels.chooseFile' | translate}}</h2>
    <ng-container *ngIf="badgeBufferMain == 1">
        <button mat-menu-item (click)="removeFile()">
            <mat-icon>close</mat-icon>
            <span style="font-size: 16px; font-weight: 500;">{{NomeFile}}</span>
        </button>
    </ng-container>
    <ng-container *ngIf="badgeBufferMain == 0">
        <button title="{{'labels.chooseFile' | translate}}"
                (click)="fileInput.click()"
                mat-icon-button>
            <mat-icon>cloud_upload</mat-icon>
        </button>

        <input type="file" (change)="fileChangeEvent($event)" hidden #fileInput id="fileUpload" name="fileUpload"
               multiple="multiple" accept="*"/>
    </ng-container>
    <br><br>
    <hr>
    <br>
    <button mat-stroked-button (click) = "this.sendFile()">{{'buttons.send' | translate}}</button>
</div>
