
<div style = "height: 100%;">
  <div class = "sticky" style = "height: 10%; width: 100%">
    <mat-form-field >
      <mat-label>
        <mat-icon style="margin-right: 8px">search</mat-icon>
        {{'menu.search' | translate}}
      </mat-label>
      <input matInput [(ngModel)] = "this.filterString"
           >
      <button mat-button  matSuffix mat-icon-button aria-label="Clear" (click) = "this.clearFilter()"
              >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div style = "height: 83%; overflow: auto; width: 100%">
    <mat-list >
      <ng-container *ngFor="let user of this.users">
        <mat-list-item [ngClass]="{
   'selected': user.selected
}"  (click)="user.selected = !user.selected;" *ngIf = "(user.completeName.toLowerCase().includes(this.filterString.toLowerCase()) || this.filterString == '') && (!user.completeName.toLowerCase().includes('admin') && !user.completeName.toLowerCase().includes('administrator') && !user.completeName.toLowerCase().includes('amministratore') && !user.completeName.toLowerCase().includes('arxivar.admin') && !user.completeName.toLowerCase().includes('backup.admin') && !user.completeName.toLowerCase().includes('every') && !user.completeName.toLowerCase().includes('eros.matteoli') && !user.completeName.toLowerCase().includes('fabio.testa') && !user.completeName.toLowerCase().includes('guest') && !user.completeName.toLowerCase().includes('giovanni.scarpellini') && !user.completeName.toLowerCase().includes('krbtgt') && !user.completeName.toLowerCase().includes('non classificato') && !user.completeName.toLowerCase().includes('riservatissimo') && !user.completeName.toLowerCase().includes('riservato') && !user.completeName.toLowerCase().includes('segreto') && !user.completeName.toLowerCase().includes('non Classificato') && !user.completeName.toLowerCase().includes('user') && !user.completeName.toLowerCase().includes('xad'))">
          <b>{{'labels.user' | translate}}: </b> {{user.completeName}}
        </mat-list-item>
      </ng-container>

    </mat-list>
  </div>
  <div class = "stickybottom" style = "height: 5%; width: 100%; margin-top: 2%">
    <button style="float: left;" title="{{'labels.close' | translate}}" (click)="closeDialog()" mat-stroked-button color="warn" aria-label="icon-button save">
      <mat-icon style = "margin: auto; margin: 5px">close</mat-icon><span>{{'labels.close' | translate}}</span>
    </button>
    <button style="float: right; color: black" title="{{'labels.save' | translate}}" (click)="saveTask()" matBadge="{{this.countSelected()}}"   mat-stroked-button aria-label="icon-button save">
      <mat-icon style = "margin: auto; margin: 5px">send</mat-icon><span>{{'labels.forward' | translate}}</span>
    </button>
  </div>
</div>
