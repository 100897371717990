<div style="display: block">
  <div style="height: 10%; width: 100%; display: flex">
    <h3 style="color: {{this.apiService.dashColor}}; width: 60%">Elenco checklist per <br>{{this.title}}<br><b>Ore
      impegnate: {{this.oreImpegnate}}</b><br>
      <button mat-stroked-button style="color: {{this.apiService.dashColor}}; margin-left: 0px !important;"
              (click)="this.openProject()">Vai al progetto
      </button>

    </h3>

    <div style=" width: 40%; text-align: right">
      <button title="Salva" (click)="closeDialog()" mat-icon-button color="warn"
              aria-label="icon-button save">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <h3><b>Programmazione giorno: {{this.TransformDataFormatToShow(this.selectedDay)}}</b></h3>
  <hr class="riga" style="color:{{this.apiService.dashColor}}; margin: 0px !important">

  <div>
      <div *ngFor="let event of this.events" style="border-bottom: 1px solid {{event['color']}}; display: flex; padding: 15px">
        <div style="width: 5%; margin: auto"><mat-icon mat-list-icon>hardware</mat-icon></div>
        <div style="width: 55%">
          <div mat-line title="{{event['oggetto']}}">Oggetto: {{event['oggetto']}}</div>
          <div mat-line>Data inizio: {{this.TransformDataFormatToShow(event['dataInizio'])}} Data
            fine: {{this.TransformDataFormatToShow(event['dataFine'])}}</div>
          <div mat-line>Tempo residuo: {{event['tempo']}} </div>
        </div>
        <div style="width: 40%">
          <mat-form-field class="example-form-field" style = "margin-left: 10px">
            <mat-label>Ore previste</mat-label>
            <input matInput type="number" [(ngModel)]="event.setHour">
            <button *ngIf="event.setHour" matSuffix mat-icon-button aria-label="Clear" (click)="event.setHour=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
  </div>

</div>

