<div style="text-align: center; color: black">
  <h2>{{'labels.changePassword' | translate}}</h2>
  <hr>
  <br>
  <mat-form-field class="full-width-input" appearance="outline">
    <mat-label>{{'labels.oldPassword' | translate}}</mat-label>
    <input matInput required (change)="checkErrors()" [(ngModel)]="this.oldPassword" type="password">
  </mat-form-field>
  <mat-form-field class="full-width-input" appearance="outline">
    <mat-label>{{'labels.newPassword' | translate}}</mat-label>
    <input matInput required (change)="checkErrors()" [(ngModel)]="this.newPassword" type="password">
  </mat-form-field>
  <mat-form-field class="full-width-input" appearance="outline">
    <mat-label>{{'labels.confirmPassword' | translate}}</mat-label>
    <input matInput required (change)="checkErrors()" [(ngModel)]="this.confirmPassword" type="password">
  </mat-form-field>
  <button mat-stroked-button (click)="changePassword()">{{'labels.changePassword' | translate}}</button>
  <button title="{{'labels.close' | translate}}" (click)="closeDialog()" mat-stroked-button color="warn">
    {{'labels.close' | translate}}
  </button>
  <br>
  <p style="color: red;">{{this.error}}</p>
</div>
