import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {ApiService} from '../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {DialogMultiDTOComponent} from './dialogMultiDTOComponent';
import {DialogTableDTOComponent} from './dialogTableDTOComponent';
import {DialogAddressBookComponent} from './dialogAddressBookComponent';
import {DialogComboDTOComponent} from './dialogComboDTOComponents';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BodyFieldToSave} from '../bodyFieldToSave';
import {DatePipe} from '@angular/common';
import {DialogMaskComponent} from './dialogMaskComponents';
import {DialogClassViewDTOComponentDTOComponent} from './dialogClassViewDTOComponent';
import {DialogSearchDocComponent} from './dialogSearchDocComponents';
import {DialogDocumentalClassComponent} from './dialogDocumentalClass';
import {DialogAddressBookV2Component} from './dialog-address-book-v2/dialog-address-book-v2.component';
import moment from 'moment';

@Component({
  selector: 'app-profile',
  styleUrls: ['profile-css.css'],
  templateUrl: 'dialog-content-profile.html'
})

export class DialogProfileComponent implements OnInit {
  id: string;
  docId: string;
  taskId = this.activatedRoute.snapshot.paramMap.get('taskId');
  idView = this.activatedRoute.snapshot.paramMap.get('idv');
  profile: Array<any> = new Array<any>();
  breakpoint: number;
  edit = false;
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;
  maskName: string;
  splitMaskName: string;
  splitMaskOrder: string;
  splitMaskCat: string;
  classProfileType: string;
  classProfileName: string;
  classProfileValue: string;
  fields: Array<any> = new Array<any>();
  nomiAoo: Array<any> = new Array<any>();
  originsFields: Array<any> = new Array<any>();
  documenTypes: Array<any> = new Array<any>();
  stateFields: Array<any> = new Array<any>();
  toFields: Array<any> = new Array<any>();
  FromFields: Array<any> = new Array<any>();
  Row: [];
  fieldDataSource: Array<any> = [];
  multiValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  tableValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  comboValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  addressBookSelected: Map<string, string[]> = new Map<string, string[]>();
  addressBookSelectedToShow = '';
  fieldName = '';
  keyField = '';
  mapMultiValueToSave: Map<string, object[]> = new Map<string, object[]>();
  mapTableValueToSave: Map<string, object[]> = new Map<string, object[]>();
  tableAssociationField: object = {};
  comboAssociationField: any = {};
  mapTableValueToSee: Map<string, string> = new Map<string, string>();
  mapAddressBookToSee: Map<string, string> = new Map<string, string>();
  mapClasseBookToSave: Map<string, Array<string>> = new Map<string, Array<string>>();
  // CLASSE BOX
  ELEMENT_DATA_CLASS: Array<object> = new Array<object>();
  arrayToObjectClass: { [index: string]: { content: string } } = {};
  dataSourceClass: MatTableDataSource<object>;
  displayedColumnsClass: Array<string> = new Array<string>();
  docNumberClasseboxTemp: Array<string> = new Array<string>();
  optionsMask: any = {};
  dataMaskToSave: any = {};
  dataValueAssociations: any = {};
  validationCheck: boolean;
  jsonToSendArxivar: any = {};
  fileListMain: FileList;
  fileMain: File;
  formDataMain: FormData;
  bufferIdInfo: Array<any> = new Array<any>();
  fileInfo: any = {name: '', buffer: ''};
  badgeBufferMain: number;
  arrayToObject: { [index: string]: { content: string } } = {};
  canUnlock = true;
  idContact: string;
  fieldsNoAdditional: Array<any> = new Array<any>();
  additionalFields: Array<any> = new Array<any>();
  mapAddressBookSave: Array<any> = new Array<any>();
  comboValues: any = {};
  tempCombo: Array<any> = new Array<any>();
  selectToAdd: string;
  keyToAdd: string;
  whatToShow = 'default';

  // note section
  notesList: any;
  showAddNote = false;
  newNote: string;
  addLabel = 'Aggiungi';

  // chip list
  mapMultiValueToSee: Map<string, any> = new Map<string, any>();
  multiValuteDTOSelectedToShow: Array<any> = new Array<any>();
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  constructor(public dialogRef: MatDialogRef<DialogProfileComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private datePipe: DatePipe, public dialog: MatDialog, private route: ActivatedRoute,
              private router: Router, iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer, public apiService: ApiService, private activatedRoute: ActivatedRoute,
              private snackBar: MatSnackBar, private bodyFieldToSave: BodyFieldToSave) {
    this.docId = data.systemId;
  }

  ngOnInit(): void {
    this.apiService.getNotes(this.docId).subscribe(v => {
      this.notesList = v;
    });
    this.canUnlock = true;
    this.dashColor = this.apiService.dashColor;
    this.backColor = this.apiService.backColor;
    this.squareColor = this.apiService.squareColor;
    this.cardColor = this.apiService.cardColor;
    this.breakpoint = (window.innerWidth <= 600) ? 4 : 6;
    this.apiService.getProfileSchemaSwitched(this.docId).subscribe(data => {
      this.id = data.maskId;
      this.fields = data.fields;
      // // console.log(data);
      this.apiService.getDocumentType(this.id).subscribe(results => {
        this.documenTypes = results;
      });
      this.optionsMask = data.options;
      // // console.log(data);
      if (data.editOptions.canUnlockProfile === false) {
        this.canUnlock = false;
      }
      /*this.maskName = data.maskName;
      this.splitMaskCat = this.maskName.split('-')[0];
      this.splitMaskOrder = this.maskName.split('-')[1];
      this.splitMaskName = this.maskName.split('-')[2];
      data.fields = data.fields.sort((a, b) => {
          return a.order - b.order;
      });*/

      data.fields.forEach(findClass => {
        if (findClass.visible) {
          this.dataMaskToSave[findClass.name] = findClass.value;
          this.dataValueAssociations[findClass.name] = findClass.associations;
        }
        if (findClass.className === 'AdditionalFieldClasseDTO') {
          this.mapClasseBookToSave.set(findClass.name, findClass.value);
          findClass.columns.forEach(column => {
            this.displayedColumnsClass.push(column.label);
          });
          this.displayedColumnsClass.push('Azioni');
          this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
        }
        if (findClass.className === 'ToFieldDTO') {
          const selected: Array<string> = new Array<string>('');
          // console.log(findClass);
          this.addressBookSelectedToShow = '';
          if (findClass.value) {
            findClass.value.forEach(item => {
              // console.log(item);
              this.addressBookSelectedToShow += item.society + ';\n';
              selected.push(item.addressBookId);

            });

            this.addressBookSelected.set(findClass.name, selected);
            // console.log(this.addressBookSelected);
            this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
            // console.log(this.mapAddressBookToSee);
          } else {
            this.mapAddressBookToSee.set(findClass.name, '');
          }

        }
        if (findClass.className === 'AdditionalFieldMultivalueDTO') {
          this.dataMaskToSave[findClass.name] = findClass.value;
          this.multiValuteDTOSelectedToShow = new Array<any>();
          this.dataMaskToSave[findClass.name].forEach(v => {
            this.multiValuteDTOSelectedToShow.push(v);
          });
          this.mapMultiValueToSee.set(findClass.name, this.multiValuteDTOSelectedToShow);
        }
        if (findClass.className === 'FromFieldDTO') {
          const selected: Array<string> = new Array<string>('');
          this.addressBookSelectedToShow = '';
          if (findClass.value) {
            this.addressBookSelectedToShow += findClass.value.society + ';\n';
            selected.push(findClass.value.addressBookId);
            // console.log(this.addressBookSelectedToShow);
            this.addressBookSelected.set(findClass.name, selected);
            this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
            // console.log(this.mapAddressBookToSee);
          } else {
            this.mapAddressBookToSee.set(findClass.name, '');
          }


        }
        if (findClass.className === 'CcFieldDTO') {
          let selected: Array<string> = new Array<string>('');
          this.addressBookSelectedToShow = '';
          if (findClass.value) {
            findClass.value.forEach(item => {
              // console.log(item);
              this.addressBookSelectedToShow += item.society + ';\n';
              selected.push(item.addressBookId);

            });
            this.addressBookSelected.set(findClass.name, selected);
            this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
          } else {
            this.mapAddressBookToSee.set(findClass.name, '');
          }

        }
        if (findClass.className === 'DocumentTypeFieldDTO') {
          this.classProfileType = findClass.className;
          this.classProfileName = findClass.name;
          this.classProfileValue = findClass.value;
        }
      });

      this.fields.forEach(v => {
        if (v.className === 'AdditionalFieldComboDTO') {
          this.apiService.getProfileValuesWithFilter(v.name, this.getJsonFields().fields).subscribe(results => {
            // console.log(results);
            this.tempCombo = new Array<any>();
            results.dataSource.forEach(res => {
              this.selectToAdd = '';
              this.keyToAdd = '';
              res.columns.forEach(r => {
                if (results.selectField.toLowerCase().includes(r.id.toLowerCase())) {
                  this.selectToAdd += r.value + ' - ';
                }
                if (results.keyField.toLowerCase().includes(r.id.toLowerCase())) {
                  this.keyToAdd += r.value + ' - ';
                }
              });
              this.tempCombo.push({
                selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3)
              });
              this.comboValues[v.name] = this.tempCombo;
            });
          });
        }
      });
      this.checkFormula();
    });

    this.apiService.getAoo().subscribe(data => {
      this.nomiAoo = data;
    });
    this.apiService.getOrigins().subscribe(data => {
      this.originsFields = data;
    });

    this.apiService.getStates().subscribe(data => {
      this.stateFields = data;
    });
    /*if (true) {
        this.apiService.getProfileDetails(this.docId, this.taskId).subscribe(data => {
            this.optionsMask = data.options;
            // // console.log(data);
            this.maskName = data.maskName;
            this.splitMaskCat = this.maskName.split('-')[0];
            this.splitMaskOrder = this.maskName.split('-')[1];
            this.splitMaskName = this.maskName.split('-')[2];
            data.fields = data.fields.sort((a, b) => {
                return a.order - b.order;
            });
            this.fields = data.fields;
            data.fields.forEach(findClass => {
                if (findClass.className === 'AdditionalFieldClasseDTO') {
                    findClass.columns.forEach(column => {
                        this.displayedColumnsClass.push(column.label);
                    });
                    this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
                }
                if (findClass.className === 'DocumentTypeFieldDTO') {
                    this.classProfileType = findClass.className;
                    this.classProfileName = findClass.name;
                    this.classProfileValue = findClass.value;
                }
            });
        });
    }*/

  }
  checkInputFormula(): void {
    this.formulaCalculatorNoLoading(0);
  }
  getAllNullProfileValue(): void {
    console.log('lalal');
    this.fields.forEach((val, index) => {
      if (this.comboValues[val.name]){
        console.log(this.comboValues[val.name]);
        if (this.comboValues[val.name].length === 1){
          if (val.className === 'AdditionalFieldComboDTO') {
            this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
              console.log(results);
              this.tempCombo = new Array<any>();
              results.dataSource.forEach(res => {
                this.selectToAdd = '';
                this.keyToAdd = '';
                res.columns.forEach(r => {
                  if (results.selectField.toLowerCase().includes(r.id.toLowerCase())) {
                    this.selectToAdd += r.value + ' - ';
                  }
                  if (results.keyField.toLowerCase().includes(r.id.toLowerCase())) {
                    this.keyToAdd += r.value + ' - ';
                  }
                });
                this.tempCombo.push({
                  selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                  keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3)
                });
                this.comboValues[val.name] = this.tempCombo;
                if (results.dataSource.length === 1 && !this.dataMaskToSave[val.name]){
                  this.dataMaskToSave[val.name] = this.keyToAdd.substr(0, this.keyToAdd.length - 3);
                }
              });
            });
          } else {
            if (val.className === 'AdditionalFieldTableDTO' || val.className === 'AdditionalFieldMultivalueDTO') {
              this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
                this.tableAssociationField = {};
                this.tableAssociationField = Object.keys(results.associations)
                  .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
                console.log(this.tableAssociationField);
                if (results.dataSource.length === 0) {
                  this.fields.forEach((item, subindex) => {
                    if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                      this.fields[subindex].value = '';
                      this.dataMaskToSave[this.fields[subindex].name] = '';
                    }
                    if (item.name === val.name) {
                      this.fields[subindex].value = '';
                      this.dataMaskToSave[this.fields[subindex].name] = '';
                    }
                  });
                }
              });
            }
          }
        }
      }else{
        if (!val.value) {
          if (val.className === 'AdditionalFieldComboDTO') {
            this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
              console.log(results);
              this.tempCombo = new Array<any>();
              results.dataSource.forEach(res => {
                this.selectToAdd = '';
                this.keyToAdd = '';
                res.columns.forEach(r => {
                  if (results.selectField.toLowerCase().includes(r.id.toLowerCase())) {
                    this.selectToAdd += r.value + ' - ';
                  }
                  if (results.keyField.toLowerCase().includes(r.id.toLowerCase())) {
                    this.keyToAdd += r.value + ' - ';
                  }
                });
                this.tempCombo.push({
                  selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                  keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3)
                });
                this.comboValues[val.name] = this.tempCombo;
                if (results.dataSource.length === 1 && !this.dataMaskToSave[val.name]){
                  this.dataMaskToSave[val.name] = this.keyToAdd.substr(0, this.keyToAdd.length - 3);
                }
              });
            });
          } else {
            if (val.className === 'AdditionalFieldTableDTO' || val.className === 'AdditionalFieldMultivalueDTO') {
              this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
                this.tableAssociationField = {};
                this.tableAssociationField = Object.keys(results.associations)
                  .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
                console.log(this.tableAssociationField);
                if (results.dataSource.length === 0) {
                  this.fields.forEach((item, subindex) => {
                    if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                      this.fields[subindex].value = '';
                      this.dataMaskToSave[this.fields[subindex].name] = '';
                    }
                    if (item.name === val.name) {
                      this.fields[subindex].value = '';
                      this.dataMaskToSave[this.fields[subindex].name] = '';
                    }
                  });
                }
              });
            }
          }
        }
      }

    });
  }
  formulaCalculatorNoLoading(i: number): void {

    let pastFormulas: Array<any> = new Array<any>();
    let found = false;
    pastFormulas = new Array<any>();
    if (this.fields[i].formula) {
      found = false;
      pastFormulas.push({name: this.fields[i].name, formula: this.fields[i].formula});
      pastFormulas.forEach(pf => {
        if (pf.formula.toString().includes(this.fields[i].name.toString())) {
          this.apiService.getProfileFormula(this.getUpdatedFields(this.fields[i].name, this.fields[i].formula)).subscribe(res => {
            if (this.fields[i].className === 'AdditionalFieldDateTimeDTO') {
              this.dataMaskToSave[this.fields[i].name] = moment(res.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
            } else {
              this.dataMaskToSave[this.fields[i].name] = res;
            }
            if (this.fields[i + 1]) {
              this.formulaCalculatorNoLoading(i + 1);
            }
            found = true;
            this.apiService.getProfileFormula(this.getUpdatedFields(pf.name, pf.formula)).subscribe(res2 => {
              if (pf.className === 'AdditionalFieldDateTimeDTO') {
                this.dataMaskToSave[pf.name] = moment(res2.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
              } else {
                this.dataMaskToSave[pf.name] = res2;
              }
              if (!this.fields[i + 1]) {
                this.getAllNullProfileValue();
              }
            });
          });
        }
      });
      if (!found) {
        this.apiService.getProfileFormula(this.getUpdatedFields(this.fields[i].name, this.fields[i].formula)).subscribe(res => {
          if (this.fields[i].className === 'AdditionalFieldDateTimeDTO') {
            this.dataMaskToSave[this.fields[i].name] = moment(res.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
          } else {
            this.dataMaskToSave[this.fields[i].name] = res;
          }
          if (this.fields[i + 1]) {
            this.formulaCalculatorNoLoading(i + 1);
          }
          if (!this.fields[i + 1]) {

            this.getAllNullProfileValue();
          }
          this.fields.forEach(field => {
            if (field.formula) {
              if (field.formula.toString().includes(this.fields[i].name)) {
                this.apiService.getProfileFormula(this.getUpdatedFields(field.name, field.formula)).subscribe(res2 => {
                  if (field.className === 'AdditionalFieldDateTimeDTO') {
                    this.dataMaskToSave[field.name] = moment(res2.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
                  } else {
                    this.dataMaskToSave[field.name] = res2;
                  }
                  if (!this.fields[i + 1]) {
                    this.getAllNullProfileValue();
                  }
                });
              }
            }

          });
        });
      }
    } else {
      if (this.fields[i + 1]) {
        this.formulaCalculatorNoLoading(i + 1);
      } else {
        this.getAllNullProfileValue();
      }
    }
  }
  checkComboAssociations(field: any): void {
    let comboResult: any = {};
    let selectedElement;
    this.fieldName = field.name;
    this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
      comboResult = results;
      this.comboAssociationField = {};
      this.comboAssociationField = Object.keys(results.associations);
      this.fieldDataSource = new Array<any>();
      this.keyField = results.keyField;
      if (results.dataSource != null) {

        results.dataSource.forEach(item => {
          // console.log(item);
          item.columns.forEach(v => {
            if (v.id.toUpperCase() === this.keyField.toUpperCase() && v.value === field.value) {
              selectedElement = item;
            }
          });

        });
      } else {
        // EMPTY COMBO
      }
      this.comboAssociationField.forEach(res => {
        selectedElement.columns.forEach(v2 => {
          if (v2.id === comboResult.associations[res]) {
            this.dataMaskToSave[res.toUpperCase()] = v2.value;
          }
        });
      });
      this.getAllProfileValue();
    });
  }

  checkFormula(): void {
    this.formulaCalculator(0);
  }

  formulaCalculator(i: number): void {
    let pastFormulas: Array<any> = new Array<any>();
    let found = false;
    pastFormulas = new Array<any>();
    if (this.fields[i].formula) {
      found = false;
      pastFormulas.push({name: this.fields[i].name, formula: this.fields[i].formula});
      pastFormulas.forEach(pf => {
        if (pf.formula.toString().includes(this.fields[i].name.toString())) {
          this.apiService.getProfileFormula(this.getUpdatedFields(this.fields[i].name, this.fields[i].formula)).subscribe(res => {
            if (this.fields[i].className === 'AdditionalFieldDateTimeDTO'){
              this.dataMaskToSave[this.fields[i].name] =  moment(res.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
            }else{
              this.dataMaskToSave[this.fields[i].name] = res;
            }

            if (this.fields[i + 1]) {
              this.formulaCalculator(i + 1);
            }
            found = true;
            this.apiService.getProfileFormula(this.getUpdatedFields(pf.name, pf.formula)).subscribe(res2 => {
              if (pf.className === 'AdditionalFieldDateTimeDTO'){
                this.dataMaskToSave[pf.name] =   moment(res2.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
              }else{
                this.dataMaskToSave[pf.name] = res2;
              }
            });
          });
        }
      });
      if (!found) {
        this.apiService.getProfileFormula(this.getUpdatedFields(this.fields[i].name, this.fields[i].formula)).subscribe(res => {
          if (this.fields[i].className === 'AdditionalFieldDateTimeDTO'){
            this.dataMaskToSave[this.fields[i].name] =   moment(res.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
          }else{
            this.dataMaskToSave[this.fields[i].name] = res;
          }
          if (this.fields[i + 1]) {
            this.formulaCalculator(i + 1);
          }
          this.fields.forEach(field => {
            if (field.formula) {
              if (field.formula.toString().includes(this.fields[i].name)) {
                this.apiService.getProfileFormula(this.getUpdatedFields(field.name, field.formula)).subscribe(res2 => {
                  if (field.className === 'AdditionalFieldDateTimeDTO'){
                    this.dataMaskToSave[field.name] =  moment(res2.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
                  }else{
                    this.dataMaskToSave[field.name] = res2;
                  }

                });
              }
            }

          });
        });
      }
    } else {
      if (this.fields[i + 1]) {
        this.formulaCalculator(i + 1);
      } else {
      }
    }
  }

  getUpdatedFields(name: string, form: string): any {
    this.jsonToSendArxivar =
      {
        fieldName: name,
        formula: form,
        searchFilterDto: {
          fields: []
        }
      };
    this.fields.forEach((field, index) => {
      if (field.visible || this.dataMaskToSave[field.name]) {
        if (field.className === 'AdditionalFieldDateTimeDTO') {
          this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
        } else if (field.className === 'AdditionalFieldDoubleDTO') {
          if (this.dataMaskToSave[field.name]) {
            this.fields[index].value = (this.dataMaskToSave[field.name].toString().replace(',', '.'));
          } else {
            this.fields[index].value = this.dataMaskToSave[field.name];
          }
        } else {
          this.fields[index].value = this.dataMaskToSave[field.name];
        }
      }
      this.jsonToSendArxivar.searchFilterDto.fields.push(this.fields[index]);
    });
    return this.jsonToSendArxivar;
  }

  deleteNote(id: string): void {
    this.apiService.deleteNoteProfile(id).subscribe(res => {
      this.apiService.getNotes(this.docId).subscribe(v => {
        this.notesList = v;
      });
      this.snackBar.open('Nota eliminata con successo', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-warning-bg']
      });
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  saveNewNote(): void {
    if (this.newNote !== undefined && this.newNote !== '') {
      this.apiService.addNote({
        docnumber: this.docId,
        comment: this.newNote,
        isEdit: false,
        aosflag: false,
        isPaNote: false
      }).subscribe(res => {
        this.newNote = '';
        this.apiService.getNotes(this.docId).subscribe(v => {
          this.notesList = v;
        });
        this.snackBar.open('Nota aggiunta con successo', null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });
      }, errorLog => {
        this.snackBar.open(errorLog.error.userMessage, null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    } else {
      this.snackBar.open('Il testo della nuova nota non è valido', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    }

  }

  addNoteTrue(): void {
    if (this.showAddNote) {
      this.showAddNote = false;
      this.addLabel = 'Aggiungi';
    } else {
      this.showAddNote = true;
      this.addLabel = 'Nascondi';
    }
  }

  documentChanged(event: any, tipo1: string, tipo2: string, tipo3: string): void {
    if (event.isUserInput) {
      this.apiService.additionalProfiles(tipo1, tipo2, tipo3, this.fields[0].value).subscribe(data => {
        this.fieldsNoAdditional = new Array<any>();
        this.fields.forEach(v => {
          if (v.isAdditional === false) {
            this.fieldsNoAdditional.push(v);
          }
        });
        this.additionalFields = data;
        this.additionalFields.forEach(v => {
          this.fieldsNoAdditional.push(v);
        });
        this.fields = this.fieldsNoAdditional;
        this.fields.forEach(v => {
          if (v.className === 'AdditionalFieldComboDTO') {
            this.apiService.getProfileValuesWithFilter(v.name, this.getJsonFields().fields).subscribe(results => {
              // console.log(results);
              this.tempCombo = new Array<any>();
              results.dataSource.forEach(res => {
                this.selectToAdd = '';
                this.keyToAdd = '';
                res.columns.forEach(r => {
                  if (results.selectField.toLowerCase().includes(r.id.toLowerCase())) {
                    this.selectToAdd += r.value + ' - ';
                  }
                  if (results.keyField.toLowerCase().includes(r.id.toLowerCase())) {
                    this.keyToAdd += r.value + ' - ';
                  }
                });
                this.tempCombo.push({
                  selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                  keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3)
                });
                this.comboValues[v.name] = this.tempCombo;
              });
            });
          }
        });
        // console.log(this.fields);
      });
    }
  }

  searchMaskFromClassDTO(field: any): void {
    if (this.edit) {
      if (!field.locked) {
        const dialogRef = this.dialog.open(DialogSearchDocComponent, {
          width: '100%',
          height: 'fit-content',
          data: {
            classField: field
          }
        });
        dialogRef.afterClosed().subscribe(res => {
          // // console.log(res);
          res.dataResult.forEach(result => {
            this.docNumberClasseboxTemp = new Array<string>();
            // // console.log(this.mapClasseBookToSave);
            this.docNumberClasseboxTemp = this.mapClasseBookToSave.get(result.fieldName);
            // // console.log(this.docNumberClasseboxTemp);

            this.docNumberClasseboxTemp.push(result.docNumber);
            this.mapClasseBookToSave.set(result.fieldName, this.docNumberClasseboxTemp);
            this.arrayToObject = {};
            this.apiService.getProfileDetailsClass(result.docNumber).subscribe(callResults => {
              callResults.fields.forEach(fieldClass => {
                this.displayedColumnsClass.forEach(colum => {
                  if (colum === fieldClass.description) {
                    this.arrayToObject[colum] = fieldClass.value;
                  }
                });
              });
              this.ELEMENT_DATA_CLASS.push(this.arrayToObject);
              this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
              // // console.log(this.ELEMENT_DATA_CLASS);
            });
          });
        });
      }
    }
  }

  opendocumentalClassDialog(): void {
    this.apiService.classDocMode = '1';
    const dialogRef = this.dialog.open(DialogDocumentalClassComponent, {
      width: '40%',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result) {
        this.fields.forEach(v => {
          if (v.name === 'DocumentType') {
            this.dataMaskToSave[v.name] = Number(result.docClassId);
            v.value = Number(result.docClassId);
            this.documentChanged({isUserInput: true}, result.docType, result.type1, result.type2);
          }
        });
      }
    });
  }

  remove(v: string, fieldName: string): void {
    this.multiValuteDTOSelectedToShow = new Array<any>();
    this.dataMaskToSave[fieldName].forEach((value, index) => {
      if (value === v) {
        this.dataMaskToSave[fieldName].splice(index, 1);
      }
    });
    this.dataMaskToSave[fieldName].forEach((value) => {
      this.multiValuteDTOSelectedToShow.push(value);
    });
    this.mapMultiValueToSee = new Map<string, any>();
    this.mapMultiValueToSee.set(fieldName, this.multiValuteDTOSelectedToShow);
  }

  openMultiValueDTO(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
        // console.log(results);
        this.fieldDataSource = new Array<object>();
        if (results.dataSource != null) {
          results.dataSource.forEach(item => {
            this.fieldDataSource.push(item.columns);
          });
        } else {
          // EMPTY MULTI VALUE
        }
        const dialogRef = this.dialog.open(DialogMultiDTOComponent, {
          id: 'style-4',
          width: '40%',
          height: '60%',
          data: {
            additionalFieldMultiDTO: this.fieldDataSource,
            fieldName: field.name,
            multiValueToSelected: this.multiValuteDTOSelected,
            selectField: results.selectField
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result.dataResult === 'delete') {
            this.multiValuteDTOSelectedToShow = new Array<any>();
            this.dataMaskToSave[this.fieldName] = '';
            this.mapMultiValueToSee.set(this.fieldName, '');
            this.multiValuteDTOSelected.set(this.fieldName, []);
          }
          this.multiValuteDTOSelectedToShow = new Array<any>();
          if (result.dataResult !== false) {
            result.dataResult.forEach(item => {
              this.multiValuteDTOSelectedToShow.push(item);
            });
            this.dataMaskToSave[this.fieldName] = result.dataResult;
            this.mapMultiValueToSave.set(this.fieldName, result.dataToSave); // non serve probabilmente
            this.multiValuteDTOSelected.set(this.fieldName, result.dataResult); // non serve probabilmente
            this.mapMultiValueToSee.set(this.fieldName, this.multiValuteDTOSelectedToShow);
            console.log(this.mapMultiValueToSee);
          }
          this.getAllProfileValue();
          this.checkFormula();
        });
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close({dataResult: false});
  }

  openTableDTO(field: any): void {
    if (this.edit) {
      if (!field.locked) {
        this.fieldName = field.name;
        this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
          this.tableAssociationField = {};
          this.tableAssociationField = Object.keys(results.associations)
            .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
          this.fieldDataSource = new Array<object>();
          if (results.dataSource != null) {
            this.keyField = results.keyField;
            results.dataSource.forEach(item => {
              this.fieldDataSource.push(item.columns);
            });
          } else {
            // EMPTY MULTI VALUE
          }
          const dialogRef = this.dialog.open(DialogTableDTOComponent, {
            id: 'style-4',
            width: '80%',
            height: '95%',
            data: {
              additionalFieldTableDTO: this.fieldDataSource,
              fieldName: field.name,
              tableValueToSelected: this.tableValuteDTOSelected,
              selectField: results.selectField
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result.dataResult === 'delete') {
              this.fields.forEach((item, index) => {
                if (this.comboAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                  this.fields[index].value = '';
                  this.dataMaskToSave[this.fields[index].name] = '';
                }
                if (item.name === this.fieldName) {
                  this.fields[index].value = '';
                  this.dataMaskToSave[this.fields[index].name] = '';
                }
              });
            }
            if (result.dataResult !== false) {
              this.mapTableValueToSave = result.dataResult;
              this.fields.forEach((item, index) => {
                if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                  this.fields[index].value = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
                  this.dataMaskToSave[this.fields[index].name] =
                    result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
                }
                if (item.name === this.fieldName) {
                  this.fields[index].value = result.dataResult[this.keyField];
                  this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.keyField];
                }
              });
            }
            this.getAllProfileValue();
          });
        });
      }
    }
  }

  openAddressBook(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.SearchAllAddressBook().subscribe(results => {
        this.apiService.viewDataToShow = results;
        const dialogRef = this.dialog.open(DialogAddressBookV2Component, {
          id: 'style-4',
          width: '80%',
          height: '95%',
          data: {
            fieldName: field.name,
            addressBookSelected: this.addressBookSelected,
            selectField: 'DM_RUBRICA_RAGIONE_SOCIALE'
          }
        });


        dialogRef.afterClosed().subscribe(result => {
          this.mapAddressBookSave = new Array<any>();
          let fromFieldValue = new Object();
          this.addressBookSelectedToShow = '';
          // this.addressBookSelected = new Map<string, string[]>();
          let type: string;
          switch (this.fieldName) {
            case 'To':
              type = '0';
              break;
            case 'From':
              type = '1';
              break;
            case 'Cc':
              type = '2';
              break;
            case 'Senders':
              type = '3';
              break;
          }
          result.dataResult.forEach((val, index) => {

            this.idContact = val;
            this.apiService.getContactAddressBook(this.idContact, type).subscribe(res => {
              // // console.log(res);
              if (type === '0') {
                this.mapAddressBookSave.push(res);
              } else {
                fromFieldValue = res;
                this.dataMaskToSave[this.fieldName] = fromFieldValue;
              }
              if (index === result.dataResult.length - 1) {
                if (result.dataResult !== false) {
                  result.contactsNames.forEach(item => {
                    this.addressBookSelectedToShow += item + ';\n';
                  });
                  if (type === '0') {
                    this.dataMaskToSave[this.fieldName] = this.mapAddressBookSave;
                  } else {
                    this.dataMaskToSave[this.fieldName] = fromFieldValue;
                  }
                  this.addressBookSelected.set(this.fieldName, result.dataResult);
                  this.mapAddressBookToSee.set(this.fieldName, this.addressBookSelectedToShow);
                }
                this.checkFormula();
                this.getAllProfileValue();
              }

            });
          });


          /*this.multiValuteDTOSelectedToShow = new Array<any>();
          if (result.dataResult !== false) {
              result.dataResult.forEach(item => {
                  this.multiValuteDTOSelectedToShow.push(item);
              });
              this.dataMaskToSave[this.fieldName] = result.dataResult;
              this.mapMultiValueToSave.set(this.fieldName, result.dataToSave); // non serve probabilmente
              this.multiValuteDTOSelected.set(this.fieldName, result.dataResult);
              this.mapMultiValueToSee.set(this.fieldName, this.multiValuteDTOSelectedToShow);
          }*/
        });
      });
    }
  }


  openComboValueDTO(field: any): void {
    if (!field.locked) {
      // console.log(this.getJsonFields().fields);
      this.fieldName = field.name;
      this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
        this.comboAssociationField = {};
        this.comboAssociationField = Object.keys(results.associations)
          .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
        this.fieldDataSource = new Array<object>();
        if (results.dataSource != null) {
          this.keyField = results.keyField;
          results.dataSource.forEach(item => {
            this.fieldDataSource.push(item.columns[0]);
          });
        } else {
          // EMPTY MULTI VALUE
        }
        const dialogRef = this.dialog.open(DialogComboDTOComponent, {
          id: 'style-4',
          width: '100%',
          height: '95%',
          data: {
            additionalFieldComboDTO: this.fieldDataSource,
            fieldName: field.name,
            comboValueToSelected: this.comboValuteDTOSelected,
            selectField: results.selectField
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result.dataResult === 'delete') {
            this.fields.forEach((item, index) => {
              if (this.comboAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                this.fields[index].value = '';
                this.dataMaskToSave[this.fields[index].name] = '';
              }
              if (item.name === this.fieldName) {
                this.fields[index].value = '';
                this.dataMaskToSave[this.fields[index].name] = '';
              }
            });
          }
          if (result.dataResult !== false) {
            this.fields.forEach((item, index) => {
              if (this.comboAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                this.fields[index].value = result.dataResult[this.comboAssociationField[item.name.toLowerCase()]];
                this.dataMaskToSave[this.fields[index].name] =
                  result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
              }
              if (item.name === this.fieldName) {
                this.fields[index].value = result.dataResult[this.keyField];
                this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.keyField];
              }
            });
          }
          this.getAllProfileValue();
        });
      });
    }
  }

  insertMaskFromClassDTO(field: any): void {
    if (this.edit) {
      if (!field.locked) {
        this.fieldName = field.name;
        const dialogRef = this.dialog.open(DialogMaskComponent, {
          width: '90%',
          height: 'fit-content',
          data: {
            id: field.insertMaskId,
            fieldName: field.name,
            dataMask: this.dataMaskToSave,
            dataAssociations: this.dataValueAssociations
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          // // console.log(result);
          if (result.hasOwnProperty('docNumber')) {
            this.docNumberClasseboxTemp = new Array<string>();
            this.docNumberClasseboxTemp = this.mapClasseBookToSave.get(result.fieldClassName);
            this.docNumberClasseboxTemp.push(result.docNumber);
            this.mapClasseBookToSave.set(result.fieldClassName, this.docNumberClasseboxTemp);
            this.arrayToObject = {};
            this.apiService.getProfileDetailsClass(result.docNumber).subscribe(callResults => {
              callResults.fields.forEach(fieldClass => {
                this.displayedColumnsClass.forEach(colum => {
                  if (colum === fieldClass.description) {
                    this.arrayToObject[colum] = fieldClass.value;
                  }
                });
              });
              this.ELEMENT_DATA_CLASS.push(this.arrayToObject);
              this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
              // // console.log(this.ELEMENT_DATA_CLASS);
            });
          }
        });
      }
    }
  }

  openClassMasks(field: any): void {
    if (this.edit) {
      if (!field.locked) {
        this.fieldName = field.name;
        const dialogRef = this.dialog.open(DialogClassViewDTOComponentDTOComponent, {
          width: '90%',
          height: 'fit-content',
          data: {
            classField: field,
            mapClassValue: this.mapClasseBookToSave.get(field.name)
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          // // console.log(this.mapClasseBookToSave.get(result.fieldName));
        });
      }
    }
  }

  onResize(event): void {
    this.breakpoint = (event.target.innerWidth <= 600) ? 4 : 6;
  }

  showFileSelection(event): void {
    this.fileInfo = {name: '', buffer: ''};
    this.fileListMain = event.target.files;
    if (this.fileListMain.length > 0) {
      this.fileMain = this.fileListMain[0];
      this.formDataMain = new FormData();
      this.formDataMain.append('file', this.fileMain, this.fileMain.name);
      this.apiService.openBuffer(this.formDataMain).subscribe(result => {
        this.fileInfo.name = this.fileMain.name;
        this.fileInfo.buffer = result[0];
        this.bufferIdInfo.push(this.fileInfo);
        this.badgeBufferMain = this.bufferIdInfo.length;
      });
    }
  }

  showAttachments(): void {
  }

  showNotes(): void {
    this.whatToShow = 'note';
  }

  showDesk(): void {
    this.whatToShow = 'default';
  }

  removeFile(file: any): void {
    this.apiService.deleteBuffer(file).subscribe(result => {
      if (result) {
        this.snackBar.open('File ' + file.name + ' eliminato!', null, {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });
        this.bufferIdInfo.forEach((item, index) => {
          if (item.buffer === file.buffer) {
            this.bufferIdInfo.splice(index, 1);
          }
        });
        this.badgeBufferMain = this.bufferIdInfo.length;
      }
    });
  }

  saveMask(): void {
    this.whatToShow = 'default';
    this.validationCheck = true;
    this.fields.forEach(field => {
      if (field.visible) {
        if (field.required) {
          // // console.log(this.dataMaskToSave);
          if (this.dataMaskToSave[field.name] === '' || this.dataMaskToSave[field.name] == null) {
            this.snackBar.open('Il campo ' + ((field.description === 'Aoo') ? 'Azienda' : field.description) +
              ' è obbligatorio', null, {
              duration: 5000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
            this.validationCheck = false;
          }
        }
      }
    });
    if (this.validationCheck) {
      this.buildDataMask();
    }
  }

  getJsonFields(): any {
    if (this.bufferIdInfo.length > 0) {
      this.jsonToSendArxivar = {
        document: {
          fileNames: [],
          bufferIds: []
        },
        fields: []
      };
      this.bufferIdInfo.forEach(file => {
        this.jsonToSendArxivar.document.fileNames.push(file.name);
        this.jsonToSendArxivar.document.bufferIds.push(file.buffer);
      });
    } else {
      this.jsonToSendArxivar = {
        fields: []
      };
    }
    // // // console.log(this.dataMaskToSave);
    this.fields.forEach((field, index) => {
      if (field.visible) {
        // // console.log(this.dataMaskToSave);
        if (field.className === 'AdditionalFieldDateTimeDTO') {
          this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
        } else if (field.className === 'AdditionalFieldDoubleDTO') {
          if (this.dataMaskToSave[field.name]) {
            this.fields[index].value = (this.dataMaskToSave[field.name].toString().replace(',', '.'));
          } else {
            this.fields[index].value = this.dataMaskToSave[field.name];
          }
        } else {
          this.fields[index].value = this.dataMaskToSave[field.name];
        }


        // // // console.log(this.dataMaskToSave[field.name]);

        // // // console.log(field);
      }
      this.jsonToSendArxivar.fields.push(this.fields[index]);
    });
    return this.jsonToSendArxivar;
  }

  buildDataMask(): void {
    if (this.bufferIdInfo.length > 0) {
      this.jsonToSendArxivar = {
        document: {
          fileNames: [],
          bufferIds: []
        },
        fields: []
      };
      this.bufferIdInfo.forEach(file => {
        this.jsonToSendArxivar.document.fileNames.push(file.name);
        this.jsonToSendArxivar.document.bufferIds.push(file.buffer);
      });
    } else {
      this.jsonToSendArxivar = {
        fields: []
      };
    }
    // // // console.log(this.dataMaskToSave);
    this.fields.forEach((field, index) => {
      if (field.visible) {
        // // console.log(this.dataMaskToSave);
        if (field.className === 'AdditionalFieldDateTimeDTO') {
          this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
        } else if (field.className === 'AdditionalFieldDoubleDTO') {
          if (this.dataMaskToSave[field.name]) {
            this.fields[index].value = (this.dataMaskToSave[field.name].toString().replace(',', '.'));
          } else {
            this.fields[index].value = this.dataMaskToSave[field.name];
          }
        } else {
          this.fields[index].value = this.dataMaskToSave[field.name];
        }

        // // // console.log(this.dataMaskToSave[field.name]);

        // // // console.log(field);
      }
      this.jsonToSendArxivar.fields.push(this.fields[index]);
    });
    // // console.log(this.dataMaskToSave);
    this.apiService.updateProfile(this.jsonToSendArxivar, this.docId).subscribe(results => {
      // // console.log(results);
      this.snackBar.open('Profilo aggiornato con successo', null, {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-success-bg']
      });
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 7000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });

    // this.goBack();
    this.edit = false;
  }

  TransformDataFormatToSave(Data: string): string {

    if (Data === '') {
      return '0001-01-01T00:00:00';
    }
    return this.datePipe.transform(Data, 'yyyy-MM-dd', '', 'en-GB');
  }

  lock(): void {
    this.whatToShow = 'default';
    this.edit = false;

  }

  unLock(): void {
    if (this.canUnlock) {
      this.edit = true;
    } else {
      // // console.log('non sbloccabile');
    }
  }


  TransformDataFormat(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');
  }

  getAllProfileValue(): void {
    this.fields.forEach((val, index) => {
      if (val.className === 'AdditionalFieldComboDTO') {
        this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
          // console.log(results);
          this.tempCombo = new Array<any>();
          results.dataSource.forEach(res => {
            this.selectToAdd = '';
            this.keyToAdd = '';
            res.columns.forEach(r => {
              if (results.selectField.toLowerCase().includes(r.id.toLowerCase())) {
                this.selectToAdd += r.value + ' - ';
              }
              if (results.keyField.toLowerCase().includes(r.id.toLowerCase())) {
                this.keyToAdd += r.value + ' - ';
              }
            });
            this.tempCombo.push({
              selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
              keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3)
            });
            this.comboValues[val.name] = this.tempCombo;
          });
        });
      } else {
        if (val.className === 'AdditionalFieldTableDTO' || val.className === 'AdditionalFieldMultivalueDTO') {
          this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
            if (results.dataSource.length === 0) {
              this.fields[index].value = '';
              this.dataMaskToSave[this.fields[index].name] = '';
            }
          });
        }
      }

    });
    console.log(this.fields);
    this.checkFormula();

  }

  goBack(): void {
    this.edit = false;
  }

}
