<div class="center">
    <mat-card class="cardcomponentMasks" id = "style-4">
       <div style = "display: flex">
        <!--<button style="float:left" title="Indietro" style="color: {{this.dashColor}}" (click)="goBack()" mat-icon-button
                aria-label="icon-button send">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>-->
        <button style="float:right; color:{{this.dashColor}};" title="{{'labels.save' | translate}}" (click)="save()" mat-icon-button
                aria-label="icon-button send">
            <mat-icon>save</mat-icon>
        </button>
       </div>
        <hr class="riga" style = "color: {{this.dashColor}}"><br>
        <mat-card-content>
            <br><mat-grid-list class="griglia" [cols]="breakpoint"  cols="4"  rowHeight="80px" (window:resize)="onResize($event)">
                    <mat-grid-tile  class="gridtile"  >
                        <mat-form-field  class="form-field"  appearance="outline" >
                            <mat-label>{{'labels.organization' | translate}}</mat-label>
                            <input maxlength="500" matInput placeholder="{{'labels.organization' | translate}}" [(ngModel)]="this.Organizzazione" value = "{{this.Organizzazione}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile  class="gridtile"  >
                        <mat-form-field  class="form-field"  appearance="outline" >
                            <mat-label>{{'labels.contactList' | translate}}</mat-label>
                            <mat-select [value]="this.Rubrica" [(ngModel)]="this.Rubrica">
                                <ng-container *ngFor="let rubrica of nomicategorierubrica">
                                    <mat-option [value]= "rubrica.id">
                                        {{rubrica.addressBook}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile  class="gridtile"  >
                        <mat-form-field  class="form-field"  appearance="outline" >
                            <mat-label>{{'labels.code' | translate}}</mat-label>
                            <input maxlength="500" matInput placeholder="{{'labels.code' | translate}}" [(ngModel)]="this.Codice"  value = "{{this.Codice}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile  class="gridtile"  >
                        <mat-form-field  class="form-field"  appearance="outline" >
                            <mat-label>{{'labels.company' | translate}}</mat-label>
                            <mat-select [value]="this.AziendaNome" [(ngModel)]="this.AziendaNome">
                                <ng-container *ngFor="let aoo of nomiAoo">
                                    <mat-option   [value]="aoo.name">
                                        {{aoo.name}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile  class="gridtile"  >
                        <mat-form-field  class="form-field"  appearance="outline" >
                            <mat-label>{{'labels.category' | translate}}</mat-label>
                            <input maxlength="500" matInput placeholder="{{'labels.category' | translate}}"  [(ngModel)]="this.Categoria" value = "{{this.Categoria}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile  class="gridtile"  >
                        <mat-form-field  class="form-field"  appearance="outline" >
                            <mat-label>{{'labels.priority' | translate}}</mat-label>
                            <mat-select [(value)]="this.PrioritaNome" [(ngModel)]="this.PrioritaNome">
                                <mat-option value="No invio">
                                  {{'labels.noSend' | translate}}
                                </mat-option>
                                <mat-option value="Solo Fax">
                                  {{'labels.onlyFax' | translate}}
                                </mat-option>
                                <mat-option value="Solo Email">
                                  {{'labels.onlyMail' | translate}}
                                </mat-option>
                                <mat-option value="Fax/Email">
                                    Fax/Email
                                </mat-option>
                                <mat-option value="Email/Fax">
                                    Email/Fax
                                </mat-option>
                                <mat-option value="Fax+Email">
                                    Fax+Email
                                </mat-option>
                                <mat-option value="Solo Stampa">
                                  {{'labels.onlyPrint' | translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile  class="gridtile"  >
                        <mat-form-field  class="form-field"  appearance="outline" >
                            <mat-label>{{'labels.notesA' | translate}}</mat-label>
                            <textarea style="resize: none;" rows="3" maxlength="1000" matInput placeholder="{{'labels.notesA' | translate}}" [(ngModel)]="this.Appunti" value = "{{this.Appunti}}"></textarea>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile  class="gridtile"  >
                        <mat-form-field  class="form-field"  appearance="outline" >
                            <mat-label>{{'labels.active' | translate}}</mat-label>
                            <mat-select [(ngModel)]="this.Attivo" [(value)]="this.Attivo">
                                <mat-option value="true">
                                    NO
                                </mat-option>
                                <mat-option value="false">
                                  {{'labels.yes' | translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
            </mat-grid-list>


            <h2 style = "color: {{this.dashColor}}">{{'labels.numberTelephones' | translate}}</h2><hr><br>
            <mat-grid-list class="griglia" [cols]="breakpointnum"   cols="3"  rowHeight="80px" (window:resize)="onResizenum($event)">


                <mat-grid-tile  class="gridtile"  >
                    <mat-form-field  class="form-field"  appearance="outline" >
                        <mat-label>Tel</mat-label>
                        <input maxlength="500" matInput placeholder="Tel" [(ngModel)]="this.Tel" value = "{{this.Tel}}">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile  class="gridtile"  >
                    <mat-form-field  class="form-field"  appearance="outline" >
                        <mat-label>Fax</mat-label>
                        <input maxlength="500" matInput placeholder="Fax" [(ngModel)]="this.Fax" value = "{{this.Fax}}">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile  class="gridtile"  >
                    <mat-form-field  class="form-field"  appearance="outline" >
                        <mat-label>{{'labels.mobile' | translate}}</mat-label>
                        <input maxlength="500" matInput placeholder="{{'labels.mobile' | translate}}" [(ngModel)]="this.Cel" value = "{{this.Cel}}">
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>

            <h2 style = "color: {{this.dashColor}}">{{'labels.addresses' | translate}}</h2><hr><br>
            <mat-grid-list class="griglia" [cols]="breakpointnum"   cols="3"  rowHeight="80px" (window:resize)="onResizenum($event)">


                <mat-grid-tile  class="gridtile"  >
                    <mat-form-field  class="form-field"  appearance="outline" >
                        <mat-label>{{'labels.address' | translate}}</mat-label>
                        <input maxlength="500" matInput placeholder="{{'labels.address' | translate}}" [(ngModel)]="this.Indirizzo" value = "{{this.Indirizzo}}">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile  class="gridtile"  >
                    <mat-form-field  class="form-field"  appearance="outline" >
                        <mat-label>{{'labels.locality' | translate}}</mat-label>
                        <input maxlength="500" matInput placeholder="{{'labels.locality' | translate}}" [(ngModel)]="this.Localita" value = "{{this.Localita}}">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile  class="gridtile"  >
                    <mat-form-field  class="form-field"  appearance="outline" >
                        <mat-label>{{'labels.province' | translate}}</mat-label>
                        <input maxlength="500" matInput placeholder="{{'labels.province' | translate}}" [(ngModel)]="this.Provincia" value = "{{this.Provincia}}">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile  class="gridtile"  >
                    <mat-form-field  class="form-field"  appearance="outline" >
                        <mat-label>{{'labels.cap' | translate}}</mat-label>
                        <input maxlength="500" matInput placeholder="{{'labels.cap' | translate}}" [(ngModel)]="this.Cap" value = "{{this.Cap}}">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile  class="gridtile"  >
                    <mat-form-field  class="form-field"  appearance="outline" >
                        <mat-label>Email</mat-label>
                        <input maxlength="500" matInput placeholder="Email" [(ngModel)]="this.Email" value = "{{this.Email}}">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile  class="gridtile"  >
                    <mat-form-field  class="form-field"  appearance="outline" >
                        <mat-label>{{'labels.country' | translate}}</mat-label>
                        <input maxlength="500" matInput placeholder="{{'labels.country' | translate}}" [(ngModel)]="this.Nazione" value = "{{this.Nazione}}">
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>

            <h2 style = "color: {{this.dashColor}}">{{'labels.taxInfo' | translate}}</h2><hr><br>
            <mat-grid-list class="griglia"   cols="2"  rowHeight="80px" >


                <mat-grid-tile  class="gridtile"  >
                    <mat-form-field  class="form-field"  appearance="outline" >
                        <mat-label>{{'labels.taxCode' | translate}}</mat-label>
                        <input maxlength="500" matInput placeholder="{{'labels.taxCode' | translate}}" [(ngModel)]="this.CodiceFiscale" value = "{{this.CodiceFiscale}}">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile  class="gridtile"  >
                    <mat-form-field  class="form-field"  appearance="outline" >
                        <mat-label>{{'labels.vatNumber' | translate}}</mat-label>
                        <input maxlength="500" matInput placeholder="{{'labels.vatNumber' | translate}}" [(ngModel)]="this.PartitaIva" value = "{{this.PartitaIva}}">
                    </mat-form-field>
                </mat-grid-tile>

            </mat-grid-list>

                   <!-- da inserire con un for -->








        </mat-card-content>
    </mat-card>
</div>
