<div style="display: block; text-align: center">
  <div style="width: 100%; height: 20%">
    <h3>{{'labels.chooseTypeDoc' | translate}}</h3>
  </div>
  <div style="width: 100%; height: 80%">
    <mat-form-field class="example-full-width" appearance="outline" style="width: 90%;">
      <mat-label>{{'labels.chooseType' | translate}}</mat-label>
      <input type="text"
             matInput (input) = "this.checkAutocomplete(this.inputValue)"
             [(ngModel)] = "this.inputValue"
             [matAutocomplete]="auto">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
        <ng-container *ngFor="let maschera of this.Maschere">
          <mat-option *ngIf="maschera.maskName && maschera.kind == 0 && maschera.disabled == false" [value]="maschera.maskName" (onSelectionChange)="openMask(maschera.id)">
            {{maschera.maskName}}
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
  </div>


</div>
