<mat-card style="text-align: center; width: 95%; height: 30%; margin: auto; resize: vertical;
  overflow: auto;" *ngIf="this.hasPreview">
  <button mat-icon-button style="float: right" (click)="this.openFullPreview()">
    <mat-icon>fullscreen</mat-icon>
  </button>
  <cdk-virtual-scroll-viewport itemSize="50" style="height: 100%; width: 100%" id="style-5">
    <button mat-icon-button title="{{'labels.previousPage' | translate}}" (click)="this.prevPage()"
            [disabled]="this.index == 0">
      <mat-icon>arrow_left</mat-icon>
    </button>
    <span>{{this.index + 1}}/{{this.maxPages}}</span>
    <button mat-icon-button title="{{'labels.nextPage' | translate}}" [disabled]="this.index + 1 == this.maxPages"
            (click)="this.nextPage()">
      <mat-icon>arrow_right</mat-icon>
    </button>
    <div>
      <img id="myimage" [src]='this.image'/>
    </div>
  </cdk-virtual-scroll-viewport>
</mat-card>
<mat-toolbar class="mat-toolbar mat-toolbar-single-row" style="display: flex; text-align: left; height: 86px;  width: 98%; background: unset !important;
    "> <!--
      <button *ngIf="this.takeCharge == false && this.canExit == true" title="Esito" style="color: black"
              (click)="openActionDialog()"
              [disabled]="!this.activeButtons" mat-stroked-button
              aria-label="Procedi">
        <mat-icon>send</mat-icon>
        Procedi

      </button>  -->
  <div style="width: 50%">
    <button mat-stroked-button (click)="this.exitTask('')" [disabled]="this.takeCharge"
            style=" width: fit-content; color: black;">{{'buttons.send' | translate}}
    </button>
    <mat-form-field style="width: 35%; padding-right: 5%; height: 10%;" id="{{'labels.nextPage' | translate}}"
                    appearance="standard" *ngIf="!this.takeCharge == true">
      <mat-label>{{'labels.action' | translate}}</mat-label>
      <mat-select name="esito" (selectionChange)="exitSelected($event.value)"
                  (click)="this.loadExitCodesWithError()" [(value)]="this.selected">
        <mat-option *ngFor="let esito of this.exitCodes" [value]="esito.value">
          {{esito.value}}
        </mat-option>
      </mat-select>

    </mat-form-field>

  </div>


  <!-- <mat-form-field style="width: 50%; height: auto; " appearance="fill">
       <mat-label>Commento</mat-label>
       <textarea matInput rows="5"></textarea>
   </mat-form-field>-->
  <div style="width: 50%; text-align: right;
    margin: auto;
">
    <button *ngIf="this.takeCharge == true" title="{{'labels.takeCharge' | translate}}" (click)="doTakeCharge()"
            mat-stroked-button
            style="color: black"
            aria-label="Prendi in carico">
      <mat-icon>pan_tool</mat-icon>
      {{'labels.takeCharge' | translate}}

    </button>
    <button *ngIf="this.showProcessVisible" title="Processo" (click)="processClick()" style="color: black"
            mat-stroked-button
            aria-label="Processo">
      <mat-icon>developer_board</mat-icon>
      {{'labels.process' | translate}}

    </button>
    <button title="Note" (click)="openNotesClick()" style="color: black"
            mat-stroked-button matBadge="{{this.notesNumber}}" matBadgeOverlap="false"
            aria-label="Note">
      <mat-icon>note</mat-icon>
      {{'labels.notes' | translate}}

    </button>
  </div>
  <!-- <button  mat-stroked-button (click)="onNoClick()" style="text-align: center">Annulla</button>-->


</mat-toolbar>
<div class="aParent" style = "padding-top: 0 !important;" [ngStyle]="{'height': this.hasPreview ? '60%' : ''}">
  <mat-card class="mat-card mat-focus-indicator ng-star-inserted cardcomponentMasks1" style="position: relative"
            id="style-4">
    <mat-card-title style="text-align: left; color: {{this.dashColor}}"><b>{{this.taskName}}</b></mat-card-title>
    <mat-card-content>
      <p [innerHTML]="this.taskDescription" style="text-align: left; color: black"></p>
    </mat-card-content>
  </mat-card>
  <mat-card class="mat-card mat-focus-indicator ng-star-inserted cardcomponentMasks1" id="style-4">
    <mat-card-title style="text-align: left; color: {{this.dashColor}}"><b>{{'labels.documents' | translate}}:</b>
    </mat-card-title>
    <ng-container *ngIf="isLoading" style="text-align: center;">
      <mat-progress-bar *ngIf="this.isLoading"
                        color="warn"
                        mode="buffer"
                        class="example-tree-progress-bar"></mat-progress-bar>
    </ng-container>
    <div style="text-align: left;" *ngIf="!isLoading">
      <mat-selection-list #documenti [multiple]="false">
        <ng-container *ngFor="let doc of this.documentFields">
          <ng-container *ngFor="let campo of doc.fields">
            <b
              *ngIf="campo.name == 'DocumentType'">
              <h2 style="color: #333333">{{campo.classDescription}}:</h2>
            </b>

          </ng-container>
          <mat-accordion><!--*ngIf="doc.id == this.activeDocNumber"-->
            <mat-expansion-panel style="display:contents;!important;" [expanded]="false"
                                 (opened)="panelOpenState = true" (dblclick)="this.profileClick(doc.id)"
                                 (closed)="panelOpenState = false">
              <mat-expansion-panel-header class="hover">
                <mat-panel-title>
                  <ng-container *ngIf="doc.id == this.activeDocNumber"><b
                    style="color: {{this.squareColor}};">SystemID: {{doc.id}}</b>
                  </ng-container>
                  <b *ngIf="doc.id != this.activeDocNumber">SystemID: {{doc.id}} </b>
                </mat-panel-title>
                <mat-panel-description>
                  <ng-container *ngFor="let campo of doc.fields">
                    <b
                      *ngIf="campo.className == 'BusinessUnitFieldDTO'">{{'labels.company' | translate}}:
                      <ng-container *ngFor="let aoo of nomiAoo">
                        <ng-container *ngIf="campo.value === aoo.code">
                          {{aoo.name}}
                        </ng-container>
                      </ng-container>
                    </b>

                  </ng-container>
                  <ng-container *ngIf="doc.profileInfo.fileName != ''">
                    <mat-icon style="margin-left: 15px; color:{{this.dashColor}}">article
                    </mat-icon>
                  </ng-container>

                </mat-panel-description>
              </mat-expansion-panel-header>
              <ng-container *ngFor="let campo of doc.fields">
                <p
                  *ngIf="campo.className == 'DocumentDateFieldDTO' && campo.value != null">
                  <em>{{'labels.documentDate' | translate}}
                    : </em>{{this.TransformDataFormat(campo.value)}}</p>
                <p *ngIf="campo.className == 'SubjectFieldDTO' && campo.value != ''">
                  <em> {{'labels.subject' | translate}}:</em> {{campo.value}}</p>
                <p *ngIf="campo.className == 'FromFieldDTO' && campo.value != null"><em>{{'labels.from' | translate}}
                  :</em>
                  <ng-container
                  >{{campo.value.society}}</ng-container>
                </p>
                <p *ngIf="campo.className == 'ToFieldDTO' && campo.value != null"><em>{{'labels.to' | translate}}:</em>
                  <ng-container
                    *ngFor="let field of campo.value">{{field.society}},
                  </ng-container>
                </p>
                <p *ngIf="campo.className == 'NumberFieldDTO' && campo.value != ''">
                  <em>{{'labels.number' | translate}}:</em> {{campo.value}}</p>
              </ng-container>

              <div align="start">
                <!--<button mat-stroked-button (click)="openPreviewDialog(doc.id)" style="text-align: right">
                    Anteprima
                    <mat-icon svgIcon="DocumentIcon" style="margin-right: 8px"></mat-icon>
                </button>-->
                <button mat-stroked-button (click)="openPreviewDialog(doc.id)">
                  <mat-icon style="margin-right: 15px;" svgIcon="DocumentIcon"></mat-icon>
                  {{'labels.previewProfile' | translate}}

                </button>
                <button (click)="this.profileClick(doc.id)"
                        style="color: black; " mat-stroked-button
                        aria-label="icon-button send">
                  <mat-icon style="color: #29648A;" svgIcon="ProfileIcon" style="margin-right: 15px;"
                            style="color: black"></mat-icon>
                  {{'labels.editProfile' | translate}}
                </button>
                <button style="color: black; " mat-stroked-button
                        (click)="this.downloadDoc(doc.id, doc.profileInfo.fileName)"
                        aria-label="icon-button send">
                  <mat-icon svgIcon="OpenIcon" style="color: black" style="margin-right: 15px;"></mat-icon>
                  {{'labels.downloadDocument' | translate}}
                </button>

                <!--<button mat-menu-item>
                    <mat-icon svgIcon="NotesIcon"></mat-icon>
                    <span>Elenco note</span>
                </button>-->
                <!--<button mat-menu-item disabled>
                    <mat-icon svgIcon="ExportIcon"></mat-icon>
                    <span>Esporta</span>
                </button>-->
                <button mat-stroked-button [matMenuTriggerFor]="modifica" style="color: black; ">
                  <mat-icon style="color: black" svgIcon="EditIcon" style="margin-right: 15px;"></mat-icon>
                  <span style="color: black">{{'labels.editDocument' | translate}}</span>
                </button>

                <!-- <button mat-menu-item disabled>
                     <mat-icon svgIcon="ShareIcon"></mat-icon>
                     <span>Condivisione documento</span>
                 </button>-->
                <mat-menu #modifica="matMenu" style="color: black; ">
                  <button mat-menu-item (click)="openEditFileDialog(doc.id)" style="color: black">
                    <mat-icon style="color: black">attach_file</mat-icon>
                    <span style="color: black">{{'labels.fromFile' | translate}}</span>
                  </button>
                  <!--<button mat-menu-item style="color: black"
                          (click)="openEditRevisionDialog(doc.id)">
                    <mat-icon style="color: black">skip_previous</mat-icon>
                    <span>Da revisionare</span>
                  </button>-->
                </mat-menu>
                <button mat-stroked-button (click)="openPreview(doc.id, doc.profileInfo.revision)"
                        style="color: black; "
                        *ngIf="false">
                  <mat-icon style="color: black" style="margin-right: 15px;">preview</mat-icon>
                  <span style="color: black">{{'labels.preview' | translate}}</span>
                </button>
                <button mat-stroked-button (click)="loadTaskPreview()" style="color: black; "
                        *ngIf="!this.hasPreview && doc.id == this.activeDocNumber">
                  <mat-icon style="color: black" style="margin-right: 15px;">cached</mat-icon>
                  <span style="color: black">{{'labels.generatePreview' | translate}}</span>
                </button>
                <button mat-stroked-button (click)="hidePreview()" style="color: black;"
                        *ngIf="this.hasPreview && doc.id == this.activeDocNumber">
                  <mat-icon style="color: black" style="margin-right: 15px;">hide_image</mat-icon>
                  <span style="color: black">{{'labels.hidePreview' | translate}}</span>
                </button>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </mat-selection-list>
    </div>
    <hr class="riga">
    <div style="height: fit-content; display: flex;">

      <div style="width: 100%;" *ngIf="this.layoutTask">
        <ng-container *ngFor="let layout of this.layoutTask.details">
          <div *ngIf="layout.elementId == 'widgetTaskAttachments'"
               style="text-align: left; margin-bottom: 2%; margin-top: 2%; color: black !important;">
            <button mat-stroked-button (click)="maskClick()">
              <mat-icon style="margin-right: 8px">tab</mat-icon>
              <span class="spanlabel">{{'labels.addInternalAttachment' | translate}}</span>
            </button>
            <button mat-stroked-button (click)="this.openExternalAttachments()"
            >
              <mat-icon style="color: black">add</mat-icon>
              {{'labels.addExternalAttachment' | translate}}
            </button>
            <button *ngIf="this.docAttachments" mat-stroked-button (click)="openTaskAttachmentsDialog()"
                    style="color: black; "
                    matBadgePosition="after" matBadge="{{this.docAttachments.length}}" matBadgeOverlap="false">
              <mat-icon style="color: black" svgIcon="AttachIcon" style="margin-right: 15px;"></mat-icon>
              <span style="color: black">{{'labels.attachmentsList' | translate}}</span>
            </button>
          </div>

        </ng-container>
        <div style="text-align: left;
    margin-bottom: 2%;
    margin-top: 2%;
    color: black !important;">
          <ng-container *ngIf="this.taskWorkDocumentOperations != null && this.takeCharge == false">
            <h2 style="color: black">{{'labels.operations' | translate}}:</h2>
            <ng-container *ngFor="let operazione of this.taskWorkDocumentOperations">
              <button mat-stroked-button
                      (click)="openMask(operazione.maskId, operazione.id, operazione.moduleId, operazione.viewId)">
                <mat-icon style="color: black">add</mat-icon>
                <ng-container *ngIf="operazione.isExecuted">
                  <mat-icon style="color: black">check</mat-icon>
                </ng-container>
                <span style="color: black">{{operazione.description}}
                  <ng-container
                    *ngIf="operazione.isRequired == true && operazione.isExecuted == false">*</ng-container></span>
              </button>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="this.taskWorkSignOperations != null">
            <h2 style="color: black">Sign Operations:</h2>
            <ng-container *ngFor="let operazione of this.taskWorkDocumentOperations">

              <button mat-stroked-button style="color: black">
                <mat-icon style="color: black">add</mat-icon>
                <span style="color: black">{{operazione.description}}</span>
              </button>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="this.taskWorkCommandsOperations != null">
            <h2 style="color: black">Commands Operations:</h2>
            <ng-container *ngFor="let operazione of this.taskWorkCommandsOperations">

              <button mat-stroked-button style="color: black">
                <mat-icon style="color: black">add</mat-icon>
                <span style="color: black">{{operazione.description}}</span>
              </button>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="this.taskWorkDynamicJobOperation != null">
            <h2 style="color: black">{{'labels.dynamicOperation' | translate}}:</h2>
            <ng-container>

              <button mat-stroked-button style="color: black">
                <mat-icon style="color: black">add</mat-icon>
                <span style="color: black"></span>
              </button>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="this.taskWorkOperatingInstructions != null">
            <h2 style="color: black">Operating Operations:</h2>
            <ng-container *ngFor="let operazione of this.taskWorkOperatingInstructions">

              <button mat-stroked-button style="color: black">
                <mat-icon style="color: black">add</mat-icon>
                <span style="color: black">{{operazione.description}}</span>
              </button>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="this.taskWorkProfessionalRoleOperations && false">
            <h2 style="color: black">Professional Operations:</h2>
            <ng-container>

              <button mat-stroked-button style="color: black" *ngIf="this.taskWorkProfessionalRoleOperations">
                <mat-icon style="color: black">add</mat-icon>
                <span style="color: black">{{operazione.description}}</span>
              </button>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="this.taskWorkVariablesOperation.processVariables && this.takeCharge == false">
            <h2 style="color: black; margin-top: 15px">
              <ng-container style="color: red"
                            *ngIf="taskWorkVariablesOperation.required == true && taskWorkVariablesOperation.executed == false">
                *
              </ng-container>
              {{'labels.variables' | translate}}:
            </h2>
            <ng-container *ngFor="let operazione of this.booleanVariables">
              <section class="example-section" style="color: black">
                <mat-slide-toggle [(ngModel)]="operazione.value" style="color: black"
                                  (ngModelChange)="boolChange($event, operazione.name, operazione.className, operazione.taskWorkVariableId);"
                                  class="example-margin"
                                  [color]="color"
                                  [checked]="operazione.value">
                  {{operazione.description}}
                </mat-slide-toggle>
              </section>
            </ng-container>
            <ng-container *ngFor="let operazione of this.stringVariables">
              <mat-form-field class="example-full-width" style="color: black">
                <mat-label style="color: black">{{operazione.description}}</mat-label>
                <input matInput [required]="operazione.isRequired" style="color: black"
                       (ngModelChange)="stringChange($event, operazione.name, operazione.className, operazione.taskWorkVariableId);"
                       placeholder="{{operazione.description}}" [ngModel]="operazione.value">
              </mat-form-field>
            </ng-container>
            <ng-container *ngFor="let operazione of this.tableVariables">
              <mat-form-field

                appearance="outline" (click)="openTableDTO(operazione)">
                <!--TableDTO-->
                <mat-label>{{operazione.description}}</mat-label>
                <input ngModel [value]="operazione.value || ''"
                       placeholder="{{operazione.description}}" [ngModel]="operazione.value" matInput
                >
                <button mat-button matSuffix mat-icon-button style="color: {{this.dashColor}}">
                  <mat-icon>table_chart</mat-icon>
                </button>
              </mat-form-field>
            </ng-container>
            <ng-container *ngFor="let operazione of this.comboVariables">

              <mat-form-field
                appearance="outline">
                <mat-label>{{operazione.description}}</mat-label>
                <mat-select [id]="operazione.name" [(ngModel)]="this.comboData[operazione.name]"
                            [required]="operazione.required" [(value)]="this.comboData[operazione.name]"
                            (selectionChange)="comboSelected(operazione, $event.value, operazione.name, operazione.className, operazione.taskWorkVariableId);"
                            name="{{operazione.name}}"
                            [disabled]="operazione.locked">
                  <mat-option>--{{'labels.cancel' | translate}}--</mat-option>
                  <mat-option *ngFor="let value of this.comboValues[operazione.name]"
                              [value]="value.keyField">
                    {{value.selectField}}
                  </mat-option>

                </mat-select>

              </mat-form-field>

            </ng-container>
            <ng-container *ngFor="let field of this.dateTimeVariables">
              <mat-form-field *ngIf="field.value == '0001-01-01T00:00:00'"
                              appearance="outline"
              >
                <!--AdditionalFieldDateTimeDTO-->
                <mat-label>{{field.description}}</mat-label>
                <input style="color:#29648A;" [(ngModel)]="field.value" matInput
                       [matDatepicker]="picker" onkeydown="return false;"
                       name="{{field.name}}"
                       [id]="field.name" [required]="field.required"
                       (dateChange)="this.dateChanged($event.value, field.name, field.className, field.taskWorkVariableId)"
                       [disabled]="true" (click)="picker.open()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker disabled="{{field.locked}}" #picker color="primary"></mat-datepicker>

              </mat-form-field>
              <mat-form-field *ngIf="field.value != '0001-01-01T00:00:00'"
                              appearance="outline"
              >
                <!--AdditionalFieldDateTimeDTO-->
                <mat-label>{{field.description}}</mat-label>
                <input style="color:#29648A;" [(ngModel)]="field.value" matInput
                       [matDatepicker]="picker" onkeydown="return false;"
                       name="{{field.name}}"
                       [id]="field.name" [required]="field.required"
                       (dateChange)="this.dateChanged($event.value, field.name, field.className, field.taskWorkVariableId)"
                       [disabled]="field.locked" (click)="picker.open()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker disabled="{{field.locked}}" #picker color="primary"></mat-datepicker>

              </mat-form-field>
            </ng-container>
            <ng-container *ngFor="let operazione of this.doubleVariables">
              <mat-form-field class="example-full-width">
                <mat-label style="color: black">{{operazione.description}}</mat-label>
                <input style="color: black" matInput type="number" [required]="operazione.required"
                       (ngModelChange)="doubleChange($event, operazione.name, operazione.className, operazione.taskWorkVariableId);"
                       placeholder="{{operazione.description}}" [ngModel]="operazione.value">
              </mat-form-field>

            </ng-container>
          </ng-container>
        </div>
      </div>

    </div>
  </mat-card>
</div>
