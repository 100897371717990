<div>
  <mat-form-field>
    <mat-label>{{'labels.find' | translate}}</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="{{'labels.find' | translate}}..." #input>
  </mat-form-field>
  <button title="{{'labels.save' | translate}}" (click)="closeDialog()" mat-icon-button color="warn" aria-label="icon-button save">
    <mat-icon>close</mat-icon>
  </button>
  <button title="{{'labels.clearField' | translate}}" (click)="deleteField()" mat-icon-button color="warn" aria-label="icon-button save">
    <mat-icon>delete</mat-icon>
  </button>
</div>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource">
    <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
      <ng-container>
        <th mat-header-cell *matHeaderCellDef>{{disCol}}</th>
        <td mat-cell *matCellDef="let element" (click)="selectedRow(element)">{{element[disCol]}}
        </td>
      </ng-container>
      <mat-divider [vertical]="true"></mat-divider>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">{{'labels.noRecord' | translate}}: "{{input.value}}"</td>
    </tr>
  </table>
</div>
