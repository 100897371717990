<mat-toolbar style="background: {{this.apiService.dashColor}}; color:#fff;">
  <mat-form-field style="padding: 10px; width: 30%;">
    <mat-label>Filtra Task</mat-label>
    <input matInput [(ngModel)]="this.filter" (ngModelChange)="this.filterChange()">
    <button mat-button matSuffix mat-icon-button aria-label="Filtri" style="color: {{this.dashColor}}"
            (click)="openFilterDialog()">
      <mat-icon>filter_list</mat-icon>
    </button>
    <button mat-button *ngIf="this.filter" style="color: {{this.dashColor}}" matSuffix mat-icon-button
            aria-label="Cancella" (click)="this.filter=''; this.filterChange()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field style="padding: 10px; width: 30%;">
    <mat-label>Filtro lettura</mat-label>
    <mat-select [(value)]="this.selectedRead" (selectionChange)="readChange()">
      <mat-option value="Tutti">Tutti</mat-option>
      <mat-option value="S">Letti</mat-option>
      <mat-option value="N">Non letti</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field style="padding: 10px; width: 30%;">
    <mat-label>Filtro scadenza</mat-label>
    <mat-select [(value)]="this.selectedExpired" (selectionChange)="expiredChange()">
      <mat-option value="Tutti">Tutti</mat-option>
      <mat-option value="Scaduti">Scaduti</mat-option>
      <mat-option value="Non scaduti">Non scaduti</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field style="padding: 10px; width: 30%;">
    <mat-label>Filtro priorità</mat-label>
    <mat-select [(value)]="this.selectedPriority" (selectionChange)="priorityChange()">
      <mat-option value="Tutti">Tutti</mat-option>
      <mat-option value="2">Alta</mat-option>
      <mat-option value="1">Media</mat-option>
      <mat-option value="0">Bassa</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-icon-button title="Aggiorna" (click)="this.refreshTasks()" style="float: right; color: #fff">
    <mat-icon>autorenew</mat-icon>
  </button>
</mat-toolbar>
<div class="center">
  <mat-card class="cardcomponentMasks" id="style-4">


    <mat-card-title style="color:{{this.apiService.dashColor}};float:left; text-transform:uppercase; font-size:16px;width: 10%;"><p style="float: left; margin-left: 10%;">Task
      # {{this.nTask}} </p>
    </mat-card-title>


    <hr class="riga" style="color:{{this.apiService.dashColor}}; width: 90%; float: right;">
    <br>
    <cdk-virtual-scroll-viewport *ngIf="nTask == 0" style="margin-left: 3%; margin-right: 3%; padding-bottom: 70%;"
                                 id="style-4"
                                 itemSize="6"
                                 class="cdk">
      <h1 style="text-align: center; color: {{this.dashColor}}">NESSUN TASK TROVATO</h1>
    </cdk-virtual-scroll-viewport>
    <mat-card-content *ngIf="nTask != 0 && this.typeOfTaskView == 'panel'">
      <div style="width: 100%;">
        <ng-container class="cdkcontains">
          <div *ngFor="let task of this.taskList">

            <mat-card #tasks [multiple]="false" *ngIf="task.disabledForFilter == false && task.disabledForPriority == false &&
                                                task.disabledForExpired == false && task.disabledForRead == false"
                      style="text-align: left; margin: 1%;">
              <ng-container>
                <mat-icon mat-list-icon *ngIf="task.defaultFields.get('LETTO') == 'S'"
                          style="color: {{this.getColor(task.defaultFields.get('DM_PROCESSI_COLORE'))}};  margin-right:2%;">
                  drafts
                </mat-icon>
                <mat-icon *ngIf="task.defaultFields.get('LETTO') == 'N'"
                          style="color: {{this.getColor(task.defaultFields.get('DM_PROCESSI_COLORE'))}};  margin-right:2%;">
                  markunread
                </mat-icon>
              </ng-container>
              <mat-card-title><b>{{task.defaultFields.get('NOMETASK')}}</b></mat-card-title>
              <mat-card-subtitle>{{'labels.author' | translate}}
                : {{task.defaultFields.get('DM_PROFILE_AUTOREDESCRIPTION')}}</mat-card-subtitle>
              <mat-card-subtitle>
                <ng-container *ngFor="let additional of task.additionalFields">
                  <ng-container *ngIf="additional.description">
                    <em>{{additional.label}}:</em> {{additional.description}}<br>
                  </ng-container>
                </ng-container>
              </mat-card-subtitle>
              <mat-card-subtitle style="color: #BC3F39;"
                                 *ngIf="this.convertStringToDate(task.defaultFields.get('DATA_SCADENZA')) < this.todayDate">{{'labels.expired' | translate}}
              </mat-card-subtitle>
              <mat-card-actions>
                <button mat-raised-button style="background: {{this.apiService.dashColor}}; color:#fff;"
                        (click)="clickTask(task.defaultFields.get('ID'))">Vai al task
                </button>
              </mat-card-actions>
            </mat-card>
          </div>
        </ng-container>
      </div>
    </mat-card-content>
    <mat-card-content *ngIf="nTask != 0 && this.typeOfTaskView == 'plain'">
      <div style="width: 100%;">
        <ng-container class="cdkcontains">
          <div style="text-align: left">
            <mat-selection-list #tasks [multiple]="false">
              <div *ngFor="let task of this.taskList">
                <ng-container
                  *ngIf="task.disabledForFilter == false && task.disabledForPriority == false &&
                                                task.disabledForExpired == false && task.disabledForRead == false">
                  <ng-container style="width: 20%">
                    <ng-container
                    >
                      <button mat-button style="float:right; color:{{this.dashColor}};"
                              (click)="unreadTask(task.defaultFields.get('ID'))">
                        <mat-icon>markunread</mat-icon>
                      </button>
                    </ng-container>
                    <button mat-button [matMenuTriggerFor]="menu"
                            style="float:right; color:{{this.dashColor}};"
                    >
                      <mat-icon>priority_high</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" xPosition="after">
                      <button mat-menu-item
                              (click)="setPriority(task.defaultFields.get('ID'),0)">
                        <span>Priorità bassa</span>
                      </button>
                      <button mat-menu-item
                              (click)="setPriority(task.defaultFields.get('ID'),1)">
                        <span>Priorità media</span>
                      </button>
                      <button mat-menu-item
                              (click)="setPriority(task.defaultFields.get('ID'),2)">
                        <span>Priorità alta</span>
                      </button>
                    </mat-menu>
                  </ng-container>
                  <mat-list-item (click)="clickTask(task.defaultFields.get('ID'))"
                                 style="width: 80%;">
                    <mat-icon *ngIf="task.defaultFields.get('LETTO') == 'S'"
                              style="color: {{this.getColor(task.defaultFields.get('DM_PROCESSI_COLORE'))}}">
                      drafts
                    </mat-icon>
                    <mat-icon *ngIf="task.defaultFields.get('LETTO') == 'N'"
                              style="color: {{this.getColor(task.defaultFields.get('DM_PROCESSI_COLORE'))}}">
                      markunread
                    </mat-icon>
                    <div style="margin-left: 2%; width: 30%;">
                      <b>{{task.defaultFields.get('NOMETASK')}}  </b></div>
                    <div style="margin-left: 2%; width: 30%;">
                      <b>Autore: {{task.defaultFields.get('DM_PROFILE_AUTOREDESCRIPTION')}}</b>
                    </div>
                    <div style="margin-left: 2%; width: 30%;">
                      <i> Stato: <b>{{task.defaultFields.get('DM_PROFILE_STATO')}}</b>
                        <br>Priorità:
                        <ng-container *ngIf="task.defaultFields.get('ASSOCIA')==0">
                          <b>Bassa</b>
                        </ng-container>
                        <ng-container *ngIf="task.defaultFields.get('ASSOCIA')==1">
                          <b>Media</b>
                        </ng-container>
                        <ng-container *ngIf="task.defaultFields.get('ASSOCIA')==2">
                          <b>Alta</b>
                        </ng-container>
                      </i>
                    </div>
                    <span style="width: 10%; color: red">
                                                     <ng-container
                                                       *ngIf="this.convertStringToDate(task.defaultFields.get('DATA_SCADENZA')) < this.todayDate">Scaduto</ng-container></span>
                  </mat-list-item>
                </ng-container>
              </div>
            </mat-selection-list>
          </div>
        </ng-container>
      </div>
    </mat-card-content>
  </mat-card>
</div>

