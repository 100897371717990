
<div style="text-align: center; color: black">
    <h2>{{'labels.enterData' | translate}}</h2>
    <hr><br>
    <mat-form-field appearance="fill">
        <mat-label>E-mail</mat-label>
        <input matInput required  [(ngModel)]="this.email" type = "text">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{'labels.name' | translate}}</mat-label>
        <input matInput required  [(ngModel)]="this.name" type = "text">
    </mat-form-field>
    <br>
    <button mat-stroked-button (click)="checkSigner()">{{'labels.send' | translate}}</button>
    <button title="{{'labels.close' | translate}}" (click)="closeDialog()" mat-stroked-button color="warn">
      {{'labels.cancel' | translate}}
</button>
    <br>
    <p style="color: red;">{{this.error}}</p>
</div>
