import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TasksJSON} from './tasksJSON';
import {SearchJSON} from './searchJSON';
import {AddressBookField} from './addressBookField';
import {ProfileValuelsBody} from './profileValuelsBody';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // -------ARXIVAR VARIABLES

  BASE_URL = 'http://edilflow.betsoft-srl.it:94/ARXivarNextWebApi/api/';
  CLIENT_ID = 'frontelndly';
  CLIENT_SECRET = '55C6F36B0A6D4223';
  // BASE_URL = 'http://cloud.websolinfo.it/ARXivarNextWebapi/api/'; http://next.betsoft-srl.it//ARXivarNextWebApi/api/     betsoftApi    7F73868161174D619C3E70DCE6A1FF53
  // CLIENT_ID = 'InterfaceBetSoft';
  //  CLIENT_SECRET = 'CF47CE5D14124930';
  // BASE_URL = 'http://10.69.86.10/webApi/api/';
  // BASE_URL = 'http://grc.gshield.cloud/ARXivarNextWebApi/api/';
  // CLIENT_ID = 'Get_Solution_Frontend';
  // CLIENT_SECRET = 'BD7E031A192A4BFB';
  // ------ ETAPLAST -------
  // BASE_URL = 'https://etaplasttest.sigemi.it/ARXivarNextWebApi/api/';
  // CLIENT_ID = 'netsuite';
  // CLIENT_SECRET = '65DAF696D3CD494E';
  // -----------------------

  ACCESS_TOKEN: string;
  REFRESH_TOKEN: string;
  EXPIRES_DATE: Date;
  homeReload = true;
  private loggedInStatus = JSON.parse(localStorage.getItem('loggedIn') || 'false');
  today: number;
  fixedTimezone: number;
  profileValuesApiBody: JSON;
  dashColor: string;
  backColor: string;
  viewDataToShow: any = {};
  squareColor: string;
  cardColor: string;
  taskId: string;
  taskNumber: Array<any> = new Array<any>();
  classDocMode: string;
  classDocAoo: string;
  viewBody: any;
  parentFolderId;
  namePath = 'Default';
  namePathV2: Array<any> = new Array<any>();
  previousSelectedPath: any;
  userSettings: any;
  tempParamsForRedirect: any;
  currentURL = '';
  isMenuClick = true;
  allStates: Array<any> = new Array<any>();
  profileCommands: any;
  commandFolders: Array<any> = new Array<any>();
  sidenavOpened = true;
  userInfo: any;
  // ------- DOCUSIGN VARIABLES
  IsDocuSignLogged = false;
  DocuSignUserInfo: any;
  DocuSignStatus: string;
  OAUTHCODE: string;
  DocuSignAccountId: string;


  // -------- GOOGLE VARIABLES
  AUTHCODE: string;
  googleApiId = '364286843142-mar8vgbs4ftenpr73ejn4o3t0kf4br25.apps.googleusercontent.com';
  googleApiSecret = 'ZwohZIg62fDZn4OsrLGcYRfh';
  googleURI = 'http://localhost:4200/';


  // maps var
  mapsMenu = false;
  mapRange = 5;
  reloadRange = false;
  typeSearch = 'Categoria';
  categories = [
    {Id: 'accounting', Name: 'Contabilità'},
    {Id: 'airport', Name: 'Aereoporto'},
    {Id: 'amusement_park', Name: 'Parco divertimenti'},
    {Id: 'aquarium', Name: 'Acquario'},
    {Id: 'art_gallery', Name: 'Galleria d\'arte'},
    {Id: 'atm', Name: 'ATM'},
    {Id: 'bakery', Name: 'Pasticceria'},
    {Id: 'bank', Name: 'Banca'},
    {Id: 'bar', Name: 'Bar'},
    {Id: 'beauty_salon', Name: 'Salone di bellezza'},
    {Id: 'bicycle_store', Name: 'Negozio di biciclette'},
    {Id: 'book_store', Name: 'Negozio di libri'},
    {Id: 'bowling_alley', Name: 'Sala da bowling'},
    {Id: 'bus_station', Name: 'Stazione bus'},
    {Id: 'cafe', Name: 'Caffetteria'},
    {Id: 'campground', Name: 'Area campeggio'},
    {Id: 'car_dealer', Name: 'Rivenditore auto'},
    {Id: 'car_rental', Name: 'Noleggio auto'},
    {Id: 'car_repair', Name: 'Meccanico auto'},
    {Id: 'car_wash', Name: 'Lavaggio auto'},
    {Id: 'casino', Name: 'Casinò'},
    {Id: 'cemetery', Name: 'Cimitero'},
    {Id: 'church', Name: 'Chiesa'},
    {Id: 'city_hall', Name: 'Municipio'},
    {Id: 'clothing_store', Name: 'Negozio di vestiti'},
    {Id: 'convenience_store', Name: 'Minimarket'},
    {Id: 'courthouse', Name: 'Tribunale'},
    {Id: 'dentist', Name: 'Dentista'},
    {Id: 'department_store', Name: 'Grande magazzino'},
    {Id: 'doctor', Name: 'Dottore'},
    {Id: 'drugstore', Name: 'Farmacia'},
    {Id: 'electrician', Name: 'Elettricista'},
    {Id: 'electronics_store', Name: 'Negozio di elettronica'},
    {Id: 'embassy', Name: 'Ambasciata'},
    {Id: 'fire_station', Name: 'Caserma vigili del fuoco'},
    {Id: 'florist', Name: 'Fiorista'},
    {Id: 'funeral_home', Name: 'Onoranze funebri'},
    {Id: 'furniture_store', Name: 'Negozio arredamento'},
    {Id: 'gas_station', Name: 'Stazione rifornimento'},
    {Id: 'gym', Name: 'Palestra'},
    {Id: 'hair_care', Name: 'Parrucchiere'},
    {Id: 'hardware_store', Name: 'Negozio hardware'},
    {Id: 'hindu_temple', Name: 'Tempio Hindu'},
    {Id: 'home_goods_store', Name: 'Negozio articoli per casa'},
    {Id: 'hospital', Name: 'Ospedale'},
    {Id: 'insurance_agency', Name: 'Agenzia assicurativa'},
    {Id: 'jewelry_store', Name: 'Gioielleria'},
    {Id: 'laundry', Name: 'Lavanderia'},
    {Id: 'lawyer', Name: 'Studio legale'},
    {Id: 'library', Name: 'Libreria'},
    {Id: 'light_rail_station', Name: 'Stazione metrotranvia'},
    {Id: 'liquor_store', Name: 'Negozio di liquori'},
    {Id: 'local_government_office', Name: 'Ufficio governativo locale'},
    {Id: 'locksmith', Name: 'Fabbro'},
    {Id: 'lodging', Name: 'Alloggio'},
    {Id: 'meal_delivery', Name: 'Consegna pasti'},
    {Id: 'meal_takeaway', Name: 'Takeaway'},
    {Id: 'mosque', Name: 'Moschea'},
    {Id: 'movie_rental', Name: 'Videoteca'},
    {Id: 'movie_theater', Name: 'Cinema'},
    {Id: 'moving_company', Name: 'Azienda trasloco'},
    {Id: 'museum', Name: 'Museo'},
    {Id: 'night_club', Name: 'Locale notturno'},
    {Id: 'painter', Name: 'Pittore'},
    {Id: 'park', Name: 'Parco'},
    {Id: 'parking', Name: 'Parcheggio'},
    {Id: 'pet_store', Name: 'Negozio di animali'},
    {Id: 'pharmacy', Name: 'Farmacia'},
    {Id: 'physiotherapist', Name: 'Fisioterapista'},
    {Id: 'plumber', Name: 'Idraulico'},
    {Id: 'police', Name: 'Stazione di polizia'},
    {Id: 'post office', Name: 'Ufficio psotale'},
    {Id: 'primary_school', Name: 'Scuola primaria'},
    {Id: 'real_estate_agency', Name: 'Agenzia immobiliare'},
    {Id: 'restaurant', Name: 'Ristorante'},
    {Id: 'roofing_contractor', Name: 'Impresa edile'},
    {Id: 'rv_park', Name: 'Area camper'},
    {Id: 'school', Name: 'Scuola'},
    {Id: 'secondary_school', Name: 'Scuola secondaria'},
    {Id: 'shoe_store', Name: 'Negozio di scarpe'},
    {Id: 'shopping_mall', Name: 'Centro commerciale'},
    {Id: 'spa', Name: 'Centro benessere'},
    {Id: 'stadium', Name: 'Stadio'},
    {Id: 'storage', Name: 'Magazzino'},
    {Id: 'store', Name: 'Negozio'},
    {Id: 'subway_station', Name: 'Stazione metropolitana'},
    {Id: 'supermarket', Name: 'Supermercato'},
    {Id: 'synagogue', Name: 'Sinagoga'},
    {Id: 'taxi_stand', Name: 'Posteggio taxi'},
    {Id: 'tourist_attraction', Name: 'Attrazione turistica'},
    {Id: 'train_station', Name: 'Stazione treni'},
    {Id: 'travel_agency', Name: 'Agenzia viaggi'},
    {Id: 'university', Name: 'Università'},
    {Id: 'veterinary_care', Name: 'Veterinario'},
    {Id: 'zoo', Name: 'Zoo'}
  ];
  placeToSearch = '';
  formCategories = new UntypedFormControl();


  // demo

  isDemo = false;


  IKEY = '6690f7f3-cd62-4c47-8064-4240906a55cd';
  SKEY = 'f6e7b543-f584-45d0-8741-112c3c723f31';
  CALLBACK = 'http://localhost:4200';

  hostenv = 'account-d.docusign.com';


  // prod
  /*
          IKEY = 'a44831c1-0ce2-49c1-ab3d-18aab098204a';
          SKEY = 'ccb0996d-6f01-4b36-b776-f0e043c7fdc8';
          CALLBACK = 'http://frontendly.it';
  */


  // stati
  states = {
    fields: [
      {
        index: 1,
        icon: 'clear'
      },
      {
        index: 2,
        icon: 'receipt_long'
      },
      {
        index: 3,
        icon: 'vpn_key'
      },
      {
        index: 4,
        icon: 'note_alt'
      },
      {
        index: 5,
        icon: 'square_foot'
      },
      {
        index: 6,
        icon: 'done_outline'
      },
      {
        index: 7,
        icon: 'help'
      },
      {
        index: 8,
        icon: 'delete_outline'
      },
      {
        index: 9,
        icon: 'mode'
      },
      {
        index: 10,
        icon: 'details'
      },
      {
        index: 11,
        icon: 'power_input'
      },
      {
        index: 12,
        icon: 'album'
      },
      {
        index: 13,
        icon: 'shopping_cart'
      },
      {
        index: 14,
        icon: 'insights'
      },
      {
        index: 15,
        icon: 'cancel'
      },
      {
        index: 16,
        icon: 'warning_amber'
      },
      {
        index: 17,
        icon: 'folder_open'
      },
      {
        index: 18,
        icon: 'do_disturb_on'
      },
      {
        index: 19,
        icon: 'pause_circle'
      },
      {
        index: 20,
        icon: 'info'
      },
      {
        index: 21,
        icon: 'watch_later'
      },
      {
        index: 22,
        icon: 'auto_fix_high'
      },
      {
        index: 23,
        icon: 'traffic'
      },
      {
        index: 24,
        icon: 'drive_file_move'
      },
      {
        index: 25,
        icon: 'nearby_error'
      },
      {
        index: 26,
        icon: 'save'
      },
      {
        index: 27,
        icon: 'snippet_folder'
      },
      {
        index: 28,
        icon: 'lock'
      },
      {
        index: 29,
        icon: 'sports_score'
      },
      {
        index: 30,
        icon: 'arrow_forward'
      },
      {
        index: 31,
        icon: 'pan_tool'
      },
      {
        index: 32,
        icon: 'language'
      },
      {
        index: 33,
        icon: 'chat'
      },
      {
        index: 34,
        icon: 'fact_check'
      },
      {
        index: 35,
        icon: 'stop'
      },
      {
        index: 36,
        icon: 'school'
      },
      {
        index: 37,
        icon: 'verified'
      },
      {
        index: 38,
        icon: 'gpp_bad'
      },
      {
        index: 39,
        icon: 'gpp_maybe'
      },
      {
        index: 40,
        icon: 'panorama_fish_eye'
      },
      {
        index: 41,
        icon: 'panorama_fish_eye'
      },
      {
        index: 42,
        icon: 'settings'
      },
      {
        index: 43,
        icon: 'grade'
      },
      {
        index: 44,
        icon: 'public'
      },
      {
        index: 45,
        icon: 'summarize'
      },
      {
        index: 46,
        icon: 'coronavirus'
      },
      {
        index: 47,
        icon: 'radar'
      },
      {
        index: 48,
        icon: 'hardware'
      },
      {
        index: 49,
        icon: 'arrow_back'
      },
      {
        index: 50,
        icon: 'health_and_safety'
      },
      {
        index: 51,
        icon: 'yard'
      },
      {
        index: 52,
        icon: 'loop'
      },
      {
        index: 53,
        icon: 'try'
      },
      {
        index: 54,
        icon: 'shield'
      },
      {
        index: 55,
        icon: 'dangerous'
      },
      {
        index: 56,
        icon: 'restore'
      },
      {
        index: 57,
        icon: 'done'
      },
      {
        index: 58,
        icon: 'clear'
      },
      {
        index: 59,
        icon: 'arrow_upward'
      },
      {
        index: 60,
        icon: 'arrow_downward'
      },
      {
        index: 61,
        icon: 'alarm_on'
      },
      {
        index: 62,
        icon: 'subject'
      },
      {
        index: 63,
        icon: 'sd_card_alert'
      },
      {
        index: 64,
        icon: 'mark_email_read'
      },
      {
        index: 65,
        icon: 'unsubscribe'
      },
    ]
  };


  constructor(private translate: TranslateService, private snackBar: MatSnackBar, private http: HttpClient, private tasks: TasksJSON, private search: SearchJSON,
              private profileValuelsBody: ProfileValuelsBody,
              private addressBookField: AddressBookField, private datePipe: DatePipe) {

    this.http.get('assets/Assets.json').subscribe(data => {
    });
    if (localStorage.getItem('currentURL') === null) {
      localStorage.setItem('currentURL', this.CALLBACK);
    } else {
      this.currentURL = localStorage.getItem('currentURL');
    }
    // this.namePathV2.push({label: 'Default', route: 'dashboard/dashnav/defaulthome', params: {}, active: true});
    if (localStorage.getItem('BASE_URL') === null) {

      // Secam
      /*
            this.BASE_URL = 'http://45.94.0.169:35080//arxivarnextwebapi/api/';
            this.CLIENT_ID = 'frontendly';
            this.CLIENT_SECRET = '256E0AF41A35408BB679C47989ACEB3C';
      */
      // Demo
      /*
this.BASE_URL = 'http://45.94.0.169:35080//arxivarnextwebapi/api/';
      this.CLIENT_ID = 'frontendly';
      this.CLIENT_SECRET = '256E0AF41A35408BB679C47989ACEB3C';
       Acquamos
             this.BASE_URL = 'http://45.94.0.169:35080//arxivarnextwebapi/api/';
      this.CLIENT_ID = 'frontendly';
      this.CLIENT_SECRET = '256E0AF41A35408BB679C47989ACEB3C';
      this.BASE_URL = 'http://next.betsoft-srl.it//arxivarnextwebapi/api/';
      this.CLIENT_ID = 'betsoftApi';
      this.CLIENT_SECRET = '7F73868161174D619C3E70DCE6A1FF53';





            this.BASE_URL = 'http://smart.betsoft-srl.it:45080//arxivarnextwebapi/api/';
            this.CLIENT_ID = 'Acquamos';
            this.CLIENT_SECRET = 'DD3CAE76F657482B9F890B7C480464BA';
 this.BASE_URL = 'http://arxdemo.betsoft-srl.it/arxivarnextwebapi/api/';
      this.CLIENT_ID = 'frontSecam';
      this.CLIENT_SECRET = '686E0AAAD64D469E9EBC5D7FA974EBE6';
 this.BASE_URL = 'http://smart.betsoft-srl.it:45080//arxivarnextwebapi/api/';
      this.CLIENT_ID = 'Acquamos';
      this.CLIENT_SECRET = 'DD3CAE76F657482B9F890B7C480464BA';
*/
      /*
this.BASE_URL = 'http://next.betsoft-srl.it//arxivarnextwebapi/api/';
      this.CLIENT_ID = 'betsoftApi';
      this.CLIENT_SECRET = '7F73868161174D619C3E70DCE6A1FF53';
this.BASE_URL = 'http://smart.betsoft-srl.it:45080//arxivarnextwebapi/api/';
      this.CLIENT_ID = 'Acquamos';
      this.CLIENT_SECRET = 'DD3CAE76F657482B9F890B7C480464BA';
      this.BASE_URL = 'https://etaplasttest.sigemi.it/ARXivarNextWebApi/api/';
      this.CLIENT_ID = 'netsuite';

      this.CLIENT_SECRET = '65DAF696D3CD494E';

      this.BASE_URL = 'http://smart.betsoft-srl.it:45080//arxivarnextwebapi/api/';
      this.CLIENT_ID = 'maia';
      this.CLIENT_SECRET = 'E0191FB49E5240CBA5B57772C113FBDF';
       this.BASE_URL = 'http://smart.betsoft-srl.it:45080//arxivarnextwebapi/api/';
      this.CLIENT_ID = 'maia';
      this.CLIENT_SECRET = 'E0191FB49E5240CBA5B57772C113FBDF';
          this.BASE_URL = 'http://45.94.0.169:35080//arxivarnextwebapi/api/';
      this.CLIENT_ID = 'frontendly';
      this.CLIENT_SECRET = '256E0AF41A35408BB679C47989ACEB3C';




       this.BASE_URL = 'http://arxdemo.betsoft-srl.it/arxivarnextwebapi/api/';
      this.CLIENT_ID = 'frontendly';
      this.CLIENT_SECRET = '40B0222A26204521BEB5CF4949AE8A90';
      this.isDemo = true;
       this.BASE_URL = 'http://arxdemo.betsoft-srl.it/arxivarnextwebapi/api/';
      this.CLIENT_ID = 'versace';
      this.CLIENT_SECRET = '7643F412B5BD454ABB31F8567E87BF64';
   this.BASE_URL = 'http://smart.betsoft-srl.it:45080//arxivarnextwebapi/api/';
      this.CLIENT_ID = 'Acquamos';
      this.CLIENT_SECRET = 'DD3CAE76F657482B9F890B7C480464BA';
          this.BASE_URL = 'http://172.16.1.82:8080//arxivarnextwebapi/api/';
      this.CLIENT_ID = 'api';
      this.CLIENT_SECRET = '900BB999E970444BA463AEB69D9391E6';


this.BASE_URL = 'http://arxdemo.betsoft-srl.it/arxivarnextwebapi/api/';
      this.CLIENT_ID = 'frontendly';
      this.CLIENT_SECRET = '40B0222A26204521BEB5CF4949AE8A90';




      \\M23 INTERNA
        this.BASE_URL = 'http://10.0.3.246:8080/ArxivarNextWebApi/api/';
        this.CLIENT_ID = 'apiFrontendly';
        this.CLIENT_SECRET = '7186B7B379944E4EA542BC5A8D740044';

      \\M23 PUBBLICA
        this.BASE_URL = 'http://10.0.1.243/ArxivarNextWebApi/api/';
        this.CLIENT_ID = 'apiFrontendly';
        this.CLIENT_SECRET = '4DBDFD6FCB594DC18BE1D65B0752C36C';

this.BASE_URL = 'http://arxdemo.betsoft-srl.it/arxivarnextwebapi/api/';
      this.CLIENT_ID = 'frontendly';
      this.CLIENT_SECRET = '40B0222A26204521BEB5CF4949AE8A90';

          this.BASE_URL = 'https://next.betsoft-srl.it//arxivarnextwebapi/api/';
      this.CLIENT_ID = 'betsoftApi';
      this.CLIENT_SECRET = '7F73868161174D619C3E70DCE6A1FF53';


        this.BASE_URL = 'https://next.betsoft-srl.it//arxivarnextwebapi/api/';
      this.CLIENT_ID = 'betsoftApi';
      this.CLIENT_SECRET = '7F73868161174D619C3E70DCE6A1FF53';
      this.BASE_URL = 'https://next.betsoft-srl.it//arxivarnextwebapi/api/';
      this.CLIENT_ID = 'betsoftApi';
      this.CLIENT_SECRET = '7F73868161174D619C3E70DCE6A1FF53';
*/
      this.BASE_URL = 'http://2.40.106.218/ARXivarNextWebApi/api/';
      this.CLIENT_ID = 'Frontendly';
      this.CLIENT_SECRET = 'C1B37574B8604EFCA962B9C232309B70';
      // BETTIG4

      // this.BASE_URL = 'http://93.42.176.19//ARXivarNextWebApi/api/';
      // this.CLIENT_ID = 'frontendly';
      // this.CLIENT_SECRET = 'CE2EBC77E61A413084ABB3C5106891C8';


      // EDILFLOW

      // this.BASE_URL = 'http://edilflow.betsoft-srl.it:94/ARXivarNextWebApi/api/';
      // this.CLIENT_ID = 'betsoft';
      // this.CLIENT_SECRET = '6D550737529F46C586AB62B006C6DF20';


      // DEMO
      /*
          this.BASE_URL = 'http://192.168.52.34//ARXivarNextWebApi/api/';
          this.CLIENT_ID = 'frontendly';
          this.CLIENT_SECRET = '40B0222A26204521BEB5CF4949AE8A90';
      */
      localStorage.setItem('BASE_URL', this.BASE_URL);
      localStorage.setItem('CLIENT_ID', this.CLIENT_ID);
      localStorage.setItem('CLIENT_SECRET', this.CLIENT_SECRET);
    } else {
      this.BASE_URL = localStorage.getItem('BASE_URL');
      this.CLIENT_ID = localStorage.getItem('CLIENT_ID');
      this.CLIENT_SECRET = localStorage.getItem('CLIENT_SECRET');
    }


  }

  mapsMenuClick(): void {
    this.mapsMenu = !this.mapsMenu;
  }

  addPath(labelp: string, routep: string, paramsp: any): void {
    this.previousSelectedPath = this.namePathV2[this.namePathV2.length - 1];
    let found = false;
    this.namePathV2.forEach(v => {
      if (v.label === labelp) {
        found = true;
        v.active = true;
      } else {
        v.active = false;
      }
    });

    if (found === false) {
      if (this.namePathV2.length === 3) {
        this.namePathV2.shift();
      }
      this.namePathV2.push({label: labelp, route: routep, params: paramsp, active: true});

    }
  }

// ********************************************************************************
// *** AUTHENTICATION ***
  login(body): Observable<any> {
    return this.http.post(this.BASE_URL + 'Authentication', body);
  }

  setLoggedInInformation(data): void {
    this.loggedInStatus = true;
    localStorage.setItem('loggedIn', 'true');
    localStorage.setItem('accessToken', data.accessToken);
    localStorage.setItem('refreshToken', data.refreshToken);
    localStorage.setItem('expiresDate', data.expires);
  }

  setLoggedOut(): void {
    this.loggedInStatus = false;
    localStorage.setItem('loggedIn', 'false');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expiresDate');
    localStorage.removeItem('username');
  }

  isLoggedIn(): boolean {
    return this.loggedInStatus;
  }

  getTokenArxAssistant(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'authentication/getTokenArxAssistant/', httpOptions);
  }

// ********************************************************************************
// *** API ClientSettings ***
  // @ts-ignore

  getUserSettings(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'Settings/user', httpOptions);
  }


  // ********************************************************************************
  // *** REPORT ***
  getReports(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.get(this.BASE_URL + 'Report', httpOptions);
  }

  getReport(id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.get(this.BASE_URL + 'Report/' + id, httpOptions);
  }

  reportExecuteAsync(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.post(this.BASE_URL + 'Report/Execute/Async', body, httpOptions);
  }

  queueJobInfoList(body: any, id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.post(this.BASE_URL + 'Queue/GetQueueJobInfoList?queueId=' + id, body, httpOptions);
  }

  queueJobInfo(id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.get(this.BASE_URL + 'Queue/JobInfo/' + id, httpOptions);
  }

  getBuffer(id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'Buffer/' + id, httpOptions);
  }

  getBufferFileText(id: string): Observable<any> {
    const httpOptions = {
      responseType: 'text' as const,
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      }),
    };
    return this.http.get(this.BASE_URL + 'Buffer/file/' + id, httpOptions);
  }

  getBufferFileBlob(id: string): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as const,
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      }),
    };
    return this.http.get(this.BASE_URL + 'Buffer/file/' + id, httpOptions);
  }

  getFilePreview(docN, revision, index): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as const,
      headers: new HttpHeaders({
        'Content-Type': 'image/jpeg',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.get(this.BASE_URL + 'previews/14/' + docN + '/page/' + index + '/' + revision, httpOptions);
  }

  getTaskViewDetails(taskWorkId: string, operationId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'v3/views/task/' + taskWorkId + '/operation/' + operationId, httpOptions);
  }

  getDynamicJobOperations(ids: any, exitValue: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    const body = {
      taskIds: ids,
      value: exitValue
    };
    return this.http.post(this.BASE_URL + 'taskoperations/getdynamicjoboperations/exitcode/', body, httpOptions);
  }

  saveDynamicJobMultiple(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.put(this.BASE_URL + 'taskoperations/dynamicjobmultiple/', body, httpOptions);
  }

  getDynamicJobUsers(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'taskoperations/dynamicjobusers/', httpOptions);
  }

  getFilePreviewForTask(docN, revision, index): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as const,
      headers: new HttpHeaders({
        'Content-Type': 'image/jpeg',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.get(this.BASE_URL + 'previews/212/' + docN + '/page/' + index + '/' + revision, httpOptions);
  }

  getFileTotalPagesPreview(docN, revision): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'previews/14/' + docN + '/totalpages/' + revision, httpOptions);
  }

  getFileTotalPagesPreviewForTask(docN, revision): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'previews/212/' + docN + '/totalpages/' + revision, httpOptions);
  }

// ********************************************************************************
// *** PASSWORDMANAGER ***
  getPasswordConstrains(): Observable<any> {
    const httpOptions = {

      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'PasswordManager/ChangePasswordConstraints', httpOptions);
  }

  changePassword(oldPSW: string, newPSW: string): Observable<any> {
    const bod: JSON = {
      oldPassword: oldPSW,
      newPassword: newPSW
    } as unknown as JSON;


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'PasswordManager/ChangePassword', bod, httpOptions);
  }


// ********************************************************************************
// *** API MASKS ***
  getMasks(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'desktop', httpOptions);
  }

  getAllMasks(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'Masks', httpOptions);
  }


  getProfileSchema(maskid: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'Masks/' + maskid + '/profileSchema', httpOptions);
  }

  getProfileSchemaAdditionalField(additionalFieldName: string, body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'Masks/byclassadditionalfield/' + additionalFieldName + '/profileSchema', body, httpOptions);
  }

  getClassAdditionalFieldsV2(fieldName: string, documentType: string, arrayDocN: Array<string>): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'v2/ClassAdditionalFields/fieldclasscomposevalues/' + fieldName + '/' + documentType, arrayDocN, httpOptions);
  }

  getProfileValuesByName(fieldName: string, classType: string, className: string, classValue: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'Profiles/Values',
      this.profileValuelsBody.generateBody(fieldName, classType, className, classValue), httpOptions);
  }

  getProfileValuesWithFilter(fieldName: string, fields: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'Profiles/Values',
      this.profileValuelsBody.generateBodyV2(fieldName, fields), httpOptions);
  }

  deleteProfile(docN: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.delete(this.BASE_URL + 'Profiles/' + docN, httpOptions);
  }

  getProfileValuesByAoo(fieldName: string, classType: string, className: string,
                        classValue: string, AooName: string, AooClass: string, AooValue: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'Profiles/Values',
      this.profileValuelsBody.generateBodyAoo(fieldName, classType, className, classValue, AooName, AooClass, AooValue), httpOptions);
  }

  deleteNoteProfile(docId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.delete(this.BASE_URL + 'notes/' + docId, httpOptions);
  }

  getNotes(docId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'notes/' + docId, httpOptions);
  }

  addNote(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'notes', body, httpOptions);
  }

  TransformDataFormatToShow(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy h:mm:ss', '', 'en-GB');
  }

  getProfileRevisions(docN: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'revisions/bydocnumber/' + docN, httpOptions);
  }

  revisionProfile(docN: string, revision: string, option: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'Revisions/' + docN + '/' + revision + '/' + option, {}, httpOptions);
  }

  downloadTaskAttachment(id: string): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as const,
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      }),
    };
    return this.http.get(this.BASE_URL + 'documents/taskattachments/' + id + '?forView=true', httpOptions);
  }


  downloadAttachment(attachId: string, forView: boolean): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as const,
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      }),
    };
    return this.http.get(this.BASE_URL + 'Documents/profileAttachment/' + attachId + '?forView=' + forView, httpOptions);
  }

  addInternalAttachmentTask(docN: string, taskN: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'taskattachments/bytaskwork/' + taskN + '/internal/' + docN, {}, httpOptions);
  }

  getTaskWorkDocumentsBuffer(procDocId: string, taskWorkId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'TaskWorkDocuments/documentPreviewBufferId?processdocId='
      + procDocId + '&taskworkId=' + taskWorkId, httpOptions);
  }

  downloadDocRevision(revId: string, forView: boolean): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as const,
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      }),
    };
    return this.http.get(this.BASE_URL + 'documents/byRevision/' + revId + '?forView=' + forView, httpOptions);
  }

  deleteRevision(revId: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.delete(this.BASE_URL + 'Revisions/' + revId, httpOptions);
  }

  postRelations(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'Relations', body, httpOptions);
  }

  checkInOut(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    const body = {
      fields: [{
        name: 'DM_DOCOPEN_UTENTE_DESCRIPTION',
        selected: true,
        index: 0,
        fieldType: 0
      }, {
        sqlSelectField: 'DM_PROFILE.DOCNUMBER',
        toCalculate: false,
        index: 1,
        selected: true,
        fieldType: 0,
        orderBy: {direction: 0, index: 0},
        externalId: null,
        label: 'System ID',
        name: 'DOCNUMBER',
        userSelectionEnabled: true,
        userSelectionGroup: 1
      }, {
        sqlSelectField: 'DM_PROFILE.NOTE',
        toCalculate: false,
        index: 2,
        selected: true,
        fieldType: 0,
        orderBy: {direction: 0, index: 0},
        externalId: null,
        label: 'Note',
        name: 'NOTE',
        userSelectionEnabled: true,
        userSelectionGroup: 0
      }, {
        sqlSelectField: 'DM_PROFILE.ALLEGATI',
        toCalculate: false,
        index: 3,
        selected: true,
        fieldType: 0,
        orderBy: {direction: 0, index: 0},
        externalId: null,
        label: 'Allegati',
        name: 'ALLEGATI',
        userSelectionEnabled: true,
        userSelectionGroup: 0
      }, {
        sqlSelectField: 'DM_PROFILE.STATO AS STATO',
        toCalculate: false,
        index: 4,
        selected: true,
        fieldType: 0,
        orderBy: {direction: 0, index: 0},
        externalId: null,
        label: 'Stato',
        name: 'STATO',
        userSelectionEnabled: true,
        userSelectionGroup: 0
      }, {
        sqlSelectField: '{INOUT}',
        toCalculate: false,
        index: 5,
        selected: true,
        fieldType: 0,
        orderBy: {direction: 0, index: 0},
        externalId: null,
        label: 'Origine',
        name: 'INOUT',
        userSelectionEnabled: true,
        userSelectionGroup: 0
      }, {
        sqlSelectField: 'DM_PROFILE.WORKFLOW',
        toCalculate: false,
        index: 6,
        selected: true,
        fieldType: 0,
        orderBy: {direction: 0, index: 0},
        externalId: null,
        label: 'Workflow',
        name: 'WORKFLOW',
        userSelectionEnabled: true,
        userSelectionGroup: 0
      }, {
        sqlSelectField: '{Relazioni}',
        toCalculate: false,
        index: 7,
        selected: true,
        fieldType: 0,
        orderBy: {direction: 0, index: 0},
        externalId: null,
        label: 'Relazioni',
        name: 'RELAZIONI',
        userSelectionEnabled: true,
        userSelectionGroup: 0
      }, {
        sqlSelectField: '{Formato}',
        toCalculate: false,
        index: 8,
        selected: true,
        fieldType: 0,
        orderBy: {direction: 0, index: 0},
        externalId: null,
        label: 'Formato',
        name: 'FORMATO',
        userSelectionEnabled: true,
        userSelectionGroup: 0
      }, {
        sqlSelectField: 'DM_PROFILE.REVISIONE',
        toCalculate: false,
        index: 9,
        selected: true,
        fieldType: 0,
        orderBy: {direction: 0, index: 0},
        externalId: null,
        label: 'Revisione',
        name: 'REVISIONE',
        userSelectionEnabled: true,
        userSelectionGroup: 1
      }, {
        sqlSelectField: 'DM_PROFILE.DOCNAME',
        toCalculate: false,
        index: 10,
        selected: true,
        fieldType: 0,
        orderBy: {direction: 0, index: 0},
        externalId: null,
        label: 'Oggetto',
        name: 'DOCNAME',
        userSelectionEnabled: true,
        userSelectionGroup: 1
      }, {
        sqlSelectField: 'DM_PROFILE.DATADOC',
        toCalculate: false,
        index: 11,
        selected: true,
        fieldType: 0,
        orderBy: {direction: 0, index: 0},
        externalId: null,
        label: 'Data documento',
        name: 'DATADOC',
        userSelectionEnabled: true,
        userSelectionGroup: 1
      }, {
        sqlSelectField: 'DM_PROFILE.MITTENTE',
        toCalculate: false,
        index: 12,
        selected: true,
        fieldType: 0,
        orderBy: {direction: 0, index: 0},
        externalId: null,
        label: 'Da',
        name: 'MITTENTE',
        userSelectionEnabled: true,
        userSelectionGroup: 1
      }, {
        sqlSelectField: 'DM_PROFILE.DESTINATARIO',
        toCalculate: false,
        index: 13,
        selected: true,
        fieldType: 0,
        orderBy: {direction: 0, index: 0},
        externalId: null,
        label: 'A',
        name: 'DESTINATARIO',
        userSelectionEnabled: true,
        userSelectionGroup: 1
      }, {
        sqlSelectField: 'DM_PROFILE.NUMERO',
        toCalculate: false,
        index: 14,
        selected: true,
        fieldType: 0,
        orderBy: {direction: 0, index: 0},
        externalId: null,
        label: 'Numero',
        name: 'NUMERO',
        userSelectionEnabled: true,
        userSelectionGroup: 1
      }, {
        sqlSelectField: '{CLASSEDOCDESC}',
        toCalculate: false,
        index: 15,
        selected: true,
        fieldType: 0,
        orderBy: {direction: 0, index: 0},
        externalId: null,
        label: 'Classe Doc. descrizione',
        name: 'CLASSEDOCDESC',
        userSelectionEnabled: true,
        userSelectionGroup: 1
      }, {name: 'WFVERSIONSTATE', selected: true, visible: false, index: 16, fieldType: 0}], maxItems: 0
    };
    return this.http.post(this.BASE_URL + 'v2/CheckInOut/', body, httpOptions);
  }

  checkInOutProfile(docId: string, fileId: string, option: number, undoCheckOut): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'v2/CheckInOut/checkIn/' + docId + '/' +
      fileId + '/' + option + '/' + undoCheckOut, {}, httpOptions);
  }

  openBuffer(file: FormData): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'Buffer/insert', file, httpOptions);
  }

  deleteBuffer(file: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.delete(this.BASE_URL + 'Buffer/' + file.buffer, httpOptions);
  }

  insertExternal(docId: string, buffer: string, comment: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'Attachments/InsertExternal/' + buffer + '/' + docId, '"' + comment + '"', httpOptions);
  }

  insertTaskExternal(taskId: string, buffer: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'taskattachments/bytaskwork/' + taskId + '/external/' + buffer, {}, httpOptions);
  }

  deleteAttachment(id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.delete(this.BASE_URL + 'Attachments/' + id, httpOptions);
  }

  deleteTaskAttachment(id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.delete(this.BASE_URL + 'taskattachments/' + id, httpOptions);
  }

  insertNewProfile(jsonToSend: any, maskId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'Masks/' + maskId + '/Profile', jsonToSend, httpOptions);
  }

  updateProfile(jsonToSend: any, docId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.put(this.BASE_URL + 'Profiles/' + docId, jsonToSend, httpOptions);
  }

  getProfileDetailsClass(docNumber: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'Profiles/detail/' + docNumber, httpOptions);
  }

  getDocTypeMode(mode: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'DocumentTypes/' + mode + '/mode', httpOptions);
  }

  insertDocumentInTaskByView(taskWordId: string, operationId: string, docNumbers: Array<string>): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.put(this.BASE_URL + 'TaskWork/' + taskWordId + '/documentsoperations/' + operationId + '/byselection', docNumbers, httpOptions);
  }

  getAllDocTypes(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'DocumentTypes', httpOptions);
  }

  getDocTypeModeWithAoo(mode: string, Aoo: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'DocumentTypes/' + mode + '/mode?businessUnitCode=' + Aoo, httpOptions);
  }

  additionalProfiles(tipo1: string, tipo2: string, tipo3: string, aoo: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'Profiles/Additional/' + tipo1 + '/' + tipo2 + '/' + tipo3 + '?aoo=' + aoo, httpOptions);
  }

// ********************************************************************************
// *** API VIEWS ***
  getViews(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'views', httpOptions);
  }

  getViewById(viewid: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'Views/' + viewid, httpOptions);
  }

  getViewFilter(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'v3/Views', body, httpOptions);
  }

  getViewFilterForTask(body: any, taskWorkId: string, operationId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'v3/views/task/' + taskWorkId + '/operation/' + operationId, body, httpOptions);
  }

  getComboValues(name: string): Observable<any> {
    const body: JSON = {
      searchFilterDto: {
        id: 0,
        document: {
          fileNames: [
            'string'
          ],
          bufferIds: [
            'string'
          ]
        },
        fields: [],
        postProfilationActions: [
          {
            shortDescription: 'string',
            description: 'string',
            action: 0,
            visible: true,
            value: true
          }
        ],
        constrainRoleBehaviour: 0,
        attachments: [
          'string'
        ],
        notes: [
          {
            id: 0,
            docnumber: 0,
            user: 0,
            userCompleteName: 'string',
            creationDate: '2020-09-24T07:39:21.613Z',
            comment: 'string',
            revision: 0,
            aosflag: true,
            countersTable: 0,
            externalId: 0
          }
        ],
        paNotes: [
          'string'
        ],
        authorityData: {
          id: 0,
          docNumber: 0,
          protocol: 'string',
          protocolDate: '2020-09-24T07:39:21.613Z',
          office: 'string',
          person: 'string',
          shipping: 'string',
          yourReferent: 'string'
        },
        generatePaProtocol: true
      },
      fieldName: name,
      filterValue: 'string',
      filterId: 'string'
    } as unknown as JSON;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'Profiles/Values', body, httpOptions);
  }

// ********************************************************************************
// *** API FIELDS ***
  getProfiles(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'Profiles', httpOptions);
  }

  getAoo(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'BusinessUnits', httpOptions);
  }

  getAddressBookField(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'AddressBook', this.addressBookField.addressBookFildBody, httpOptions);
  }

  SearchAllAddressBook(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'v3/addressbooksearches', this.addressBookField.addressBookAllFieldsBody, httpOptions);
  }

  SearchAddressBookFilter(filter: string, enabledAddressBook: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    this.addressBookField.addressBookForSearchFilter.filter = filter;
    if (enabledAddressBook) {
      this.addressBookField.addressBookForSearchFilter.addressBookCategoryId = [enabledAddressBook];
    } else {
      this.addressBookField.addressBookForSearchFilter.addressBookCategoryId = [-3];
    }
    return this.http.post(this.BASE_URL + 'v3/addressbook', this.addressBookField.addressBookForSearchFilter, httpOptions);
  }

  newContact(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'AddressBook/newinstance', httpOptions);
  }

  deleteContact(contactId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.delete(this.BASE_URL + 'AddressBook/contact/' + contactId, httpOptions);
  }

  deleteAddressBook(contactId: Array<string>): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'AddressBook/delete/addressbooks', contactId, httpOptions);
  }

  getContactAddressBook(contactId: string, type: string): Observable<any> {
    // TYPE -------> Possible values: 0: To 1: From 2: Cc 3: Senders
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'AddressBook/Contact/' + contactId + '/UserProfile/' + type, httpOptions);
  }

  addCategory(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'AddressBookCategory', body, httpOptions);
  }

  renameCategory(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.put(this.BASE_URL + 'AddressBookCategory', body, httpOptions);
  }

  deleteCategory(catId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.delete(this.BASE_URL + 'AddressBookCategory/' + catId, httpOptions);
  }

  getDocumentType(maskId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'Masks/' + maskId + '/DocumentTypes', httpOptions);
  }

  getDocumentTypeMode(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'DocumentTypes/0/mode', httpOptions);
  }

  getStates(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'States', httpOptions);
  }

  getOrigins(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'Origins', httpOptions);
  }

// ********************************************************************************
// *** API MODELS ***
  getModels(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'Models', httpOptions);
  }

  getModelGroups(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'ModelsGroups', httpOptions);
  }

  getModelProfileSchema(modelId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'Models/' + modelId + '/profileSchema', httpOptions);
  }

  getModelProfileSchemaForDocument(taskWorkId: string, taskWorkDocumentOperationId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'v2/TaskWork/' + taskWorkId + '/documentsoperations/' + taskWorkDocumentOperationId + '/modelprofileSchema', httpOptions);
  }

  insertModelProfile(jsonToSend: any, id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'Models/' + id + '/Profile', jsonToSend, httpOptions);
  }

  insertTaskByModel(taskId: string, taskWorkDocumentOperation: string, body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.post(this.BASE_URL + 'v2/TaskWork/' + taskId + '/documentsoperations/' + taskWorkDocumentOperation + '/bymodel', body, httpOptions);
  }

  insertDocument(docNumber: string, cacheId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'Documents/' + docNumber + '/' + cacheId, {}, httpOptions);
  }

// ********************************************************************************
// *** API ADDRESS BOOK ***
  getAddressBook(body): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'AddressBook', body, httpOptions);
  }

  getAddressBookDetail(id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'AddressBook/addressbook/' + id, httpOptions);
  }

  putAddressBook(body: any, id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.put(this.BASE_URL + 'AddressBook/addressbook/' + id, body, httpOptions);
  }

  postAddressBook(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'AddressBook/addressbook/', body, httpOptions);
  }


  getAddressBookCategry(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'AddressBookCategory', httpOptions);
  }

// ********************************************************************************
// *** API PARSER DATA ***
  entriesObject(object): object {
    return Object.entries(object).map(([type, value]) => ({type, value}));
  }

  mappingObject(object): any {
    const dictionary = [];
    object[0].value.value.forEach(item => {
      dictionary.push({
        value: item.columns[0],
        type: item.columns[1],
        id: item.columns[2],
        idC: item.columns[3],
        Cat: item.columns[4],
      });
    });
    return dictionary;
  }

  mappingObjectMask(object): any {
    const dictionary = [];
    object[0].value.value.forEach(item => {
      dictionary.push({
        type: item.columns[0],
        value: item.columns[1],
        id: item.columns[2],
        idC: item.columns[3],
      });
    });
    return dictionary;
  }

// ********************************************************************************
// *** API TASKS ***
  docAttachments(docN: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'Attachments/docnumber/' + docN, httpOptions);
  }

  getTaskAttachments(procId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'v2/TaskAttachments/byprocessid/' + procId, httpOptions);
  }

  postTasksIdv2(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'v2/TaskWork', body, httpOptions);
  }

  postTasksId(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'v2/TaskWork', this.tasks.TakBody, httpOptions);
  }

  getTaskDetail(id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'TaskWork/' + id, httpOptions);
  }

  exitcodes(id: string): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'TaskWork/exitcodes', [id], httpOptions);
  }

  getDocDetails(docNumber: string): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'Profiles/detail/' + docNumber, httpOptions);
  }

  postDocumnets(processId: string): Observable<any> {
    const body: JSON = {
      fields: [
        {
          sqlSelectField: 'string',
          toCalculate: true,
          index: 0,
          selected: true,
          fieldType: 1,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: 'string',
          label: 'string',
          name: 'string',
          userSelectionEnabled: true,
          userSelectionGroup: 0
        }
      ],
      maxItems: 0
    } as unknown as JSON;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'v2/TaskWork/documents/' + processId, body, httpOptions);
  }

  getDocPreview(docNumber: string): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'Previews/' + docNumber + '/profile', httpOptions);
  }

  getTaskOperations(taskId: string): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'TaskOperations/byTaskWork/' + taskId, httpOptions);
  }

  getTaskNotes(processId: string): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'TaskNotes/byProcessId/' + processId, httpOptions);
  }

  getProcessValues(body, processVariableId: string, taskWorkId: string): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'TaskOperations/' + taskWorkId + '/processvariable/' + processVariableId + '/getValues',
      body, httpOptions);
  }

  canFinalize(taskWorkId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.post(this.BASE_URL + 'v2/TaskWork/canfinalize', [taskWorkId], httpOptions);

  }

  insertNote(taskId: string, text: string): Observable<any> {
    const body: JSON = {
      date: '2020-09-23T06:31:36.567Z',
      id: 0,
      processId: 0,
      taskWorkId: 0,
      note: text,
      userCompleteName: 'string',
      user: 0,
      taskWorkName: 'string'
    } as unknown as JSON;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.post(this.BASE_URL + 'TaskNotes/taskWorkId/' + taskId, body, httpOptions);
  }

  deleteNote(noteId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.delete(this.BASE_URL + 'TaskNotes/' + noteId, httpOptions);

  }

  takeCharge(taskId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.put(this.BASE_URL + 'v2/TaskWork/' + taskId + '/TakeCharge', [], httpOptions);
  }

  finalizeTask(comment: string, exitCod: string, taskWorkId: string): Observable<any> {
    const body: JSON = {
      taskWorkIds: [
        taskWorkId
      ],
      exitCode: exitCod,
      note: comment
    } as unknown as JSON;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'v2/TaskWork/finalize', body, httpOptions);
  }

  readTask(idTask: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.put(this.BASE_URL + 'v2/TaskWork/read', [idTask], httpOptions);
  }

  setProcessVariables(taskId: string, body): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.post(this.BASE_URL + 'TaskOperations/' + taskId + '/setprocessvariables', body, httpOptions);
  }

  getMaskProfileForTask(taskId: string, operationId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.get(this.BASE_URL + 'v2/TaskWork/' + taskId + '/documentsoperations/' + operationId +
      '/maskprofileSchema', httpOptions);
  }

  getStandardProfileForTask(taskId: string, operationId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.get(this.BASE_URL + 'v2/TaskWork/' + taskId + '/documentsoperations/' + operationId +
      '/standardprofileSchema', httpOptions);
  }

  unread(taskId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.put(this.BASE_URL + 'v2/TaskWork/unread', [taskId], httpOptions);
  }

  setPriority(taskId: string, priority: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.put(this.BASE_URL + 'v2/TaskWork/priority/' + priority, [taskId], httpOptions);
  }

  getProfileDetails(docNumber: string, taskId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.get(this.BASE_URL + 'Profiles/detail/' + docNumber + '/task/' + taskId, httpOptions);
  }

  getProfileFormula(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.post(this.BASE_URL + 'Profiles/Formula', body, httpOptions);
  }

  getProfileSchemaSwitched(docN: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.get(this.BASE_URL + 'Profiles/' + docN + '/schema/false', httpOptions);
  }

  insertProfileToArchive(jsonToSend: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'Profiles', jsonToSend, httpOptions);
  }

  getProcessInfo(processId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.get(this.BASE_URL + 'ProcessInfo/' + processId + '/Task', httpOptions);
  }

  getWorkflowId(docNum: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.get(this.BASE_URL + 'Workflow/bydocnumber/' + docNum + '/history', httpOptions);
  }

  getWorkflowEvents(docNum: Array<number>): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.post(this.BASE_URL + 'workflow/events', docNum, httpOptions);
  }

  startWorkflow(docN: string, eventId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.put(this.BASE_URL + 'workflow/start/' + docN + '/' + eventId, {}, httpOptions);
  }

  startWorkflowByEvent(eventId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.put(this.BASE_URL + 'workflow/start/byevent/' + eventId, {}, httpOptions);
  }

  canStartEventByDocnumber(docNum: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'workflow/canstartbydocnumber/' + docNum, httpOptions);
  }

  deleteTask(taskId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.delete(this.BASE_URL + 'v2/TaskWork/' + taskId, httpOptions);
  }

  defaultSelect(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.get(this.BASE_URL + 'v2/TaskWork/defaultselect', httpOptions);
  }

  insertTaskByMask(taskId: string, taskWorkDocumentOperation: string, body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.post(this.BASE_URL + 'v2/TaskWork/' + taskId + '/documentsoperations/' + taskWorkDocumentOperation + '/bymask', body, httpOptions);
  }

// ********************************************************************************
// *** API SETTINGS ***
  setBetUserSetting(id: number): Observable<any> {
    const httpLogo: JSON = {
      clientId: this.CLIENT_ID,
      clientSecret: this.CLIENT_SECRET,
      userId: this.userInfo.user,
      styleSelected: id
    } as unknown as JSON;
    return this.http.post('https://api.betsoft-srl.it/setUserStyle', httpLogo);
  }

  getBetUserSetting(): Observable<any> {
    const httpLogo: JSON = {
      clientId: this.CLIENT_ID,
      clientSecret: this.CLIENT_SECRET,
      userId: this.userInfo.user,
    } as unknown as JSON;
    return this.http.post('https://api.betsoft-srl.it/getUserStyle', httpLogo);
  }

  updateBetUserSetting(styleId: number): Observable<any> {
    const httpLogo: JSON = {
      clientId: this.CLIENT_ID,
      clientSecret: this.CLIENT_SECRET,
      userId: this.userInfo.user,
      styleSelected: styleId
    } as unknown as JSON;
    return this.http.post('https://api.betsoft-srl.it/updateUserStyle', httpLogo);
  }

  saveLogo(image: string): Observable<any> {
    const httpLogo: JSON = {
      clientId: this.CLIENT_ID,
      clientSecret: this.CLIENT_SECRET,
      image
    } as unknown as JSON;
    return this.http.post('https://api.betsoft-srl.it/saveLogo', httpLogo);
  }

  getLogo(): Observable<any> {
    const body: JSON = {
      clientId: this.CLIENT_ID,
      clientSecret: this.CLIENT_SECRET,
    } as unknown as JSON;
    return this.http.post('https://api.betsoft-srl.it/getLogo', body);
  }

  getSettings(): Observable<any> {
    const body: JSON = {
      clientId: this.CLIENT_ID,
      clientSecret: this.CLIENT_SECRET,
    } as unknown as JSON;
    return this.http.post('https://api.betsoft-srl.it/getSettings', body);
  }

  // tslint:disable-next-line:typedef
  saveSettings(color: string): Observable<any> {
    const httpData: JSON = {
      clientId: this.CLIENT_ID,
      clientSecret: this.CLIENT_SECRET,
      color
    } as unknown as JSON;
    return this.http.post('https://api.betsoft-srl.it/saveSettings', httpData);
  }

  addSetting(dash: string, back: string, square: string, card: string, desc: string, select: number): Observable<any> {
    const body: JSON = {
      clientId: this.CLIENT_ID,
      clientSecret: this.CLIENT_SECRET,
      dash_color: dash,
      back_color: back,
      square_color: square,
      card_color: card,
      description: desc,
      selected: select
    } as unknown as JSON;
    return this.http.post('https://api.betsoft-srl.it/createSettings', body);
  }

  deleteSetting(themeId): Observable<any> {
    const body: JSON = {
      id: themeId
    } as unknown as JSON;
    return this.http.post('https://api.betsoft-srl.it/deleteSetting', body);
  }

  getTheme(): Observable<any> {
    const body: JSON = {
      clientId: this.CLIENT_ID,
      clientSecret: this.CLIENT_SECRET,
    } as unknown as JSON;
    return this.http.post('https://api.betsoft-srl.it/getTheme', body);
  }

  // *** FOLDERS ***
  getFolder(id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.get(this.BASE_URL + 'v2/Folders/' + id, httpOptions);
  }

  removeDocsFromFolder(folderId: string, docNumbers: Array<any>): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.put(this.BASE_URL + 'v2/Folders/' + folderId + '/documents/delete', docNumbers, httpOptions);
  }
  retrievePassword(userName: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'PasswordManager/RetrievePassword', {username: userName}, httpOptions);
  }
  getContainFolder(id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'folders/docnumber/' + id, httpOptions);
  }

  addProfileToFolder(folderId: string, ids: Array<any>): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.post(this.BASE_URL + 'folders/' + folderId + '/add', ids, httpOptions);
  }

  getChilds(parentId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'v2/Folders/parent/' + parentId, httpOptions);
  }


  addFolder(parentId: string, name: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.post(this.BASE_URL + 'v2/Folders/' + parentId + '/new?name=' + name, {}, httpOptions);
  }

  deleteFolder(id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.delete(this.BASE_URL + 'v2/Folders/' + id, httpOptions);
  }

  renameFolder(id: string, name: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.put(this.BASE_URL + 'v2/Folders/rename/' + id + '?name=' + name, null, httpOptions);
  }


  insertTaskByStandard(taskId: string, taskWorkDocumentOperation: string, body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.post(this.BASE_URL + 'v2/TaskWork/' + taskId + '/documentsoperations/' + taskWorkDocumentOperation + '/bystandard', body, httpOptions);
  }

  getTaskLayout(id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.get(this.BASE_URL + 'tasklayout/taskwork/' + id, httpOptions);
  }

  getDocsId(folderId: string): Observable<any> {
    const body: JSON = {
      fields: [
        {
          fieldType: 0,
          index: 1,
          name: 'NOTE',
          orderBy: {direction: 0, index: 0},
          selected: true
        },
        {
          fieldType: 0,
          index: 3,
          name: 'ALLEGATI',
          orderBy: {direction: 0, index: 0},
          selected: true
        },
        {
          fieldType: 0,
          index: 4,
          name: 'STATO',
          orderBy: {direction: 0, index: 0},
          selected: true
        },
        {
          fieldType: 0,
          index: 0,
          name: 'INOUT',
          orderBy: {direction: 0, index: 0},
          selected: true
        },
        {
          fieldType: 0,
          index: 2,
          name: 'WORKFLOW',
          orderBy: {direction: 0, index: 0},
          selected: true
        },
        {
          fieldType: 0,
          index: 5,
          name: 'FORMATO',
          orderBy: {direction: 1, index: 0},
          selected: true
        },
        {
          fieldType: 0,
          index: 10,
          name: 'DOCNAME',
          orderBy: {direction: 0, index: 0},
          selected: true
        },
        {
          fieldType: 0,
          index: 12,
          name: 'DATADOC',
          orderBy: {direction: 0, index: 0},
          selected: true
        },
        {
          fieldType: 0,
          index: 8,
          name: 'MITTENTE',
          orderBy: {direction: 0, index: 0},
          selected: true
        },
        {
          fieldType: 0,
          index: 9,
          name: 'DESTINATARIO',
          orderBy: {direction: 0, index: 0},
          selected: true
        },
        {
          fieldType: 0,
          index: 11,
          name: 'NUMERO',
          orderBy: {direction: 0, index: 0},
          selected: true
        },
        {
          fieldType: 0,
          index: 6,
          name: 'DOCNUMBER',
          orderBy: {direction: 0, index: 0},
          selected: true
        },
        {
          fieldType: 0,
          index: 7,
          name: 'REVISIONE',
          orderBy: {direction: 0, index: 0},
          selected: true
        },
        {
          fieldType: 0,
          index: 13,
          name: 'AOO',
          orderBy: {direction: 0, index: 0},
          selected: true
        },
        {
          fieldType: 0,
          index: 0,
          name: 'ORIGINALE',
          selected: true
        },
        {
          fieldType: 0,
          index: 0,
          name: 'CLASSEDOC',
          selected: true
        },
      ],
      maxItems: 0
    } as unknown as JSON;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.post(this.BASE_URL + 'v2/Folders/' + folderId + '/documents', body, httpOptions);
  }

  /*findFolder(name: string): Observable<any> {
      const httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
          })
      };

      return this.http.get(this.BASE_URL + 'v2/Folders/find' + name, httpOptions);
  }*/

  downloadDocument(idFolder: string): any {
    const httpOptions = {
      responseType: 'blob' as const,
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      }),
    };

    return this.http.get(this.BASE_URL + 'Documents/' + idFolder, httpOptions);
    /*  .pipe(map((result: HttpResponse<Blob>) => {
      // console.log(result);
      return result;
  }));*/
  }

  getDocsState(processId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.get(this.BASE_URL + 'ProcessInfo/' + processId + '/Document', httpOptions);
  }

// ********************************************************************************
  // *** SEARCHES ***
  getSearchFields(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.get(this.BASE_URL + 'v3/searches', httpOptions);
  }

  doSearch(body): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.post(this.BASE_URL + 'v3/searches', body, httpOptions);
  }

  additionalSearches(tipo1: string, tipo2: string, tipo3: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'searches/Additional/' + tipo1 + '/' + tipo2 + '/' + tipo3, httpOptions);
  }

// ********************************************************************************
  // *** UTILITY ***
  startEventScript(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }), responseType: 'json' as 'json'
    };
    const payload = new HttpParams()
      .set('token', localStorage.getItem('accessToken'));
    return this.http.post('http://192.168.71.118:45553/mscript/index.php', payload, httpOptions);
  }


  getStateIcon(iconIndex: number): string {
    let found = false;
    this.states.fields.forEach(v => {
      if (v.index === iconIndex) {
        found = true;
        console.log(v.icon);
        return v.icon;
      }
    });
    if (found === false) {
      return 'clear';
    }
  }

  getState(stateName: string): string {
    let state: string;
    this.allStates.forEach(s => {
      if (s.id === stateName) {
        this.states.fields.forEach(v => {
          if (v.index === s.iconIndex) {
            state = v.icon;
          }
        });
      }
    });
    return state;
  }

  exportCSV(data, filename = 'data', header): void {
    const csvData = this.ConvertToCSV(data, header);
    // console.log(csvData);
    const blob = new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {  // if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList): any {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No;';

    for (const index in headerList) {
      row += headerList[index] + ';';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (const index in headerList) {
        const head = headerList[index];

        line += ';' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

  TransformDataFormat(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');
  }

  putMenu(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.put(this.BASE_URL + 'Layout/0', body, httpOptions);
  }

  getLayoutMenu(userId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'Layout/User/' + userId + '/' + 2 + '/' + 1, httpOptions);
  }

  getLayoutCommands(userId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'Layout/User/' + userId + '/' + 2 + '/' + 2, httpOptions);
  }

  getUserInfo(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'Users/UserInfo', httpOptions);
  }

  getUsers(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.get(this.BASE_URL + 'users', httpOptions);
  }

  searchDocument(docType: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.post(this.BASE_URL + 'v3/searches', this.search.getBody(docType), httpOptions);
  }

  getProcIdByDocId(docId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.get(this.BASE_URL + 'Workflow/bydocnumber/' + docId + '/history', httpOptions);
  }

  taskExitSnack(): void {
    this.snackBar.open('Azione eseguita correttamente', null, {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['mat-toolbar', 'custom-success-bg']
    });
  }

  // *** INSERT FILE ***
  insertFileInCache(file: FormData): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken') + '',
      })
    };

    return this.http.post(this.BASE_URL + 'Cache/insert', file, httpOptions);
  }

  checkInOutFileTask(procDocId: string, taskWorkId: string, fileId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'v2/CheckInOut/checkInForTask/' + procDocId + '/' + taskWorkId + '/' + fileId + '/0', {}, httpOptions);
  }

  revisionTask(procDocId: string, taskWorkId: string, revision: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('accessToken') + '',
      })
    };
    return this.http.post(this.BASE_URL + 'Revisions/task/' + taskWorkId + '/' + procDocId + '/' + revision + '/0', {}, httpOptions);
  }

  loginIxFe(username: string, password: string): Observable<any> {
    // const ixfeurl = 'https://ixapidemo.arxivar.it/Auth/api/v1/account/token';
    const prod = 'https://ixapi.arxivar.it/Auth/api/v1/account/token';

    const body: JSON = {
      grant_type: 'password',
      username: '' + username,
      password: '' + password

    } as unknown as JSON;

    return this.http.post(prod, body);
  }

  getAoosDetails(token: string): Observable<any> {
    const ixfeurl = 'https://ixapi.arxivar.it/Invoice/api/v2.1/aoo/aoos';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Authorization': token
      })
    };
    return this.http.get(ixfeurl, httpOptions);
  }

  getSentInvoices(token: string, idAoo: string): Observable<any> {
    const ixfeurl = 'https://ixapi.arxivar.it/Invoice/api/v2.1/trasmissione/aoos/' + idAoo + '/fatturetrasmissione/ricerca';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Authorization': token
      })
    };
    const criteri: JSON = {} as unknown as JSON;
    const body: JSON = {
      criteri

    } as unknown as JSON;
    return this.http.post(ixfeurl, body, httpOptions);
  }

  getRicInvoices(token: string, idAoo: string): Observable<any> {
    const ixfeurl = 'https://ixapi.arxivar.it/Invoice/api/v2.1/ricezione/aoos/' + idAoo + '/fatturericezione/ricerca';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Authorization': token
      })
    };
    const criteri: JSON = {} as unknown as JSON;
    const body: JSON = {
      criteri

    } as unknown as JSON;
    return this.http.post(ixfeurl, body, httpOptions);
  }

  downloadPDF(token: string, idFatt: string, lottoIndex: string): Observable<any> {
    const ixfeurl = 'https://ixapi.arxivar.it/Invoice/api/v2.1/trasmissione/aoos/fatturetrasmissione/' + idFatt + '/' + lottoIndex + '/pdf';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        'X-Authorization': token
      }),
      responseType: 'blob' as 'json',
    };
    return this.http.get(ixfeurl, httpOptions);
  }

  downloadHTML(token: string, idFatt: string): Observable<any> {
    const ixfeurl = 'https://ixapi.arxivar.it/Invoice/api/v2.1/trasmissione/aoos/fatturetrasmissione/' + idFatt + '/html';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/html',
        'X-Authorization': token
      }),
      responseType: 'blob' as 'json',
    };
    return this.http.get(ixfeurl, httpOptions);
  }

  downloadFILE(token: string, idFatt: string): Observable<any> {
    const ixfeurl = 'https://ixapi.arxivar.it/Invoice/api/v2.1/trasmissione/aoos/fatturetrasmissione/' + idFatt + '/file';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        'X-Authorization': token
      }),
      responseType: 'blob' as 'json',
    };
    return this.http.get(ixfeurl, httpOptions);
  }

  downloadPDFRic(token: string, idFatt: string, lottoIndex: string): Observable<any> {
    const ixfeurl = 'https://ixapi.arxivar.it/Invoice/api/v2.1/ricezione/aoos/fatturericezione/' + idFatt + '/' + lottoIndex + '/pdf';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        'X-Authorization': token
      }),
      responseType: 'blob' as 'json',
    };
    return this.http.get(ixfeurl, httpOptions);
  }

  downloadHTMLRic(token: string, idFatt: string): Observable<any> {
    const ixfeurl = 'https://ixapi.arxivar.it/Invoice/api/v2.1/ricezione/aoos/fatturericezione/' + idFatt + '/htmlfile';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/html',
        'X-Authorization': token
      }),
      responseType: 'blob' as 'json',
    };
    return this.http.get(ixfeurl, httpOptions);
  }

  downloadFILERic(token: string, idFatt: string): Observable<any> {
    const ixfeurl = 'https://ixapi.arxivar.it/Invoice/api/v2.1/ricezione/aoos/fatturericezione/' + idFatt + '/file';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        'X-Authorization': token
      }),
      responseType: 'blob' as 'json',
    };
    return this.http.get(ixfeurl, httpOptions);
  }

  getRicNotifications(token: string, idFatt: string): Observable<any> {
    const ixfeurl = 'https://ixapi.arxivar.it/Invoice/api/v2.1/ricezione/aoos/fatturericezione/' + idFatt + '/notifiche';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Authorization': token
      })
    };
    return this.http.get(ixfeurl, httpOptions);
  }

  getSentNotifications(token: string, idFatt: string): Observable<any> {
    const ixfeurl = 'https://ixapi.arxivar.it/Invoice/api/v2.1/trasmissione/aoos/fatturetrasmissione/' + idFatt + '/notifiche/ricerca';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Authorization': token
      })
    };
    const criter: JSON = {} as unknown as JSON;
    const body: JSON = {
      criteri: criter
    } as unknown as JSON;
    return this.http.post(ixfeurl, body, httpOptions);
  }

  /*openBuffer(file: FormData): Observable<any> {
      const httpOptions = {
          headers: new HttpHeaders({
              Authorization: 'Bearer ' + localStorage.getItem('accessToken') + '',
          })
      };
      return this.http.post(this.BASE_URL + 'Buffer/insert', file, httpOptions);
  }*/


  // -------------------DOCUSIGN-----------------------

  docusignGetUserInfo(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'bearer ' + localStorage.getItem('access_token') + '',
      })
    };
    return this.http.get(this.currentURL + '/oauth/userinfo', httpOptions);
  }

  docusignGetAccessToken(): Observable<any> {
    const code = btoa(this.IKEY + ':' + this.SKEY);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic ' + code,
        'Access-Control-Allow-Origin': '*'
      }), responseType: 'json' as 'json'
    };
    const payload = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('code', localStorage.getItem('authcode'));
    return this.http.post(this.currentURL + '/oauth/token', payload, httpOptions);
  }

  docusignSendEnvelope(file: string, fileName: string, fileExt: string, signerEmail: string, signerName: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('access_token') + '',
      })
    };
    return this.http.post(this.currentURL + '/restapi/v2.1/accounts/' + this.DocuSignAccountId + '/envelopes', this.docusignCreateEnvelope(file, fileName, fileExt, signerEmail, signerName), httpOptions);
  }

  docusignCreateEnvelope(file: string, fileName: string, fileExt: string, signerEmail: string, signerName: string): any {
    const bod: JSON = {
      emailSubject: 'Nuovo documento da firmare',
      documents: [
        {
          documentBase64: file,
          name: fileName,
          fileExtension: fileExt,
          documentId: '1'
        }],
      recipients: {
        signers: [
          {
            email: signerEmail,
            name: signerName,
            recipientId: '1',
            routingOrder: '1',
            tabs: {
              signHereTabs: [
                {
                  anchorString: '**signature_1**',
                  anchorUnits: 'pixels',
                  anchorXOffset: '20',
                  anchorYOffset: '10'
                },
                {
                  anchorString: '/sn1/',
                  anchorUnits: 'pixels',
                  anchorXOffset: '20',
                  anchorYOffset: '10'
                }
              ]
            }
          }
        ]

      },
      status: 'sent'
    } as unknown as JSON;
    return bod;
  }

  // -------------------GOOGLE-----------------------
  googleGetAccessToken(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }), responseType: 'json' as 'json'
    };
    const payload = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('code', localStorage.getItem('authcodegoogle'))
      .set('client_id', this.googleApiId)
      .set('client_secret', this.googleApiSecret)
      .set('redirect_uri', this.googleURI);
    return this.http.post('https://www.googleapis.com/oauth2/v4/token', payload, httpOptions);
  }

  googleGetUserInfo(): Observable<any> {
    console.log(localStorage.getItem('googleToken'));
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('googleToken').toString() + '',
      })
    };
    return this.http.get('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', httpOptions);
  }
}
