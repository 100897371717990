<div style="width: 100%">
    <button style="float: right;" title="{{'labels.close' | translate}}" (click)="closeDialog()" mat-icon-button color="warn"
            aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div style="width: 100%; display: flex">
    <h2 style="text-align: center; width: 100%;">{{'labels.create' | translate}} {{'labels.category' | translate}} in<br> '{{this.name}}'</h2>
</div>
<div style="text-align: center; width: 100%">
    <mat-form-field>
        <input [(ngModel)]="this.newCat" matInput name="Nome"
               placeholder="{{'labels.name' | translate}} {{'labels.category' | translate}}"
        >
    </mat-form-field>
    <br><br>
    <hr>
    <br>
    <button mat-stroked-button (click)="create()">{{'labels.create' | translate}}</button>
</div>
