
<div style = "height: 15%">
<button style="float: right;" title="{{'labels.close' | translate}}" (click)="closeDialog()" mat-icon-button color="warn"
                                        aria-label="icon-button save">
  <mat-icon>close</mat-icon>
</button></div>
<div style = "text-align: center">
    <button *ngIf="badgeBufferMain >0" [matMenuTriggerFor]="menuFile"
            aria-label="{{'labels.seeFile' | translate}}"
            mat-icon-button>
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menuFile="matMenu" style = "height: fit-content">
        <ng-container *ngFor="let file of bufferIdInfo">
            <button mat-menu-item (click)="removeFile(file)" style ="line-height:65px;
                    height:65px;" >
                <mat-icon>close</mat-icon>
              <span>{{file.name}}</span>
            </button>
        </ng-container>
    </mat-menu>
    <button
            title="{{'labels.uploadFile' | translate}}"
            (click)="fileInput.click()"
            mat-button>
        <mat-icon [matBadge]="badgeBufferMain">cloud_upload</mat-icon><h2>{{'labels.uploadFile' | translate}}</h2>
    </button>
    <input type="file" (change)="showFileSelection($event)" hidden #fileInput id="fileUpload" name="fileUpload"
           multiple="multiple" accept="*"/>
    <br>
    <mat-form-field appearance="fill" style="width: 90%;" *ngIf = "!this.isTask">
        <mat-label>{{'labels.comment' | translate}}:</mat-label>
        <textarea  matInput rows="5" [(ngModel)]="this.commento"></textarea>
    </mat-form-field>
    <br>
    <hr>
    <br>
    <button mat-stroked-button (click) = send()>{{'labels.send' | translate}}</button>
</div>
